export const blocks = [
  {
    icon: 'Euro',
    text: 'modalMandateSignIn.shortBlock.1.text',
    title: 'modalMandateSignIn.shortBlock.1.title',
  },
  {
    icon: 'Handshake',
    text: 'modalMandateSignIn.shortBlock.2.text',
    title: 'modalMandateSignIn.shortBlock.2.title',
  },
  {
    icon: 'Masteos',
    text: 'modalMandateSignIn.shortBlock.3.text',
    title: 'modalMandateSignIn.shortBlock.3.title',
  },
];

export const COLUMN_NUMBER_DESKTOP = 3;
export const COLUMN_NUMBER_MOBILE = 1;
