import React, { useCallback, useEffect, useState } from 'react';

import { Platform, View } from 'react-native';

import { useContainerQueriesHook, useResponsive } from '@masteos/aphrodite';

import { SearchEngineHeader } from '@app/features/search-engine/compounds/search-engine-header/SearchEngineHeader';
import {
  SearchEngineLocationItem,
  SearchEngineSection,
  SearchEngineSource,
  SearchEngineType,
} from '@app/features/search-engine/searchEngine.types';
import { UniversalDrawer } from '@app/shared/components/UniversalDrawer/UniversalDrawer';
import { PlatformEnum } from '@app/types/platform';
import { SearchEngineList } from '@app/features/search-engine/compounds/search-engine-list/SearchEngineList';
import { getStyle } from '@app/features/search-engine/searchEngine.styles';
import { SearchEngineInTransaction } from '@app/features/search-engine/compounds/search-engine-in-transaction/SearchEngineInTransaction';
import { StateOfCustomer } from '@app/shared/hooks/useMe';
import { RealEstateItem } from '@app/shared/components/real-estate-card/realEstateCard.type';

import { SearchEngineFiltersDrawer } from './compounds/search-engine-filters/search-engine-filters-drawer/SearchEngineFiltersDrawer';
import { SearchEngineFiltersHorizontal } from './compounds/search-engine-filters/search-engine-filters-horizontal/SearchEngineFiltersHorizontal';

const query = {
  noHorizontalFilters: 856,
};

export const SearchEngineTemplate: React.FC<SearchEngineType> = ({
  list,
  header,
  state,
  filter,
  resetForm: clearForm,
  hasMore,
  position,
  listLoadingIsRefresh,
  defaultThreshold,
  countryList,
  regionListByCountry,
  listLoading,
  filterLoading,
  onFilterReset,
  onFilterShow,
  onFilterUpdate,
  onFilterCountryClick,
  onInformationCardPress,
  onItemBookmark,
  onItemClick,
  onItemTooltipHover,
  onLoadMoreRealEstates,
  form,
}) => {
  const { isMobile, isLowerThanTablet } = useResponsive();
  const [showDrawer, setShowDrawer] = useState(false);
  const styles = getStyle({ isLowerThanTablet, isMobile });
  const {
    matches: { noHorizontalFilters },
    onLayout,
  } = useContainerQueriesHook({ query });

  const handleFilterShow = useCallback(
    source => () => {
      if (Platform.OS === PlatformEnum.Web) {
        document.body.style.overflow = 'hidden';
      }
      setShowDrawer(true);
      onFilterShow(source);
    },
    [onFilterShow]
  );

  const handleFilterHide = useCallback(
    (clear = false) => {
      if (filterLoading) {
        return;
      }

      clear && clearForm();
      if (Platform.OS === PlatformEnum.Web) {
        document.body.style.overflow = 'auto';
      }
      setShowDrawer(false);
    },
    [filterLoading, clearForm]
  );

  const handleItemClick = useCallback(
    (item: RealEstateItem, info: SearchEngineLocationItem) =>
      Promise.resolve(onItemClick(item, info)),
    [onItemClick]
  );

  const handleItemBookmark = useCallback(
    (item: RealEstateItem) => Promise.resolve(onItemBookmark(item)),
    [onItemBookmark]
  );

  const handleInformationCardPress = useCallback(
    (key?: number, type?: string) => Promise.resolve(onInformationCardPress(key, type)),
    [onInformationCardPress]
  );

  // We enable back the scrolling if the component is unmounted while filter is open
  useEffect(
    () => () => {
      if (Platform.OS === PlatformEnum.Web && document.body.style.overflow === 'hidden') {
        document.body.style.overflow = 'auto';
      }
    },
    []
  );

  return (
    <View style={styles.container}>
      <View style={styles.headerContainer} onLayout={onLayout}>
        <SearchEngineHeader
          section={header.section}
          state={state}
          loading={listLoading}
          resultCount={header.resultCount}
          bookmarkedCount={header.bookmarkedCount}
          filterCount={header.filterCount}
          onSectionChange={header.onSectionChange}
          onFilterShow={handleFilterShow(SearchEngineSource.DRAWER)}
          hideFilterButton={!noHorizontalFilters}
          onSubmit={onFilterUpdate}
          form={form}
          {...filter}
        />
        {!noHorizontalFilters && header.section !== SearchEngineSection.BOOKMARKED ? (
          <SearchEngineFiltersHorizontal
            onFilterShow={handleFilterShow(SearchEngineSource.DRAWER)}
            form={form}
            {...filter}
            state={state}
            countryList={countryList}
            regionListByCountry={regionListByCountry}
            loading={filterLoading}
            filterCount={header.filterCount}
            onSubmit={onFilterUpdate}
            onCountryClick={onFilterCountryClick}
          />
        ) : null}
      </View>

      {state === StateOfCustomer.IN_TRANSACTION ? (
        <SearchEngineInTransaction onPressInformationCard={handleInformationCardPress} />
      ) : (
        <>
          <View style={styles.bodyContainer}>
            <SearchEngineList
              list={list}
              hasMore={hasMore}
              section={header.section}
              position={position}
              defaultThreshold={defaultThreshold}
              loading={listLoading}
              loadingIsRefresh={listLoadingIsRefresh}
              onInformationCardPress={handleInformationCardPress}
              onItemBookmark={handleItemBookmark}
              onItemClick={handleItemClick}
              onItemTooltipHover={onItemTooltipHover}
              onFilterShow={handleFilterShow(SearchEngineSource.NO_RESULT)}
              onFilterReset={() => onFilterReset(SearchEngineSource.NO_RESULT)}
              onLoadMoreRealEstates={onLoadMoreRealEstates}
            />
          </View>

          <UniversalDrawer
            isOpen={showDrawer}
            direction="right"
            showCloseBtn={true}
            onClickCrossBtn={() => handleFilterHide(true)}
            onClickBackdrop={() => handleFilterHide(true)}
          >
            {!!showDrawer && (
              <View style={styles.drawerContainer}>
                <SearchEngineFiltersDrawer
                  {...filter}
                  form={form}
                  state={state}
                  countryList={countryList}
                  regionListByCountry={regionListByCountry}
                  loading={filterLoading}
                  onSubmit={onFilterUpdate}
                  onSubmitError={console.error}
                  handleFilterHide={handleFilterHide}
                  onReset={onFilterReset}
                  onCountryClick={onFilterCountryClick}
                />
              </View>
            )}
          </UniversalDrawer>
        </>
      )}
    </View>
  );
};
