import React, { memo, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigation, getFocusedRouteNameFromRoute } from '@react-navigation/native';
import { View } from 'react-native';

import { BottomBarMenu, IconName, SidebarItem, useTheme } from '@masteos/aphrodite';

import { useMe } from '@app/shared/hooks/useMe';
import { MainMenuNavigatorRoutes } from '@app/navigation/types/routes';
import { useCurrentRoute } from '@app/navigation/helpers/useCurrentRoutes';

import { getStyles } from './navigation.styles.web';
import { MobileNavigationNavigationProp } from './navigation.types';
import { DOM_BODY_CONTAINER_ID } from './navigation.constants';
import { filterNavigationLinks } from '../../navigation/utils/filterNavigationLinks.utils';

export const MobileNavigation: React.FC<{ children?: React.ReactNode }> = memo(({ children }) => {
  const navigation = useNavigation<MobileNavigationNavigationProp>();
  const currentStack = useCurrentRoute();
  const routeName = getFocusedRouteNameFromRoute(currentStack);
  const theme = useTheme();

  const styles = useMemo(() => getStyles(theme), [theme]);

  const { t } = useTranslation();
  const { customer } = useMe();

  const redirectPage = (path: MainMenuNavigatorRoutes) => () => {
    navigation.reset({
      index: 0,
      routes: [{ name: path }],
    });
  };

  const sideBarItemsProps = useMemo(
    () =>
      filterNavigationLinks(
        [
          {
            iconName: 'New' as IconName,
            isActive: routeName === MainMenuNavigatorRoutes.Suggestions,
            isMobile: true,
            key: MainMenuNavigatorRoutes.Suggestions,
            label: t('navigation.topBar.mySuggestions'),
            onPress: redirectPage(MainMenuNavigatorRoutes.Suggestions),
            testID: 'suggestion-page',
          },
          {
            iconName: 'SearchProperty' as IconName,
            isActive: routeName === MainMenuNavigatorRoutes.RealEstates,
            isMobile: true,
            key: MainMenuNavigatorRoutes.RealEstates,
            label: t('navigation.search'),
            onPress: redirectPage(MainMenuNavigatorRoutes.RealEstates),
            testID: 'button-search-page',
          },
          {
            iconName: 'Target' as IconName,
            isActive: routeName === MainMenuNavigatorRoutes.Projects,
            isMobile: true,
            key: MainMenuNavigatorRoutes.Projects,
            label: t('navigation.projects'),
            onPress: redirectPage(MainMenuNavigatorRoutes.Projects),
            testID: 'button-projects-page',
          },
          {
            iconName: 'Phone' as IconName,
            isActive: routeName === MainMenuNavigatorRoutes.Advisor,
            isMobile: true,
            key: MainMenuNavigatorRoutes.Advisor,
            label: t('shared.advisorLabel'),
            onPress: redirectPage(MainMenuNavigatorRoutes.Advisor),
            testID: 'button-advisor-page',
          },
          {
            iconName: 'User' as IconName,
            isActive: routeName === MainMenuNavigatorRoutes.Profile,
            isMobile: true,
            key: MainMenuNavigatorRoutes.Profile,
            label: t('navigation.profileNav'),
            onPress: redirectPage(MainMenuNavigatorRoutes.Profile),
            testID: 'navbar-profile-link-button',
          },
        ],
        customer
      ),
    [routeName, redirectPage, t, customer]
  );

  return (
    <View style={styles.container} testID="mobile-navigation">
      <View style={styles.body} testID="mobile-navigation-body" nativeID={DOM_BODY_CONTAINER_ID}>
        {children}
      </View>

      <BottomBarMenu.MenuWrapper wrapperStyle={styles.mobileMenu} testID="properties-mobile-navbar">
        {sideBarItemsProps.map(({ key, ...rest }) => (
          <SidebarItem {...rest} key={key} />
        ))}
      </BottomBarMenu.MenuWrapper>
    </View>
  );
});

MobileNavigation.displayName = 'MobileNavigation';
