import React from 'react';

import { View, StyleSheet } from 'react-native';

import { DataBlock, Spacing, useResponsive, IconPopoverOrModal } from '@masteos/aphrodite';
import { FinancialModel } from '@masteos/agora';

import { currencyFormatter } from '@app/utils/currency-formatter';
import { useRealEstateTranslation } from '@app/shared/hooks/useRealEstateTranslations';
import { trackTooltip } from '@app/services/tracking/trackTracking';

import { areEqual, differentFuncParameters } from './PropertyFinances.utils';

const getStyles = (isLowerThanDesktop: boolean) =>
  StyleSheet.create({
    container: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: '100%',
    },
    evenElement: {
      marginBottom: Spacing.SIZE_06,
      marginRight: isLowerThanDesktop ? Spacing.NONE : Spacing.SIZE_04,
      width: isLowerThanDesktop ? '100%' : `calc(50% - ${Spacing.SIZE_04}px)`,
    },
    oddElement: {
      marginBottom: Spacing.SIZE_06,
      marginLeft: isLowerThanDesktop ? Spacing.NONE : Spacing.SIZE_04,
      width: isLowerThanDesktop ? '100%' : `calc(50% - ${Spacing.SIZE_04}px)`,
    },
  });

interface PropertyFinancialSummaryProps {
  financialModel: FinancialModel;
  annualRentIncome: number;
  propertyId: string;
}

export const PropertyFinancialSummary: React.FC<PropertyFinancialSummaryProps> = React.memo(
  props => {
    const { financialModel, annualRentIncome, propertyId } = props;
    const { t } = useRealEstateTranslation();
    const { isLowerThanDesktop } = useResponsive();
    const formattedProjectAmount = currencyFormatter(financialModel.projectAmount, 0);
    const formattedTotalLoanAmount = currencyFormatter(financialModel.totalLoan, 0);
    const formattedLoanMonthlyAmount = currencyFormatter(financialModel.loanMonthlyAmount, 0);
    const formattedAnnualRentIncome = currencyFormatter(annualRentIncome / 12, 0);
    const styles = getStyles(isLowerThanDesktop);
    const trackTooltipProjectCost = () =>
      trackTooltip({ propertyId, tooltipType: 'Coût du projet' });
    const trackTooltipTotalLoanAmount = () =>
      trackTooltip({ propertyId, tooltipType: 'Capital emprunté' });

    return (
      <View style={styles.container}>
        <DataBlock
          style={styles.evenElement}
          title={t('shared.financialTool.projectCost')}
          data={formattedProjectAmount}
          hint={
            <IconPopoverOrModal
              title={t('shared.financialTool.projectCost')}
              content={t('shared.financialTool.tooltip.projectAmountDescription')}
              iconName="Info"
              onOpen={trackTooltipProjectCost}
              iconSize="s"
            />
          }
        />

        <DataBlock
          style={styles.oddElement}
          title={t('shared.financialTool.totalLoanAmount')}
          data={formattedTotalLoanAmount}
          hint={
            <IconPopoverOrModal
              title={t('shared.financialTool.totalLoanAmount')}
              content={t('shared.financialTool.tooltip.totalLoanAmountDescription')}
              iconName="Info"
              onOpen={trackTooltipTotalLoanAmount}
              iconSize="s"
            />
          }
        />

        <DataBlock
          style={styles.evenElement}
          title={t('shared.financialTool.monthlyRentIncomeNoCharges')}
          data={formattedAnnualRentIncome}
        />

        <DataBlock
          style={styles.oddElement}
          title={t('shared.financialTool.loanMonthlyAmount')}
          data={formattedLoanMonthlyAmount}
        />
      </View>
    );
  },
  areEqual<PropertyFinancialSummaryProps>(
    ['annualRentIncome'],
    (prevState: PropertyFinancialSummaryProps, nextState: PropertyFinancialSummaryProps) => {
      return differentFuncParameters(prevState, nextState, [
        'projectAmount',
        'totalLoan',
        'loanMonthlyAmount',
      ]);
    }
  )
);

PropertyFinancialSummary.displayName = 'PropertyFinancialSummary';
