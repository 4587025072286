import React, { PropsWithChildren, useContext, useMemo } from 'react';

import { Platform, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { MainNavigation } from '@app/features/navigation/MainNavigation.web';
import { PlatformEnum } from '@app/types/platform';
import { BannerContext } from '@app/shared/contexts/banner-manager/BannerManagerProvider';
import { BannerDisplayer } from '@app/shared/contexts/banner-manager/BannerDisplayer';

import { getStyles } from './MainLayout.styles';

export const MainLayout: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const { top } = useSafeAreaInsets();
  const isWeb = Platform.OS === PlatformEnum.Web;

  const { isDisplayed: isBannerDisplayed } = useContext(BannerContext);

  const styles = useMemo(() => getStyles({ topOffset: top }), [top]);

  return (
    <>
      <BannerDisplayer />

      {isWeb ? (
        <View style={styles.container} testID="web-container">
          <MainNavigation>{children}</MainNavigation>
        </View>
      ) : (
        <>
          {!!isBannerDisplayed && <View style={styles.componentOffset} testID="offset-mobile" />}
          {children}
        </>
      )}
    </>
  );
};
