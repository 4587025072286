import { useEffect } from 'react';

import { RealEstate, useViewRealEstateMutation } from '@app/libs/apollo/introspection';

import { useMe } from './useMe';

export const useViewRealEstate = (realEstate?: RealEstate): void => {
  const { customer } = useMe();

  const [viewRealEstate] = useViewRealEstateMutation();

  useEffect(() => {
    const metadata = realEstate?.metadata;
    if (!!customer?.id && metadata && !metadata.isViewed) {
      viewRealEstate({ variables: { realEstateId: realEstate.id } });
    }
  }, [customer, realEstate]);
};
