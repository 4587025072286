import { StyleSheet } from 'react-native';

import { Spacing } from '@masteos/aphrodite';

export const styles = StyleSheet.create({
  dataBlock: {
    height: 171,
  },
  dataFieldWrapper: {
    flexDirection: 'row',
    flexWrap: 'wrap',

    width: '100%',
  },
  wrapper: {
    maxWidth: '680px',
    paddingVertical: Spacing.SIZE_05,
    width: '100%',
  },
});
