import React from 'react';

import { StyleSheet, View } from 'react-native';

import { DataBlock, Spacing, useResponsive, IconPopoverOrModal } from '@masteos/aphrodite';
import { FinancialModel } from '@masteos/agora';

import { currencyFormatter } from '@app/utils/currency-formatter';
import { useFeatureFlags } from '@app/shared/hooks/useFeatureFlag';
import { useRealEstateTranslation } from '@app/shared/hooks/useRealEstateTranslations';
import { FeatureFlags } from '@app/constants/feature-flags';
import { trackTooltip } from '@app/services/tracking/trackTracking';

import { areEqual, differentFuncParameters } from './PropertyFinances.utils';

const DATA_BLOCK_SIZE = 470;

const getStyles = (isLowerThanDesktop: boolean) =>
  StyleSheet.create({
    container: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: '100%',
    },
    evenElement: {
      marginBottom: Spacing.SIZE_06,
      marginRight: isLowerThanDesktop ? Spacing.NONE : Spacing.SIZE_04,
      maxWidth: isLowerThanDesktop ? '100%' : `calc(50% - ${Spacing.SIZE_04}px)`,
      width: isLowerThanDesktop ? '100%' : DATA_BLOCK_SIZE,
    },
    oddElement: {
      marginBottom: Spacing.SIZE_06,
      marginLeft: isLowerThanDesktop ? Spacing.NONE : Spacing.SIZE_04,
      maxWidth: isLowerThanDesktop ? '100%' : `calc(50% - ${Spacing.SIZE_04}px)`,
      width: isLowerThanDesktop ? '100%' : DATA_BLOCK_SIZE,
    },
  });

interface PropertyFinancialAnalysisSummaryProps {
  financialModel: FinancialModel;
  financialModelVersion: number;
  propertyId: string;
}

export const PropertyFinancialAnalysisSummary: React.FC<PropertyFinancialAnalysisSummaryProps> =
  React.memo(
    props => {
      const { financialModel, financialModelVersion, propertyId } = props;
      const { isLowerThanDesktop } = useResponsive();
      const { t } = useRealEstateTranslation();
      const formattedGrossReturn = financialModel.grossReturn + '%';
      const formattedNetReturn = financialModel.netReturn + '%';
      const formattedMonthlyCashflow = currencyFormatter(financialModel.monthlyCashflow, 0);
      const formattedMonthlyEnrichment = currencyFormatter(financialModel.monthlyEnrichment, 0);
      const styles = getStyles(isLowerThanDesktop);
      const { isEnabled } = useFeatureFlags();
      const isMonthlyEnrichmentFlagEnabled = isEnabled(FeatureFlags.monthlyEnrichment);
      const trackTooltipNetReturn = () =>
        trackTooltip({ propertyId, tooltipType: 'Rendement net' });
      const trackTooltipMonthlyEnrichment = () =>
        trackTooltip({ propertyId, tooltipType: 'Enrichissement mensuel moyen' });
      const trackTooltipMonthlyCashflow = () =>
        trackTooltip({ propertyId, tooltipType: "Effort d'épargne mensuel" });
      const trackTooltipGrossReturn = () =>
        trackTooltip({ propertyId, tooltipType: 'Rendement brut' });

      return (
        <View style={styles.container}>
          <DataBlock
            style={styles.evenElement}
            title={t('shared.financialTool.grossReturn')}
            data={formattedGrossReturn}
            hint={
              <IconPopoverOrModal
                onOpen={trackTooltipGrossReturn}
                title={t('shared.financialTool.grossReturn')}
                content={t('shared.financialTool.tooltip.grossReturnDescription', {
                  context: `v${financialModelVersion}`,
                })}
                iconName="Info"
                iconSize="s"
              />
            }
          />

          <DataBlock
            style={styles.oddElement}
            title={t('shared.financialTool.netReturn')}
            data={formattedNetReturn}
            hint={
              <IconPopoverOrModal
                title={t('shared.financialTool.netReturn')}
                content={t('shared.financialTool.tooltip.netReturnDescription', {
                  context: `v${financialModelVersion}`,
                })}
                onOpen={trackTooltipNetReturn}
                iconName="Info"
                iconSize="s"
              />
            }
          />

          <DataBlock
            style={styles.evenElement}
            title={
              financialModel.monthlyCashflow < 0
                ? t('shared.financialTool.negativeMonthlyCashflowLabel')
                : t('shared.financialTool.positiveMonthlyCashflowLabel')
            }
            data={formattedMonthlyCashflow}
            hint={
              <IconPopoverOrModal
                title={
                  financialModel.monthlyCashflow < 0
                    ? t('shared.financialTool.negativeMonthlyCashflowLabel')
                    : t('shared.financialTool.positiveMonthlyCashflowLabel')
                }
                onOpen={trackTooltipMonthlyCashflow}
                content={t('shared.financialTool.tooltip.monthlyCashflowDescription')}
                iconName="Info"
                iconSize="s"
              />
            }
          />

          {!!isMonthlyEnrichmentFlagEnabled && (
            <DataBlock
              style={styles.oddElement}
              title={t('shared.financialTool.monthlyEnrichment')}
              data={formattedMonthlyEnrichment}
              hint={
                <IconPopoverOrModal
                  title={t('shared.financialTool.monthlyEnrichment')}
                  content={
                    financialModel.resellingCumulativeCashFlow < 0
                      ? t('shared.financialTool.tooltip.negativeMonthlyEnrichmentDescription')
                      : t('shared.financialTool.tooltip.positiveMonthlyEnrichmentDescription')
                  }
                  iconName="Info"
                  iconSize="s"
                  onOpen={trackTooltipMonthlyEnrichment}
                />
              }
            />
          )}
        </View>
      );
    },
    areEqual<PropertyFinancialAnalysisSummaryProps>(
      [],
      (
        prevState: PropertyFinancialAnalysisSummaryProps,
        nextState: PropertyFinancialAnalysisSummaryProps
      ) => {
        return differentFuncParameters(prevState, nextState, [
          'grossReturn',
          'netReturn',
          'monthlyCashflow',
          'monthlyEnrichment',
          'resellingCumulativeCashFlow',
        ]);
      }
    )
  );

PropertyFinancialAnalysisSummary.displayName = 'PropertyFinancialAnalysisSummary';
