import React, { PropsWithChildren, useEffect } from 'react';

import { ApolloError } from '@apollo/client';

import { GetFeatureFlagsQuery, useGetFeatureFlagsQuery } from '@app/libs/apollo/introspection';
import { getLastVersion } from '@app/utils/getLastWebVersion';
import { FeatureFlags } from '@app/constants/feature-flags';
import { useMe } from '@app/shared/hooks/useMe';

export type FeatureFlagsContextType = {
  data?: GetFeatureFlagsQuery;
  error?: ApolloError;
  loading?: boolean;
  refetch?: () => void;
  isEnabled: (flagName: FeatureFlags) => boolean;
};
export const FeatureFlagsContext = React.createContext<FeatureFlagsContextType>({
  isEnabled: () => false,
});

export const FeatureFlagsProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { data, error, loading, refetch } = useGetFeatureFlagsQuery({
    notifyOnNetworkStatusChange: true,
    variables: { nativeReleaseVersion: getLastVersion() },
  });
  const { customer } = useMe();

  const isEnabled = (flagName: FeatureFlags) => {
    return !!data?.featureFlags.find(item => item.name === flagName);
  };

  useEffect(() => {
    if (customer) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  return (
    <FeatureFlagsContext.Provider value={{ data, error, isEnabled, loading, refetch }}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};
