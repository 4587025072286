import React from 'react';

import { Dpe } from '@masteos/agora';
import { BottomSheet, PerformanceIndicator } from '@masteos/aphrodite';

import { useTranslation } from '@app/services/translations/translations';

interface PropertyRenovationDPEModalProps {
  value: string;
  isVisibleDPEModal: boolean;
  setIsVisibleDPEModal: () => void;
}

export const PropertyRenovationDPEModal: React.FC<PropertyRenovationDPEModalProps> = ({
  value,
  isVisibleDPEModal,
  setIsVisibleDPEModal,
}) => {
  const { t } = useTranslation();
  const data = [
    {
      color: '#01C249',
      description: t('shared.performanceIndicator.DPE.aDescription'),
      label: t('shared.performanceIndicator.DPE.aLabel'),
      value: Dpe.A,
    },
    {
      color: '#7BC201',
      description: t('shared.performanceIndicator.DPE.bDescription'),
      label: t('shared.performanceIndicator.DPE.bLabel'),
      value: Dpe.B,
    },

    {
      color: '#BBC201',
      description: t('shared.performanceIndicator.DPE.cDescription'),
      label: t('shared.performanceIndicator.DPE.cLabel'),
      value: Dpe.C,
    },
    {
      color: '#FCCD26',
      description: t('shared.performanceIndicator.DPE.dDescription'),
      label: t('shared.performanceIndicator.DPE.dLabel'),
      value: Dpe.D,
    },
    {
      color: '#FCA626',
      description: t('shared.performanceIndicator.DPE.eDescription'),
      label: t('shared.performanceIndicator.DPE.eLabel'),
      value: Dpe.E,
    },
    {
      color: '#F86800',
      description: t('shared.performanceIndicator.DPE.fDescription'),
      label: t('shared.performanceIndicator.DPE.fLabel'),
      value: Dpe.F,
    },
    {
      color: '#E30C1C',
      description: t('shared.performanceIndicator.DPE.gDescription'),
      label: t('shared.performanceIndicator.DPE.gLabel'),
      value: Dpe.G,
    },
  ];

  return (
    <BottomSheet visible={isVisibleDPEModal} onPressClose={setIsVisibleDPEModal}>
      <PerformanceIndicator
        title={t('shared.performanceIndicator.DPE.title')}
        caption={t('shared.performanceIndicator.DPE.caption')}
        labelLowest={t('shared.performanceIndicator.DPE.labelLowest')}
        labelHighest={t('shared.performanceIndicator.DPE.labelHighest')}
        value={value}
        data={data}
      />
    </BottomSheet>
  );
};
