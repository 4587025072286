import { Platform, StyleProp, StyleSheet, ViewStyle } from 'react-native';

import { Theme } from '@masteos/aphrodite';

export const getStyles = (theme: Theme): Record<string, StyleProp<ViewStyle>> =>
  StyleSheet.create({
    content: {
      backgroundColor: theme.palette.base.white,
      flex: 1,
      paddingBottom: Platform.select({ android: 32 }),
      paddingHorizontal: 20,
    },
    header: { alignItems: 'flex-end' },
    iconWrapper: Platform.select({
      android: { padding: 12 },
      ios: { paddingBottom: 12, paddingLeft: 12 },
    }),
  });
