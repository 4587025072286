import React, { useState } from 'react';

import { GalleryProps } from './Gallery.type';
import { ControlledSwiperModal } from '../../SwiperModal/ControlledSwiperModal.web';

export const Gallery: React.FC<GalleryProps> = ({
  isOpen,
  onClose,
  images,
  description,
  title,
  propertyId,
}) => {
  const [slideIndex, setSlideIndex] = useState(0);

  return (
    <ControlledSwiperModal
      propertyId={propertyId}
      isOpen={isOpen}
      closeModalImage={onClose}
      currentSlideIndex={slideIndex}
      setCurrentSlideIndex={setSlideIndex}
      swiperTitle={title}
      imageList={images.map(image => ({ description, url: image }))}
    />
  );
};
