import React, { useEffect, useState } from 'react';

import { View, ScrollView, TextStyle, Platform } from 'react-native';

import {
  Spacing,
  Spacer,
  useResponsive,
  HeaderPage,
  TextWithIcon,
  palette,
} from '@masteos/aphrodite';

import { useTranslation } from '@app/services/translations/translations';
import { useMe } from '@app/shared/hooks/useMe';
import { ScreenWrapper } from '@app/core/screenWrapper/ScreenWrapper';
import { isAppointmentJustBooked } from '@app/utils/appointment.utils';
import { AdvisorEnlightenment } from '@app/features/advisor/compounds/advisor-enlightenment/AdvisorEnlightenment';
import { extractAdvisorInfo } from '@app/utils/extractAdvisorInfo';
import { AdvisorSourcePage } from '@app/services/tracking/constants';

import { getGeneralStyle } from './advisor.styles';
import { AdvisorCard } from './AdvisorCard';
import { AdvisorCallToAction } from './AdvisorCallToAction';

export const Advisor: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { isAssetManagementConsultant, customer } = useMe();
  const isWeb = Platform.OS === 'web';

  const [appointmentIsJustBooked, setAppointmentIsJustBooked] = useState(false);
  const hasAppointment = !!customer.nextAppointment;
  const { isMobile, isGreaterThanTablet } = useResponsive();
  const Component = Platform.OS === 'web' ? View : ScrollView;

  const {
    advisorAvatar,
    appointmentFormattedDate,
    advisorFullName,
    onCallClick,
    onMailClick,
    onWhatsappClick,
    phoneNumber,
  } = extractAdvisorInfo({ customer, i18n, source: AdvisorSourcePage.ADVISOR_PAGE, t });

  const styles = getGeneralStyle(isMobile, isGreaterThanTablet);

  useEffect(() => {
    isAppointmentJustBooked().then((meetingJustBooked: boolean) =>
      setAppointmentIsJustBooked(meetingJustBooked)
    );
  }, []);

  return (
    <ScreenWrapper withoutScrollView={isWeb}>
      <Component>
        <HeaderPage title={t('advisor.title')} />
        <View style={styles.contentWrapper}>
          <View style={styles.container}>
            <View>
              <Spacer height={isMobile ? Spacing.SIZE_07 : Spacing.SIZE_08} />
              <AdvisorCard
                advisorAvatar={advisorAvatar}
                advisorFullName={advisorFullName}
                appointmentFormattedDate={!appointmentIsJustBooked && appointmentFormattedDate}
                hasAppointment={hasAppointment || appointmentIsJustBooked}
              />
              {!isAssetManagementConsultant && (
                <>
                  <Spacer height={Spacing.SIZE_05} />
                  <TextWithIcon
                    icon={{ color: palette.neutral[700], name: 'Info', size: 14 }}
                    text={{ font: 'Body3', style: styles.infoText as TextStyle }}
                  >
                    {!advisorFullName ? t('advisor.noAdvisorInfo') : t('advisor.info')}
                  </TextWithIcon>
                </>
              )}
              <Spacer height={isMobile ? Spacing.SIZE_07 : Spacing.SIZE_09} />
            </View>
            {advisorFullName ? (
              <AdvisorCallToAction
                onCallClick={onCallClick}
                onMailClick={onMailClick}
                onWhatsappClick={onWhatsappClick}
                phoneNumber={phoneNumber}
              />
            ) : (
              <AdvisorEnlightenment />
            )}
          </View>
        </View>
      </Component>
    </ScreenWrapper>
  );
};
