import { AppointmentCardRules } from '@app/features/search-engine/searchEngine.types';

// "-1" = last position on list
export const appointmentCardRules: AppointmentCardRules[] = [
  {
    cards: [
      {
        position: [1],
        type: 'repeche',
      },
      {
        position: [],
        type: 'seduction',
      },
      {
        position: [],
        type: 'introduction',
      },
    ],
    range: [0, 0],
  },
  {
    cards: [
      {
        position: [],
        type: 'repeche',
      },
      {
        position: [],
        type: 'seduction',
      },
      {
        position: [2],
        type: 'introduction',
      },
    ],
    range: [1, 5],
  },
  {
    cards: [
      {
        position: [-1],
        type: 'repeche',
      },
      {
        position: [],
        type: 'seduction',
      },
      {
        position: [2],
        type: 'introduction',
      },
    ],
    range: [6, 13],
  },
  {
    cards: [
      {
        position: [-1],
        type: 'repeche',
      },
      {
        position: [9],
        type: 'seduction',
      },
      {
        position: [2],
        type: 'introduction',
      },
    ],
    range: [14, 20],
  },
  {
    cards: [
      {
        position: [-1],
        type: 'repeche',
      },
      {
        position: [9, 17],
        type: 'seduction',
      },
      {
        position: [2],
        type: 'introduction',
      },
    ],
    range: [21, 50],
  },
  {
    cards: [
      {
        position: [51],
        type: 'repeche',
      },
      {
        position: [9, 17, 32],
        type: 'seduction',
      },
      {
        position: [2],
        type: 'introduction',
      },
    ],
    range: [51, '+'],
  },
];
