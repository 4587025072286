import styled from '@emotion/styled';
import { StyleSheet, View, ViewStyle } from 'react-native';

import { Spacing, palette, MediaQuery, Breakpoints, Theme } from '@masteos/aphrodite';

import { hideOnPrint } from '@app/style/hideOnPrint';

export const BluredDiv = styled.div`
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

export const LeftButtonBox = styled.div`
  align-items: center;
  display: flex;
  height: ${Spacing.SIZE_09}px;
  position: absolute;
  z-index: 2;
  top: ${Spacing.SIZE_09}px;
  left: ${Spacing.SIZE_09}px;
  cursor: pointer;
`;

export const RightButtonBox = styled.div`
  align-items: 'center';
  display: 'flex';
  height: ${Spacing.SIZE_09}px;
  position: absolute;
  top: ${Spacing.SIZE_09}px;
  right: ${Spacing.SIZE_09}px;
  z-index: 100;
`;

export const PropertyToolboxWrapper = styled.div`
  padding: ${Spacing.SIZE_09}px;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;

  @media ${MediaQuery.tablet}, ${MediaQuery.mobile}, (max-width: ${Breakpoints.minLaptop}) {
    padding: ${Spacing.SIZE_04}px;
    z-index: 1000;
  }
`;

export const ChoiceButtonWrapper = hideOnPrint(styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: ${palette.base.white};
  border-top: 1px solid ${palette.neutral[200]};
  height: 75px;

  @media ${MediaQuery.desktop}, ${MediaQuery.wide}, ${MediaQuery.ultraWide} {
    border-top: none;
    height: initial;
  }

  @media ${MediaQuery.mobile}, ${MediaQuery.wide}, ${MediaQuery.ultraWide} {
    padding-left: ${Spacing.SIZE_06}px;
    padding-right: ${Spacing.SIZE_06}px;
  }
`);

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  width: 100%;

  @media ${MediaQuery.laptop}, ${MediaQuery.desktop}, ${MediaQuery.wide}, ${MediaQuery.ultraWide} {
    flex-direction: column;
    max-width: 1120px;
  }
`;

export const PrintableTabPanel = styled(View)`
  height: 100%;
  @media print {
    display: block !important;
    page-break-after: always;
  }
`;

export const PrintableTabList = hideOnPrint(View);

export const getStyles = ({ isTablet, theme }: { isTablet?: boolean; theme: Theme }) =>
  StyleSheet.create({
    buttonBottom: {
      minWidth: 240,
      width: 'auto',
    },
    buttonBottomLarge: { width: '100%' },
    container: {
      alignItems: 'center',
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      maxWidth: '100%',
      position: 'relative',
    },
    contentWrapper: { flexDirection: 'column' },
    tabWrapper: {
      flexDirection: 'column',
      maxWidth: 680,
      paddingTop: theme.spacing.SIZE_05,
      width: '100%',
    },
    teaserImageCard: {
      marginBottom: isTablet ? theme.spacing.SIZE_09 : theme.spacing.SIZE_11,
      marginTop: isTablet ? theme.spacing.SIZE_07 : theme.spacing.SIZE_08,
    },
    webWrapper: { height: 300 },
    wrapper: {
      alignItems: 'center',
      height: '50%',
      justifyContent: 'center',
    },
  });

interface GetToastWrapperStyles {
  inTransactionToast: ViewStyle;
}

export const getToastWrapperStyles = (
  isLowerThanDesktop: boolean,
  isLowerThanLaptop: boolean,
  footerOffset: number
): StyleSheet.NamedStyles<GetToastWrapperStyles> => {
  let bottom = Spacing.SIZE_02;
  switch (true) {
    case isLowerThanLaptop: {
      bottom = Spacing.SIZE_02 + footerOffset + Spacing.SIZE_10; // gap + bottom button + mobile navigation heights
      break;
    }
    case isLowerThanDesktop: {
      bottom = Spacing.SIZE_02 + footerOffset; // gap + bottom button heights
      break;
    }
  }
  return StyleSheet.create<GetToastWrapperStyles>({
    inTransactionToast: {
      alignItems: 'center',
      bottom,
      display: 'flex',
      position: 'sticky' as 'absolute',
      width: '90%',
      zIndex: 9999,
    },
  });
};
