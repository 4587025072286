import React, { useRef, useState } from 'react';

import { ScrollView, View } from 'react-native';

import { Button, ModalTitle, Spacer, Text, TextArea, useTheme } from '@masteos/aphrodite';

import { useTranslation } from '@app/services/translations/translations';
import { CustomerPositioningCancellationReason } from '@app/libs/apollo/introspection';

import { PositioningCancelReasonList } from '../positioning-cancel-reason-list/PositioningCancelReasonList';
import { getStyles } from './CancelPositioningFormStep.styles';
import { toggleSelectedReason } from './CancelPositioningFormStep.utils';
import { CancelPositioningFormStepProps } from './CancelPositioningFormStep.types';

export const CancelPositioningFormStep: React.FC<CancelPositioningFormStepProps> = ({
  onSubmit,
  onSuccess,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = getStyles();

  const [selectedReasons, setSelectedReasons] = useState<
    Set<CustomerPositioningCancellationReason>
  >(new Set());
  const [otherReason, setOtherReason] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  const handlePressConfirm = () => {
    setIsLoading(true);
    onSubmit(
      [...selectedReasons],
      selectedReasons.has(CustomerPositioningCancellationReason.Other) ? otherReason : '',
      () => {
        setIsLoading(false);
        onSuccess();
      },
      () => {
        setIsLoading(false);
      }
    );
  };

  const handlePressReason = (key: CustomerPositioningCancellationReason) => {
    setSelectedReasons(toggleSelectedReason(selectedReasons, key));
  };

  const handleTextAreaLayout = e => {
    if (e.nativeEvent.layout.height !== 0) {
      scrollViewRef.current?.scrollToEnd({ animated: true });
    }
  };

  const scrollViewRef = useRef<ScrollView>(null);

  return (
    <>
      <ModalTitle title={t('suggestions.actions.notInterested.title')} />
      <ScrollView style={styles.scrollView} ref={scrollViewRef}>
        <Text textStyle="Body3">{t('suggestions.actions.notInterested.description')}</Text>
        <Spacer height={theme.spacing.SIZE_08} />
        <PositioningCancelReasonList
          selectedReasons={selectedReasons}
          handlePressReason={handlePressReason}
        />
        <Spacer height={theme.spacing.SIZE_04} />
        {selectedReasons.has(CustomerPositioningCancellationReason.Other) && (
          <View onLayout={handleTextAreaLayout}>
            <TextArea
              label={t(`positioning.otherCustomerReason.label`)}
              onChangeText={setOtherReason}
              placeholder={t(`positioning.otherCustomerReason.placeholder`)}
              value={otherReason}
            />
          </View>
        )}
      </ScrollView>
      <Spacer height={theme.spacing.SIZE_04} />
      <Button
        variant="primary"
        onPress={handlePressConfirm}
        testID="suggestions-actions-not-interested-submitButton"
        isLoading={isLoading}
        style={styles.confirmButton}
      >
        {t('shared.confirmButton')}
      </Button>
    </>
  );
};
