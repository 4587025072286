import { GetSelectedRealEstatesQuery, RealEstate } from '@app/libs/apollo/introspection';
import { RealEstateTrackedProps } from '@app/services/tracking/trackTracking';

type SelectedRealEstatesQueryResult =
  | GetSelectedRealEstatesQuery['findCustomerSelectedRealEstates']['realEstates'][number]
  | RealEstate;

export const realEstateToTrackedProps = ({
  id,
  annual: { grossReturn },
  trending: {
    appreciation: { evaluationRate },
  },
  building: {
    address: { city, regionName },
  },
  project: {
    strategy,
    projectAmount,
    renting: { type: locationType },
    renovation: { amount: renovationAmount },
  },
  type,
}: SelectedRealEstatesQueryResult): RealEstateTrackedProps => ({
  annualGrossReturn: grossReturn,
  annualRevaluationRate: evaluationRate,
  city,
  investStrategy: strategy,
  locationType,
  projectAmount,
  propertyId: id,
  region: regionName,
  renovationAmount,
  type: type,
});
