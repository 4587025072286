import React, { useState } from 'react';

import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { HeaderPage } from '@masteos/aphrodite';

import { AdvisorAppointment } from '@app/features/advisorAppointment/AdvisorAppointment';
import { useTranslation } from '@app/services/translations/translations';
import { ScreenWrapper } from '@app/core/screenWrapper/ScreenWrapper';
import { AdvisorNavigatorRoutes, AdvisorStackParamList } from '@app/navigation/types/routes';

type AdvisorNavigationProp = StackNavigationProp<
  AdvisorStackParamList,
  AdvisorNavigatorRoutes.AdvisorRoot
>;

export const AdvisorAppointmentView: React.FC = () => {
  const { t } = useTranslation();
  const navigation = useNavigation<AdvisorNavigationProp>();
  const [appointmentBooked, setAppointmentBooked] = useState('default');

  const callBackAppointmentBooked = () => setAppointmentBooked('appointmentBooked');

  const headerState = {
    appointmentBooked: {
      onBack: () => navigation.navigate(AdvisorNavigatorRoutes.AdvisorRoot),
      title: t('advisorAppointment.goToAdvisorPage'),
    },
    default: { onBack: null, title: t('advisorAppointment.takeAppointment') },
  };

  return (
    <ScreenWrapper withoutScrollView>
      <HeaderPage
        title={headerState[appointmentBooked].title}
        onBack={headerState[appointmentBooked].onBack}
      />
      <AdvisorAppointment callBackAppointmentBooked={callBackAppointmentBooked} />
    </ScreenWrapper>
  );
};

// eslint-disable-next-line import/no-default-export
export default AdvisorAppointmentView;
