import React, { useMemo } from 'react';

import LivingRoom from '@assets/illustrations/living-room.svg';
import { StyleSheet, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { fromS3ID as toImageKit } from '@masteos/agora';
import {
  Button,
  palette,
  useResponsive,
  Text,
  getColorWithOpacity,
  Image,
  useTheme,
  Theme,
} from '@masteos/aphrodite';

import { useTranslation } from '@app/services/translations/translations';
import { RealEstate } from '@app/libs/apollo/introspection';
import { PropertyVirtualVisitCard } from '@app/features/propertyInfo/PropertyVirtualVisitCard';
import { PublicNavigatorRoutes, PublicStackParamList } from '@app/navigation/types/routes';
import { SliderImageObject } from '@app/utils/property';
import { StateOfCustomer, useMe } from '@app/shared/hooks/useMe';
import { fromS3ID } from '@app/utils/from-s3id';

import { BluredDiv, PropertyToolboxWrapper } from './propertyInfo.styles.web';
import { PropertyToolbox } from './PropertyToolbox';
import { PropertyVirtualVisitButton } from './PropertyVirtualVisitButton';
import { PropertyTopImagesAppointment } from './PropertyTopImagesAppointment.web';

export interface PropertyTopImagesProps {
  property: RealEstate;
  imageList: SliderImageObject[];
  isPrivateProperty: boolean;
  openModalImage: (index: number) => void;
  currentScrollPosition?: number;
}

const getStyleSheet = ({
  theme,
  isGreaterThanTablet,
  isGreaterThanLaptop,
  isPrivateProperty,
  isLaptop,
}: {
  theme: Theme;
  isGreaterThanTablet: boolean;
  isGreaterThanLaptop: boolean;
  isPrivateProperty: boolean;
  isLaptop: boolean;
}) =>
  StyleSheet.create({
    button: {
      height: theme.spacing.SIZE_10,
      width: isGreaterThanTablet ? 200 : 170,
    },
    buttonMargin: {
      borderColor: palette.base.black,
      marginRight: theme.spacing.SIZE_03,
    },

    buttonWrapper: {
      flexDirection: 'row',
    },
    container: {
      height: isLaptop ? 'calc(100vh / 3)' : 'calc(100vh / 2)',
      justifyContent: 'center',
      maxHeight: 350,
      overflow: 'hidden',
      position: 'relative',
    },
    coverImageWrapper: { alignItems: 'center', width: isGreaterThanTablet ? '50%' : '100%' },
    image: {
      borderColor: theme.palette.base.white,
      borderWidth: 1,
      cursor: isPrivateProperty ? 'pointer' : 'default',
      height: '100%',
      width: '100%',
    },
    imageContainer: {
      display: 'flex',
      flexDirection: 'row',
      height: '100%',
      overflow: 'hidden',
      position: 'relative',
      width: '100%',
    },
    privateImage: {
      height: '50%',
      overflow: 'hidden',
      position: 'relative',
      width: '50%',
    },
    publicImage: {
      backgroundColor: getColorWithOpacity(palette.base.white, 70),
      border: '1px solid white',
      height: '100%',
      left: 0,
      position: 'absolute',
      top: 0,
      width: '100%',
      zIndex: 1,
    },

    registerContainer: {
      alignItems: 'center',
      height: '100%',
      justifyContent: 'center',
      position: 'absolute',
      width: '100%',
      zIndex: 1,
    },
    thumbnailContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      height: '100%',
      width: '50%',
    },
    title: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: theme.spacing.SIZE_05,
      textAlign: 'center',
    },
    virtualVisit: {
      backdropFilter: 'blur(8px)',
      backgroundColor: getColorWithOpacity(theme.palette.base.white, 70),
      height: isGreaterThanLaptop ? '50%' : '100%',
      left: isGreaterThanLaptop ? '50%' : 0,
      overflow: 'hidden',
      position: 'absolute',
      top: 0,
      width: isGreaterThanLaptop ? '50%' : '100%',
      zIndex: 1000,
    },
  });

type PropertyTopImagesNavigationProps = StackNavigationProp<PublicStackParamList>;

export const PropertyTopImages = React.forwardRef<View, PropertyTopImagesProps>(
  (
    { property, imageList, openModalImage, isPrivateProperty = false, currentScrollPosition },
    ref
  ) => {
    const { t } = useTranslation();
    const { isGreaterThanLaptop, isGreaterThanTablet, isLaptop } = useResponsive();
    const navigation = useNavigation<PropertyTopImagesNavigationProps>();
    const theme = useTheme();
    const { stateOfCustomer } = useMe();
    const isPreAppointment = stateOfCustomer === StateOfCustomer.PRE_APPOINTMENT;
    const { url3D } = property;

    const styleSheet = getStyleSheet({
      isGreaterThanLaptop,
      isGreaterThanTablet,
      isLaptop,
      isPrivateProperty,
      theme,
    });
    const handleLogin = () => navigation.navigate(PublicNavigatorRoutes.Auth);
    const handleOnClick = () => isPrivateProperty && openModalImage(0);

    const imagesList = useMemo(() => {
      const openPrivateModalImage = index => () =>
        isPrivateProperty ? openModalImage(index + 1) : {};
      return (
        imageList &&
        imageList.slice(1, 5).map((image, index) => {
          const imageUri = image.url
            ? toImageKit(image.url, `w-278${isPrivateProperty ? '' : ',bl-25'}`)
            : LivingRoom;
          return (
            <View key={index} style={styleSheet.privateImage}>
              {!isPrivateProperty && <View style={styleSheet.publicImage} />}
              <Image
                onPress={openPrivateModalImage(index)}
                key={image.url}
                style={styleSheet.image}
                resizeMode={image.url ? 'cover' : 'contain'}
                source={{ uri: imageUri }}
              />
            </View>
          );
        })
      );
    }, [
      imageList,
      isPrivateProperty,
      styleSheet.privateImage,
      styleSheet.publicImage,
      styleSheet.image,
      openModalImage,
    ]);

    const coverImageUri = property.coverImage
      ? toImageKit(property.coverImage, `w-788${!isPrivateProperty ? ',bl-25' : ''}`)
      : LivingRoom;

    return (
      <View style={styleSheet.container}>
        <View style={styleSheet.imageContainer}>
          {!isPrivateProperty && (
            <View style={styleSheet.registerContainer} ref={ref}>
              <>
                <Text textStyle="Body1" style={styleSheet.title}>
                  {t('propertyInfo.registerTitle')}
                </Text>
                <View style={styleSheet.buttonWrapper}>
                  <Button
                    variant="tertiary"
                    onPress={handleLogin}
                    style={StyleSheet.flatten([styleSheet.button, styleSheet.buttonMargin])}
                  >
                    {t('shared.login')}
                  </Button>
                  <Button style={styleSheet.button} onPress={handleLogin}>
                    {t('shared.register')}
                  </Button>
                </View>
              </>
            </View>
          )}

          <View style={styleSheet.coverImageWrapper}>
            {!isPrivateProperty && <BluredDiv />}
            <Image
              onPress={handleOnClick}
              style={styleSheet.image}
              source={{ uri: coverImageUri }}
            />
          </View>

          {isGreaterThanTablet ? (
            <View style={styleSheet.thumbnailContainer}>
              {isPreAppointment ? (
                <PropertyTopImagesAppointment
                  imageUrl={!!imageList[0] && fromS3ID(imageList[0].url, 'w-100:bl-20')}
                />
              ) : (
                !!imagesList.length && imagesList
              )}
              {url3D && isPrivateProperty ? (
                <PropertyVirtualVisitCard style={styleSheet.virtualVisit} />
              ) : null}
            </View>
          ) : null}
        </View>
        {!isGreaterThanTablet ? (
          <>
            <PropertyToolboxWrapper>
              <PropertyToolbox property={property} currentScrollPosition={currentScrollPosition} />
            </PropertyToolboxWrapper>
            <PropertyVirtualVisitButton />
          </>
        ) : null}
      </View>
    );
  }
);

PropertyTopImages.displayName = 'PropertyTopImages';
