import { Linking } from 'react-native';

/** @TODO create a hook with notify etc.. Ticket is logged for 15% */
export const openUrl = async (url: string, errorCallback?: () => void): Promise<void> => {
  const supported = url && (await Linking.canOpenURL(url));

  if (!supported) {
    errorCallback();
    return;
  }

  await Linking.openURL(url);
};
