import React, { useState } from 'react';

import { StyleSheet, View } from 'react-native';

import { palette, Spacing, Text, Spacer, BorderRadius } from '@masteos/aphrodite';

import {
  trackTooltip,
  trackResellingYear,
  trackEvaluationRate,
} from '@app/services/tracking/trackTracking';
import { Importance, PropertyAttribute } from '@app/features/propertyDescription/PropertyAttribute';
import { currencyFormatter, percentFormatter } from '@app/utils/currency-formatter';
import { useRealEstateTranslation } from '@app/shared/hooks/useRealEstateTranslations';
import { SliderInputNumberOutput as SliderInput } from '@app/shared/components/SliderInput/SliderInputNumberOutput';

import { areEqual } from './PropertyFinances.utils';

const styles = StyleSheet.create({
  border: {
    borderBottomColor: palette.neutral[300],
    borderBottomWidth: 1,
    flex: 1,
  },
  container: {
    borderColor: palette.neutral[300],
    borderRadius: BorderRadius.SIZE_02,
    borderWidth: 1,
    flex: 1,
    paddingBottom: Spacing.SIZE_04,
    paddingHorizontal: Spacing.SIZE_06,
    paddingTop: Spacing.SIZE_03,
  },
});

interface TrendingAppreciation {
  appreciationZone?: string;
  evaluationRate?: number;
}

interface PropertyEnrichmentProjectionProps {
  bankCapitalStillDue: number;
  trendingAppreciation: TrendingAppreciation;
  cumulativeCashFlow: number;
  enrichementResellingYear: number;
  evaluationRate: number;
  loanDownAmount: number;
  resellingPrice: number;
  resellingYear: number;
  resellingYearCashFlow: number;
  setEvaluationRate: (evaluationRate: number) => void;
  setResellingYear: (resellingYear: number) => void;
  propertyId: string;
}

export const PropertyEnrichmentProjection: React.FC<PropertyEnrichmentProjectionProps> = React.memo(
  ({
    bankCapitalStillDue,
    trendingAppreciation,
    cumulativeCashFlow,
    enrichementResellingYear,
    evaluationRate: defaultEvaluationRate,
    loanDownAmount,
    resellingPrice,
    resellingYear: defaultResallingYear,
    resellingYearCashFlow,
    setEvaluationRate: updateEvaluationRate,
    setResellingYear: updateResallingYear,
    propertyId,
  }) => {
    const { t } = useRealEstateTranslation();
    const [resellingYear, setResellingYear] = useState(defaultResallingYear);
    const [evaluationRate, setEvaluationRate] = useState(defaultEvaluationRate);

    const baseEvaluationRate = trendingAppreciation?.evaluationRate || 0;
    const formattedAnnualPercentEvolutionValue = Math.floor(evaluationRate * 10) / 10;
    const formattedBaseEvaluationRate = percentFormatter(baseEvaluationRate * 100, 1);
    const formattedBankCapitalStillDue = currencyFormatter(-bankCapitalStillDue, 0);
    const formattedCumulativeCashFlow = currencyFormatter(cumulativeCashFlow, 0);
    const formattedEnrichementResellingYear = currencyFormatter(enrichementResellingYear, 0);
    const formattedEvaluationRate = percentFormatter(evaluationRate, 1);
    const formattedLoanDownAmount = currencyFormatter(-loanDownAmount, 0);
    const formattedReselingYearCashFlow = currencyFormatter(resellingYearCashFlow, 0);
    const formattedResellingPrice = currencyFormatter(resellingPrice, 0);
    const formattedResellingYear = t('propertyFinances.countYears', { count: resellingYear });
    const cumulativeCashFlowKey =
      cumulativeCashFlow > 0 ? 'cumulativeCashFlowPositive' : 'cumulativeCashFlowNegative';
    const evaluationRateKey =
      baseEvaluationRate && baseEvaluationRate > 0
        ? 'annualPercentEvolution'
        : 'evaluationRateUnavaible';

    const zoneEvaluation = t(`shared.trending.${trendingAppreciation?.appreciationZone}`);

    const handleResellingYear = (value: number): void => {
      trackResellingYear(value, propertyId);
    };
    const handleAnnualPercentEvolution = (value: number): void => {
      trackEvaluationRate({
        initializationRate: baseEvaluationRate,
        propertyId,
        rateSetByUser: value,
      });
    };
    const trackTooltipAnnualPercentEvolution = () =>
      trackTooltip({ propertyId, tooltipType: 'Evolution annuelle du prix du bien' });
    const trackTooltipResellingYear = () =>
      trackTooltip({ propertyId, tooltipType: 'Durée de détention' });
    const trackTooltipResellingPrice = () =>
      trackTooltip({ propertyId, tooltipType: 'Prix de revente' });
    const trackTooltipBankCapitalStillDue = () =>
      trackTooltip({ propertyId, tooltipType: 'Capital restant dû à la banque' });
    const trackTooltipCumulativeCashFlow = () =>
      trackTooltip({ propertyId, tooltipType: "Cumul effort d'épargne" });
    const trackTooltipLoanDownAmount = () => trackTooltip({ propertyId, tooltipType: 'Apport' });

    const resallingYearSlider = React.useMemo(
      () => (
        <SliderInput
          min={1}
          step={1}
          max={30}
          setSliderValue={(value: number) => {
            setResellingYear(value);
            updateResallingYear(value);
          }}
          value={resellingYear}
          onSlidingComplete={handleResellingYear}
        />
      ),
      [resellingYear]
    );

    const evaluationRateSlider = React.useMemo(
      () => (
        <SliderInput
          min={-5}
          step={0.1}
          max={20}
          setSliderValue={(value: number) => {
            setEvaluationRate(value);
            updateEvaluationRate(value);
          }}
          value={formattedAnnualPercentEvolutionValue}
          onSlidingComplete={handleAnnualPercentEvolution}
        />
      ),
      [evaluationRate]
    );

    return (
      <View style={styles.container}>
        <View>
          <PropertyAttribute
            label={t('shared.financialTool.resellingYear')}
            tooltip={{
              description: t('shared.financialTool.tooltip.resellingYearDescription'),
              onOpen: trackTooltipResellingYear,
            }}
            value={formattedResellingYear}
            importance={Importance.LOW}
          />
          {resallingYearSlider}
        </View>

        <View>
          <PropertyAttribute
            label={t('shared.financialTool.annualPercentEvolution')}
            tooltip={{
              description: t(`shared.financialTool.tooltip.${evaluationRateKey}`, {
                evaluationRate: formattedBaseEvaluationRate,
                zone: zoneEvaluation,
              }),
              onOpen: trackTooltipAnnualPercentEvolution,
            }}
            value={formattedEvaluationRate}
            importance={Importance.LOW}
          />
          {baseEvaluationRate > 0 ? (
            <Text textStyle="Caption1">
              {t('shared.financialTool.annualPercentEvolutionDescription', {
                evolution: formattedBaseEvaluationRate,
                years: 5,
              })}
            </Text>
          ) : null}
          {evaluationRateSlider}
        </View>

        <PropertyAttribute
          label={t('shared.financialTool.resellingPrice')}
          value={formattedResellingPrice}
          importance={Importance.LOW}
          isOdd
          tooltip={{
            description: t('shared.financialTool.tooltip.resellingPrice', {
              evaluationRate: formattedEvaluationRate,
              resellingYear,
            }),
            onOpen: trackTooltipResellingPrice,
          }}
        />

        <PropertyAttribute
          label={t('shared.financialTool.bankCapitalStillDue')}
          value={formattedBankCapitalStillDue}
          importance={Importance.LOW}
          tooltip={{
            description: t('shared.financialTool.tooltip.bankCapitalStillDue'),
            onOpen: trackTooltipBankCapitalStillDue,
          }}
        />

        <View style={styles.border} />
        <Spacer height={Spacing.SIZE_04} />
        <PropertyAttribute
          label={t('shared.financialTool.resellingYearCashFlow')}
          value={formattedReselingYearCashFlow}
          importance={Importance.HIGH}
        />

        <PropertyAttribute
          label={t('shared.financialTool.loanDown')}
          value={formattedLoanDownAmount}
          importance={Importance.LOW}
          isOdd
          tooltip={{
            description: t('shared.financialTool.tooltip.loanDownDescription'),
            onOpen: trackTooltipLoanDownAmount,
          }}
        />

        <PropertyAttribute
          label={t(`shared.financialTool.${cumulativeCashFlowKey}`)}
          value={formattedCumulativeCashFlow}
          importance={Importance.LOW}
          tooltip={{
            description: t(`shared.financialTool.tooltip.${cumulativeCashFlowKey}`),
            onOpen: trackTooltipCumulativeCashFlow,
          }}
        />

        <View style={styles.border} />
        <Spacer height={Spacing.SIZE_04} />
        <PropertyAttribute
          label={t('shared.financialTool.enrichmentResellingYear', { year: resellingYear })}
          value={formattedEnrichementResellingYear}
          importance={Importance.HIGH}
        />
      </View>
    );
  },
  areEqual<PropertyEnrichmentProjectionProps>([
    'bankCapitalStillDue',
    'trendingAppreciation',
    'cumulativeCashFlow',
    'enrichementResellingYear',
    'resellingPrice',
    'loanDownAmount',
    'resellingYearCashFlow',
  ])
);

PropertyEnrichmentProjection.displayName = 'PropertyEnrichmentProjection';
