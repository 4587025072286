import { FinancialModel } from '@masteos/agora';

import { RealEstate } from '@app/libs/apollo/introspection';
import {
  PropertyPage,
  trackUnbookmark,
  trackInterestedPropertyButton,
  trackBookmark,
  TrackingOrigin,
  PropertyLikeType,
} from '@app/services/tracking/trackTracking';

import { realEstateToTrackedProps } from '../search-engine/utils/real-estate-to-tracked-props';

interface PropertyInterestActionEffectTrackingParam {
  action: string;
  property: RealEstate;
  computedFM: FinancialModel;
  currentTab?: TrackingOrigin;
}

export const trackPropertyInterestActionEffect = ({
  action,
  property,
  currentTab,
}: PropertyInterestActionEffectTrackingParam): void => {
  if (action === 'OPEN') {
    trackInterestedPropertyButton(PropertyPage.PROPERTY);
  } else if (action === 'BOOKMARK' || action === 'POSITION_ON') {
    trackBookmark({
      currentTab,
      likeType:
        action === 'POSITION_ON' ? PropertyLikeType.PRESELECTION : PropertyLikeType.FAVORITE,
      page: PropertyPage.PROPERTY,
      ...realEstateToTrackedProps(property),
    });
  } else if (action === 'UNBOOKMARK') {
    trackUnbookmark({
      currentTab,
      page: PropertyPage.PROPERTY,
      ...realEstateToTrackedProps(property),
    });
  }
};
