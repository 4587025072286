import { ChipProps } from '@masteos/aphrodite';

export const statusChipProps = {
  exclusive: {
    iconName: 'Masteos',
    text: 'propertyPreview.isExclusive',
    tint: 'orange',
  },
  new: {
    iconName: 'New',
    text: 'propertyPreview.isNew',
    tint: 'green',
  },
} as { [key: string]: ChipProps };

type getStatusRealEstateProps = (args: { isNew: boolean; isExclusive: boolean }) => ChipProps;

export const getStatusRealEstate: getStatusRealEstateProps = ({
  isNew = false,
  isExclusive = false,
}) => {
  if (isNew) {
    return statusChipProps.new;
  }

  if (isExclusive) {
    return statusChipProps.exclusive;
  }

  return null;
};
