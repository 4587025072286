import React, { PropsWithChildren } from 'react';

import { Platform, StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

const getStyles = (isMobile: boolean) =>
  StyleSheet.create({
    container: { paddingHorizontal: isMobile ? 20 : 0 },
  });

interface DebugMenuHeaderProps {
  style?: StyleProp<ViewStyle>;
}

export const DebugMenuContentWrapper = ({
  children,
  style,
}: PropsWithChildren<DebugMenuHeaderProps>): JSX.Element => {
  const isMobile = Platform.OS !== 'web';
  const styles = getStyles(isMobile);

  return (
    <View style={[styles.container, style]} testID="wrapper">
      {children}
    </View>
  );
};
