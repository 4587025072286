import { StyleProp, StyleSheet, ViewStyle } from 'react-native';

export const getStyles = ({
  bannerHeight,
  paddingTop,
  topOffset,
}: {
  isWeb: boolean;
  bannerHeight: number;
  paddingTop: number;
  topOffset: number;
}): Record<string, StyleProp<ViewStyle>> =>
  StyleSheet.create({
    bannerStyle: {
      paddingTop,
    },
    componentOffset: { marginTop: -topOffset },
    container: {
      height: `calc(100vh - ${bannerHeight}px)`,
      overflow: 'auto' as 'visible',
      top: bannerHeight,
    },
  });
