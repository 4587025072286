import { useMyProjectsList } from '@app/features/projectList/useProjectList.hook';
import { TransactionStatus } from '@app/libs/apollo/introspection';

import { useMe } from './useMe';

export const useLocationInfoPermission = (propertyID: string): boolean => {
  const { customer, isAssetManagementConsultant } = useMe();
  const { data: { myProjects } = { myProjects: [] } } = useMyProjectsList();

  if (isAssetManagementConsultant) {
    return true;
  }

  if (!customer?.id) {
    return false;
  }

  const hasValidSearchMandate = customer.searchMandate?.valid;
  const hasOngoingProjectOnRealEstate = !!myProjects?.find(
    ({ realEstate, status }) =>
      realEstate.id === propertyID && status !== TransactionStatus.Canceled
  );

  return hasValidSearchMandate || hasOngoingProjectOnRealEstate;
};
