import { Platform, StyleSheet } from 'react-native';

import { Theme } from '@masteos/aphrodite';

import { PlatformEnum } from '@app/types/platform';

const OPACITY_VIEWED_CARD = 0.5;

export const getStyle = ({
  isViewed,
  isNew,
  theme,
}: {
  isViewed: boolean;
  isNew: boolean;
  theme: Theme;
}) => {
  return StyleSheet.create({
    cardContainer: {
      opacity: isViewed ? OPACITY_VIEWED_CARD : 1,
    },
    chipContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      left: theme.spacing.SIZE_04,
      position: 'absolute',
      right: theme.spacing.SIZE_04,
      top: theme.spacing.SIZE_04,
      zIndex: 1,
    },
    chipItemLeft: {
      marginRight: theme.spacing.SIZE_03,
      maxWidth: isNew ? '55%' : '100%',
    },
    chipItemRight: {
      maxWidth: '40%',
    },
    footer: {
      alignItems: 'center',
      flexDirection: 'row',
      marginTop: 16,
    },
    footerBookmark: {
      marginLeft: 'auto',
    },
    footerPrice: {
      alignItems: 'baseline',
    },
    textInfo: {
      color: theme.palette.neutral[700],
      paddingBottom: Platform.OS !== PlatformEnum.Web ? 3 : 0,
    },
  });
};
