import { ListItemUnionProps } from '@masteos/aphrodite';

import { stringToSelect } from '@app/utils/select-formatter';

/**
 * This function manage the case where all region are checked (and therefore automatically check option "all")
 */
export const getRegionListValue = (
  value: string[],
  regionOptionList: ListItemUnionProps[]
): ListItemUnionProps[] =>
  value.length >= regionOptionList.length - 1
    ? regionOptionList
    : value.map(v => stringToSelect(v));
