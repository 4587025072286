import React, { PropsWithChildren, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { AphroditeConfigProvider, Lang } from '@masteos/aphrodite';

export const parseLanguage = (language: string): Lang => {
  return language?.split('-')[0] as Lang;
};

export const AphroditeConfigBridge: React.FC<PropsWithChildren> = props => {
  const { i18n } = useTranslation();

  const currentLanguage = useMemo(
    () => parseLanguage(i18n.resolvedLanguage),
    [i18n.resolvedLanguage]
  );

  return <AphroditeConfigProvider currentLang={currentLanguage} {...props} />;
};
