import React, { useMemo } from 'react';

import { View } from 'react-native';

import {
  DataBlock,
  Text,
  Spacer,
  Spacing,
  useResponsive,
  IconPopoverOrModal,
  useTheme,
} from '@masteos/aphrodite';

import { InvestmentWarning } from '@app/features/investmentWarning/InvestmentWarning';
import { DataBlockWrapper } from '@app/shared/components/DataBlockWrapper.web';
import { RealEstate } from '@app/libs/apollo/introspection';
import { useRealEstateTranslation } from '@app/shared/hooks/useRealEstateTranslations';
import { getStyles } from '@app/features/propertyInfo/propertyInfo.styles.web';

import { usePropertyRent } from './hooks/usePropertyRent';

interface PropertyRentProps {
  property: RealEstate;
}

export const PropertyRent: React.FC<PropertyRentProps> = ({ property }: PropertyRentProps) => {
  const { t } = useRealEstateTranslation();
  const { isGreaterThanTablet } = useResponsive();
  const { onTooltipOpen, rows } = usePropertyRent(property);
  const theme = useTheme();
  const propertyInfoStyles = useMemo(() => getStyles({ theme }), [theme]);

  return (
    <>
      <View style={propertyInfoStyles.contentWrapper}>
        <Text textStyle="Title3Medium">{t('shared.synthesis')}</Text>
        <Spacer height={isGreaterThanTablet ? Spacing.SIZE_08 : Spacing.SIZE_07} />
        <DataBlockWrapper $columns={3}>
          {rows.map(dataBlock => (
            <DataBlock
              key={dataBlock.title}
              title={dataBlock.title}
              icon={dataBlock.icon}
              data={dataBlock.data}
              hint={
                !!dataBlock.tooltip && (
                  <IconPopoverOrModal
                    title={dataBlock.tooltip.title}
                    content={dataBlock.tooltip.content}
                    iconName="Info"
                    iconSize="s"
                    onOpen={() => onTooltipOpen(dataBlock.tooltip.title)}
                  />
                )
              }
              testID={dataBlock.testID}
            />
          ))}
        </DataBlockWrapper>
        <Spacer height={isGreaterThanTablet ? Spacing.SIZE_11 : Spacing.SIZE_09} />
      </View>
      <InvestmentWarning />
    </>
  );
};
