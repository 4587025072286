import React from 'react';

import { Spacer, Text, useResponsive, useTheme } from '@masteos/aphrodite';

import { styles } from './projectCard.styles';

interface ProjectCardTitleProps {
  address: string;
  realEstateTitle: string;
}

type NumberOfLines = { numberOfLines?: number };

const realEstateTitleProps = (isMobile: boolean): NumberOfLines => ({
  numberOfLines: !isMobile ? 1 : undefined,
});

const realEstateAddressProps = (isMobile: boolean): NumberOfLines => ({
  numberOfLines: !isMobile ? 2 : undefined,
});

export const ProjectCardTitle: React.FC<ProjectCardTitleProps> = ({ address, realEstateTitle }) => {
  const { isMobile } = useResponsive();
  const theme = useTheme();

  return (
    <>
      <Text textStyle="Headline3Medium" {...realEstateTitleProps(isMobile)}>
        {realEstateTitle}
      </Text>
      <Spacer height={theme.spacing.SIZE_02} />
      <Text textStyle="Body3" style={styles.text} {...realEstateAddressProps(isMobile)}>
        {address}
      </Text>
    </>
  );
};
