export enum RealEstateBadgeType {
  new = 'novelty',
  none = 'no_badge',
  isExclusive = 'exclusivity',
}

export enum SuggestionsSourcePage {
  SUGGESTIONS_PAGE = 'suggestionsPage',
  PROPERTY_PAGE = 'propertyPage',
}

export enum AdvisorSourcePage {
  SUGGESTIONS_PAGE = 'suggestionsPage',
  ADVISOR_PAGE = 'advisorPage',
  PROJECT_PAGE = 'projectPage',
}
