import React, { useContext, useEffect, useRef, useState } from 'react';

import { Animated, Platform } from 'react-native';

import { PlatformEnum } from '@app/types/platform';

type TabAnimationContextType = {
  index?: number;
  setIndex: (a: number) => void;
  animated: Animated.Value;
};

export const TabAnimationContext = React.createContext<TabAnimationContextType>({
  animated: new Animated.Value(0),
  index: 0,
  setIndex: () => undefined,
});

export const useTabAnimationContext = (): TabAnimationContextType => {
  return useContext(TabAnimationContext);
};

export const TabAnimationContextProvider: React.FC<{
  children: React.ReactNode;
  index?: number;
}> = ({ children, index: initialIndex = 0 }) => {
  const [index, setIndex] = useState(initialIndex);
  const animated = useRef(new Animated.Value(index)).current;

  useEffect(() => {
    realSetIndex(initialIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialIndex]);

  const realSetIndex = (newIndex: number) => {
    Animated.timing(animated, {
      duration: 300,
      toValue: newIndex,
      useNativeDriver: Platform.OS !== PlatformEnum.Web,
    }).start();
    setIndex(newIndex);
  };

  return (
    <TabAnimationContext.Provider
      value={{
        animated,
        index,
        setIndex: realSetIndex,
      }}
    >
      {children}
    </TabAnimationContext.Provider>
  );
};

const MIN_OPACITY = 0.3,
  MAX_OPACITY = 1;

export const useAnimatedValuesForTab = (
  index: number,
  width: number
): {
  opacity: Animated.AnimatedInterpolation<string>;
  translateX: Animated.AnimatedInterpolation<string>;
} => {
  const { animated } = useTabAnimationContext();
  const inputRange = [index - 1, index, index + 1];
  const outputRange = [MIN_OPACITY, MAX_OPACITY, MIN_OPACITY];
  const outputRange2 = [-width, 0, width];
  const opacity = animated.interpolate({
    extrapolate: 'clamp',
    inputRange,
    outputRange,
  });
  const translateX = animated.interpolate({
    extrapolate: 'clamp',
    inputRange,
    outputRange: outputRange2,
  });

  return { opacity, translateX };
};
