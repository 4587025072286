import React from 'react';

import { Platform, ViewStyle } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { Button } from '@masteos/aphrodite';

import { useTranslation } from '@app/services/translations/translations';
import {
  TakeAppointmentSource,
  trackTakeAdvisorAppointmentClick,
} from '@app/services/tracking/trackTracking';
import {
  AdvisorNavigatorRoutes,
  MainMenuNavigatorRoutes,
  RealEstateNavigatorRoutes,
  RealEstatesStackParamList,
} from '@app/navigation/types/routes';
import { PlatformEnum } from '@app/types/platform';

type PropertyAppointmentButtonNavigationProp = StackNavigationProp<
  RealEstatesStackParamList,
  RealEstateNavigatorRoutes.RealEstate
>;

interface PropertyAppointmentButton {
  style?: ViewStyle | React.CSSProperties;
  propertyId: string;
}

export const PropertyAppointmentButton: React.FC<PropertyAppointmentButton> = ({
  propertyId,
  style,
}) => {
  const { t } = useTranslation();
  const navigation = useNavigation<PropertyAppointmentButtonNavigationProp>();

  const onButtonPress = () => {
    trackTakeAdvisorAppointmentClick({
      propertyId,
      source: TakeAppointmentSource.propertyPage,
    });
    if (Platform.OS === PlatformEnum.Web) {
      navigation.getParent().navigate(MainMenuNavigatorRoutes.Advisor, {
        screen: AdvisorNavigatorRoutes.AdvisorAppointment,
      });
    } else {
      navigation.navigate(RealEstateNavigatorRoutes.AdvisorAppointment);
    }
  };

  return (
    <Button
      iconName="Phone"
      iconPlacement="left"
      onPress={onButtonPress}
      style={style}
      testID="property-appointment-btn"
    >
      {t('propertyActions.takeAppointment')}
    </Button>
  );
};
