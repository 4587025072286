import React from 'react';

import { View } from 'react-native';

import { Reinsurance } from '@masteos/aphrodite';

import { getStyle } from '@app/features/search-engine/compounds/search-engine-in-transaction/SearchEngineInTransaction.styles';
import { useTranslation } from '@app/services/translations/translations';

interface SearchEngineTransactionProps {
  onPressInformationCard: () => Promise<void> | void;
}

export const SearchEngineInTransaction: React.FC<SearchEngineTransactionProps> = ({
  onPressInformationCard,
}) => {
  const { t } = useTranslation();
  const styles = getStyle();

  return (
    <View style={styles.container}>
      <View style={styles.wrapper}>
        <Reinsurance
          title={t('advisor.newResearch')}
          description={t('advisor.newAppointment')}
          buttonLabel={t('advisor.createAppointmentButton')}
          image="Magnifying"
          onPress={onPressInformationCard}
        />
      </View>
    </View>
  );
};
