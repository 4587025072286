import React, { useEffect } from 'react';

import DeusOups from '@webImages/deusOups.svg';
import { StyleSheet, View, useWindowDimensions } from 'react-native';

import { palette, Text, Image, Button, useResponsive } from '@masteos/aphrodite';

import { useTranslation } from '@app/services/translations/translations';
import { trackChangePage, WebPageName } from '@app/services/tracking/pageTracking';

type ErrorPageProps = {
  message: string;
  resetError?: () => void;
};

export const ErrorScreen: React.FC<ErrorPageProps> = ({ message, resetError }) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();
  const { height: windowHeight } = useWindowDimensions();

  const handleButton = () => {
    resetError?.();
  };

  const styleSheet = StyleSheet.create({
    containerButton: {
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '40px',
    },
    containerImage: {
      backgroundColor: palette.base.black,
      bottom: 0,
      left: 0,
      margin: 0,
      position: 'absolute' as const,
      right: 0,
      top: 0,
      zIndex: -1,
    },
    containerMessage: {
      alignItems: 'center',
      height: '60%',
      paddingTop: 10,
    },
    image: {
      position: 'absolute',
    },
    message: {
      maxWidth: 600,
      minWidth: 300,
      opacity: 0.5,
    },
    text: {
      color: palette.base.white,
      textAlign: 'center',
    },
    wrapper: {
      height: windowHeight,
      width: '100%',
    },
    wrapperImage: {
      bottom: 0,
      height: '50%',
      left: 0,
      maxHeight: '75%',
      maxWidth: 792,
      position: 'absolute',
      width: '100%',
    },
  });

  useEffect(() => {
    trackChangePage(WebPageName.ERROR);
  }, []);

  return (
    <View style={styleSheet.wrapper}>
      <View style={styleSheet.containerImage}>
        {!isMobile && (
          <View style={styleSheet.wrapperImage}>
            <Image resizeMode="contain" style={styleSheet.image} source={{ uri: DeusOups }} />
          </View>
        )}
      </View>
      <View style={styleSheet.containerMessage}>
        <View style={styleSheet.message}>
          <Text style={styleSheet.text} textStyle="Title2Medium">
            {t('errorScreen.title')}
          </Text>
          <Text textStyle="Headline1Medium" style={[styleSheet.text, styleSheet.message]}>
            {message}
          </Text>
          <View style={styleSheet.containerButton}>
            <Button variant="secondary" onPress={handleButton}>
              {t('errorScreen.buttonLabel')}
            </Button>
          </View>
        </View>
      </View>
    </View>
  );
};

// eslint-disable-next-line import/no-default-export
export default ErrorScreen;
