import { makeVar } from '@apollo/client';
import { TFunction } from 'react-i18next';

import { RealEstateItem } from '@app/shared/components/real-estate-card/realEstateCard.type';

import { appointmentCardRules } from '../compounds/search-engine-list/searchEngineCardRules';
import {
  InformationCardItem,
  AppointmentCardRules,
  InformationCardType,
  SearchEngineCardsProps,
} from '../searchEngine.types';

export const reinsuranceCardList = makeVar([]);

export const getAppointmentCard: (t: TFunction) => InformationCardItem[] = t => [
  {
    buttonText: t('searchEngine.informationCards.1.buttonText'),
    image: 'Phone',
    key: 1,
    text: t('searchEngine.informationCards.1.content'),
    title: t('searchEngine.informationCards.1.title'),
    type: 'introduction',
  },
  {
    buttonText: t('searchEngine.informationCards.2.buttonText'),
    image: 'Phone',
    key: 2,
    text: t('searchEngine.informationCards.2.content'),
    title: t('searchEngine.informationCards.2.title'),
    type: 'introduction',
  },
  {
    buttonText: t('searchEngine.informationCards.3.buttonText'),
    image: 'Phone',
    key: 3,
    text: t('searchEngine.informationCards.3.content'),
    title: t('searchEngine.informationCards.3.title'),
    type: 'introduction',
  },
  {
    buttonText: t('searchEngine.informationCards.4.buttonText'),
    image: 'Phone',
    key: 4,
    text: t('searchEngine.informationCards.4.content'),
    title: t('searchEngine.informationCards.4.title'),
    type: 'introduction',
  },
  {
    buttonText: t('searchEngine.informationCards.5.buttonText'),
    image: 'Foundations',
    key: 5,
    text: t('searchEngine.informationCards.5.content'),
    title: t('searchEngine.informationCards.5.title'),
    type: 'seduction',
  },
  {
    buttonText: t('searchEngine.informationCards.6.buttonText'),
    image: 'Documents',
    key: 6,
    text: t('searchEngine.informationCards.6.content'),
    title: t('searchEngine.informationCards.6.title'),
    type: 'seduction',
  },
  {
    buttonText: t('searchEngine.informationCards.7.buttonText'),
    image: 'Magnifying',
    key: 7,
    text: t('searchEngine.informationCards.7.content'),
    title: t('searchEngine.informationCards.7.title'),
    type: 'seduction',
  },
  {
    buttonText: t('searchEngine.informationCards.8.buttonText'),
    image: 'Ruin',
    key: 8,
    text: t('searchEngine.informationCards.8.content'),
    title: t('searchEngine.informationCards.8.title'),
    type: 'seduction',
  },
  {
    buttonText: t('searchEngine.informationCards.9.buttonText'),
    image: 'Documents',
    key: 9,
    text: t('searchEngine.informationCards.9.content'),
    title: t('searchEngine.informationCards.9.title'),
    type: 'seduction',
  },
  {
    buttonText: t('searchEngine.informationCards.10.buttonText'),
    image: 'Pearl',
    key: 10,
    text: t('searchEngine.informationCards.10.content'),
    title: t('searchEngine.informationCards.10.title'),
    type: 'repeche',
  },
  {
    buttonText: t('searchEngine.informationCards.11.buttonText'),
    image: 'Magnifying',
    key: 11,
    text: t('searchEngine.informationCards.11.content'),
    title: t('searchEngine.informationCards.11.title'),
    type: 'repeche',
  },
  {
    buttonText: t('searchEngine.informationCards.12.buttonText'),
    image: 'Desert',
    key: 12,
    text: t('searchEngine.informationCards.12.content'),
    title: t('searchEngine.informationCards.12.title'),
    type: 'repeche',
  },
  {
    buttonText: t('searchEngine.informationCards.13.buttonText'),
    image: 'Desert',
    key: 13,
    text: t('searchEngine.informationCards.13.content'),
    title: t('searchEngine.informationCards.13.title'),
    type: 'repeche',
  },
];

export const addAppointmentCardOnList = (
  searchEngineList: RealEstateItem[],
  t: TFunction
): SearchEngineCardsProps => {
  const appointmentCard = getAppointmentCard(t);
  const rules = getRules(searchEngineList.length);
  return addOnList(searchEngineList, appointmentCard, rules);
};

export const getRules = (results: number): AppointmentCardRules => {
  return appointmentCardRules.find(
    rule => (rule.range[0] <= results && results <= rule.range[1]) || rule.range[1] === '+'
  );
};

export const addOnList = (
  searchEngineList: SearchEngineCardsProps,
  appointmentCard: InformationCardItem[],
  cardRules: AppointmentCardRules
): SearchEngineCardsProps => {
  const reinsuranceList = [];

  const list = [...searchEngineList];
  const cardRulesFlat = cardRules.cards.flatMap(rule =>
    rule.position.map(b => ({ position: b, type: rule.type }))
  );

  cardRulesFlat.sort((a, b) => a.position - b.position);

  let currentSavedCard = reinsuranceCardList();

  if (currentSavedCard.length > 0 && cardRulesFlat.length != currentSavedCard.length) {
    clearReinsuranceCardList();
    currentSavedCard = [];
  }

  cardRulesFlat.forEach((rule, index) => {
    let randomCard;
    if (currentSavedCard.length) {
      randomCard = currentSavedCard[index];
    } else {
      randomCard = getRandomCard(appointmentCard, rule.type, list);
      reinsuranceList.push(randomCard);
    }

    list.splice(rule.position === -1 ? list.length : rule.position - 1, 0, randomCard);
  });

  if (reinsuranceList.length) {
    reinsuranceCardList(reinsuranceList);
  }

  return list;
};

const getRandomCard = (
  appointmentCard: InformationCardItem[],
  type: InformationCardType,
  items: SearchEngineCardsProps
): InformationCardItem => {
  const apppointmentCardsAvalaible = appointmentCard.filter(
    card => card.type === type && !items.includes(card)
  );
  return apppointmentCardsAvalaible[Math.floor(Math.random() * apppointmentCardsAvalaible.length)];
};

export const clearReinsuranceCardList = (): void => {
  reinsuranceCardList([]);
};
