import { StyleSheet, Platform } from 'react-native';

import { Spacing, Theme } from '@masteos/aphrodite';

import { PlatformEnum } from '@app/types/platform';

export const getProjectListStyles = (parentWidth: number, isMobile: boolean, columnsNb = 1) =>
  StyleSheet.create({
    italicText: {
      fontStyle: 'italic',
    },
    item: {
      paddingBottom: Spacing.SIZE_07,
      paddingHorizontal: isMobile ? Spacing.SIZE_06 : 0,
      width: (parentWidth - Spacing.SIZE_07 * (columnsNb - 1)) / columnsNb,
    },
    itemContainer: {
      gap: Spacing.SIZE_07,
      width: parentWidth,
    },
    listHeaderWrapper: { paddingHorizontal: isMobile ? Spacing.SIZE_06 : 0 },
    statusDescription: {
      marginBottom: Spacing.SIZE_06,
      maxWidth: 550,
    },
    wrapper: Platform.OS === PlatformEnum.Web ? { paddingBottom: '60px' } : { flex: 1 },
  });

export const getProjectEmptyListStyles = (isMobile: boolean, theme: Theme) =>
  StyleSheet.create({
    buttonLabelStyle: { paddingHorizontal: 0 },
    buttonWrapper: {
      height: 48,
      width: isMobile ? '100%' : 260,
    },
    italicText: {
      color: theme.palette.neutral[800],
      fontStyle: 'italic',
    },
    wrapper: { paddingHorizontal: isMobile ? 24 : 0 },
  });
