import React, { useState } from 'react';

import { SliderInputNumberOutput as SliderInput } from '@app/shared/components/SliderInput/SliderInputNumberOutput';
import { Importance, PropertyAttribute } from '@app/features/propertyDescription/PropertyAttribute';
import { useRealEstateTranslation } from '@app/shared/hooks/useRealEstateTranslations';
import { trackTooltip } from '@app/services/tracking/trackTracking';
import { AvailableCountryCode } from '@app/libs/apollo/introspection';

interface AnnualAvgOccupancyProps {
  annualAvgOccupancyInMonths: number;
  countryCode: AvailableCountryCode;
  financialModelVersion: number;
  isFurnished: boolean;
  propertyId: string;
  setAnnualAvgOccupancyInMonths: (v: number) => void;
}

export const AnnualAvgOccupancy: React.FC<AnnualAvgOccupancyProps> = ({
  annualAvgOccupancyInMonths,
  countryCode,
  financialModelVersion,
  isFurnished,
  propertyId,
  setAnnualAvgOccupancyInMonths,
}) => {
  const { t } = useRealEstateTranslation();
  const [annualAvgOccupancyInMonthsState, setAnnualAvgOccupancyInMonthsState] = useState<number>(
    annualAvgOccupancyInMonths
  );

  const isAnnualAvgOccupancyVisible =
    (financialModelVersion >= 4 && countryCode === AvailableCountryCode.Fr) ||
    (financialModelVersion >= 3 && countryCode === AvailableCountryCode.Es);

  const trackTooltipAnnualAvgOccupancy = () =>
    trackTooltip({ propertyId, tooltipType: "Taux d'occupation annuel" });

  const annualTurnoverIndiceSlider = React.useMemo(
    () => (
      <SliderInput
        min={0}
        step={0.5}
        max={12}
        setSliderValue={(value: number) => {
          setAnnualAvgOccupancyInMonthsState(value);
          setAnnualAvgOccupancyInMonths(value);
        }}
        testID="annualAvgOccupancy-annual-turnover-indice-slider"
        value={annualAvgOccupancyInMonthsState}
      />
    ),
    [annualAvgOccupancyInMonthsState, setAnnualAvgOccupancyInMonths]
  );

  const annualTurnoverDescription = t(
    'shared.financialTool.tooltip.annualTurnoverIndiceDescription',
    {
      context: isFurnished ? 'furnished' : 'unfurnished',
    }
  );

  return (
    <>
      <PropertyAttribute
        label={t('shared.financialTool.annualAvgOccupancy.label')}
        value={t('shared.financialTool.annualAvgOccupancy.value', {
          amount: annualAvgOccupancyInMonthsState,
        })}
        importance={Importance.LOW}
        tooltip={{
          description: annualTurnoverDescription,
          onOpen: trackTooltipAnnualAvgOccupancy,
          title: t(`shared.financialTool.tooltip.annualTurnoverIndice`),
        }}
        isOdd
      />

      {!!isAnnualAvgOccupancyVisible && annualTurnoverIndiceSlider}
    </>
  );
};
