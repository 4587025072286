import React from 'react';

import { KeyboardAvoidingView, SafeAreaView, View } from 'react-native';

import { useResponsive } from '@masteos/aphrodite';

import { useUtmValues } from '@app/shared/hooks/useUtmValues.web';
import { LayoutSideText } from '@app/shared/components/LayoutSideText/LayoutSideText';

import { OnboardingContext } from './OnboardingContext';
import { OnboardingContent } from './onboardingContent/OnboardingContent';
import { getStyles } from './onboarding.styles';

export const Onboarding: React.FC = () => {
  const { isGreaterThanTablet } = useResponsive();
  const styles = getStyles({ isGreaterThanTablet });

  useUtmValues();

  return (
    <OnboardingContext>
      <SafeAreaView style={styles.safeAreaWrapper}>
        <KeyboardAvoidingView keyboardVerticalOffset={0} style={styles.safeMainWrapper}>
          <LayoutSideText>
            <View style={styles.mainWrapper}>
              <OnboardingContent />
            </View>
          </LayoutSideText>
        </KeyboardAvoidingView>
      </SafeAreaView>
    </OnboardingContext>
  );
};
