import { Alert, Platform, Share } from 'react-native';

import { logMessage } from '@app/services/errorMonitoring';

export interface Sharable {
  title?: string;
  url: string;
}

export const canShare = (content: Sharable, isMobile: boolean): boolean =>
  Platform.OS === 'ios' ||
  Platform.OS === 'android' ||
  (Platform.OS === 'web' && isMobile && navigator.canShare?.(content));

export const onShare = async (content: Sharable, isMobile: boolean): Promise<void> => {
  const { title, url } = content;

  const shouldUseShare = canShare(content, isMobile);

  if (shouldUseShare) {
    if (Platform.OS === 'web') {
      await shareFromWeb(title, url);
    } else {
      await shareFromIosOrAndroid(title, url);
    }
  } else {
    if (navigator?.clipboard?.writeText) {
      navigator.clipboard.writeText(url);
    } else {
      alert('Not supported');
    }
  }
};

async function shareFromIosOrAndroid(title: string, url: string) {
  try {
    await Share.share({
      message: `${title}\n${url}`,
      url,
    });
  } catch (error) {
    Alert.alert(error.message);
  }
}

async function shareFromWeb(title: string, url: string) {
  try {
    await navigator.share({
      title,
      url,
    });
  } catch (e) {
    if (!e.toString().includes('AbortError')) {
      logMessage(e.toString());
    }
  }
}
