import { Theme } from '@masteos/aphrodite';

import { PositioningStatus } from '@app/libs/apollo/introspection';

import { BannerProps } from './realEstateCardBanner.type';

export const getBannerProps = (theme: Theme): Partial<Record<PositioningStatus, BannerProps>> => ({
  [PositioningStatus.Pending]: {
    color: theme.palette.neutral[800],
    testID: 'real-estate-card-banner-pending',
    text: 'propertyPreview.banner.selectedTitle',
    tooltip: {
      content: 'propertyPreview.banner.tooltipSelectedDesc',
      title: 'propertyPreview.banner.tooltipSelectedTitle',
      tooltipType: 'Bien partagé au conseillé',
    },
  },

  [PositioningStatus.Confirmed]: {
    color: theme.palette.system.primary[500],
    testID: 'real-estate-card-banner-confirmed',
    text: 'propertyPreview.banner.confirmedTitle',
    tooltip: {
      content: 'propertyPreview.banner.tooltipConfirmedContent',
      title: 'propertyPreview.banner.tooltipConfirmedTitle',
      tooltipType: 'Positionnement confirmé',
    },
  },
  [PositioningStatus.Cancelled]: {
    color: theme.palette.base.black,
    testID: 'real-estate-card-banner-cancelled',
    text: 'propertyPreview.banner.cancelledTitle',
    tooltip: {
      content: 'propertyPreview.banner.cancelTooltipSelectedDesc',
      title: 'propertyPreview.banner.cancelTooltipSelectedTitle',
      tooltipType: 'Positionnement annulé',
    },
  },
  [PositioningStatus.PrePositioningCancelled]: {
    color: theme.palette.base.black,
    testID: 'real-estate-card-banner-pre-positioning-cancelled',
    text: 'propertyPreview.banner.prePositioningCancelledTitle',
    tooltip: {
      content: 'propertyPreview.banner.tooltipPrePositioningCancelledDesc',
      title: 'propertyPreview.banner.tooltipPrePositioningCancelledTitle',
      tooltipType: 'Pré-positionnement annulé',
    },
  },
});
