import React, { useState } from 'react';

import { GestureResponderEvent, StyleSheet, View } from 'react-native';
import ReactCodeInput from 'react-verification-code-input';
import { StackScreenProps } from '@react-navigation/stack';

import { Button, Spacer, Spacing, Text } from '@masteos/aphrodite';

import { useTranslation } from '@app/services/translations/translations';
import { trackForgotPasswordConfirmedCode } from '@app/services/tracking/trackTracking';
import {
  ForgotPasswordNavigatorRoutes,
  ForgotPasswordStackParamList,
} from '@app/navigation/types/routes';
import { identityClient } from '@app/libs/identity/identityClient';

import { FormStyleSheets, InputError } from '../forgotPassword.styles.web';
import { ForgotPasswordStepWrapper } from './ForgotPasswordStepWrapper.web';

const styles = StyleSheet.create({
  button: {
    bottom: 0,
    position: 'absolute',
  },
  codeSendText: {
    marginBottom: Spacing.SIZE_08,
    textAlign: 'center',
  },
  container: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
  enterCodeText: {
    marginBottom: Spacing.SIZE_08,
  },
});

type CodeStepScreenProp = StackScreenProps<
  ForgotPasswordStackParamList,
  ForgotPasswordNavigatorRoutes.ForgotPasswordCodeStep
>;

export const CodeStep: React.FC<CodeStepScreenProp> = ({ route, navigation }) => {
  const email = route.params.email?.toLowerCase().trim();
  const { t } = useTranslation();
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>(undefined);
  const codeLength = 6;

  const submit = (e: GestureResponderEvent | React.MouseEvent | string) => {
    const newCode = typeof e === 'string' ? e : code;

    setLoading(true);
    identityClient
      .resetPassword({ code: newCode, email })
      .then(({ hash }) => {
        setLoading(false);
        trackForgotPasswordConfirmedCode();
        navigation.navigate(ForgotPasswordNavigatorRoutes.ForgotPasswordNewPasswordStep, {
          email,
          hash: encodeURIComponent(hash),
        });
      })
      .catch(() => setError(t('forgotPassword.error.code')));
  };

  return (
    <ForgotPasswordStepWrapper>
      <View style={styles.container}>
        <Text textStyle="Body1" style={styles.codeSendText}>
          {t('forgotPassword.codeSend', { email })}
        </Text>

        <Text textStyle="Body3Medium" style={styles.enterCodeText}>
          {t('forgotPassword.enterCode')}
        </Text>
        <ReactCodeInput fieldHeight={40} fieldWidth={45} onChange={setCode} onComplete={submit} />
        {!!error && <InputError>{error}</InputError>}

        <Spacer height={82} />

        <View style={styles.button}>
          <Button
            style={FormStyleSheets.button}
            isLoading={loading}
            disabled={code.length < codeLength}
            onPress={submit}
          >
            {t('forgotPassword.confirmButton')}
          </Button>
        </View>
      </View>
    </ForgotPasswordStepWrapper>
  );
};
