import React from 'react';

import { createStackNavigator } from '@react-navigation/stack';
import { Platform } from 'react-native';

import { OnboardingView } from '@app/views/onboarding/OnboardingView';
import { LoginEmailPasswordView } from '@app/views/loginEmailPassword/LoginEmailPasswordView';
import { AuthBridgeView } from '@app/views/authBridge/AuthBridgeView';
import { ForgotPasswordNavigator } from '@app/navigation/ForgotPasswordNavigator';
import { SsoRedirect } from '@app/features/authBridge/sso-redirect/SsoRedirect.web';
import { RealEstateView } from '@app/views/realEstate/RealEstateView';

import { SCREEN_OPTIONS } from './constants/navigation-options';
import { PublicNavigatorRoutes, PublicStackParamList } from './types/routes';

const Stack = createStackNavigator<PublicStackParamList>();

const PublicNavigator: React.FC = () => (
  <Stack.Navigator screenOptions={SCREEN_OPTIONS}>
    <Stack.Screen name={PublicNavigatorRoutes.Auth} component={AuthBridgeView} />
    <Stack.Screen name={PublicNavigatorRoutes.Register} component={OnboardingView} />
    <Stack.Screen name={PublicNavigatorRoutes.ForgotPassword} component={ForgotPasswordNavigator} />
    <Stack.Screen name={PublicNavigatorRoutes.Login} component={LoginEmailPasswordView} />
    <Stack.Screen name={PublicNavigatorRoutes.SsoRedirect} component={SsoRedirect} />
    {Platform.OS === 'web' && (
      <Stack.Screen name={PublicNavigatorRoutes.RealEstate} component={RealEstateView} />
    )}
  </Stack.Navigator>
);

export { PublicNavigator };
