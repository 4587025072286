import React, { useCallback, useMemo, useRef, useState } from 'react';

import { ScrollView, View, Platform } from 'react-native';
import { useTranslation } from 'react-i18next';

import { Spacer, Text, useTheme } from '@masteos/aphrodite';

import { Form, FormContext } from '@app/shared/contexts/form/index.web';
import { config } from '@app/config';
import { trackEmailPasswordButtonClicked } from '@app/services/tracking/trackTracking';
import { DeletedAccountModal } from '@app/features/deletedAccountModal/DeletedAccountModal.web';
import { AuthenticationStrategy } from '@app/libs/identity/identity.type';
import { useDeletedAccountModal } from '@app/features/deletedAccountModal/useDeletedAccountModal';
import { DismissKeyboard } from '@app/shared/containers/DismissKeyboard';
import { SubscriptionHeader } from '@app/shared/components/SubscriptionHeader/SubscriptionHeader';
import { LayoutSideText } from '@app/shared/components/LayoutSideText/LayoutSideText';
import { RedirectByEmailProps, useRedirectByEmail } from '@app/shared/hooks/useRedirectByEmail';
import { PlatformEnum } from '@app/types/platform';
import { isFacebookBrowser, isInstagramBrowser } from '@app/utils/detectBrowser';
import { useToastContext } from '@app/shared/contexts/toast/Toast';
import { Provider } from '@app/libs/apollo/introspection';

import { AuthBridgeForm } from './authBridgeForm/AuthBridgeForm';
import { getValidatorRules } from './authBridge.rules';
import { getStyles } from './authBridge.styles';
import { SignInMethod } from './authBridge.constants';
import { SsoProvider } from './ssoProvider/SsoProvider';
import { useAuthBridgeInit } from './useAuthBridgeInit/useAuthBridgeInit';
import { SSOProfile } from './authBridge.types';
import { AppleSignIn } from './appleSignIn/AppleSignIn';
import { GoogleSignIn } from './googleSignIn/GoogleSignIn';

interface InitialValues {
  email: string;
}

const initialValues: InitialValues = {
  email: '',
};

interface AuthBridgeProps {
  deletedAccount?: boolean;
}

export const AuthBridge: React.FC<AuthBridgeProps> = ({ deletedAccount = false }) => {
  const [isLoading, setIsLoading] = useState(false);
  const appleSignInRef = useRef(null);
  const googleSignInRef = useRef(null);
  const { redirectByEmail, redirectByEmailSSO } = useRedirectByEmail();
  const { t } = useTranslation();
  const { setErrorMsg } = useToastContext();

  const theme = useTheme();
  const styles = getStyles(theme);
  const validatorRules = useMemo(() => getValidatorRules(t), [t]);

  const disableGoogle =
    Platform.OS === PlatformEnum.Web && (isInstagramBrowser() || isFacebookBrowser());

  const { isVisibleDeletedAccountModal, toggleIsVisibleDeletedAccountModal } =
    useDeletedAccountModal(deletedAccount);

  useAuthBridgeInit();

  const forceSignInWithSSO = async providers => {
    if (providers.includes(Provider.Google) && googleSignInRef.current) {
      googleSignInRef.current.signIn();
    } else if (providers.includes(Provider.Apple) && appleSignInRef.current) {
      appleSignInRef.current.signIn();
    }
  };

  const redirect = useCallback(
    async (p: RedirectByEmailProps) => {
      setIsLoading(true);
      try {
        if (p.strategy !== AuthenticationStrategy.Local) {
          await redirectByEmailSSO(p, setIsLoading);
        } else {
          await redirectByEmail(p, setIsLoading, forceSignInWithSSO);
        }
      } catch {
        setErrorMsg({
          title: t('authTunnel.apiErrorMessage'),
        });
      }
    },
    [redirectByEmail, redirectByEmailSSO]
  );

  const handleSubmitForm = useCallback(
    async (values: InitialValues): Promise<void> => {
      const email = values.email.toLowerCase().trim();
      trackEmailPasswordButtonClicked();

      return await redirect({
        method: SignInMethod.Mail,
        strategy: AuthenticationStrategy.Local,
        userInfo: {
          email,
        },
      });
    },
    [redirect]
  );

  const createSignInSSOHandler = useMemo(
    () =>
      (method: SignInMethod, strategy: AuthenticationStrategy) =>
      (idToken: string, userInfo: SSOProfile) =>
        redirect({
          idToken,
          method,
          strategy,
          userInfo,
        }),
    [redirect]
  );

  const handleSignInWithApple = useMemo(
    () => createSignInSSOHandler(SignInMethod.Apple, AuthenticationStrategy.Apple),
    [createSignInSSOHandler]
  );
  const handleSignInWithGoogle = useMemo(
    () => createSignInSSOHandler(SignInMethod.Google, AuthenticationStrategy.Google),
    [createSignInSSOHandler]
  );

  const onSignInFailure = () => setIsLoading(false);

  return (
    <DismissKeyboard>
      <>
        <SsoProvider
          googleWebClientId={config.WEB_GOOGLE_SSO_ID}
          googleIosClientId={config.IOS_GOOGLE_SSO_ID}
          googleAndroidClientId={config.ANDROID_GOOGLE_SSO_ID}
        >
          <LayoutSideText>
            <ScrollView>
              <SubscriptionHeader
                onyLanguageSwitcher
                languageSwitcherStyle={styles.languageSwitcher}
              />

              <View style={styles.root}>
                <Text style={styles.alignCenter} textStyle="Title2">
                  {t('authTunnel.title3')}
                </Text>
                <Text textStyle="Body2" style={styles.subtitle}>
                  {t('authTunnel.title2')}
                </Text>

                <Form
                  schema={validatorRules}
                  onSubmit={handleSubmitForm}
                  initialValues={initialValues}
                >
                  <FormContext.Consumer>
                    {({ handleSubmit }) => (
                      <AuthBridgeForm isLoading={isLoading} onSubmit={handleSubmit} />
                    )}
                  </FormContext.Consumer>
                </Form>

                <Text textStyle="Body2" style={styles.orText}>
                  {t('shared.or')}
                </Text>

                {disableGoogle ? null : (
                  <>
                    <View style={styles.buttonContainer}>
                      <GoogleSignIn
                        isLoading={isLoading}
                        ref={googleSignInRef}
                        onSignInSuccess={handleSignInWithGoogle}
                        onSignInFailure={onSignInFailure}
                      />
                    </View>

                    <Spacer height={theme.spacing.SIZE_06} />
                  </>
                )}

                <View style={styles.buttonContainer}>
                  <AppleSignIn
                    isLoading={isLoading}
                    ref={appleSignInRef}
                    onSignInSuccess={handleSignInWithApple}
                    onSignInFailure={onSignInFailure}
                  />
                </View>

                <Spacer height={theme.spacing.SIZE_10} flex={1} />
              </View>
            </ScrollView>
          </LayoutSideText>
        </SsoProvider>

        <DeletedAccountModal
          hideModal={toggleIsVisibleDeletedAccountModal}
          visible={isVisibleDeletedAccountModal}
        />
      </>
    </DismissKeyboard>
  );
};
