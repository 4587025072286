import { RoomCountFilter } from '@app/libs/apollo/introspection';

import { Room } from '../searchEngineFilters.type';

export const getRoomList: (t) => Room[] = t => [
  { label: '1', roomCount: RoomCountFilter.One },
  { label: '2', roomCount: RoomCountFilter.Two },
  { label: '3', roomCount: RoomCountFilter.Three },
  { label: '4', roomCount: RoomCountFilter.Four },
  { label: '5', roomCount: RoomCountFilter.Five },
  {
    label: t('propertiesPreferences.sixAndMore'),
    roomCount: RoomCountFilter.SixOrMore,
  },
];
