import React from 'react';

import { StackScreenProps } from '@react-navigation/stack';

import { LoaderMasteos } from '@masteos/aphrodite';

import { useQueryFinancialModelTemplates } from '@app/shared/hooks/useQueryFinancialModelTemplates';
import { PropertyInfo } from '@app/features/propertyInfo/PropertyInfo.web';
import { useQueryRealEstate } from '@app/shared/hooks/useQueryRealEstate';
import { RealEstate } from '@app/libs/apollo/introspection';
import { RealEstateProvider } from '@app/shared/contexts/realEstate/RealEstateProvider';
import { useViewRealEstate } from '@app/shared/hooks/useViewRealEstate';
import { GalleryProvider } from '@app/shared/components/Gallery/GalleryProvider';
import { RealEstateNavigatorRoutes, RealEstatesStackParamList } from '@app/navigation/types/routes';

const styles = {
  loader: {
    alignItems: 'center',
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
  },
};

type RealEstatesStackRouteProps = StackScreenProps<
  RealEstatesStackParamList,
  RealEstateNavigatorRoutes.RealEstate
>;

export const RealEstateView: React.FC<RealEstatesStackRouteProps> = ({ route, navigation }) => {
  const { position: propertyPosition, id: realEstateId, currentScrollPosition } = route.params;

  const { loading, error, realEstate } = useQueryRealEstate({ id: realEstateId });
  const { loading: templatesLoading } = useQueryFinancialModelTemplates();

  useViewRealEstate(realEstate as RealEstate);

  if (loading || templatesLoading) {
    return (
      <div style={styles.loader}>
        <LoaderMasteos variant="inLight" />
      </div>
    );
  }

  if (!realEstate) {
    if (error instanceof Error) {
      navigation.navigate(RealEstateNavigatorRoutes.RealEstateList);
    }
    return null;
  }

  return (
    <RealEstateProvider realEstate={realEstate}>
      <GalleryProvider propertyId={realEstate.id}>
        <PropertyInfo
          propertyPosition={propertyPosition}
          property={realEstate as RealEstate}
          isFromSearchEngine={route.params.isFromSearchEngine}
          currentScrollPosition={currentScrollPosition}
        />
      </GalleryProvider>
    </RealEstateProvider>
  );
};

// eslint-disable-next-line import/no-default-export
export default RealEstateView;
