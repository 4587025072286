import React from 'react';

import { Spacer, Text, useResponsive, useTheme } from '@masteos/aphrodite';

import { InvestmentWarning } from '@app/features/investmentWarning/InvestmentWarning';
import { useFinancialModel } from '@app/shared/hooks/useFinancialModel';
import { RealEstate } from '@app/libs/apollo/introspection';
import { useRealEstateTranslation } from '@app/shared/hooks/useRealEstateTranslations';

import { PropertyEnrichmentProjection } from './PropertyEnrichmentProjection';
import { PropertyEnrichmentProjectionSummary } from './PropertyEnrichmentProjectionSummary';
import { PropertyFinancialAnalysisSummary } from './PropertyFinancialAnalysisSummary';
import { PropertyFinancialAnnualCharges } from './PropertyFinancialAnnualCharges';
import { PropertyFinancialAnnualIncomes } from './annualIncomes/PropertyFinancialAnnualIncomes';
import { PropertyFinancialFunding } from './PropertyFinancialFunding';
import { PropertyFinancialInvestment } from './PropertyFinancialInvestment';
import { PropertyFinancialResults } from './PropertyFinancialResults';
import { PropertyFinancialSummary } from './PropertyFinancialSummary';

import './propertyFinancesWeb.css';

interface PropertyFinancesProps {
  property?: RealEstate;
}

export const PropertyFinances: React.FC<PropertyFinancesProps> = ({ property }) => {
  const { t } = useRealEstateTranslation();
  const { isLowerThanLaptop } = useResponsive();
  const theme = useTheme();
  const {
    defaultFinancialModel,
    evaluationRate,
    financialModel,
    isPaymentCash,
    loanDown,
    loanDuration,
    loanInterestRate,
    resellingYear,
    setEvaluationRate,
    setIsPaymentCash,
    setLoanDown,
    setLoanDuration,
    setLoanInterestRate,
    setResellingYear,
    setWithBrokerageFees,
    setWithPropertyManagement,
    setAnnualAvgOccupancyInMonths,
    withBrokerageFees,
    withPropertyManagement,
    loading,
  } = useFinancialModel(property);

  if (loading || !defaultFinancialModel || !financialModel) {
    return null;
  }

  return (
    <>
      <Text textStyle="Title3Medium">{t('shared.financialTool.financialsTitle')}</Text>
      <Spacer height={theme.spacing.SIZE_08} />

      <>
        <PropertyFinancialSummary
          annualRentIncome={property.project.renting.annual.income}
          financialModel={financialModel}
          propertyId={property.id}
        />

        <Spacer height={theme.spacing.SIZE_04} />

        <PropertyFinancialInvestment
          financialModel={financialModel}
          isPaymentCash={isPaymentCash}
          property={property}
          setWithBrokerageFees={setWithBrokerageFees}
          withBrokerageFees={withBrokerageFees}
        />
        <Spacer height={theme.spacing.SIZE_08} />

        <PropertyFinancialFunding
          defaultFinancialModel={defaultFinancialModel}
          financialModel={financialModel}
          isPaymentCash={isPaymentCash}
          loanDown={loanDown}
          loanDuration={loanDuration}
          loanInterestRate={loanInterestRate}
          property={property}
          setIsPaymentCash={setIsPaymentCash}
          setLoanDown={setLoanDown}
          setLoanDuration={setLoanDuration}
          setLoanInterestRate={setLoanInterestRate}
        />

        <Spacer height={theme.spacing.SIZE_10} />
        <Text textStyle={isLowerThanLaptop ? 'Headline1Medium' : 'Title3Medium'}>
          {t('shared.financialTool.enrichmentProjection')}
        </Text>
        <Spacer height={theme.spacing.SIZE_08} />
        <PropertyEnrichmentProjectionSummary
          enrichmentCoeficient={financialModel?.resellingInvestmentCapitalMultiplier}
          enrichmentResellingYear={financialModel?.resellingNetEnrichment}
          resellingYear={resellingYear}
          resellingYearCashFlow={financialModel?.resellingAvaibleCashFlow}
          resellingCumulativeCashFlow={financialModel?.resellingCumulativeCashFlow}
          propertyId={property.id}
        />

        <Spacer height={theme.spacing.SIZE_08} />
        <PropertyEnrichmentProjection
          propertyId={property.id}
          trendingAppreciation={property.trending?.appreciation}
          bankCapitalStillDue={financialModel?.resellingCapitalStillDue}
          cumulativeCashFlow={financialModel?.resellingCumulativeCashFlow}
          enrichementResellingYear={financialModel?.resellingNetEnrichment}
          evaluationRate={evaluationRate}
          loanDownAmount={financialModel?.loanDownPayment}
          resellingPrice={financialModel?.resellingYearPrice}
          resellingYear={resellingYear}
          resellingYearCashFlow={financialModel?.resellingAvaibleCashFlow}
          setEvaluationRate={setEvaluationRate}
          setResellingYear={setResellingYear}
        />

        <Spacer height={theme.spacing.SIZE_08} />

        <>
          <Text textStyle={isLowerThanLaptop ? 'Headline1Medium' : 'Title3Medium'}>
            {t('shared.financialTool.analysisTitle')}
          </Text>
          <Spacer height={theme.spacing.SIZE_08} />
          <PropertyFinancialAnalysisSummary
            financialModel={financialModel}
            financialModelVersion={property.financialModelVersion}
            propertyId={property.id}
          />
          <Spacer height={theme.spacing.SIZE_04} />

          <PropertyFinancialAnnualIncomes
            annualCharges={property.annual.charges}
            annualRentIncome={property.project.renting.annual.income}
            annualTotalIncome={financialModel.annualTotalIncome}
            annualAvgOccupancyInMonths={financialModel.annualTurnoverIndice}
            financialModelVersion={property.financialModelVersion}
            isFurnished={property.project.furnishing.isFurnished ?? true}
            countryCode={property.building.address.countryCode}
            propertyId={property.id}
            setAnnualAvgOccupancyInMonths={setAnnualAvgOccupancyInMonths}
          />
          <Spacer height={theme.spacing.SIZE_08} />
          <PropertyFinancialAnnualCharges
            financialModel={financialModel}
            property={property}
            setWithPropertyManagement={setWithPropertyManagement}
            withPropertyManagement={withPropertyManagement}
          />
          <Spacer height={theme.spacing.SIZE_08} />
          <PropertyFinancialResults financialModel={financialModel} />
          <Spacer height={theme.spacing.SIZE_08} />
        </>

        <InvestmentWarning />
      </>
    </>
  );
};
