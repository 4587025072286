import React from 'react';

import { View } from 'react-native';

import { Button, ModalTitle, Spacer, Text, useResponsive, useTheme } from '@masteos/aphrodite';

import { useTranslation } from '@app/services/translations/translations';

import { getStyles } from './ApprovePositioningModal.styles';
import { ApprovePositioningModalProps } from './ApprovePositioningModal.types';

export const ApprovePositioningModal: React.FC<ApprovePositioningModalProps> = ({
  onCancel,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { isNativeOrResponsive } = useResponsive();
  const styles = getStyles({ isNativeOrResponsive });

  return (
    <>
      <ModalTitle title={t('suggestions.actions.interested.title')} />
      <Text textStyle="Body3">{t('suggestions.actions.interested.description')}</Text>
      <Spacer height={theme.spacing.SIZE_08} />
      <View style={styles.actionsContainer}>
        <Button
          style={styles.actionButton}
          variant="tertiary"
          onPress={onCancel}
          testID="suggestions-actions-interested-cancelButton"
        >
          {t('shared.cancel')}
        </Button>
        <Spacer height={theme.spacing.SIZE_03} width={theme.spacing.SIZE_03} />
        <Button
          style={styles.actionButton}
          variant="primary"
          onPress={onSubmit}
          testID="suggestions-actions-interested-submitButton"
        >
          {t('suggestions.actions.interested.submitButton')}
        </Button>
      </View>
    </>
  );
};
