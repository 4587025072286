import React from 'react';

import * as ErrorMonitoring from '@app/services/errorMonitoring';

export const DEFAULT_LAZY_RETRIES = 5;

const RETRY_TIME = 1500;

type ComponentLoader = <T = unknown>(
  lazyComponent: () => Promise<{
    default: React.FC<T>;
  }>,
  attemptsLeft: number
) => Promise<{
  default: React.FC<T>;
}>;

export const componentLoader: ComponentLoader = (lazyComponent, attemptsLeft) => {
  return new Promise(resolve => {
    lazyComponent()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          if (attemptsLeft === 1) {
            ErrorMonitoring.logError(error);
            location.reload();

            return;
          }

          componentLoader(lazyComponent, attemptsLeft - 1).then(resolve);
        }, RETRY_TIME);
      });
  });
};
