import React from 'react';

import { snakeCase } from 'lodash';
import { ViewStyle } from 'react-native';

import { DataBlock, useTranslationContentContext } from '@masteos/aphrodite';
import { TranslationContentValue } from '@masteos/aphrodite/lib/typescript/atoms/translation-content/TranslationContent.types';

import { SynthesisTheme } from '@app/libs/apollo/introspection';

import { getIconFromTheme } from './propertySynthesisItem.utils';

export const PropertySynthesisItem: React.FC<{
  t: (key: string) => string;
  thematic: SynthesisTheme;
  translation: TranslationContentValue;
  style?: ViewStyle;
}> = ({ t, thematic, translation, style }) => {
  const { showTranslation } = useTranslationContentContext();
  const textContent = showTranslation ? translation.translatedText : translation.sourceText;

  return (
    <DataBlock
      style={style}
      key={thematic}
      title={textContent}
      icon={getIconFromTheme(thematic)}
      data={t(`propertySynthesis.theme.${snakeCase(thematic)}`)}
    />
  );
};
