import React, { useCallback, useState } from 'react';

import { View } from 'react-native';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { useTheme } from '@masteos/aphrodite';

import { LayoutSideText } from '@app/shared/components/LayoutSideText/LayoutSideText';
import { Form } from '@app/shared/contexts/form/Form.web';
import { DismissKeyboard } from '@app/shared/containers/DismissKeyboard';
import { resetInfinityScroll } from '@app/utils/propertiesSearchResults';
import { SubscriptionHeader } from '@app/shared/components/SubscriptionHeader/SubscriptionHeader';
import { PublicNavigatorRoutes, PublicStackParamList } from '@app/navigation/types/routes';
import { useQueryParam } from '@app/shared/hooks/useQueryParams';
import { useAuthentication } from '@app/shared/hooks/useAuthentication';

import { LoginEmailPasswordForm } from './LoginEmailPasswordForm';
import { getStyles } from './loginEmailPassword.style';

interface InitialValues {
  email: string;
  password: string;
}

type LoginEmailPasswordNavigationProp = StackNavigationProp<
  PublicStackParamList,
  PublicNavigatorRoutes.Login
>;

export const LoginEmailPassword: React.FC = () => {
  const { t } = useTranslation();
  const navigation = useNavigation<LoginEmailPasswordNavigationProp>();
  const theme = useTheme();
  const { loginWithEmailPassword } = useAuthentication();
  const styles = getStyles(theme);

  const [error, setError] = useState<string>(null);
  const [loading, setLoading] = useState(false);
  const emailFromQueryParam = useQueryParam('email')?.toLowerCase().trim();

  const initialValues: InitialValues = {
    email: emailFromQueryParam,
    password: '',
  };
  const schema = yup.object().shape({
    email: yup.string(),
    password: yup.string(),
  });

  const handleGoBack = () => navigation.navigate(PublicNavigatorRoutes.Auth);

  const onLogin = useCallback(
    async ({ email, password }: { email: string; password: string }): Promise<void> => {
      setLoading(true);
      loginWithEmailPassword(email.toLowerCase().trim(), password.trim())
        .then(async () => resetInfinityScroll())
        .catch(err => {
          if (err.response.status === 401) {
            setError(t('login.invalidCreds'));
          } else {
            setError(t('shared.genericError'));
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [t, loginWithEmailPassword]
  );

  const cleanError = () => {
    setError(null);
  };

  return (
    <LayoutSideText>
      <SubscriptionHeader title={t('shared.connexion')} onPressArrow={handleGoBack} />
      <DismissKeyboard>
        <View style={styles.root}>
          <Form initialValues={initialValues} schema={schema} onSubmit={onLogin}>
            {({ handleSubmit }) => (
              <LoginEmailPasswordForm
                onSubmit={handleSubmit}
                loginError={error}
                loginLoading={loading}
                cleanError={cleanError}
              />
            )}
          </Form>
        </View>
      </DismissKeyboard>
    </LayoutSideText>
  );
};
