import React, { useState } from 'react';

import { ScrollView, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';

import { palette, Spacer, Spacing, Text } from '@masteos/aphrodite';

import { useTranslation } from '@app/services/translations/translations';
import { getAPILang } from '@app/libs/i18n/helper';
import { I18nSupportedLanguage } from '@app/libs/i18n/i18n.types';
import { useToastContext } from '@app/shared/contexts/toast/Toast';

import { MenuItem } from './compounds/MenuItem';
import { DebugMenuHeader } from './compounds/DebugMenuHeader';
import { DebugMenuWrapper } from './compounds/DebugMenuWrapper';
import { DebugMenuContentWrapper } from './compounds/DebugMenuContentWrapper';

const ciMode = 'cimode';

export const DebugMenuTranslations: React.FC = () => {
  const navigation = useNavigation();
  const { setValidMsg } = useToastContext();
  const { i18n } = useTranslation();
  const [apiMode, setApiMode] = useState(
    getAPILang(i18n.resolvedLanguage as I18nSupportedLanguage) ?? ciMode
  );

  const languageList = [ciMode, ...Object.values(I18nSupportedLanguage)];

  const toggleLanguage = state => async () => {
    await i18n.changeLanguage(state === apiMode ? '' : state);
    setValidMsg({ icon: 'Check', title: `Translation changed to ${state}` });
    setApiMode(getAPILang(i18n.resolvedLanguage as I18nSupportedLanguage) ?? ciMode);
  };

  return (
    <DebugMenuWrapper>
      <DebugMenuHeader
        title="Translations"
        onPress={() => navigation.goBack()}
        subtitle="List of all translations"
      />
      <ScrollView>
        <DebugMenuContentWrapper>
          <Text textStyle="Caption1" style={{ color: palette.neutral[700] }}>
            Current language
          </Text>

          <Text textStyle="Body1Demi" style={{ color: palette.system.primary[500] }}>
            {apiMode ?? ciMode}
          </Text>

          <Spacer height={Spacing.SIZE_07} />

          <Text textStyle="Caption1" style={{ color: palette.neutral[700] }}>
            Available languages
          </Text>
          <Spacer height={Spacing.SIZE_03} />

          {languageList.map((lang, index) => (
            <View key={lang} testID="menu-item">
              <MenuItem
                icon="LanguageFilled"
                title={lang}
                onPress={toggleLanguage(lang)}
                hasTopRadius={index === 0}
                hasBottomRadius={index === languageList.length - 1}
              />
            </View>
          ))}
        </DebugMenuContentWrapper>
      </ScrollView>
    </DebugMenuWrapper>
  );
};
