import React, { useCallback } from 'react';

import { StyleSheet, View } from 'react-native';

import { palette, Spacing, Expandable, Spacer } from '@masteos/aphrodite';
import { FinancialModel } from '@masteos/agora';

import {
  trackBrokerFees,
  trackFinancialToggle,
  trackFinancialUntoggle,
} from '@app/services/tracking/trackTracking';
import { currencyFormatter } from '@app/utils/currency-formatter';
import { RealEstate } from '@app/libs/apollo/introspection';
import { useRealEstateTranslation } from '@app/shared/hooks/useRealEstateTranslations';

import {
  Importance,
  PropertyAttribute,
  PropertyAttributeProps,
} from '../propertyDescription/PropertyAttribute';
import { PropertyFinancialHeader } from './propertyFinancialHeader';
import { getInvestmentAttributes, getAttributesByCountryCode } from './PropertyAttributesMapper';
import { areEqual, differentFuncParameters } from './PropertyFinances.utils';

const styles = StyleSheet.create({
  border: {
    borderBottomColor: palette.neutral[300],
    borderBottomWidth: 1,
    width: '100%',
  },
  container: {
    borderColor: palette.neutral[300],
    borderRadius: 4,
    borderWidth: 1,
    paddingBottom: Spacing.SIZE_04,
    paddingHorizontal: Spacing.SIZE_06,
    paddingTop: Spacing.SIZE_03,
    width: '100%',
  },
});

export interface PropertyFinancialInvestmentProps {
  property: RealEstate;
  financialModel: FinancialModel;
  withBrokerageFees: boolean;
  isPaymentCash: boolean;
  setWithBrokerageFees: (v: boolean) => void;
}

const isOdd = (index: number): boolean => index % 2 !== 0;

export const PropertyFinancialInvestment: React.FC<PropertyFinancialInvestmentProps> = React.memo(
  ({ property, financialModel, withBrokerageFees, isPaymentCash, setWithBrokerageFees }) => {
    const { t } = useRealEstateTranslation();
    const projectAmount = currencyFormatter(financialModel.projectAmount, 0);
    const handleBrokerageFeesCheckbox = useCallback((value: boolean) => {
      trackBrokerFees({ isChecked: value, propertyId: property.id });
      setWithBrokerageFees(value);
    }, []);
    const { countryCode } = property.building.address;

    const trackToggle = ({ expanded }) =>
      expanded
        ? trackFinancialUntoggle({ propertyId: property.id, sectionName: 'project' })
        : trackFinancialToggle({ propertyId: property.id, sectionName: 'project' });

    const mapperData = {
      bankCommission: currencyFormatter(financialModel.bankCommission, 0),
      brokerageFees: currencyFormatter(financialModel.brokerageFees, 0),
      deedPurchaseFees: currencyFormatter(financialModel?.stateAssetsTransferTax, 0),
      financialModelVersion: property.financialModelVersion,
      furnituresAmount: currencyFormatter(property.project.furnishing.amount, 0),
      handleBrokerageFeesCheckbox,
      isPaymentCash,
      masteosCommission: currencyFormatter(financialModel.masteosCommission, 0),
      notaryCommission: currencyFormatter(financialModel.notaryCommission, 0),
      renovationAmount: currencyFormatter(property.project.renovation.amount, 0),
      salePrice: currencyFormatter(financialModel.salePrice, 0),
      stateAssetsTransferTax: currencyFormatter(financialModel?.stateAssetsTransferTax, 0),
      withBrokerageFees,
    };

    const investmentAttributesData = getInvestmentAttributes(mapperData, t, property.id);
    const data = getAttributesByCountryCode(investmentAttributesData, countryCode);

    return (
      <View style={styles.container} testID="project-cost">
        <Expandable onPress={trackToggle}>
          <Expandable.Header testID="project-cost-header">
            <Expandable.Context.Consumer>
              {({ expanded }) => (
                <PropertyFinancialHeader
                  title={t(`shared.financialTool.projectCost`)}
                  icon="Wallet"
                  hasChevron
                  chevronOpen={expanded}
                />
              )}
            </Expandable.Context.Consumer>
          </Expandable.Header>
          <Expandable.Content>
            {data.map((d: PropertyAttributeProps, index: number) => (
              <PropertyAttribute
                key={d?.testID}
                label={d?.label}
                value={d?.value}
                importance={d?.importance}
                isOdd={isOdd(index + 1)}
                testID={d?.testID}
                tooltip={d?.tooltip}
                inputSwitch={d?.inputSwitch}
              />
            ))}

            <Spacer height={Spacing.SIZE_05} />

            <View style={styles.border} />

            <Spacer height={Spacing.SIZE_04} />

            <PropertyAttribute
              label={t('shared.financialTool.projectAmount')}
              value={projectAmount}
              importance={Importance.HIGH}
              testID="property-financial-line-project-amount"
            />
          </Expandable.Content>
        </Expandable>
      </View>
    );
  },
  areEqual(
    ['isPaymentCash', 'withBrokerageFees'],
    (prevState: PropertyFinancialInvestmentProps, nextState: PropertyFinancialInvestmentProps) => {
      return differentFuncParameters(prevState, nextState, [
        'projectAmount',
        'brokerageFees',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        'deedPurchaseFees',
        'masteosCommission',
        'notaryCommission',
        'bankCommission',
        'stateAssetsTransferTax',
        'salePrice',
      ]);
    }
  )
);

PropertyFinancialInvestment.displayName = 'PropertyFinancialInvestment';
