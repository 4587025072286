import { useEffect, useReducer } from 'react';

import {
  FinancialModel,
  FinancialModelComputationProps,
  financialModelEngine as getFinancialModel,
  PropertyKind,
  LocationType,
} from '@masteos/agora';

import { useQueryFinancialModelTemplates } from '@app/shared/hooks/useQueryFinancialModelTemplates';
import { RealEstate } from '@app/libs/apollo/introspection';

import { FinancialModelActions } from './actions';
import { financialModelReducer, initialState } from './reducer';

export interface UseFinancialModelOutput {
  defaultFinancialModel: FinancialModel;
  evaluationRate: number;
  financialModel: FinancialModel;
  isPaymentCash: boolean;
  loanDown: number;
  loanDuration: number;
  loading: boolean;
  loanInterestRate: number;
  resellingYear: number;
  setEvaluationRate: (v: number) => void;
  setIsPaymentCash: (v: boolean) => void;
  setLoanDown: (v: number) => void;
  setLoanDuration: (v: number) => void;
  setLoanInterestRate: (v: number) => void;
  setResellingYear: (v: number) => void;
  setWithBrokerageFees: (v: boolean) => void;
  setWithPropertyManagement: (v: boolean) => void;
  setAnnualAvgOccupancyInMonths: (v: number) => void;
  withBrokerageFees: boolean;
  withPropertyManagement: boolean;
}

export const realEstateToFinancialModelCompProps = (
  realEstate: RealEstate
): Partial<FinancialModelComputationProps> => {
  return {
    financialData: {
      agencySellerCommission: realEstate.project.transaction.commission.agency,
      annualCharges: realEstate.annual.charges,
      annualCurrentExpenses: realEstate.building.annual.expenses,
      annualHomeOwnerInsurance: realEstate.annual.ownerInsurance,
      annualPropertyTax: realEstate.annual.tax,
      annualRentIncome: realEstate.project.renting.annual.income,
      evaluationRate: realEstate.trending?.appreciation?.evaluationRate,
      furnituresAmount: realEstate.project.furnishing.amount,
      netSeller: realEstate.project.transaction.netSeller,
      renovationAmount: realEstate.project.renovation.amount,
    },
    financialModelVersion: realEstate.financialModelVersion,
    propertyData: {
      isFurnished: realEstate.project.furnishing.isFurnished ?? true,
      locationType: realEstate.project.renting.type?.toUpperCase() as LocationType,
      regionCode: realEstate.building.address.regionCode,
      surface: realEstate.surface,
      type: realEstate.type as unknown as PropertyKind,
    },
  };
};

export const useFinancialModel = (realEstate: RealEstate): UseFinancialModelOutput => {
  const compProps = realEstateToFinancialModelCompProps(realEstate);

  const [financialModelState, dispatchFinancialModel] = useReducer(
    financialModelReducer,
    initialState
  );
  const { templates, loading } = useQueryFinancialModelTemplates();

  const calc = () => {
    const fiscality = {
      isPaymentCash: financialModelState.params.isPaymentCash,
      loanDownPayment: financialModelState.params.loanDown,
      loanDuration: financialModelState.params.loanDuration,
      loanInterestRate: financialModelState.params.loanInterestRate,
      withBrokerageFees: financialModelState.params.withBrokerageFees,
      withPropertyManagement: financialModelState.params.withPropertyManagement,
    };

    const resellingData = {
      resellingAnnualPercentEvolution: financialModelState.params.evaluationRate / 100,
      resellingYear: financialModelState.params.resellingYear,
    };

    const financialData = {
      ...compProps.financialData,
      annualAvgOccupancyInMonths: financialModelState.params.annualAvgOccupancyInMonths,
    };

    const fm = getFinancialModel({
      ...compProps,
      financialData,
      fiscality,
      resale: resellingData,
      templates,
    } as FinancialModelComputationProps);

    dispatchFinancialModel({
      payload: fm,
      type: FinancialModelActions.UPDATE_FINANCIAL_MODEL,
    });
  };

  useEffect(() => {
    if (!!templates && !financialModelState.financialModelComputation) {
      const fm = getFinancialModel({
        ...compProps,
        templates,
      } as FinancialModelComputationProps);
      dispatchFinancialModel({
        payload: {
          compProps,
          fm,
        },
        type: FinancialModelActions.INIT,
      });
    }

    const timout = setTimeout(() => {
      if (financialModelState.financialModelComputation) {
        calc();
      }
    }, 0);

    return () => {
      clearTimeout(timout);
    };
  }, [realEstate.id, templates, financialModelState.params]);

  const setLoanDown = (loanDown: number) => {
    dispatchFinancialModel({
      payload: loanDown,
      type: FinancialModelActions.SET_LOAN_DOWN,
    });
  };

  const setEvaluationRate = (evaluationRate: number) => {
    dispatchFinancialModel({
      payload: evaluationRate,
      type: FinancialModelActions.SET_EVALUATION_RATE,
    });
  };

  const setIsPaymentCash = (isPaymentCash: boolean) => {
    dispatchFinancialModel({
      payload: isPaymentCash,
      type: FinancialModelActions.SET_IS_PAYMENT_CASH,
    });
  };

  const setLoanDuration = (loanDuration: number) => {
    dispatchFinancialModel({
      payload: loanDuration,
      type: FinancialModelActions.SET_LOAN_DURATION,
    });
  };

  const setLoanInterestRate = (loanInterestRate: number) => {
    dispatchFinancialModel({
      payload: loanInterestRate,
      type: FinancialModelActions.SET_LOAN_INTEREST_RATE,
    });
  };

  const setResellingYear = (resellingYear: number) => {
    dispatchFinancialModel({
      payload: resellingYear,
      type: FinancialModelActions.SET_RESELLING_YEAR,
    });
  };

  const setWithBrokerageFees = (withBrokerageFees: boolean) => {
    dispatchFinancialModel({
      payload: withBrokerageFees,
      type: FinancialModelActions.SET_WITH_BROKERAGE_FEES,
    });
  };

  const setWithPropertyManagement = (propertyManagement: boolean) => {
    dispatchFinancialModel({
      payload: propertyManagement,
      type: FinancialModelActions.SET_WITH_PROPERTY_MANAGEMENT,
    });
  };

  const setAnnualAvgOccupancyInMonths = (annualAvgOccupancyInMonths: number) => {
    dispatchFinancialModel({
      payload: annualAvgOccupancyInMonths,
      type: FinancialModelActions.SET_ANNUAL_AVG_OCCUPANCY_IN_MONTHS,
    });
  };

  return {
    defaultFinancialModel: financialModelState.defaultFinancialModelComputation,
    evaluationRate: financialModelState.params.evaluationRate,
    financialModel: financialModelState.financialModelComputation,
    isPaymentCash: financialModelState.params.isPaymentCash,
    loading,
    loanDown: financialModelState.params.loanDown,
    loanDuration: financialModelState.params.loanDuration,
    loanInterestRate: financialModelState.params.loanInterestRate,
    resellingYear: financialModelState.params.resellingYear,
    setAnnualAvgOccupancyInMonths,
    setEvaluationRate,
    setIsPaymentCash,
    setLoanDown,
    setLoanDuration,
    setLoanInterestRate,
    setResellingYear,
    setWithBrokerageFees,
    setWithPropertyManagement,
    withBrokerageFees: financialModelState.params.withBrokerageFees,
    withPropertyManagement: financialModelState.params.withPropertyManagement,
  };
};
