/* eslint-disable sort-keys, sort-keys-fix/sort-keys-fix */

import { assign, createMachine } from 'xstate';

import { InvestBudget } from '@masteos/agora';

import { storage } from '@app/services/storage/storage';

import { registerMachineStates } from './registerMachine/registerMachine.states';
import { OnboardingMachineContext, OnboardingMachineEvent } from './stateMachine.types';

export const ONBOARDING_REGISTER_UNIVERSAL_STORAGE_KEY = 'masteos.onboarding';

interface OnboardingStorage {
  firstName?: string;
  lastName?: string;
  email?: string;
  isSubscribeNewsletter?: boolean;
}
interface RegisterInfos {
  email: string;
  firstName: string;
  lastName: string;
  subscribed: boolean;
  investBudget: InvestBudget;
  hasBorrowingCapacity: boolean;
}

const invokeSynchronization = async (): Promise<OnboardingStorage> => {
  const onboardingStorage = await storage.readObject<OnboardingStorage>('masteos.onboarding');
  const oldRegisterData = await storage.readObject<RegisterInfos>('masteos.register');

  let isSubscribeNewsletter = false;
  const oldRegisterDataExists = oldRegisterData && typeof oldRegisterData.subscribed === 'boolean';
  const onboardingStorageExists =
    onboardingStorage && typeof onboardingStorage.isSubscribeNewsletter === 'boolean';

  if (oldRegisterDataExists) {
    isSubscribeNewsletter = oldRegisterData.subscribed;
  } else if (onboardingStorageExists) {
    ({ isSubscribeNewsletter } = onboardingStorage);
  }

  return {
    isSubscribeNewsletter,
    firstName: oldRegisterData?.firstName || onboardingStorage?.firstName,
    lastName: oldRegisterData?.lastName || onboardingStorage?.lastName,
    email: oldRegisterData?.email || onboardingStorage?.email,
  };
};

const willResumeToStepRegister = () => true; // Used for @xstate/inspect

export const onboardingMachine = createMachine<OnboardingMachineContext, OnboardingMachineEvent>({
  id: 'onboardingFunnel',
  initial: 'initialization',
  context: {
    error: '',
    isLoading: false,
    url: null,
    firstName: '',
    lastName: '',
    email: '',
    isSubscribeNewsletter: false,
  },
  states: {
    initialization: {
      initial: 'synchronizeState',

      states: {
        synchronizeState: {
          invoke: {
            id: 'invokeSynchronization',
            src: invokeSynchronization,
            onError: 'resolveSynchronization',
            onDone: {
              target: 'resolveSynchronization',
              actions: assign((_, event) => event.data),
            },
          },
        },

        resolveSynchronization: {
          always: [
            {
              target: '#onboardingFunnel.stepRegister',
              cond: willResumeToStepRegister,
            },
          ],
        },
      },
    },

    stepRegister: { ...registerMachineStates.stepRegister },
  },
});
