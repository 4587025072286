import { fromS3ID } from '@app/utils/from-s3id';
import { PropertyStatuses } from '@app/libs/apollo/introspection';
import { RealEstateItem } from '@app/shared/components/real-estate-card/realEstateCard.type';

import { realEstateToItemMapperProps } from '../searchEngine.types';

export const realEstateToItemMapper = ({
  id,
  type,
  coverImage,
  surface,
  roomCount,
  annual,
  project,
  building,
  status,
  isNew,
  isExclusive,
  metadata: { isFavorites: isBookmarked, isViewed, positioning },
  trending,
  financialModelVersion: version,
}: Partial<realEstateToItemMapperProps>): RealEstateItem => {
  const {
    address: { city, regionCode },
    totalLots: lots,
  } = building;

  const { projectAmount: price } = project || {};
  return {
    annual,
    appreciationZone: trending?.appreciation?.appreciationZone,
    bedrooms: roomCount,
    building,
    grossReturnRate: annual.grossReturn / 100,
    id,
    image: fromS3ID(coverImage, 'w-600'),
    isBookmarked,
    isExclusive,
    isInTransaction: status === PropertyStatuses.Transaction,
    isNew,
    isViewed: isViewed,
    location: city,
    lots,
    netReturnRate: annual.netReturn / 100,
    positioningStatus: positioning?.status,
    price,
    project,
    region: regionCode,
    surface,
    trending,
    type,
    version,
  };
};
