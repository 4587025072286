import { ApolloClient, InMemoryCacheConfig, NormalizedCacheObject } from '@apollo/client';

import { myRealEstatesMemoryCache } from '../myRealEstatesMemoryCache';

export const apolloInMemoryCache: InMemoryCacheConfig = {
  typePolicies: {
    Query: {
      fields: {
        searchRealEstates: myRealEstatesMemoryCache,
      },
    },
    RealEstate: {
      fields: {
        annual: { merge: (existing, incoming) => ({ ...existing, ...incoming }) },
        building: { merge: (existing, incoming) => ({ ...existing, ...incoming }) },
        metadata: {
          merge: (existing, incoming) => ({ ...existing, ...incoming }),
        },
      },
    },
  },
};

export const invalidateCachedQuery = (
  client: ApolloClient<NormalizedCacheObject>,
  queryName: string
): void => {
  client.cache.evict({
    broadcast: false,
    fieldName: queryName,
    id: 'ROOT_QUERY',
  });
};
