import React, { useMemo } from 'react';

import { Linking, View } from 'react-native';

import {
  DataBlock,
  IconName,
  Spacer,
  TeaserImageCard,
  Text,
  TranslatedContent,
  useResponsive,
  useTheme,
} from '@masteos/aphrodite';

import { InvestmentWarning } from '@app/features/investmentWarning/InvestmentWarning';
import { getStyles } from '@app/features/propertyInfo/propertyInfo.styles.web';
import { DataBlockWrapper } from '@app/shared/components/DataBlockWrapper.web';
import { PropertyDescriptionMap } from '@app/features/propertyMap/propertyDescriptionMap.web';
import { useTranslation } from '@app/services/translations/translations';
import { trackTranslationSwitch } from '@app/services/tracking/trackTracking';
import { RealEstate } from '@app/libs/apollo/introspection';
import { percentFormatter } from '@app/utils/currency-formatter';
import { TranslateContentType, TRANSLATION_SERVICE } from '@app/constants/constants';
import { PropertyHuntersDescription } from '@app/features/propertyDescription/property-hunter-description/PropertyHunterDescription';
import {
  getTeaserImage,
  teaserImageCardAction,
} from '@app/features/propertyDescription/utils/property-description.utils';

import { usePropertyDescription } from './hooks/usePropertyDescription';
import { PropertyAttributesList } from './PropertyAttributesList';
import { PropertySynthesisWrapper } from './property-synthesis/PropertySynthesisWrapper';
import { PropertySynthesisItem } from './property-synthesis/PropertySyntesisItem';

interface PropertyDescriptionProps {
  property: RealEstate;
  isPublicProperty: boolean;
  openMapModal: () => void;
}

export const PropertyDescription: React.FC<PropertyDescriptionProps> = ({
  property,
  isPublicProperty,
  openMapModal,
}) => {
  const { t } = useTranslation();
  const { financialModel, isLocationInformationVisible, rows, trendingUp } =
    usePropertyDescription(property);
  const { isGreaterThanTablet, isTablet } = useResponsive();
  const theme = useTheme();
  const propertyInfoStyles = useMemo(() => getStyles({ isTablet, theme }), [isTablet, theme]);
  const appreciationZone = property.trending?.appreciation?.appreciationZone || 'NO_DATA';
  const dataLevelLng = t(`shared.trending.${appreciationZone}`);
  const canSeeMap =
    isLocationInformationVisible &&
    !!property?.building?.address?.lat &&
    !!property?.building?.address?.lng;
  const handleSwitchLanguage = (
    type: TranslateContentType,
    sourceLang: string,
    targetLang: string
  ): void => {
    trackTranslationSwitch({
      propertyId: property.id,
      sourceLang,
      targetLang,
      type,
    });
  };

  const descriptionMap = useMemo(() => {
    return canSeeMap ? (
      <PropertyDescriptionMap property={property} openMapModal={openMapModal} />
    ) : null;
  }, [canSeeMap, openMapModal, property]);
  const onPressTeaserImageCard = () =>
    teaserImageCardAction(property.id, Linking.openURL.bind(Linking));

  const teaserImageUrl = useMemo(
    () => getTeaserImage(isGreaterThanTablet ? 'w-1000:q-100' : 'w-500:q-100'),
    [isGreaterThanTablet]
  );

  return (
    <>
      <Spacer height={theme.spacing.SIZE_05} />
      <View style={propertyInfoStyles.contentWrapper}>
        <Text textStyle="Title3Medium">{t('shared.synthesis')}</Text>
        <Spacer height={isGreaterThanTablet ? theme.spacing.SIZE_08 : theme.spacing.SIZE_07} />
        <DataBlockWrapper $columns={2}>
          {rows.map(row => (
            <DataBlock
              key={row.testID}
              title={row.title}
              icon={row.icon}
              data={row.data === 'UNKNOWN' ? null : row.data}
              testID={row.testID}
              hint={row.hint}
            />
          ))}
        </DataBlockWrapper>
        {trendingUp > 0 && (
          <>
            <Text textStyle="Body2">
              {t('shared.financialTool.lokimo1', {
                avgLast5Years: percentFormatter(trendingUp, 1),
                dataLevel: dataLevelLng,
              })}
            </Text>
          </>
        )}
        <Spacer height={isGreaterThanTablet ? theme.spacing.SIZE_11 : theme.spacing.SIZE_09} />
      </View>

      <PropertySynthesisWrapper synthesis={property.synthesis}>
        <DataBlockWrapper $columns={2}>
          {property.synthesis.map(({ theme: thematic, translation }) => (
            <PropertySynthesisItem
              key={thematic}
              t={t}
              thematic={thematic}
              translation={translation}
            />
          ))}
        </DataBlockWrapper>
      </PropertySynthesisWrapper>
      <Spacer height={theme.spacing.SIZE_09} />
      {!!property.description && <PropertyHuntersDescription translation={property.translation} />}

      <View style={propertyInfoStyles.contentWrapper}>
        <TeaserImageCard
          backgroundUrl={teaserImageUrl}
          button={{
            iconName: 'ExternalLink' as IconName,
            label: t('shared.seeOurAchievements'),
          }}
          description={t('propertyDescription.discoverProjectsRenovated')}
          onPress={onPressTeaserImageCard}
          title={t('propertyDescription.niceHeritage')}
        />
      </View>
      <Spacer height={theme.spacing.SIZE_11} />

      <View style={propertyInfoStyles.contentWrapper}>
        <Text textStyle="Title3Medium">{t('propertyDescription.propertyInfo')}</Text>
        <Spacer height={isGreaterThanTablet ? theme.spacing.SIZE_08 : theme.spacing.SIZE_07} />
        {!!financialModel && (
          <PropertyAttributesList property={property} financialModel={financialModel} />
        )}
        <Spacer height={isGreaterThanTablet ? theme.spacing.SIZE_11 : theme.spacing.SIZE_09} />
      </View>

      {!isPublicProperty && (
        <div data-testid="property-location-section">
          <Text textStyle="Headline1Medium">{t('shared.descriptionFields.access')}</Text>
          <Spacer height={theme.spacing.SIZE_06} />
          <TranslatedContent
            translation={property.building.access.translation}
            onHandleDone={handleSwitchLanguage}
            translatedBy={t('shared.translatedBy', { providerName: TRANSLATION_SERVICE })}
            showOriginal={t('shared.showOriginal')}
            showTranslated={t('shared.showTranslated')}
          />
          <Spacer height={theme.spacing.SIZE_07} />
          {descriptionMap}
        </div>
      )}

      {property.building.neighborhood.description ? (
        <>
          <Text textStyle="Headline1Medium">{t('shared.neighborhoodTitle')}</Text>
          <Spacer height={theme.spacing.SIZE_06} />
          <TranslatedContent
            translation={property.building.neighborhood.translation}
            onHandleDone={handleSwitchLanguage}
            translatedBy={t('shared.translatedBy', { providerName: TRANSLATION_SERVICE })}
            showOriginal={t('shared.showOriginal')}
            showTranslated={t('shared.showTranslated')}
          />
          <Spacer height={theme.spacing.SIZE_07} />
        </>
      ) : null}

      <InvestmentWarning />
    </>
  );
};
