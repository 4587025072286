import React, { useMemo } from 'react';

import { View } from 'react-native';

import {
  Avatar,
  HeaderPageTitle,
  IconName,
  TeaserImageCard,
  Text,
  useResponsive,
} from '@masteos/aphrodite';

import { AdvisorSelector } from '@app/shared/components/advisor-selector/AdvisorSelector';
import { useTranslation } from '@app/services/translations/translations';
import { fromS3ID } from '@app/utils/from-s3id';
import { PageContainer } from '@app/core/page-layout/compounds/PageContainer';
import { PageLayout } from '@app/core/page-layout/PageLayout';
import { AdvisorSourcePage } from '@app/services/tracking/constants';

import { SuggestionEmpty } from './compounds/suggestions-empty/SuggestionsEmpty';
import { getStyles } from './Suggestions.styles';
import { SuggestionsTemplateProps } from './Suggestions.types';
import { SuggestionCardList } from './compounds/suggestion-card-list/SuggestionCardList';

const TEASER_IMAGE = 'masteosapp/project/LocalBannerProjet.png';

export const SuggestionsTemplate: React.FC<SuggestionsTemplateProps> = ({
  suggestions,
  advisor,
  gotoSearch,
  notificationStatus,
  ...rest
}) => {
  const { isLowerThanLaptop } = useResponsive();
  const { t } = useTranslation();

  const styles = useMemo(() => getStyles({ isLowerThanLaptop }), [isLowerThanLaptop]);

  const hasSuggestion = !!suggestions && suggestions.length;

  const header = useMemo(
    () => (
      <View style={styles.header} testID="suggestions-template-header">
        <HeaderPageTitle title={t('navigation.topBar.mySuggestions')} />
        <AdvisorSelector source={AdvisorSourcePage.SUGGESTIONS_PAGE} />
      </View>
    ),
    [styles.header, t]
  );

  const footer = useMemo(
    () => (
      <TeaserImageCard
        backgroundUrl={fromS3ID(TEASER_IMAGE, 'ar-1,w-1080,q-100')}
        button={{
          iconName: 'SearchProperty' as IconName,
          iconPlacement: 'left',
          label: t('suggestions.page.banner.searchProject'),
        }}
        title={t('suggestions.page.banner.title')}
        description={t('suggestions.page.banner.description')}
        center
        noRadiusMobile
        onPress={gotoSearch}
      />
    ),
    [t, gotoSearch]
  );

  const title = hasSuggestion
    ? suggestions.length > 1
      ? 'suggestions.page.title.other'
      : 'suggestions.page.title.one'
    : 'suggestions.page.titleEmpty';

  return (
    <PageLayout header={header} footer={footer}>
      <PageContainer>
        <View style={styles.advisorBlock} testID="suggestions-template-advisor-block">
          <Avatar
            avatarUrl={advisor.avatar}
            testID="suggestions-template-advisor-avatar"
            size={48}
          />
          <Text
            textStyle="Headline1Medium"
            style={styles.title}
            testID="suggestions-template-title"
          >
            {t(title, { advisorName: advisor.name })}
          </Text>
        </View>
        {hasSuggestion ? (
          <View testID="suggestions-template-list">
            <SuggestionCardList list={suggestions} advisorName={advisor.name} {...rest} />
          </View>
        ) : (
          <SuggestionEmpty notificationStatus={notificationStatus} />
        )}
      </PageContainer>
    </PageLayout>
  );
};
