import React from 'react';

import { View } from 'react-native';

import {
  Spacer,
  Spacing,
  Text,
  TranslationContentGroup,
  useResponsive,
  useTheme,
} from '@masteos/aphrodite';

import { useTranslation } from '@app/services/translations/translations';
import { TranslatableWithSwitchWrapper } from '@app/shared/components/TranslatableWithSwithWrapper/TransalatableWithSwitchWrapper';
import { ApollonTranslation } from '@app/libs/apollo/introspection';

import { getStyles } from './PropertyHunterDescription.styles';

export interface PropertyHunterDescriptionsProps {
  translation: ApollonTranslation;
}

export const PropertyHuntersDescription: React.FC<PropertyHunterDescriptionsProps> = ({
  translation,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = getStyles(theme);
  const { isMobile, isGreaterThanTablet } = useResponsive();

  const hasTranslatedText = !!translation?.translatedText;

  return (
    <>
      <TranslatableWithSwitchWrapper hasTranslatedText={hasTranslatedText}>
        <View style={styles.descriptionContainer}>
          <Text textStyle={isMobile ? 'Headline1Medium' : 'Title3Medium'}>
            {t('propertyDescription.generalAbout')}
          </Text>
          <Spacer height={isGreaterThanTablet ? theme.spacing.SIZE_08 : theme.spacing.SIZE_07} />
          <TranslationContentGroup.Item textStyle="Body2Medium" translation={translation} />
          <Spacer height={Spacing.SIZE_08} />
        </View>
      </TranslatableWithSwitchWrapper>
      <Spacer height={isGreaterThanTablet ? theme.spacing.SIZE_11 : theme.spacing.SIZE_09} />
    </>
  );
};
