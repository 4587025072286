/* eslint-disable sort-keys, sort-keys-fix/sort-keys-fix */

import React, { useEffect } from 'react';

import { View, KeyboardAvoidingView, Platform } from 'react-native';
import { useTranslation } from 'react-i18next';
import { StackNavigationProp } from '@react-navigation/stack';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';

import { useResponsive } from '@masteos/aphrodite';

import { SubscriptionHeader } from '@app/shared/components/SubscriptionHeader/SubscriptionHeader';
import { PublicNavigatorRoutes, PublicStackParamList } from '@app/navigation/types/routes';

import { useOnboardingStateMachine } from '../stateMachine/stateMachine.hooks';
import { getStyles } from '../onboarding.styles';
import { OnboardingServiceState } from '../stateMachine/stateMachine.types';
import { StepRegister } from '../stepRegister/StepRegister';

interface StepsInfoMapping {
  [key: string]: {
    step: number;
    stepCount: number;
    component: React.ReactNode;
  };
}

const stepsInfoMapping: StepsInfoMapping = {
  stepRegister: {
    step: 1,
    stepCount: 1,
    component: <StepRegister />,
  },
};

const getStepInfo = (state: OnboardingServiceState) => {
  const activeKey: string | undefined = Object.keys(stepsInfoMapping).find((key: string) => {
    return state.matches(key);
  });

  if (activeKey) {
    return stepsInfoMapping[activeKey];
  }

  return { step: 0, stepCount: 1, component: null };
};

type OnboardingContentNavigationProp = StackNavigationProp<
  PublicStackParamList,
  PublicNavigatorRoutes.Register
>;
type OnboardingContentRouteProp = RouteProp<PublicStackParamList, PublicNavigatorRoutes.Register>;

// TODO: Remove if there is no more than 2 steps in xstate
export const OnboardingContent: React.FC = () => {
  const { t } = useTranslation();
  const route = useRoute<OnboardingContentRouteProp>();
  const navigation = useNavigation<OnboardingContentNavigationProp>();
  const { isGreaterThanTablet } = useResponsive();
  const { state, context } = useOnboardingStateMachine();
  const { url } = context;
  const styles = getStyles({ isGreaterThanTablet });

  const handleGoBack = () => navigation.goBack();

  useEffect(() => {
    if (url && !route.name.includes(url)) {
      navigation.navigate(url as PublicNavigatorRoutes);
    }
  }, [url]);

  const stepInfo = getStepInfo(state);

  return (
    <View style={styles.mainContent}>
      <SubscriptionHeader onPressArrow={handleGoBack} title={t('register.registerTitle')} />
      <KeyboardAvoidingView
        style={styles.flex1}
        behavior={Platform.OS === 'ios' ? 'padding' : null}
        keyboardVerticalOffset={40}
      >
        <View style={styles.formWrapper}>{stepInfo.component}</View>
      </KeyboardAvoidingView>
    </View>
  );
};
