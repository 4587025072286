import { avalaibleCountryCodeToCountryCodeOption } from '@app/features/search-engine/compounds/search-engine-filters/utils/filter-to-preferences.mapper';
import { CustomerPreferences, CustomerPreferencesInput } from '@app/libs/apollo/introspection';

export const customerPreferencesToPreferencesInput = (
  customerPreferences: CustomerPreferences
): CustomerPreferencesInput => ({
  budgetMax: customerPreferences?.budgetMax,
  budgetMin: customerPreferences?.budgetMin,
  countryCode: avalaibleCountryCodeToCountryCodeOption(customerPreferences?.countryCode),
  hasNeedRenovation: customerPreferences?.hasNeedRenovation,
  propertyKind: customerPreferences?.propertyKind || [],
  propertyOnTheGroundFloor: customerPreferences?.propertyOnTheGroundFloor,
  propertyRented: customerPreferences?.propertyRented,
  propertyRoomCount: customerPreferences?.propertyRoomCount || [],
  regions: customerPreferences?.regions || [],
  sharedProperty: customerPreferences?.sharedProperty,
  sortStrategy: customerPreferences?.sortStrategy,
});
