import React, { useEffect, useState } from 'react';

import { StyleSheet } from 'react-native';

import {
  Button,
  Spacer,
  Spacing,
  FoldableSection,
  Text,
  useTheme,
  useResponsive,
  Theme,
} from '@masteos/aphrodite';

import { useTranslation } from '@app/libs/i18n/i18n';
import {
  trackClickRemoveAccount,
  trackLogout,
  trackProfileLanguage,
} from '@app/services/tracking/trackTracking';
import { DebugMenu } from '@app/features/debugMenu/DebugMenu.web';
import { useAuthorized } from '@app/shared/hooks/useAuthorized';
import { authorizedRoles } from '@app/shared/contexts/debugMenu/debugMenuProvider';
// eslint-disable-next-line max-len
import {
  useGetCustomerDocumentsQuery,
  useUpdateCustomerInformationsMutation,
} from '@app/libs/apollo/introspection';
import { I18nSupportedLanguage } from '@app/libs/i18n/i18n.types';
import { LanguageSwitcher } from '@app/shared/components/languageSwitcher/LanguageSwitcher';
import { useLogout } from '@app/shared/hooks/useLogout';
import { useMe } from '@app/shared/hooks/useMe';
import { resolveLanguageCode } from '@app/utils/lang/resolveLanguageCode';

// eslint-disable-next-line max-len
import { ProfileNotificationPreferences } from './compounds/profileNotificationPreferences/ProfileNotificationPreferences';
import { ProfileDeleteAccountModal } from './ProfileDeleteAccountModal.web';
import { ProfileInformation } from './compounds/profileInformation/ProfileInformation.web';
import { ProfilePassword } from './compounds/profilePassword/ProfilePassword.web';
import { ProfileDocumentsList } from './compounds/profileDocuments/ProfileDocumentsList.web';
import * as S from './profile.styles.web';
import { useHasAnAccountWithEmailPassword } from './hooks/useHasAnAccountEmailPassword';
import { ProfileBetaTestersContent } from './profileBetaTesters/ProfileBetaTestersContent';

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    deleteUserButton: { color: theme.palette.neutral[900], textDecorationLine: 'underline' },
  });

export const Profile: React.FC = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const styles = getStyles(theme);
  const { customer } = useMe();
  const { isLowerThanLaptop } = useResponsive();

  const { logout } = useLogout();
  const { isAuthorizedCustomer } = useAuthorized(authorizedRoles);
  const hasAnAccountWithEmailPassword = useHasAnAccountWithEmailPassword();
  const [updateInformationsMutation] = useUpdateCustomerInformationsMutation({
    refetchQueries: ['getCustomer'],
  });
  const { data } = useGetCustomerDocumentsQuery();

  const [isLoading, setIsLoading] = useState(false);
  const [lang, setLang] = useState(I18nSupportedLanguage.fr);
  const [isVisibleDeleteAccountModal, setIsVisibleDeleteAccountModal] = useState<boolean>(false);

  const handleLogout = async () => {
    trackLogout();
    await logout();
  };

  const handleLangChange = (keyLang: string) => {
    const nextLang: I18nSupportedLanguage = I18nSupportedLanguage[keyLang];
    setLang(nextLang);
    trackProfileLanguage(nextLang);
  };

  const handleSaveLang = async () => {
    setIsLoading(true);

    await Promise.all([
      updateInformationsMutation({
        variables: {
          customerInformations: {
            lang,
          },
        },
      }),
    ]);

    setIsLoading(false);
  };

  useEffect(() => {
    setLang(i18n.language as I18nSupportedLanguage);
  }, [i18n.language]);

  const toggleProfileDeleteAccountModal = () => {
    trackClickRemoveAccount();
    setIsVisibleDeleteAccountModal(!isVisibleDeleteAccountModal);
  };

  return (
    <S.Container>
      <S.FlexColumn>
        <FoldableSection
          icon="User"
          title={t('profile.profileSectionTitle')}
          subtitle={t('profile.profileSectionSubtitle')}
        >
          <ProfileInformation />
        </FoldableSection>

        <FoldableSection
          icon="Lock"
          title={t('profile.passwordSectionTitle')}
          subtitle={t('profile.passwordSectionSubtitle')}
        >
          <ProfilePassword />
        </FoldableSection>

        <FoldableSection
          icon="Notification"
          title={t('profile.notificationPreferences.title')}
          subtitle={t('profile.notificationPreferences.subTitle')}
        >
          <ProfileNotificationPreferences />
        </FoldableSection>

        {!!data?.documents.length && (
          <>
            <FoldableSection
              icon="File"
              title={t('profile.documentsSectionTitle')}
              subtitle={t('profile.documentsSectionSubtitle')}
            >
              <ProfileDocumentsList documentsList={data.documents} />
            </FoldableSection>
          </>
        )}

        <FoldableSection
          icon="LanguageFilled"
          title={t('profile.lang')}
          subtitle={t('profile.editLang')}
        >
          <S.FoldableSectionLang>
            <LanguageSwitcher lang={lang} onLangChange={handleLangChange} />
            <Spacer height={Spacing.SIZE_05} />
            <Button
              isLoading={isLoading}
              onPress={handleSaveLang}
              wrapperStyle={S.saveButtonWrapperStyle}
              testID="langBtn"
            >
              {t('shared.save')}
            </Button>
          </S.FoldableSectionLang>
        </FoldableSection>

        {resolveLanguageCode(customer.lang) === 'fr' && (
          <FoldableSection
            icon="Beta"
            title={t('profile.betaTesters.title')}
            subtitle={t('profile.betaTesters.subtitle')}
          >
            <ProfileBetaTestersContent />
          </FoldableSection>
        )}

        {!!isAuthorizedCustomer && (
          <FoldableSection icon="Settings" title="Debug Menu">
            <DebugMenu testID="debugMenu" />
          </FoldableSection>
        )}

        {!!isLowerThanLaptop && (
          <S.ActionContainer data-e2e="button-logout">
            <Button variant="tertiary" onPress={handleLogout} testID="logoutBtn">
              {t('profile.logout')}
            </Button>
            <Spacer height={Spacing.SIZE_08} />
          </S.ActionContainer>
        )}

        <S.ActionContainer>
          <Text
            textStyle="Link3"
            onPress={toggleProfileDeleteAccountModal}
            style={styles.deleteUserButton}
            testID="profile-delete-user-button"
          >
            {t('profile.deleteAccountButton')}
          </Text>
        </S.ActionContainer>
      </S.FlexColumn>
      <ProfileDeleteAccountModal
        hasAPassword={hasAnAccountWithEmailPassword}
        isVisibleDeleteAccountModal={isVisibleDeleteAccountModal}
        toggleProfileDeleteAccountModal={toggleProfileDeleteAccountModal}
      />
    </S.Container>
  );
};
