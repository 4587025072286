import React, { PropsWithChildren, Suspense, useContext } from 'react';

import { View } from 'react-native';

import { PublicRealEstate, RealEstate } from '@app/libs/apollo/introspection';

export interface RealEstateContextProps {
  realEstate: RealEstate | PublicRealEstate;
  selectCountryCode: () => string;
  selectRegionCode: () => string;
}

export const RealEstateContext = React.createContext<RealEstateContextProps>({
  realEstate: null,
  selectCountryCode: () => null,
  selectRegionCode: () => null,
});

interface RealEstateProviderProps {
  realEstate: RealEstate | PublicRealEstate;
}

export const useRealEstate = (): RealEstateContextProps => useContext(RealEstateContext);

export const RealEstateProvider: React.FC<PropsWithChildren<RealEstateProviderProps>> = ({
  realEstate,
  children,
}) => {
  const selectCountryCode = () => realEstate.building.address?.countryCode;
  const selectRegionCode = () => realEstate.building.address?.regionCode;

  const value: RealEstateContextProps = {
    realEstate,
    selectCountryCode,
    selectRegionCode,
  };

  return (
    <RealEstateContext.Provider value={value}>
      <Suspense fallback={<View />}>{children}</Suspense>
    </RealEstateContext.Provider>
  );
};
