import React, { useMemo } from 'react';

import { View } from 'react-native';

import { Button, Icon, Spacer, Tip, useResponsive, useTheme } from '@masteos/aphrodite';

import { useTranslation } from '@app/services/translations/translations';
import {
  AllowNotificationsSource,
  trackNotificationsAllowed,
} from '@app/services/tracking/trackTracking';
import { ensureNotificationPermissions } from '@app/services/notification/notification-permission';

import { getStyles } from './SuggestionsEmpty.styles';
import { SuggestionsEmptyProps } from './SuggestionsEmpty.types';

export const SuggestionEmpty: React.FC<SuggestionsEmptyProps> = ({ notificationStatus }) => {
  const { isLowerThanTablet } = useResponsive();
  const { t } = useTranslation();
  const theme = useTheme();

  const styles = useMemo(() => getStyles({ theme }), [theme]);

  const footer = useMemo(() => {
    const handlePress = async () => {
      trackNotificationsAllowed(AllowNotificationsSource.Suggestions);
      await ensureNotificationPermissions();
    };

    return !!notificationStatus && notificationStatus !== 'granted' ? (
      <Button
        onPress={handlePress}
        iconName="Notification"
        variant="secondary"
        iconPlacement="left"
        testID="suggestions-page-tip-open-notification-button"
      >
        {t('suggestions.page.tip.button')}
      </Button>
    ) : null;
  }, [notificationStatus, t]);

  return (
    <View testID="suggestions-empty">
      <View style={styles.container} testID="suggestions-empty-container">
        <Icon name="Masteos" size={59} color={theme.palette.neutral[300]} />
      </View>
      <Spacer height={isLowerThanTablet ? 24 : 40} />
      <Tip
        description={t('suggestions.page.tip.description')}
        title={t('suggestions.page.tip.title')}
        footer={footer}
      />
    </View>
  );
};
