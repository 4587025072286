import React from 'react';

import { Platform, View } from 'react-native';
import { Trans } from 'react-i18next';

import {
  DataBlock,
  palette,
  Spacer,
  Spacing,
  Text,
  useResponsive,
  TranslatedContent,
} from '@masteos/aphrodite';

import { PropertyCategory } from '@app/types/property';
import { trackTranslationSwitch } from '@app/services/tracking/trackTracking';
import { RealEstate } from '@app/libs/apollo/introspection';
import { FlexGrid } from '@app/shared/components/Grid';
import { GalleryThumbnail } from '@app/shared/components/Gallery/GalleryThumbnail';
import { useRealEstateTranslation } from '@app/shared/hooks/useRealEstateTranslations';
import { TranslateContentType, TRANSLATION_SERVICE } from '@app/constants/constants';

import { usePropertyRenovation } from './hooks/usePropertyRenovation';
import { styles } from './PropertyRenovation.styles';
import { formatGalleries, staticFurniturePackGalleries } from './constants';
import { InvestmentWarning } from '../investmentWarning/InvestmentWarning';

interface PropertyRenovationProps {
  property: RealEstate;
  openModalDPE: () => void;
  openModalGES: () => void;
  isPublicProperty?: boolean;
}

export const PropertyRenovation: React.FC<PropertyRenovationProps> = ({
  property,
  openModalDPE,
  openModalGES,
}) => {
  const { t } = useRealEstateTranslation();
  const { rows, shouldDisplayPlan, shouldDisplayFurniture } = usePropertyRenovation(
    property,
    openModalDPE,
    openModalGES
  );
  const galleries = formatGalleries(staticFurniturePackGalleries, {
    translate: t,
  });
  const { isLowerThanLaptop, isLowerThanTablet } = useResponsive();

  const handleSwitchLanguage = (
    type: TranslateContentType,
    sourceLang: string,
    targetLang: string
  ) => {
    trackTranslationSwitch({
      propertyId: property.id,
      sourceLang,
      targetLang,
      type,
    });
  };

  return (
    <View style={Platform.OS !== 'web' && styles.wrapper}>
      {Platform.OS !== 'web' && <Spacer height={Spacing.SIZE_09} />}
      {!!property.project.renovation.description && (
        <>
          <Text
            textStyle={Platform.OS === 'web' ? 'Title3Medium' : 'Headline1Medium'}
            style={{ color: palette.base.black }}
          >
            {t('propertyRenovation.description')}
          </Text>
          <Spacer height={Spacing.SIZE_07} />
          <TranslatedContent
            translation={property.project.renovation.translation}
            onHandleDone={handleSwitchLanguage}
            translatedBy={t('shared.translatedBy', { providerName: TRANSLATION_SERVICE })}
            showOriginal={t('shared.showOriginal')}
            showTranslated={t('shared.showTranslated')}
          />
          <Spacer height={isLowerThanTablet ? Spacing.SIZE_07 : Spacing.SIZE_08} />
        </>
      )}

      {!!rows && (
        <>
          <FlexGrid col={isLowerThanLaptop ? 1 : 2} gap={Spacing.SIZE_06}>
            {rows.map(row => (
              <FlexGrid.Cell key={row.title}>
                <DataBlock icon={row.icon} title={row.title} data={row.data} hint={row.suffix} />
              </FlexGrid.Cell>
            ))}
          </FlexGrid>
          <Spacer height={isLowerThanTablet ? Spacing.SIZE_09 : Spacing.SIZE_11} />
        </>
      )}

      {!!shouldDisplayPlan && (
        <>
          <Text textStyle="Headline1Medium">{t('propertyRenovation.plansTitle')}</Text>
          <Spacer height={Spacing.SIZE_06} />
          <TranslatedContent
            translation={property.plans.translation}
            onHandleDone={handleSwitchLanguage}
            translatedBy={t('shared.translatedBy', { providerName: TRANSLATION_SERVICE })}
            showOriginal={t('shared.showOriginal')}
            showTranslated={t('shared.showTranslated')}
          />
          <GalleryThumbnail
            gallery={{
              images: property.plans.images,
              slug: PropertyCategory.PLANS,
              title: t('propertyRenovation.plansTitle'),
            }}
          />
          <Spacer height={isLowerThanTablet ? Spacing.SIZE_09 : Spacing.SIZE_11} />
        </>
      )}
      {!!shouldDisplayFurniture && (
        <>
          <Text textStyle="Headline1Medium">{t('propertyRenovation.furniture')}</Text>
          <Spacer height={Spacing.SIZE_06} />
          <Text textStyle="Body2">
            <Trans
              i18nKey="propertyRenovation.furnitureDescription"
              components={{
                bold: <Text textStyle="Body2Medium" />,
                medium: <Text textStyle="Body2Medium" />,
              }}
            />
          </Text>
          <Spacer height={Spacing.SIZE_07} />
          <FlexGrid
            col={2}
            verticalGap={isLowerThanLaptop ? Spacing.SIZE_04 : Spacing.SIZE_08}
            horizontalGap={isLowerThanLaptop ? Spacing.SIZE_03 : Spacing.SIZE_07}
          >
            {galleries.map((gallery, index) => (
              <FlexGrid.Cell key={index}>
                <Text textStyle={isLowerThanLaptop ? 'Body2Medium' : 'Headline2Medium'}>
                  {gallery.name}
                </Text>
                <Spacer height={Spacing.SIZE_04} />
                <GalleryThumbnail gallery={gallery} ratio={328 / 290} />
              </FlexGrid.Cell>
            ))}
          </FlexGrid>
          <Spacer height={isLowerThanTablet ? Spacing.SIZE_09 : Spacing.SIZE_11} />
        </>
      )}
      {/** TODO insert the disclaimer at the top level  */}
      {Platform.OS === 'web' && <InvestmentWarning />}
    </View>
  );
};
