import React from 'react';

import { createStackNavigator } from '@react-navigation/stack';

import { RealEstateView } from '@app/views/realEstate/RealEstateView';
import { RealEstateNavigatorRoutes, RealEstatesStackParamList } from '@app/navigation/types/routes';
import { SCREEN_OPTIONS } from '@app/navigation/constants/navigation-options';
import { AdvisorAppointmentView } from '@app/views/advisor/AdvisorAppointmentView';
import SearchEngine from '@app/features/search-engine/SearchEngine';

const Stack = createStackNavigator<RealEstatesStackParamList>();

export const RealEstateNavigator: React.FC = () => (
  <Stack.Navigator
    initialRouteName={RealEstateNavigatorRoutes.RealEstateList}
    screenOptions={SCREEN_OPTIONS}
  >
    <Stack.Screen name={RealEstateNavigatorRoutes.RealEstateList} component={SearchEngine} />
    <Stack.Screen name={RealEstateNavigatorRoutes.RealEstate} component={RealEstateView} />
    <Stack.Screen
      navigationKey="realEstate"
      name={RealEstateNavigatorRoutes.AdvisorAppointment}
      component={AdvisorAppointmentView}
    />
  </Stack.Navigator>
);
