/* eslint-disable max-lines */
import React, { useCallback, useMemo } from 'react';

import { Controller } from 'react-hook-form';
import { SafeAreaView, ScrollView, View } from 'react-native';
import { isArray } from 'lodash';

import {
  Button,
  Checkbox,
  CheckboxGroup,
  Gap,
  Input,
  Select,
  Text,
  useResponsive,
  useTheme,
  TrileanController,
  ListItemUnionProps,
  Spacer,
} from '@masteos/aphrodite';
import { ChosenOption } from '@masteos/aphrodite/lib/typescript/organisms/select/Select.types';

import { FormSection } from '@app/shared/components/FormSection/FormSection';
import { PropertyKind } from '@app/libs/apollo/introspection';
import { SearchEngineFiltersDrawerType } from '@app/features/search-engine/compounds/search-engine-filters/search-engine-filters-drawer/searchEngineFiltersDrawer.type';
import { inputToNumber, numberToInput } from '@app/utils/input-formatter';
import { getStyle } from '@app/features/search-engine/compounds/search-engine-filters/search-engine-filters-drawer/searchEngineFiltersDrawer.styles';
import { useTranslation } from '@app/services/translations/translations';
import {
  SearchEngineFilterForm,
  SearchEngineSource,
} from '@app/features/search-engine/searchEngine.types';
import { KeyboardHandler } from '@app/shared/containers/keyboard-handler/KeyboardHandler';
import { getDefaultFormValues } from '@app/features/search-engine/compounds/search-engine-filters/utils/get-default-form-values';
import {
  handleMultipleSelectChange,
  handleSelectChange,
  stringToSelect,
} from '@app/utils/select-formatter';
import { captureException } from '@app/libs/sentry/sentry';

import { useSearchEngineFormFilter } from '../hooks/useSearchEngineFormFilter';

export const SearchEngineFiltersDrawer: React.FC<SearchEngineFiltersDrawerType> = ({
  state,
  countryList,
  regionListByCountry,
  loading,
  onSubmit,
  onSubmitError,
  onReset,
  onCountryClick,
  form,
  handleFilterHide,
  ...props
}) => {
  // FIXME: use translation (re-rendering issue)
  const { t } = useTranslation();
  const theme = useTheme();
  const { isLowerThanLaptop } = useResponsive();
  const countryDisabled = state === 'postSignedMandate';

  const styles = useMemo(() => getStyle({ isLowerThanLaptop, theme }), [isLowerThanLaptop, theme]);

  const defaultValues = useMemo<SearchEngineFilterForm>(
    () => getDefaultFormValues({ capacity: props.capacity }),
    [props.capacity]
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
    resetField,
    watch,
  } = form;

  const {
    budgetMinRules,
    budgetMaxRules,
    regionOptionList,
    countryOptionList,
    roomList,
    regionListValue,
    radioLabels,
  } = useSearchEngineFormFilter(countryList, regionListByCountry, props.capacity, getValues, watch);

  const handleCheckboxChange: <T>(
    onChange: (newList: T[]) => void,
    list: T[],
    target: T
  ) => (checked: boolean) => void = useCallback(
    (onChange, list, target) => checked => {
      onChange(checked ? [...new Set([...list, target])] : list.filter(v => v !== target));
    },
    []
  );

  //TODO: move into dedicated component
  const handleCountryChange: typeof handleSelectChange = useCallback(
    onChange => target => {
      handleSelectChange(onChange)(target);
      resetField('regionList', { defaultValue: defaultValues.regionList });
    },
    [defaultValues.regionList, resetField]
  );

  //TODO: move into dedicated component
  const handleRegionChange: (
    onChange: (nextList: string[]) => void,
    list: ListItemUnionProps[]
  ) => (target: ChosenOption) => void = useCallback(
    (onChange, list) => (target: ChosenOption) => {
      let nextState: ChosenOption | ListItemUnionProps[] = target;

      /**
       * If we click on the option "all" then we need to add/remove all region list
       * (excluding the option "all") to the form
       */
      if (target.value === null) {
        nextState =
          list.length < regionOptionList.length - 1
            ? regionOptionList.filter(v => v.value !== null)
            : [];
      }

      handleMultipleSelectChange(onChange, list)(nextState);
    },
    [regionOptionList]
  );

  const submit = useCallback(
    (clearMode?: boolean) => {
      handleSubmit(
        v =>
          Promise.resolve(onSubmit(v, SearchEngineSource.DRAWER, clearMode))
            .catch(e => {
              reset(defaultValues);
              captureException(e);
            })
            .finally(() => {
              handleFilterHide && handleFilterHide(clearMode);
            }),
        v => onSubmitError?.(v)
      )();
    },
    [defaultValues, handleFilterHide, handleSubmit, onSubmit, onSubmitError, reset]
  );

  const onResetFilters = async () => {
    onReset(SearchEngineSource.DRAWER);
    handleFilterHide && handleFilterHide(false);
  };

  //TODO: move into dedicated component
  const handleCountryClick = useCallback(() => {
    Promise.resolve(onCountryClick?.(countryDisabled));
  }, [countryDisabled, onCountryClick]);

  return (
    <SafeAreaView style={styles.safeAreaView}>
      <KeyboardHandler>
        <ScrollView>
          <View style={styles.formContainer}>
            <Gap direction="vertical" gap={theme.spacing.SIZE_06}>
              <Text textStyle="Title3Medium">{t('propertiesPreferences.preferences')}</Text>

              <View>
                <FormSection title={t('searchEngine.filter.localisation')}>
                  <Gap direction="vertical" gap={theme.spacing.SIZE_05}>
                    <Controller
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <View>
                          <Select
                            label={t('searchEngine.filter.country.label')}
                            helperText={
                              !!countryDisabled && t('searchEngine.filter.country.description')
                            }
                            selectPlaceholder={t('searchEngine.filter.country.placeholder')}
                            value={(() => {
                              const hasValue = isArray(value) ? !!value.length : !!value;
                              return hasValue
                                ? [stringToSelect(value, t(`share.country.${value}`))]
                                : [];
                            })()}
                            options={countryOptionList}
                            onSelect={handleCountryChange(onChange)}
                            onOpen={handleCountryClick}
                            isDisabled={countryDisabled}
                            wrapperStyle={styles.selectWrapper}
                          />
                        </View>
                      )}
                      name="country"
                    />
                    <Controller
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          label={t('searchEngine.filter.region.label')}
                          selectPlaceholder={t('searchEngine.filter.region.placeholder')}
                          value={regionListValue(value, regionOptionList)}
                          options={regionOptionList}
                          onSelect={handleRegionChange(
                            onChange,
                            value.map(v => stringToSelect(v))
                          )}
                          isMultichoice
                          canDisplayButtons={false}
                          isDisabled={regionOptionList.length <= 1}
                          wrapperStyle={styles.selectWrapper}
                        />
                      )}
                      name="regionList"
                    />
                  </Gap>
                </FormSection>

                <FormSection title={t('propertiesPreferences.typology')}>
                  <Gap direction="vertical" gap={theme.spacing.SIZE_07}>
                    <Controller
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <CheckboxGroup label={t('propertyDescription.type')}>
                          {Object.values(PropertyKind).map(propertyKind => (
                            <Checkbox
                              testID={`search-engine-filter-${propertyKind}--checkbox`}
                              key={propertyKind}
                              checked={value.includes(propertyKind)}
                              label={t(`shared.propertyKind.${propertyKind}`)}
                              onChange={handleCheckboxChange(onChange, value, propertyKind)}
                            />
                          ))}
                        </CheckboxGroup>
                      )}
                      name="typology"
                    />
                    <Controller
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <CheckboxGroup label={t('propertiesPreferences.roomsNumber')} inline>
                          {Object.values(roomList).map(({ label, roomCount }) => (
                            <Checkbox
                              testID={`search-engine-filter-${roomCount}--checkbox`}
                              key={roomCount}
                              checked={value.includes(roomCount)}
                              label={label}
                              onChange={handleCheckboxChange(onChange, value, roomCount)}
                            />
                          ))}
                        </CheckboxGroup>
                      )}
                      name="roomCount"
                    />
                    {/*FIXME: bad typing on aphrodite*/}
                    <TrileanController
                      testID="search-engine-filter-flatSharing--trilean"
                      label={t('propertiesPreferences.withColocation')}
                      control={control as any}
                      name="flatSharing"
                      radioLabels={radioLabels}
                    />
                  </Gap>
                </FormSection>

                <FormSection title={t('propertiesPreferences.conditions')}>
                  <Gap direction="vertical" gap={theme.spacing.SIZE_06}>
                    {/*FIXME: bad typing on aphrodite*/}
                    <TrileanController
                      testID="search-engine-filter-withRenovation--trilean"
                      label={t('propertiesPreferences.hasNeedRenovation')}
                      control={control as any}
                      name="withRenovation"
                      radioLabels={radioLabels}
                    />
                    {/*FIXME: bad typing on aphrodite*/}
                    <TrileanController
                      testID="search-engine-filter-alreadyRented--trilean"
                      label={t('propertiesPreferences.propertyRented')}
                      control={control as any}
                      name="alreadyRented"
                      radioLabels={radioLabels}
                    />
                    {/*FIXME: bad typing on aphrodite*/}
                    <TrileanController
                      testID="search-engine-filter-atGroundFloor--trilean"
                      label={t('propertiesPreferences.propertyOnTheGroundFloor')}
                      control={control as any}
                      name="atGroundFloor"
                      radioLabels={radioLabels}
                    />
                  </Gap>
                </FormSection>

                <FormSection title={t('propertiesPreferences.budget')}>
                  <Controller
                    control={control}
                    rules={budgetMinRules}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        testID="search-engine-filter-budget-min--input"
                        errorMessageTestID="search-engine-filter-budget-min--input-error"
                        label={t('propertiesPreferences.minimalBudget')}
                        onBlur={onBlur}
                        onChangeText={v => onChange(inputToNumber(v))}
                        value={numberToInput(value)}
                        error={errors.budgetMin?.message}
                        keyboardType="numeric"
                      />
                    )}
                    name="budgetMin"
                  />

                  <Spacer height={theme.spacing.SIZE_02} />

                  <Controller
                    control={control}
                    rules={budgetMaxRules}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        testID="search-engine-filter-budget-max--input"
                        errorMessageTestID="search-engine-filter-budget-max--input-error"
                        label={t('propertiesPreferences.maximalBudget')}
                        onBlur={onBlur}
                        onChangeText={v => onChange(inputToNumber(v))}
                        value={numberToInput(value)}
                        error={
                          errors.budgetMax?.message ||
                          (!!errors.budgetMin && errors.budgetMin.type === 'lessThanMax' && ' ')
                        }
                        keyboardType="numeric"
                      />
                    )}
                    name="budgetMax"
                  />
                </FormSection>
              </View>
            </Gap>
          </View>
        </ScrollView>
        <View style={styles.buttonsContainer}>
          <View style={styles.buttonWrapperReset}>
            <Button
              testID="search-engine-filter-reset--button"
              variant="ghost"
              onPress={onResetFilters}
              disabled={loading}
            >
              {t('propertiesPreferences.erase')}
            </Button>
          </View>

          <Spacer width={8} />

          <View style={styles.buttonWrapperSubmit}>
            <Button
              testID="search-engine-filter--submit"
              onPress={() => submit()}
              disabled={loading}
              isLoading={loading}
            >
              {t('shared.show')}
            </Button>
          </View>
        </View>
      </KeyboardHandler>
    </SafeAreaView>
  );
};
