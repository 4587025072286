import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { SignInMethod } from '@app/features/authBridge/authBridge.constants';
import { SSOProfile } from '@app/features/authBridge/authBridge.types';
import { AuthenticationStrategy } from '@app/libs/identity/identity.type';
import { getCustomerInfoByEmail } from '@app/features/authBridge/authBridge.utils';
import { PublicNavigatorRoutes, PublicStackParamList } from '@app/navigation/types/routes';
import { Provider } from '@app/libs/apollo/introspection';

import { useAuthentication } from './useAuthentication';

export interface RedirectByEmailProps {
  idToken?: string;
  method: SignInMethod;
  strategy: AuthenticationStrategy;
  userInfo: SSOProfile;
}

export type UseRedirectByEmail = {
  redirectByEmail: (
    p: RedirectByEmailProps,
    setIsLoading?: (loading: boolean) => void,
    forceSignInWithSSO?: (providers: Provider[]) => void
  ) => Promise<void>;
  redirectByEmailSSO: (
    p: RedirectByEmailProps,
    setIsLoading?: (loading: boolean) => void
  ) => Promise<void>;
};

type UseRedirectByEmailNavigationProp = StackNavigationProp<PublicStackParamList>;

export const useRedirectByEmail = (): UseRedirectByEmail => {
  const { completeAuthentication } = useAuthentication();
  const navigation = useNavigation<UseRedirectByEmailNavigationProp>();

  const navigateToRegister = (
    { method, userInfo, idToken, strategy }: RedirectByEmailProps,
    setIsLoading?: (loading: boolean) => void
  ) => {
    const params = {
      email: userInfo.email || '',
      ['first-name']: userInfo.firstName || '',
      idToken: idToken || '',
      ['last-name']: userInfo.lastName || '',
      method: method || '',
      strategy: strategy || '',
      ...(userInfo.id && { sub: userInfo.id }),
    };

    setIsLoading?.(false);
    navigation.navigate(PublicNavigatorRoutes.Register, params);
  };

  const redirectByEmail = async (
    { method, userInfo, idToken, strategy }: RedirectByEmailProps,
    setIsLoading?: (loading: boolean) => void,
    forceSignInWithSSO?: (providers: Provider[]) => void
  ) => {
    const { customerInfoByEmail, error } = await getCustomerInfoByEmail(userInfo.email);

    if (error) {
      setIsLoading?.(false);
      throw new Error('Error when retrieving customer informations');
    }

    const { emailExists, hasAnAccountWithEmailPassword, providers } = customerInfoByEmail;
    /** If email does not exists redirect to register */
    if (!emailExists) {
      return navigateToRegister({ idToken, method, strategy, userInfo }, setIsLoading);
    }
    /** If user only has third party credentials (google or apple) then force signin with SSO */
    const hasOnlySSOLogin: boolean =
      emailExists && !hasAnAccountWithEmailPassword && providers.length !== 0;

    if (hasOnlySSOLogin) {
      setIsLoading?.(false);
      return forceSignInWithSSO(providers);
    }
    /**
     * Else redirect to login page.
     * For users without password the reset password link should remain accessible.
     * */
    setIsLoading?.(false);
    return navigation.navigate(PublicNavigatorRoutes.Login, { email: userInfo.email });
  };

  const redirectByEmailSSO = async (
    { userInfo, idToken, strategy, method }: RedirectByEmailProps,
    setIsLoading?: (loading: boolean) => void
  ) => {
    let doesEmailExists = userInfo.emailExists;
    if (userInfo.emailExists === undefined) {
      const { customerInfoByEmail, error } = await getCustomerInfoByEmail(userInfo.email);

      if (error) {
        setIsLoading?.(false);
        throw new Error();
      }

      doesEmailExists = customerInfoByEmail.emailExists;
    }

    if (!doesEmailExists) {
      navigateToRegister({ idToken, method, strategy, userInfo }, setIsLoading);
    } else {
      setIsLoading?.(false);
      await completeAuthentication(idToken, strategy);
    }
  };

  return { redirectByEmail, redirectByEmailSSO };
};
