import { StyleSheet } from 'react-native';

export const getStyle = () =>
  StyleSheet.create({
    container: {
      alignItems: 'center',
      justifyContent: 'center',
    },
    wrapper: {
      height: 500,
      maxWidth: 475,
    },
  });
