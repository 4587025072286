import React, { useMemo, useState } from 'react';

import { View } from 'react-native';

import { Icon, Image, Text, useResponsive, useTheme, Spacer } from '@masteos/aphrodite';

import { useTranslation } from '@app/services/translations/translations';

import { getStyles } from './propertyImage.style';

export interface PropertyTopImagesAppointmentProps {
  imageUrl: string;
}

export const PropertyTopImagesAppointment: React.FC<PropertyTopImagesAppointmentProps> = ({
  imageUrl,
}) => {
  const { t } = useTranslation();
  const { device } = useResponsive();
  const theme = useTheme();
  const styles = useMemo(() => getStyles(theme, device), [theme, device]);
  const [imageLoaded, setImageLoaded] = useState(false);

  const onLoad = () => setImageLoaded(true);

  return (
    <Image
      style={styles.image}
      testID="propertyTopImagesAppointment"
      blurRadius={80}
      source={{ uri: imageUrl }}
      onLoad={onLoad}
    >
      {!!imageLoaded && (
        <>
          <View style={styles.imageMask} />
          <View style={styles.container}>
            <Icon name="Lock" />
            <Spacer height={theme.spacing.SIZE_04} />
            <Text style={styles.text} textStyle="Body2Medium">
              {t('propertyInfo.takeAppointmentToSeeMorePic')}
            </Text>
          </View>
        </>
      )}
    </Image>
  );
};
