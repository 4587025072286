import { useEffect } from 'react';

interface useHoverDelayProps {
  ref: React.MutableRefObject<HTMLElement>;
  duration?: number;
  callback: () => void;
}

const ONE_SECOND_MILLISECONDS = 1000;

export const useHoverDelay = ({
  ref,
  duration = ONE_SECOND_MILLISECONDS,
  callback,
}: useHoverDelayProps): void => {
  useEffect(() => {
    if (!ref?.current) {
      return;
    }

    let timeout: NodeJS.Timeout;
    const handleMouseOver = () => {
      ref?.current?.addEventListener('mouseleave', handleMouseOut);
      timeout = setTimeout(() => {
        callback();
      }, duration);
    };

    const handleMouseOut = () => {
      clearTimeout(timeout);
      if (ref.current) {
        ref.current.removeEventListener('mouseleave', handleMouseOut);
      }
    };

    if (ref.current) {
      ref.current.addEventListener('mouseenter', handleMouseOver);
    }
    return () => {
      clearTimeout(timeout);
      if (ref.current) {
        ref.current.removeEventListener('mouseenter', handleMouseOver);
        ref.current.removeEventListener('mouseleave', handleMouseOut);
      }
    };
  }, [ref]);
};
