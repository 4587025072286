import React from 'react';

import { StyleSheet, View } from 'react-native';

import { palette, Spacing, Expandable, Spacer } from '@masteos/aphrodite';

import {
  trackTooltip,
  trackFinancialToggle,
  trackFinancialUntoggle,
} from '@app/services/tracking/trackTracking';
import { currencyFormatter } from '@app/utils/currency-formatter';
import { useRealEstateTranslation } from '@app/shared/hooks/useRealEstateTranslations';
import { AvailableCountryCode } from '@app/libs/apollo/introspection';
import { Importance, PropertyAttribute } from '@app/features/propertyDescription/PropertyAttribute';

import { PropertyFinancialHeader } from '../propertyFinancialHeader';
import { areEqual } from '../PropertyFinances.utils';
import { AnnualAvgOccupancy } from './AnnualAvgOccupancy';

const styles = StyleSheet.create({
  border: {
    borderBottomColor: palette.neutral[300],
    borderBottomWidth: 1,
    width: '100%',
  },
  container: {
    borderColor: palette.neutral[300],
    borderRadius: 4,
    borderWidth: 1,
    paddingBottom: Spacing.SIZE_04,
    paddingHorizontal: Spacing.SIZE_06,
    paddingTop: Spacing.SIZE_03,
    width: '100%',
  },
});

export interface PropertyFinancialAnnualIncomesProps {
  annualCharges: number;
  annualRentIncome: number;
  annualTotalIncome: number;
  annualAvgOccupancyInMonths: number;
  countryCode: string;
  financialModelVersion: number;
  isFurnished: boolean;
  propertyId: string;
  setAnnualAvgOccupancyInMonths: (v: number) => void;
}

export const PropertyFinancialAnnualIncomes: React.FC<PropertyFinancialAnnualIncomesProps> =
  React.memo(
    ({
      annualCharges,
      annualRentIncome,
      annualTotalIncome,
      annualAvgOccupancyInMonths,
      countryCode,
      financialModelVersion,
      isFurnished,
      propertyId,
      setAnnualAvgOccupancyInMonths,
    }) => {
      const { t } = useRealEstateTranslation();
      const formattedAnnualCharges = currencyFormatter(annualCharges, 0);
      const formattedAnnualRentIncome = currencyFormatter(annualRentIncome, 0);
      const formattedAnnualTotalIncome = currencyFormatter(annualTotalIncome, 0);

      const trackToggle = ({ expanded }) => {
        expanded
          ? trackFinancialUntoggle({ propertyId, sectionName: 'revenue' })
          : trackFinancialToggle({ propertyId, sectionName: 'revenue' });
      };
      const trackTooltipAnnualCharges = () =>
        trackTooltip({ propertyId, tooltipType: 'Charges locataire' });

      return (
        <View style={styles.container}>
          <Expandable onPress={trackToggle}>
            <Expandable.Header>
              <Expandable.Context.Consumer>
                {({ expanded }) => (
                  <PropertyFinancialHeader
                    title={t(`shared.financialTool.annualRentIncome`)}
                    icon="Euro"
                    hasChevron
                    chevronOpen={expanded}
                    amount={!expanded && formattedAnnualTotalIncome}
                  />
                )}
              </Expandable.Context.Consumer>
            </Expandable.Header>
            <Expandable.Content>
              <PropertyAttribute
                label={t('shared.financialTool.yearlyRentIncomeNoCharges')}
                value={formattedAnnualRentIncome}
                importance={Importance.LOW}
                isOdd
                testID="property-financial-line-annual-rent-income"
              />
              <PropertyAttribute
                label={t('shared.financialTool.annualCharges')}
                value={formattedAnnualCharges}
                importance={Importance.LOW}
                tooltip={{
                  description: t(`shared.financialTool.tooltip.annualChargesDescription`),
                  onOpen: trackTooltipAnnualCharges,
                  title: t(`shared.financialTool.tooltip.annualCharges`),
                }}
                testID="property-financial-line-annual-charges"
              />
              <AnnualAvgOccupancy
                propertyId={propertyId}
                countryCode={countryCode as AvailableCountryCode}
                financialModelVersion={financialModelVersion}
                isFurnished={isFurnished}
                annualAvgOccupancyInMonths={annualAvgOccupancyInMonths}
                setAnnualAvgOccupancyInMonths={setAnnualAvgOccupancyInMonths}
              />

              <Spacer height={Spacing.SIZE_05} />

              <View style={styles.border} />

              <Spacer height={Spacing.SIZE_04} />

              <PropertyAttribute
                label={t('shared.financialTool.annualRentIncome')}
                value={formattedAnnualTotalIncome}
                importance={Importance.HIGH}
                testID="property-financial-line-annual-total-income"
              />
            </Expandable.Content>
          </Expandable>
        </View>
      );
    },
    areEqual<PropertyFinancialAnnualIncomesProps>([
      'annualCharges',
      'annualRentIncome',
      'annualTotalIncome',
      'annualAvgOccupancyInMonths',
    ])
  );

PropertyFinancialAnnualIncomes.displayName = 'PropertyFinancialAnnualIncomes';
