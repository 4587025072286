import { Animated, Platform, StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native';

import { BorderRadius, ChipProps, palette, StepStatus, Theme } from '@masteos/aphrodite';

import { PlatformEnum } from '@app/types/platform';

export const getDetailStageStyles: (
  isMobile: boolean,
  status: StepStatus,
  theme: Theme
) => Record<string, StyleProp<ViewStyle | TextStyle>> = (
  isMobile: boolean,
  status: StepStatus,
  theme: Theme
) =>
  StyleSheet.create({
    additionalInfoContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
    cardContainer: {
      borderColor: theme.palette.neutral[300],
      borderRadius: theme.borderRadius.SIZE_04,
      borderWidth: status !== StepStatus.Ongoing ? 1 : 0,
      padding: 24,
      ...(status === StepStatus.Ongoing && theme.shadows[200]),
    },
    container: {
      backgroundColor: theme.palette.base.white,
      flex: 1,
    },
    contentBox: { marginRight: -20, paddingRight: 20 },
    contentContainer: { flexDirection: 'row' },
    contentSection: {
      flex: 1,
      width: '100%',
    },
    contentText: {
      color:
        status === StepStatus.Finished ? theme.palette.neutral[700] : theme.palette.neutral[800],
    },
    link: {
      color: palette.system.primary[500],
      marginVertical: 8,
      textDecorationLine: 'underline',
    },
    modalContainer: { marginBottom: 80 },
    modalContentBox: { flexDirection: 'column-reverse' },
    subSteps: {
      borderColor: palette.neutral[300],
      borderRadius: BorderRadius.SIZE_03,
      borderWidth: 1,
      marginBottom: 4,
      marginTop: 12,
      paddingHorizontal: 8,
    },
    title: {
      flex: 1,
      marginLeft: 16,
      ...(status === StepStatus.Finished && { color: theme.palette.neutral[700] }),
    },
    titleBloc: {
      alignItems: 'center',
      flexDirection: 'row',
      marginBottom: isMobile ? 12 : 8,
      width: '100%',
    },
  });

export const getDetailStageChipProps: (
  theme: Theme
) => Record<StepStatus, Partial<ChipProps>> = theme => ({
  [StepStatus.Finished]: {
    backgroundColor: theme.palette.tint.green[50],
    iconName: 'Check',
    textColor: theme.palette.tint.green[700],
  },
  [StepStatus.Ongoing]: {
    backgroundColor: theme.palette.tint.blue[50],
    iconName: 'Clock',
    textColor: theme.palette.tint.blue[700],
  },
  [StepStatus.Incoming]: { iconName: 'Calendar' },
});
export const detailStageListStyles = StyleSheet.create({ chip: { alignSelf: 'flex-start' } });

export const detailStepInfoStyles = StyleSheet.create({
  container: {
    marginTop: 16,
    maxWidth: '100%',
    minWidth: 162,
    paddingRight: 12,
  },
  info: { color: palette.neutral[900] },
  infoLine: {
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  optionalText: {
    color: palette.neutral[700],
    fontStyle: 'italic',
    marginLeft: 2,
  },
  title: {
    color: palette.neutral[700],
    marginBottom: 4,
  },
});

export const getDetailSubStepStyles = ({ isLast }: { isLast: boolean }) =>
  StyleSheet.create({
    block: {
      borderBottomColor: palette.neutral[300],
      borderBottomWidth: isLast ? 0 : 1,
      paddingVertical: 16,
    },
    textContent: { color: palette.neutral[800] },
  });

export const getDetailSubStepHeaderStyles: ({
  showChevron,
  spin,
}: {
  showChevron: boolean;
  spin: Animated.AnimatedInterpolation<string>;
}) => Record<string, ViewStyle | TextStyle> = ({ showChevron, spin }) =>
  StyleSheet.create({
    animation: { transform: [{ rotate: spin as unknown as string }] },
    chevron: { marginLeft: 'auto' },
    header: {
      alignItems: 'center',
      cursor: showChevron ? 'pointer' : 'initial',
      flexDirection: 'row',
      flexWrap: 'nowrap',
    },
    optionalText: {
      color: palette.neutral[700],
      fontStyle: 'italic',
      marginLeft: 2,
    },
    textContainer: {
      flex: 1,
      flexWrap: 'nowrap',
      marginLeft: 12,
    },
    textHeader: { color: palette.neutral[900] },
  });

export const rotateAnimation: ({
  expanded,
  rotateValue,
}: {
  expanded: boolean;
  rotateValue: Animated.Value;
}) => void = ({ expanded, rotateValue }) =>
  Animated.timing(rotateValue, {
    duration: 150,
    toValue: expanded ? 1 : 0,
    useNativeDriver: Platform.OS !== PlatformEnum.Web,
  }).start();

export const getIconColor: (theme: Theme, subStepStatus: StepStatus) => string = (
  theme,
  subStepStatus
) => {
  switch (subStepStatus) {
    case StepStatus.Finished:
      return theme.palette.alert.valid;
    // FIXME enable when data will be dynamic
    // case StepStatus.Ongoing:
    // return theme.palette.neutral[900];
    case StepStatus.Ongoing:
    case StepStatus.Incoming:
    default:
      return theme.palette.neutral[400];
  }
};
