import React, { useEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { TextInput, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { Pressable, Spacer, Spacing, Text, useTheme } from '@masteos/aphrodite';

import { Input, SubmitButton } from '@app/shared/contexts/form/index.web';
import { trackForgotPassword } from '@app/services/tracking/trackTracking';
import { useFormContext } from '@app/shared/contexts/form/FormContext.web';
import {
  ForgotPasswordNavigatorRoutes,
  PublicNavigatorRoutes,
  PublicStackParamList,
} from '@app/navigation/types/routes';

import { getStyles } from './loginEmailPassword.style';

interface LoginEmailPasswordFormProps {
  loginError: string | null;
  loginLoading: boolean;
  onSubmit: () => void;
  cleanError: () => void;
}

type LoginEmailPasswordFormNavigationProp = StackNavigationProp<
  PublicStackParamList,
  PublicNavigatorRoutes.Login
>;

export const LoginEmailPasswordForm: React.FC<LoginEmailPasswordFormProps> = ({
  loginError,
  loginLoading,
  onSubmit,
  cleanError,
}) => {
  const { t } = useTranslation();
  const navigation = useNavigation<LoginEmailPasswordFormNavigationProp>();
  const theme = useTheme();
  const { getValue, setError, getError } = useFormContext();
  const passwordRef = useRef<TextInput>();
  const styles = getStyles(theme);

  useEffect(() => {
    if (loginError && getError('password') !== loginError) {
      setError('password', loginError);
    }
  }, [loginError, getError, setError]);

  const onPressOnForgotPassword = (email: string) => {
    trackForgotPassword();
    const params = { email };
    navigation.navigate(PublicNavigatorRoutes.ForgotPassword, {
      params,
      screen: ForgotPasswordNavigatorRoutes.ForgotPasswordEmailStep,
    });
  };

  const focusPassword = () => passwordRef.current?.focus();
  const handlePressOnForgotPassword = () => onPressOnForgotPassword(getValue('email'));

  return (
    <View style={styles.formView}>
      <Input
        autoCapitalize="none"
        autoComplete="email"
        autoCorrect={false}
        keyboardType="email-address"
        label={t('shared.email')}
        name="email"
        onSubmitEditing={focusPassword}
        placeholder={t('register.emailPlaceholder')}
        testID="login-email-input"
        textContentType="emailAddress"
      />

      <Spacer height={theme.spacing.SIZE_03} />

      <Input
        autoCapitalize="none"
        autoComplete="password"
        autoCorrect={false}
        autoFocus
        isPassword
        label={t('shared.password')}
        name="password"
        onChange={cleanError}
        onSubmitEditing={onSubmit}
        ref={passwordRef}
        testID="login-password-input"
        textContentType="password"
      />
      <Spacer height={theme.spacing.SIZE_05} />

      <Pressable onPress={handlePressOnForgotPassword}>
        <Text textStyle="Body2" style={styles.forgotPasswordLink}>
          {t('login.forgotPassword')}
        </Text>
      </Pressable>

      <Spacer height={Spacing.SIZE_07} />

      <SubmitButton variant="primary" testID="login-submit-button" isLoading={loginLoading}>
        {t('shared.login')}
      </SubmitButton>
    </View>
  );
};
