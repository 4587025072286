import React, { PropsWithChildren } from 'react';

import { StyleSheet, View } from 'react-native';

import { palette, Spacer, Spacing, Text } from '@masteos/aphrodite';

interface FormSectionProps {
  title: string;
}

const styles = StyleSheet.create({
  line: { backgroundColor: palette.neutral[600], height: 1, marginTop: Spacing.SIZE_03 },
});

export const FormSection: React.FC<PropsWithChildren<FormSectionProps>> = ({ title, children }) => {
  return (
    <View>
      <Text textStyle="Body2" style={{ color: palette.neutral[600] }}>
        {title}
      </Text>

      <View style={styles.line} />

      <Spacer height={Spacing.SIZE_06} />
      {children}
      <Spacer height={Spacing.SIZE_10} />
    </View>
  );
};
