import { StyleSheet, ViewStyle } from 'react-native';

type SuggestionCardStyleType = {
  isLowerThanTablet: boolean;
};

export const getStyle = ({
  isLowerThanTablet,
}: SuggestionCardStyleType): { container: ViewStyle } =>
  StyleSheet.create({
    container: {
      flexShrink: 1,
      maxWidth: isLowerThanTablet ? '100%' : 355,
      width: '100%',
    },
  });
