import { palette } from '@masteos/aphrodite';

import { GetProjectQuery, TransactionStatus } from '@app/libs/apollo/introspection';
import { RealEstateChips } from '@app/shared/hooks/useRealEstateChips';
import { ProjectCategory } from '@app/features/project/Project.types';

export interface ProjectHeaderProps {
  address: string;
  chips: RealEstateChips;
  imageCover: string;
  onBack: () => void;
  onButtonPress: () => void;
  projectAmount: number;
  projectSteps?:
    | Record<ProjectCategory.Transaction, GetProjectQuery['project']['transaction']>
    | Record<ProjectCategory, never>;
  scrollToStage?: (step: number) => void;
  setStepActive?: (stepNumber: number, fromTimeline?: boolean) => void;
  status: TransactionStatus;
  stepActive?: number;
  title: string;
}

export const chipsProps = {
  [TransactionStatus.Finished]: {
    backgroundColor: palette.tint.green[50],
    iconName: 'CheckCircle',
    textColor: palette.tint.green[700],
  },
  [TransactionStatus.Ongoing]: {
    backgroundColor: palette.neutral[100],
    iconName: 'Clock',
    textColor: palette.neutral[800],
  },
};
