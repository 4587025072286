import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { View } from 'react-native';

import { useResponsive, useTheme } from '@masteos/aphrodite';

import { useMe } from '@app/shared/hooks/useMe';
import { useGetPandadocLinkLazyQuery } from '@app/libs/apollo/introspection';
import { OverviewModal } from '@app/shared/components/Modals/in-app-sign-in/overview/OverviewModal';
import { SignInModal } from '@app/shared/components/Modals/in-app-sign-in/sign-in/SignInModal';
import { trackMandateSignInModalClosed } from '@app/services/tracking/trackTracking';
import {
  InAppSignInModalCloseReason,
  SignInModalState,
} from '@app/shared/components/Modals/in-app-sign-in/InAppSignInModal.types';
import { CongratsModal } from '@app/shared/components/Modals/in-app-sign-in/congrats/CongratsModal';
import { ModalContext } from '@app/shared/contexts/modal-manager/ModalManagerProvider';
import { SignMandateReminderType } from '@app/shared/hooks/useSignMandateState/useSignMandateState.hook.types';
import { storage } from '@app/services/storage/storage';
import { getFullName } from '@app/utils/getFullName';
import { navigationRef } from '@app/navigation/navigationRef';
import { PrivateNavigatorRoutes } from '@app/navigation/types/routes';
import { BannerContext } from '@app/shared/contexts/banner-manager/BannerManagerProvider';

import { getStyles } from './inAppSignInModal.styles';

export const InAppSignInModal: React.FC = () => {
  const { closeModal } = useContext(ModalContext);
  const { closeBanner } = useContext(BannerContext);
  const theme = useTheme();
  const {
    customer: {
      advisor: { firstName, lastName },
    },
  } = useMe();
  const { isLowerThanLaptop } = useResponsive();

  const [getPandadocLink, { data }] = useGetPandadocLinkLazyQuery();
  const [modalState, setModalState] = useState<SignInModalState>(SignInModalState.Overview);
  const ref = useRef<SignInModalState>(modalState);

  const styles = useMemo(() => getStyles({ isLowerThanLaptop, theme }), [isLowerThanLaptop, theme]);

  const handleNextStep = useCallback(() => {
    setModalState(currentState => {
      let nextStep;
      let source;
      switch (currentState) {
        case SignInModalState.Overview:
          nextStep = SignInModalState.SignIn;
          source = InAppSignInModalCloseReason.NextStep;
          getPandadocLink();
          break;
        case SignInModalState.SignIn:
          nextStep = SignInModalState.Signed;
          source = InAppSignInModalCloseReason.NextStep;
          break;
        case SignInModalState.Signed:
          nextStep = SignInModalState.Congrats;
          source = InAppSignInModalCloseReason.NextStep;
          break;
        case SignInModalState.Congrats:
          nextStep = SignInModalState.Congrats;
          source = InAppSignInModalCloseReason.CompleteStep;
          trackMandateSignInModalClosed(source, currentState);
          closeModal({
            closeSource: InAppSignInModalCloseReason.CompleteStep,
            modalState: SignInModalState.Congrats,
          });
      }

      ref.current = nextStep;
      return nextStep;
    });
  }, [closeModal, getPandadocLink]);

  const actionsOnClose = (currentRef: SignInModalState) => {
    if (currentRef && currentRef !== SignInModalState.Congrats) {
      trackMandateSignInModalClosed(InAppSignInModalCloseReason.CrossOrOutsideClick, currentRef);
      closeModal({
        closeSource: InAppSignInModalCloseReason.CrossOrOutsideClick,
        modalState: currentRef,
      });
    }

    if (currentRef !== SignInModalState.Signed && currentRef !== SignInModalState.Congrats) {
      storage
        .readObject<SignMandateReminderType>('signMandateReminder')
        .then(signMandateReminder => {
          storage.saveObject('signMandateReminder', {
            ...signMandateReminder,
            modal: {
              lastDisplayedDate: new Date().toISOString(),
              shouldDisplay: !signMandateReminder?.modal?.lastDisplayedDate,
            },
          });
        });
    }

    if ([SignInModalState.Signed, SignInModalState.Congrats].includes(currentRef)) {
      closeModal({
        closeSource: InAppSignInModalCloseReason.CompleteStep,
        modalState: currentRef,
      });
      closeBanner();
      storage.clear('signMandateReminder').finally(() => {
        navigationRef.reset({
          index: 0,
          routes: [{ name: PrivateNavigatorRoutes.MainMenu }],
        });
      });
    }
  };

  useEffect(() => () => actionsOnClose(ref.current), [closeModal]);

  return (
    <View style={styles.container} testID="in-app-sign-in-modal">
      {modalState === SignInModalState.Overview && (
        <OverviewModal
          advisorFullName={getFullName({ firstName, lastName })}
          handleNextStep={handleNextStep}
        />
      )}
      {[SignInModalState.SignIn, SignInModalState.Signed].includes(modalState) && (
        <SignInModal
          createPandadocLink={data?.createPandadocLink}
          handleNextStep={handleNextStep}
        />
      )}
      {modalState === SignInModalState.Congrats && (
        <CongratsModal handleNextStep={handleNextStep} />
      )}
    </View>
  );
};
