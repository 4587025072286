import React, { useCallback, useContext } from 'react';

import { ModalContext } from '@app/shared/contexts/modal-manager/ModalManagerProvider';
import { ModalKey } from '@app/shared/contexts/modal-manager/modalManager.types';
import {
  CustomerPositioningCancellationReason,
  useApprovePositioningMutation,
  useCancelPositioningMutation,
  useReCallAdvisorMutation,
} from '@app/libs/apollo/introspection';
import { captureException } from '@app/libs/sentry/sentry';
import { useTranslation } from '@app/services/translations/translations';
import { useToastContext } from '@app/shared/contexts/toast/Toast';
import {
  trackPositioningConfirmed,
  trackSuggestionsCallBackClicked,
  trackSuggestionsDislikeReasonModalClosed,
  trackSuggestionsInterestedButtonClicked,
  trackSuggestionsNotInterestedClicked,
  trackSuggestionsNotInterestedSubmit,
  trackSuggestionsPrePositioningCanceledButtonClicked,
} from '@app/services/tracking/trackTracking';
import { SuggestionsSourcePage as TrackingSuggestionsSourceType } from '@app/services/tracking/constants';

import { ApprovePositioningModal } from '../compounds/modals/approve-positioning-modal/ApprovePositioningModal';
import { suggestionsCallLaunched } from '../Suggestions.utils';
import { CancelPositioningModal } from '../compounds/modals/cancel-positioning-modal/CancelPositioningModal';
import {
  TrackingNotInterestedDataType,
  UsePositioningActionsReturn,
} from './usePositioningActions.types';

export const usePositioningActions = (): UsePositioningActionsReturn => {
  const { t } = useTranslation();
  const { openModal, closeModal } = useContext(ModalContext);
  const { setValidMsg, setErrorMsg } = useToastContext();
  const [approvePositioningMutation] = useApprovePositioningMutation();
  const [reCallAdvisorMutation] = useReCallAdvisorMutation();
  const [cancelPositioningMutation] = useCancelPositioningMutation();

  // ----- Approve Positioning -------------
  const approvePositioningSubmit = useCallback(
    (
        positioningId: string,
        realEstateId: string,
        isRecommendation: boolean,
        params: { shouldSendTrackingOnClose: boolean }
      ) =>
      () => {
        approvePositioningMutation({ variables: { id: positioningId } })
          .then(() => {
            trackPositioningConfirmed(realEstateId, isRecommendation);
            params.shouldSendTrackingOnClose = false;
            closeModal();
          })
          .catch(e => {
            captureException(`[Positioning]: ApprovePositioning: ${e.message}`);
            setErrorMsg();
            closeModal();
          });
      },
    [approvePositioningMutation, closeModal, setErrorMsg]
  );

  const approvePositioningCancel = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const handleApprovePositioning = useCallback(
    (
        positioningId: string,
        realEstateId: string
      ): ((source: TrackingSuggestionsSourceType, isRecommendation: boolean) => void) =>
      (source, isRecommendation) => {
        trackSuggestionsInterestedButtonClicked(source, realEstateId);
        const params = { shouldSendTrackingOnClose: true };
        openModal(
          ModalKey.ApprovePositioning,
          <ApprovePositioningModal
            onSubmit={approvePositioningSubmit(
              positioningId,
              realEstateId,
              isRecommendation,
              params
            )}
            onCancel={approvePositioningCancel}
          />,
          {
            callbackClose: () => {
              if (params.shouldSendTrackingOnClose) {
                trackSuggestionsPrePositioningCanceledButtonClicked(realEstateId);
              }
            },
            webSize: 'm',
          }
        );
      },
    [openModal, approvePositioningSubmit, approvePositioningCancel]
  );

  // ----- Need call after confirm Positioning -------------
  const handleNeedCallAfterConfirmPositioning = useCallback(
    (positioningId: string): ((source: TrackingSuggestionsSourceType) => void) =>
      source => {
        trackSuggestionsCallBackClicked(source);
        reCallAdvisorMutation({
          variables: { id: positioningId },
        })
          .then(() => {
            const currentValue = [...suggestionsCallLaunched()];
            currentValue.push(positioningId);
            suggestionsCallLaunched(currentValue);

            setValidMsg({
              title: t('suggestions.actions.confirmReCall'),
              withClose: true,
            });
          })
          .catch(e => {
            captureException(`[Positioning]: ReCallAdvisor: ${e.message}`);
            setErrorMsg();
          });
      },
    [reCallAdvisorMutation, setErrorMsg, setValidMsg, t]
  );

  // ----- Cancel Positioning -------------
  const cancelPositioningSubmit = useCallback(
    (positioningId: string, params: { shouldSendTrackingOnClose: boolean }) =>
      (
        cancellationReasons: CustomerPositioningCancellationReason[],
        cancellationReasonOther: string,
        callbackSuccess: () => void,
        callbackError: () => void
      ) => {
        cancelPositioningMutation({
          variables: {
            cancelPositioningInputs: {
              cancellationReasonOther,
              cancellationReasons,
            },
            id: positioningId,
          },
        })
          .then(() => {
            callbackSuccess();
            params.shouldSendTrackingOnClose = false;
            const reasonsTracking: TrackingNotInterestedDataType = {};
            Object.values(CustomerPositioningCancellationReason).forEach(reason => {
              reasonsTracking[reason] = cancellationReasons.includes(reason);
            });
            trackSuggestionsNotInterestedSubmit(reasonsTracking);
          })
          .catch(e => {
            captureException(`[Positioning]: CancelPositioning: ${e.message}`);
            setErrorMsg();
            callbackError();
          });
      },
    [cancelPositioningMutation, setErrorMsg]
  );

  const handleCancelPositioning = useCallback(
    (
        positioningId: string,
        isSuggestion: boolean
      ): ((source: TrackingSuggestionsSourceType) => void) =>
      (source = TrackingSuggestionsSourceType.SUGGESTIONS_PAGE) => {
        trackSuggestionsNotInterestedClicked(source);
        const params = { shouldSendTrackingOnClose: true };

        openModal(
          ModalKey.CancelPositioning,
          <CancelPositioningModal
            isSuggestion={isSuggestion}
            onSubmit={cancelPositioningSubmit(positioningId, params)}
            onClose={approvePositioningCancel}
          />,
          {
            callbackClose: () => {
              if (params.shouldSendTrackingOnClose) {
                trackSuggestionsDislikeReasonModalClosed();
              }
            },
            webSize: 'm',
          }
        );
      },
    [openModal, cancelPositioningSubmit, approvePositioningCancel]
  );

  return {
    handleApprovePositioning,
    handleCancelPositioning,
    handleNeedCallAfterConfirmPositioning,
  };
};
