import React, { useContext } from 'react';

import { Platform, View, StatusBar } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import {
  BottomSheet,
  ModalFullScreen,
  ModalTitle,
  Spacer,
  Text,
  useTheme,
} from '@masteos/aphrodite';

import { ModalContext } from './ModalManagerProvider';
import { getStyles } from './modalDisplayer.styles';

export const ModalDisplayer: React.FC = () => {
  const { isVisible, closeModal, title, component, position, webSize } = useContext(ModalContext);
  const theme = useTheme();
  const styles = getStyles(theme);
  const { top, bottom } = useSafeAreaInsets();

  if (Platform.OS !== 'web' && position === 'fullscreen') {
    return (
      <ModalFullScreen isVisible={isVisible} onPressClose={closeModal}>
        <View style={styles.content}>
          <Spacer height={Platform.select({ android: StatusBar.currentHeight, ios: top })} />

          <Text>{title}</Text>
          {component}
          <Spacer height={bottom} />
        </View>
      </ModalFullScreen>
    );
  }

  return (
    <>
      <BottomSheet
        visible={isVisible}
        onPressClose={closeModal}
        webSize={webSize}
        testID="modal-wrapper"
      >
        {typeof title === 'string' ? <ModalTitle title={title} /> : title}
        {component}
      </BottomSheet>
    </>
  );
};
