import React, { useCallback, useMemo } from 'react';

import { ActivityIndicator, StyleProp, View, ViewStyle } from 'react-native';
import { useTranslation } from 'react-i18next';

import { Button, Card, palette, Reinsurance, useResponsive } from '@masteos/aphrodite';

import { SearchEngineListType } from '@app/features/search-engine/compounds/search-engine-list/searchEngineList.type';
import { RealEstateCard } from '@app/shared/components/real-estate-card/RealEstateCard';
import { useColumnsNb } from '@app/shared/hooks/useColumnsNb';
import { getStyle } from '@app/features/search-engine/compounds/search-engine-list/searchEngineListWeb.styles';
import { informationCardTypeArray } from '@app/features/search-engine/searchEngine.types';
import { SearchEngineNoResult } from '@app/features/search-engine/compounds/search-engine-no-result/SearchEngineNoResult';

import './searchEngineListWeb.css';
import { RealEstateItem } from '@app/shared/components/real-estate-card/realEstateCard.type';

export const SearchEngineList: React.FC<SearchEngineListType> = ({
  list,
  section,
  hasMore,
  loading,
  onInformationCardPress,
  onItemBookmark,
  onItemClick,
  onItemTooltipHover,
  onFilterShow,
  onFilterReset,
  onLoadMoreRealEstates,
}) => {
  const { t } = useTranslation();
  const { isLowerThanTablet } = useResponsive();

  const columnsNb = useColumnsNb();
  const hasSearchEngineCardItem =
    list.filter(l => !informationCardTypeArray.includes(l.type)).length > 0;

  const styles = getStyle({ columnsNb, isLowerThanTablet });

  const handleItemClick = useCallback(
    (item: RealEstateItem, pos: number) => () => onItemClick(item, { position: pos }),
    [onItemClick]
  );

  const handleItemBookmark = useCallback(
    (item: RealEstateItem) => () => onItemBookmark(item),
    [onItemBookmark]
  );

  const handlePressInformationCard = useCallback(
    (key: number, type: string) => () => onInformationCardPress(key, type),
    [onInformationCardPress]
  );

  const renderList = useMemo(() => {
    return list.map((item: any, index) =>
      informationCardTypeArray.includes(item?.type) ? (
        <Card key={item.title + item.text}>
          <Reinsurance
            title={item.title}
            description={item.text}
            image={item.image}
            {...(item.buttonText && { buttonLabel: item.buttonText })}
            {...(item &&
              item.buttonText && { onPress: handlePressInformationCard(item.key, item.type) })}
          />
        </Card>
      ) : (
        <RealEstateCard
          key={item.id}
          {...item}
          onBookmark={handleItemBookmark(item)}
          onClick={handleItemClick(item, index)}
          onTooltipHover={onItemTooltipHover}
        />
      )
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  const renderLoader = useMemo(() => {
    return (
      <div style={styles.loaderContainer}>
        <ActivityIndicator size="large" color={palette.neutral[500]} />
      </div>
    );
  }, [styles.loaderContainer]);

  if (loading && list.length === 0) {
    return renderLoader;
  }

  return (
    <div style={styles.container}>
      {!hasSearchEngineCardItem && (
        <div style={styles.noResultContainer}>
          <SearchEngineNoResult
            section={section}
            onFilterShow={onFilterShow}
            onFilterReset={onFilterReset}
          />
        </div>
      )}

      {!!onLoadMoreRealEstates && (
        <>
          <View style={styles.wrapper as StyleProp<ViewStyle>}>{renderList}</View>

          <View style={styles.seeMoreWrapper as StyleProp<ViewStyle>}>
            {!!loading && renderLoader}

            {!loading && !!hasMore && (
              <Button
                onPress={onLoadMoreRealEstates}
                wrapperStyle={styles.seeMoreButton}
                variant="tertiary"
              >
                {t('shared.seeMore')}
              </Button>
            )}
          </View>
        </>
      )}
    </div>
  );
};
