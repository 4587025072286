import React from 'react';

import { Button, ButtonProps } from '@masteos/aphrodite';

import { useFormContext } from './FormContext.web';

export const SubmitButton: React.FC<ButtonProps> = buttonProps => {
  const formContext = useFormContext();

  if (!formContext) {
    console.warn(
      '<SubmitButton /> must be used inside a Form component. Button has been rendered without onPress and isLoading props'
    );

    return <Button {...buttonProps} />;
  }

  return (
    <Button onPress={formContext.handleSubmit} isLoading={formContext.isSaving} {...buttonProps} />
  );
};
