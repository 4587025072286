import React, { ChangeEvent, useRef, useState } from 'react';

import { StyleSheet } from 'react-native';

import { Avatar, Button, Spacing } from '@masteos/aphrodite';

import { useTranslation } from '@app/services/translations/translations';
import { useUpdateCustomerAvatarMutation } from '@app/libs/apollo/introspection';
import { fromS3ID } from '@app/utils/from-s3id';
import { useMe } from '@app/shared/hooks/useMe';
import * as ErrorMonitoring from '@app/services/errorMonitoring';

import * as S from './profile.styles.web';

interface ProfilePictureProps {
  size?: number;
  avatarUrl: string;
  onChange: (value: string) => void;
}

const styles = StyleSheet.create({
  input: { display: 'none' },
});

export const ProfilePicture: React.FC<ProfilePictureProps> = ({
  size = Spacing.SIZE_12,
  avatarUrl,
  onChange,
}) => {
  const { t } = useTranslation();
  const [updateAvatar] = useUpdateCustomerAvatarMutation();
  const { customer } = useMe();
  const [imageUri, setImageUri] = useState(fromS3ID(customer?.account.avatarS3ID, `w-${size * 4}`));
  const inputFile = useRef<HTMLInputElement>();

  const handleInputChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files.item(0);
    try {
      const { data } = await updateAvatar({ variables: { image: file, type: file.type } });
      const uri = fromS3ID(data?.updateCustomerAvatar?.account?.avatarS3ID, `w-${size * 4}`);
      setImageUri(uri);
      onChange(uri);
    } catch (error) {
      ErrorMonitoring.logError(error);
    }
  };

  const removeProfilePicture = async () => {
    await updateAvatar();
    setImageUri(null);
    onChange(null);
  };

  const addOrUpdateProfilePicture = () => inputFile.current.click();

  return (
    <S.ProfilePicture>
      <Avatar avatarUrl={imageUri} size={size} />
      <S.ProfilePictureActions>
        <Button variant="tertiary" onPress={addOrUpdateProfilePicture}>
          {avatarUrl ? t('profile.edit') : t('profile.add')}
        </Button>
        {avatarUrl ? (
          <Button variant="secondary" onPress={removeProfilePicture}>
            {t('profile.delete')}
          </Button>
        ) : null}
      </S.ProfilePictureActions>

      <input
        onChange={handleInputChange}
        ref={inputFile}
        style={styles.input}
        type="file"
        accept="image/*"
      />
    </S.ProfilePicture>
  );
};
