import React, { useMemo } from 'react';

import {
  Avatar,
  Enlightenment,
  EnlightenmentProps,
  Gap,
  Icon,
  Spacing,
  Text,
} from '@masteos/aphrodite';

import { fromS3ID } from '@app/utils/from-s3id';
import { useTranslation } from '@app/services/translations/translations';

const iconList = ['conseiller3', 'conseiller', 'conseiller2'].map(icon => (
  <Avatar key={'icon_' + icon} avatarUrl={fromS3ID(`/production/assets/${icon}.png`, 'ar1-1')} />
));

export const AdvisorEnlightenment: React.FC = () => {
  const { t } = useTranslation();

  const itemList = useMemo<EnlightenmentProps['itemList']>(
    () => [
      {
        description: t('advisor.enlightenment.1.description'),
        iconList,
        title: t('advisor.enlightenment.1.title'),
      },
      {
        description: t('advisor.enlightenment.2.description'),
        iconList: [<Icon key="icon_new" name="New" />],
        title: t('advisor.enlightenment.2.title'),
      },
      {
        description: t('advisor.enlightenment.3.description'),
        iconList: [<Icon key="icon_zap" name="Zap" />],
        title: t('advisor.enlightenment.3.title'),
      },
    ],
    [t]
  );

  return (
    <Gap direction="vertical" gap={Spacing.SIZE_07}>
      <Text textStyle="Headline1Medium">{t('advisor.enlightenment.title')}</Text>
      <Enlightenment itemList={itemList} />
    </Gap>
  );
};
