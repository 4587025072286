import React, { PropsWithChildren } from 'react';

import { KeyboardAvoidingView, Platform } from 'react-native';

import { Spacing } from '@masteos/aphrodite';

import { PlatformEnum } from '@app/types/platform';
import { keyboardHandlerStyles } from '@app/shared/containers/keyboard-handler/keyboardHandler.styles';

// FIXME: move into Aphrodite

export const KeyboardHandler: React.FunctionComponent<PropsWithChildren> = ({ children }) => {
  const isWeb = Platform.OS === PlatformEnum.Web;

  const style = keyboardHandlerStyles();

  if (!isWeb) {
    return (
      <KeyboardAvoidingView
        style={style.container}
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        keyboardVerticalOffset={Spacing.SIZE_05}
      >
        {children}
      </KeyboardAvoidingView>
    );
  }

  return <React.Fragment>{children}</React.Fragment>;
};
