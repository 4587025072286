import { Platform } from 'react-native';

import { CheckboxTileType } from '@masteos/aphrodite';

import {
  CategoriesToCheckboxTilesParams,
  NotificationToCheckboxTilesParams,
  ProfileNotificationPreferenceProps,
} from '@app/features/profile/types/notificationPrefences.type';
import { PlatformEnum } from '@app/types/platform';
import {
  CustomerNotificationInput,
  CustomerNotificationStatusInput,
} from '@app/libs/apollo/introspection';

import {
  NotificationPreferencesCategory,
  TileIconName,
} from '../compounds/profileNotificationPreferences/constants';

export const categoriesToCheckboxTileGroups = ({
  categories,
  customerNotifPreferences,
  notificationPreferenceOnChange,
}: CategoriesToCheckboxTilesParams): ProfileNotificationPreferenceProps[] =>
  categories.map(category => {
    const checkboxTileGroup: ProfileNotificationPreferenceProps = {
      calloutText: `profile.notificationPreferences.${category}.callout`,
      category,
      notificationTypes: customerNotifPreferences?.[category] || {},
      onChange: notificationPreferenceOnChange,
      subTitle: `profile.notificationPreferences.${category}.subTitle`,
      title: `profile.notificationPreferences.${category}.title`,
    };

    return checkboxTileGroup;
  });

export const notificationToCheckboxTiles = ({
  category,
  notificationStatuses,
  notificationOnChange,
  t,
}: NotificationToCheckboxTilesParams): CheckboxTileType[] =>
  Object.entries(notificationStatuses)
    .map(([type, value]: [keyof CustomerNotificationInput, CustomerNotificationStatusInput]) => ({
      checked: value?.status,
      // override rule for deal emails : always disabled
      // to be extracted if we have other needs
      disabled: category === NotificationPreferencesCategory.dealFollowUp && type === 'email',
      iconName: TileIconName[type],
      label: t(`profile.notificationPreferences.${type}`),
      onChange: () => notificationOnChange(type),
      type,
    }))
    // Hide appPush tile on web
    .filter(({ type }) => Platform.OS !== PlatformEnum.Web || type !== 'appPush');
