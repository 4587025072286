import React, { useCallback, useMemo } from 'react';

import { ScrollView, View } from 'react-native';

import {
  CircleStepIndicator,
  Expandable,
  ExpandableContext,
  Icon,
  Spacer,
  StepStatus,
  Text,
  useResponsive,
  useTheme,
  ViewOrModal,
  ViewOrModalBlock,
} from '@masteos/aphrodite';

import { useTranslation } from '@app/services/translations/translations';
import { ProjectDetailSubSteps } from '@app/features/project/ProjectDetail/ProjectDetailSubSteps';
import { ProjectDetailStepProps } from '@app/features/project/ProjectDetail/ProjectDetail.type';
import { getDetailStageStyles } from '@app/features/project/ProjectDetail/ProjectDetailStage.styles';
import { useMe } from '@app/shared/hooks/useMe';
import { getFullName } from '@app/utils/getFullName';

import { ProjectDetailStepAdditionalInfo } from './ProjectDetailStepAdditionnalInfo';
export const ProjectDetailStage: React.FC<ProjectDetailStepProps> = ({
  content,
  estimatedDuration,
  onExpandSubSteps,
  onExpandSubStepsDetails,
  projectCategory,
  requiredDocuments,
  stageNumber,
  stageRefs,
  status,
  subSteps,
  title,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { customer } = useMe();
  const advisor = getFullName(customer.advisor);
  const { isGreaterThanTablet, isMobile } = useResponsive();
  const styles = useMemo(
    () => getDetailStageStyles(isMobile, status, theme),
    [isMobile, status, theme]
  );
  const handleRef = useCallback(
    () => (el: View) => (stageRefs.current[projectCategory][stageNumber - 1] = el),
    [projectCategory, stageNumber, stageRefs]
  );
  const indicator =
    status === StepStatus.Finished ? (
      <Icon name="Check" color={theme.palette.base.white} height="20" />
    ) : (
      stageNumber
    );

  const handleExpand = props => () => {
    props.openModal?.();
    onExpandSubSteps({ expanded: true });
  };

  return (
    <ViewOrModal delegatePosButton>
      {(props = {}) => (
        <View
          ref={handleRef()}
          style={[
            styles.container,
            props.isModalRender ? styles.modalContainer : styles.cardContainer,
          ]}
        >
          <View style={styles.titleBloc}>
            <CircleStepIndicator indicator={indicator} status={status} />

            <Text textStyle="Headline2Medium" style={styles.title}>
              {t(title)}
            </Text>
          </View>

          <View style={styles.contentSection}>
            <View style={styles.contentContainer}>
              {isGreaterThanTablet ? <Spacer width={theme.spacing.SIZE_10} /> : null}
              <ScrollView style={styles.contentBox}>
                <View style={!!props.isModalRender && styles.modalContentBox}>
                  <ViewOrModalBlock
                    {...props}
                    isHiddenInModal
                    testID="project-detail-stage-description--block"
                  >
                    <Text textStyle="Body2" style={styles.contentText}>
                      {t(content, { advisor })}
                    </Text>
                  </ViewOrModalBlock>

                  {!!subSteps && (
                    <>
                      <ViewOrModalBlock
                        {...props}
                        isHiddenInMobile
                        isHiddenInModal
                        testID="project-detail-stage-details-web--block"
                      >
                        <Expandable
                          id={title}
                          onPress={onExpandSubSteps}
                          initialExpanded={!!props.isModalRender && status === StepStatus.Ongoing}
                        >
                          <Expandable.Header>
                            <ExpandableContext.Consumer>
                              {({ expanded }) => (
                                <Text
                                  textStyle="Link2"
                                  style={styles.link}
                                  testID="sub-step-link--press"
                                >
                                  {t(expanded ? 'project.hideDetails' : 'project.viewDetails')}
                                </Text>
                              )}
                            </ExpandableContext.Consumer>
                          </Expandable.Header>
                          <Expandable.Content>
                            <View style={styles.subSteps}>
                              <ProjectDetailSubSteps
                                onExpandSubStep={onExpandSubStepsDetails}
                                subSteps={subSteps}
                                status={status}
                              />
                            </View>
                          </Expandable.Content>
                        </Expandable>
                      </ViewOrModalBlock>

                      <ViewOrModalBlock
                        {...props}
                        isHiddenInWeb
                        isHiddenInModal
                        testID="project-detail-stage-details-open-mobile--block"
                      >
                        <Text
                          textStyle="Link2"
                          style={styles.link}
                          onPress={handleExpand(props)}
                          testID="sub-step-link--press"
                        >
                          {t('project.viewDetails')}
                        </Text>
                      </ViewOrModalBlock>

                      <ViewOrModalBlock
                        {...props}
                        isHiddenInWeb
                        isHiddenInMobile
                        testID="project-detail-stage-details-mobile--block"
                      >
                        <ProjectDetailSubSteps
                          onExpandSubStep={onExpandSubStepsDetails}
                          subSteps={subSteps}
                          status={status}
                        />
                      </ViewOrModalBlock>
                    </>
                  )}

                  <ViewOrModalBlock {...props} isHiddenInMobile isModalRender isHiddenInWeb>
                    <Spacer height={theme.spacing.SIZE_08} />
                  </ViewOrModalBlock>

                  <View style={styles.additionalInfoContainer}>
                    {requiredDocuments?.length > 0 && (
                      <ProjectDetailStepAdditionalInfo
                        title={t('project.detailSteps.requiredDocument', {
                          count: requiredDocuments.length,
                        })}
                        infoList={requiredDocuments.map(doc => ({
                          label: t(doc.label),
                          optional: doc.optional,
                        }))}
                      />
                    )}

                    {estimatedDuration ? (
                      <ProjectDetailStepAdditionalInfo
                        title={t('project.detailSteps.estimatedDuration')}
                        infoList={[
                          {
                            label: t('project.detailSteps.estimatedDay', {
                              count: estimatedDuration,
                            }),
                          },
                        ]}
                      />
                    ) : null}
                  </View>
                </View>
              </ScrollView>
            </View>
          </View>
        </View>
      )}
    </ViewOrModal>
  );
};
