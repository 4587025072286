import React, { useRef } from 'react';

import { View, Platform, ScrollView, TextInput } from 'react-native';
import { useTranslation } from 'react-i18next';

import { PhoneInput, Spacer, Spacing, useResponsive } from '@masteos/aphrodite';

import { Values } from '@app/shared/contexts/form/types.web';
import { Input, SubmitButton, Checkbox, useFormContext } from '@app/shared/contexts/form/index.web';
import { PlatformEnum } from '@app/types/platform';

import { getStyles, submitButtonStyles } from '../onboarding.styles';
import { CguRegister } from './CguRegister';

export interface StepRegisterFormProps {
  error?: string;
  isLoading?: boolean;
  isSSOContext: boolean;
  handleSubmit: (values: Values) => void;
}

export const StepRegisterForm: React.FC<StepRegisterFormProps> = ({
  error = '',
  isSSOContext,
  isLoading = false,
  handleSubmit,
}) => {
  const { getError, getValue, setValue } = useFormContext();
  const lastNameRef = useRef<TextInput>();
  const emailRef = useRef<TextInput>();
  const passwordRef = useRef<TextInput>();
  const { t } = useTranslation();
  const { isGreaterThanTablet } = useResponsive();
  const commonStyles = getStyles({
    isFixed: Platform.OS === PlatformEnum.Web,
    isGreaterThanTablet,
  });

  const createOnSubmitEditingHandler = (nextInputRef?: TextInput) => (values: Values) => {
    if (nextInputRef && Platform.OS !== PlatformEnum.Web) {
      nextInputRef.focus();
      return;
    }

    handleSubmit(values);
  };

  return (
    <ScrollView
      contentContainerStyle={commonStyles.scrollViewContainerWeb}
      style={commonStyles.scrollView}
      showsVerticalScrollIndicator={false}
    >
      <Input
        autoCorrect={false}
        label={t('shared.firstName')}
        name="firstName"
        onSubmitEditing={createOnSubmitEditingHandler(lastNameRef.current)}
        testID="onboarding-first-name-input"
        wrapperStyle={commonStyles.input}
      />

      <Input
        autoCorrect={false}
        label={t('shared.lastName')}
        name="lastName"
        onSubmitEditing={createOnSubmitEditingHandler(emailRef.current)}
        ref={lastNameRef}
        testID="onboarding-last-name-input"
        wrapperStyle={commonStyles.input}
      />

      {!isSSOContext && (
        <>
          <Input
            autoCapitalize="none"
            autoCorrect={false}
            error={error}
            keyboardType="email-address"
            label={t('register.emailLabel')}
            name="email"
            onSubmitEditing={createOnSubmitEditingHandler(passwordRef.current)}
            placeholder={t('register.emailPlaceholder')}
            ref={emailRef}
            testID="onboarding-email-input"
            textContentType="emailAddress"
            wrapperStyle={commonStyles.input}
          />

          <Input
            autoCapitalize="none"
            isPassword
            label={t('register.passwordLabel')}
            name="password"
            onSubmitEditing={handleSubmit}
            ref={passwordRef}
            testID="onboarding-password-input"
            textContentType="password"
            wrapperStyle={commonStyles.input}
          />
        </>
      )}

      <PhoneInput
        countryValue={getValue('phoneNumberPrefix')}
        error={getError('phoneNumberSuffix')}
        label={t('register.phoneLabel')}
        onChange={v => setValue('phoneNumberSuffix', v)}
        onCountryChange={v => setValue('phoneNumberPrefix', v.toString())}
        placeholder={t('register.phoneNumberPlaceholder')}
        testID="onboarding-phone-number-input"
        value={getValue('phoneNumberSuffix') + getValue('phoneNumberPrefix')}
        hint={t('register.phoneNumberHint')}
      />

      <Spacer height={Spacing.SIZE_09} />

      <Checkbox
        hideErrorMessage={false}
        label={t(`register.newsletterLabel`)}
        name="isSubscribeNewsletter"
        testID="onboarding-newsletter-input"
      />
      <Checkbox name="terms" testID="onboarding-terms-input" hideErrorMessage={false}></Checkbox>
      <CguRegister isWeb={Platform.OS === PlatformEnum.Web} />
      <View style={commonStyles.bottomContainer}>
        <View style={commonStyles.buttonWrapper}>
          <SubmitButton
            isLoading={isLoading}
            disabled={isLoading}
            variant="primary"
            testID="onboarding-submit-button"
            style={submitButtonStyles}
          >
            {t('register.submitButton')}
          </SubmitButton>
        </View>
      </View>
    </ScrollView>
  );
};
