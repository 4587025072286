import React, { useMemo } from 'react';

import { Platform, StatusBar } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { Banner as AphroditeBanner } from '@masteos/aphrodite';

import { useTranslation } from '@app/services/translations/translations';
import { PlatformEnum } from '@app/types/platform';

import { getStyles } from './banner.styles';

type BannerProps = {
  bannerHeight: number;
  onClose: () => void;
  handleBannerOpenModal: () => void;
};

export const Banner: React.FC<BannerProps> = ({ bannerHeight, onClose, handleBannerOpenModal }) => {
  const isWeb = Platform.OS === PlatformEnum.Web;
  const { t } = useTranslation();
  const { top } = useSafeAreaInsets();

  const paddingTop = Platform.select({ android: StatusBar.currentHeight, ios: top });

  const styles = useMemo(
    () => getStyles({ bannerHeight, isWeb, paddingTop, topOffset: top }),
    [bannerHeight, isWeb, paddingTop, top]
  );

  return (
    <AphroditeBanner
      testID="mandate-banner-container"
      style={styles.bannerStyle}
      text={t('bannerMandateSignIn.finalizeSignInMandate')}
      iconName="Info"
      closeButtonLabel={t('shared.close')}
      buttonLabel={t('modalMandateSignIn.button')}
      onClose={onClose}
      onButtonPress={handleBannerOpenModal}
    />
  );
};
