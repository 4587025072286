import { useGetCustomerLazyQuery } from '@app/libs/apollo/introspection';
import { identityClient } from '@app/libs/identity/identityClient';
import { storage } from '@app/services/storage/storage';
import { getLastVersion } from '@app/utils/getLastWebVersion';
import { SignInMethod } from '@app/features/authBridge/authBridge.constants';
import { trackLogin } from '@app/services/tracking/trackTracking';
import { AuthenticationStrategy } from '@app/libs/identity/identity.type';

export interface UseAuthentication {
  completeAuthentication: (token: string, strategy: AuthenticationStrategy) => Promise<void>;
  loginWithEmailPassword: (email: string, password: string) => Promise<void>;
}

export const useAuthentication = (): UseAuthentication => {
  const [getCustomer] = useGetCustomerLazyQuery({
    variables: { nativeRelease: getLastVersion() },
  });

  const completeAuthentication = async (token: string, strategy: AuthenticationStrategy) => {
    await storage.saveString('token', token);

    const method =
      strategy === AuthenticationStrategy.Local ? SignInMethod.Mail : SignInMethod.Google;
    trackLogin(method);
    getCustomer();
  };

  const loginWithEmailPassword = async (email: string, password: string) => {
    const { access_token } = await identityClient.authenticate({
      email,
      password,
      strategy: AuthenticationStrategy.Local,
    });
    await completeAuthentication(access_token, AuthenticationStrategy.Local);
  };

  return {
    completeAuthentication,
    loginWithEmailPassword,
  };
};
