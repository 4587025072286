/* eslint-disable max-lines */
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Platform } from 'react-native';

import {
  AvailableCountryCode,
  RealEstate,
  SearchRealEstatesDocument,
  SearchRealEstatesQuery,
  useGetLocationsQuery,
  useGetMyFavoritesRealEstatesQuery,
  useSearchRealEstatesQuery,
} from '@app/libs/apollo/introspection';
import {
  PropertyPage,
  trackFilterClick,
  TrackingOrigin,
  trackLoadMoreButtonClicked,
  trackResetFilterClick,
  trackSortStrategyClick,
} from '@app/services/tracking/trackTracking';
import { useTranslation } from '@app/services/translations/translations';
import { getApolloClient } from '@app/libs/apollon/apollonClient';
import { PlatformEnum } from '@app/types/platform';
import { useMe } from '@app/shared/hooks/useMe';
import { customerPreferencesToPreferencesInput } from '@app/utils/customerPreferencesToPreferencesInput';
import { useToastContext } from '@app/shared/contexts/toast/Toast';
import { RealEstateItem } from '@app/shared/components/real-estate-card/realEstateCard.type';
import { useBookmark } from '@app/shared/hooks/useBookmark';

import { getInfoPerTotalResult } from '../utils/get-limit-per-page';
import {
  SearchEngineFilterForm,
  SearchEngineRealEstatesLoadingType,
  SearchEngineSource,
} from '../searchEngine.types';
import { filterToPreferencesMapper } from '../compounds/search-engine-filters/utils/filter-to-preferences.mapper';
import { getFilterChanges } from '../compounds/search-engine-filters/utils/get-filter-changes';
import { clearReinsuranceCardList } from '../utils/add-information-card-on-list';
import { getSortedCustomerPref } from './useGetSearchEngineDatas.utils';

interface RealEstateLocations {
  __typename?: string;
  countryCode: AvailableCountryCode;
  regions: string[];
}

interface RealEstateCursor {
  nextRealEstateId?: string;
  nextRealEstatePublishingDate?: Date;
}

export type RealEstateSort = [string, string][];

export interface UseGetSearchEngineDatasResult {
  totalBookmarks: number;
  totalRealEstates: number;
  loading: boolean;
  filterLoading: boolean;
  realEstates: RealEstate[];
  locations: RealEstateLocations[];
  bookmarkedRealEstates: RealEstate[];
  bookmarksLoading: boolean;
  cursor?: RealEstateCursor;
  loadingType: SearchEngineRealEstatesLoadingType;
  myFavoritesRefetch: () => void;
  next: () => Promise<void>;
  toggleBookmark: (item: RealEstateItem) => void;
}

export type FiltersStateProps = {
  source: SearchEngineSource;
  filtersField: SearchEngineFilterForm;
  resetMode: boolean;
};

export const useGetSearchEngineDatas = ({
  filters,
}: {
  filters: FiltersStateProps;
}): UseGetSearchEngineDatasResult => {
  const client = getApolloClient();
  const { stateOfCustomer, customer } = useMe();
  const { toggleBookmark } = useBookmark({});
  const [totalInCache, setTotalInCache] = useState(0);
  const [loadingType, setLoadingType] = useState(SearchEngineRealEstatesLoadingType.Refetch);

  const { setValidMsg } = useToastContext();
  const { t } = useTranslation();

  const customerPref = getSortedCustomerPref(customer);

  const { filtersField } = filters || {};

  const hasFilter = filters && Object.keys(filtersField).length;
  const isWeb = Platform.OS === PlatformEnum.Web;

  const limit = totalInCache || getInfoPerTotalResult(stateOfCustomer, undefined).limit;

  const formattedFiltersField = useMemo(
    () => (hasFilter ? filterToPreferencesMapper(filtersField) : undefined),
    [filtersField, hasFilter]
  );

  const currentFilters = useMemo(
    () => (hasFilter ? formattedFiltersField : customerPreferencesToPreferencesInput(customerPref)),
    [formattedFiltersField, customerPref, hasFilter]
  );

  useEffect(() => {
    const res = client.cache.readQuery<SearchRealEstatesQuery>({
      query: SearchRealEstatesDocument,
    });

    res?.searchRealEstates.realEstates.length > 0 &&
      setTotalInCache(res.searchRealEstates.realEstates.length);
  }, [client.cache]);

  useEffect(() => {
    setLoadingType(SearchEngineRealEstatesLoadingType.Refetch);
  }, [filters]);

  const onCompleted = useCallback(
    data => {
      if (!hasFilter || loadingType === SearchEngineRealEstatesLoadingType.FetchMore) {
        return;
      }

      clearReinsuranceCardList();

      if (filters?.resetMode) {
        trackResetFilterClick({
          numberOfResults: data.searchRealEstates.total,
          source: filters?.source,
        });
      } else if (filters.source == SearchEngineSource.SORT_STRATEGY) {
        trackSortStrategyClick(filtersField.sortStrategy);
      } else {
        trackFilterClick(
          getFilterChanges(filtersField),
          filterToPreferencesMapper(filtersField),
          data.searchRealEstates.total,
          filters.source
        );
      }

      const message =
        filters?.source === SearchEngineSource.NO_RESULT
          ? t('searchEngine.toast.filter.reset')
          : t('searchEngine.toast.filter.apply');

      setValidMsg({
        icon: 'Check',
        title: message,
        withClose: true,
      });
    },
    [hasFilter, filters?.resetMode, filters?.source, filtersField, setValidMsg, t, loadingType]
  );

  const {
    data: myRealEstatesData,
    loading: myRealEstatesLoading,
    fetchMore: myRealEstatesFetchMore,
  } = useSearchRealEstatesQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    onCompleted,
    skip: !stateOfCustomer,
    variables: {
      filters: currentFilters,
      limit,
      withCustomerPreferencesUpdate: !!formattedFiltersField,
    },
  });

  const { data: getLocationsData, loading: getLocationsLoading } = useGetLocationsQuery({
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: getMyFavoritesData,
    loading: getMyFavoritesLoading,
    refetch: myFavoritesRefetch,
  } = useGetMyFavoritesRealEstatesQuery({
    fetchPolicy: 'cache-and-network',
  });

  const next = useCallback(async () => {
    setLoadingType(SearchEngineRealEstatesLoadingType.FetchMore);
    trackLoadMoreButtonClicked(
      getInfoPerTotalResult(stateOfCustomer, myRealEstatesData.searchRealEstates.realEstates.length)
        .loadMoreButton
    );

    if (myRealEstatesData?.searchRealEstates.cursor) {
      myRealEstatesFetchMore({
        variables: {
          cursor: {
            nextRealEstateId: myRealEstatesData.searchRealEstates.cursor.nextRealEstateId,
            nextRealEstateNetReturn:
              myRealEstatesData.searchRealEstates.cursor.nextRealEstateNetReturn,
            nextRealEstatePublishingDate:
              myRealEstatesData.searchRealEstates.cursor.nextRealEstatePublishingDate,
          },
          filters: currentFilters,
          limit: getInfoPerTotalResult(
            stateOfCustomer,
            myRealEstatesData.searchRealEstates.realEstates.length
          ).limit,
          withCustomerPreferencesUpdate: false,
        },
      });
    }
  }, [stateOfCustomer, myRealEstatesData, currentFilters, isWeb, myRealEstatesFetchMore]);

  const realEstates = useMemo(() => {
    return (myRealEstatesData?.searchRealEstates.realEstates as RealEstate[]) ?? [];
  }, [myRealEstatesData]);

  const bookmarkedRealEstates = useMemo(() => {
    return (getMyFavoritesData?.myFavoritesRealEstates.realEstates as RealEstate[]) ?? [];
  }, [getMyFavoritesData]);

  const handleToggleBookMark = item => {
    toggleBookmark(item, {
      currentTab: TrackingOrigin.PREVIEW,
      page: PropertyPage.MATCHING,
    });
  };

  return {
    bookmarkedRealEstates,
    bookmarksLoading: getMyFavoritesLoading,
    cursor: myRealEstatesData?.searchRealEstates.cursor,
    filterLoading: getLocationsLoading,
    loading: myRealEstatesLoading,
    loadingType,
    locations: getLocationsData?.getLocations.locations ?? [],
    myFavoritesRefetch,
    next,
    realEstates,
    toggleBookmark: handleToggleBookMark,
    totalBookmarks: getMyFavoritesData?.myFavoritesRealEstates.realEstates.length ?? 0,
    totalRealEstates: myRealEstatesData?.searchRealEstates.total ?? 0,
  };
};
