import { StyleProp, StyleSheet, ViewStyle } from 'react-native';

export const HORIZONTAL_PADDING = {
  DESKTOP: 40,
  MOBILE: 20,
};
export const MENU_TOP_HEIGHT = 56;
export const MAX_WIDTH_CONTAINER = 1040;

export const projectStyles: Record<string, StyleProp<ViewStyle>> = StyleSheet.create({
  contentContainer: {
    flexGrow: 1,
    position: 'relative',
  },
  scrollViewContentContainer: { flexGrow: 1 },
});
