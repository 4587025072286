import React from 'react';

import { createStackNavigator } from '@react-navigation/stack';

import ProjectView from '@app/views/project/ProjectView';
import ProjectListView from '@app/views/projectList/ProjectListView';
import { ProjectNavigatorRoutes, ProjectsStackParamList } from '@app/navigation/types/routes';
import { SCREEN_OPTIONS } from '@app/navigation/constants/navigation-options';
import { RealEstateView } from '@app/views/realEstate/RealEstateView';

const Stack = createStackNavigator<ProjectsStackParamList>();

export const ProjectNavigator: React.FC = () => (
  <Stack.Navigator
    initialRouteName={ProjectNavigatorRoutes.ProjectList}
    screenOptions={SCREEN_OPTIONS}
  >
    <Stack.Screen name={ProjectNavigatorRoutes.Project} component={ProjectView} />
    <Stack.Screen name={ProjectNavigatorRoutes.ProjectList} component={ProjectListView} />
    <Stack.Screen
      navigationKey="project"
      name={ProjectNavigatorRoutes.RealEstate}
      component={RealEstateView}
    />
  </Stack.Navigator>
);
