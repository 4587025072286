/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';

import { useTranslation } from 'react-i18next';
import { Platform, StyleProp, ViewStyle } from 'react-native';

import { SubscriptionHeader as SubscriptionHeaderUi } from '@masteos/aphrodite';

import { I18nSupportedLanguage } from '@app/libs/i18n/i18n.types';
import { LanguageSwitcher } from '@app/shared/components/languageSwitcher/LanguageSwitcher';
import { storage } from '@app/services/storage/storage';
import { trackPublicLanguageSwitch } from '@app/services/tracking/trackTracking';
import { PlatformEnum } from '@app/types/platform';

import { styles } from './subscriptionHeader.styles';

interface SubscriptionHeaderProps {
  title?: string;
  onPressArrow?: () => void;
  style?: StyleProp<ViewStyle>;
  onyLanguageSwitcher?: boolean;
  languageSwitcherStyle?: StyleProp<ViewStyle>;
}

export const SubscriptionHeader: React.FC<SubscriptionHeaderProps> = ({
  title,
  style,
  onPressArrow,
  onyLanguageSwitcher,
  languageSwitcherStyle,
}) => {
  const { i18n } = useTranslation();

  const handlePressArrow = () => onPressArrow && onPressArrow();

  const handleLangChange = async (langKey: string) => {
    const nextLang: I18nSupportedLanguage = I18nSupportedLanguage[langKey];

    i18n.changeLanguage(nextLang);
    trackPublicLanguageSwitch(nextLang);

    if (Platform.OS !== PlatformEnum.Web) {
      await storage.saveString('i18nextLng', nextLang);
    }
  };

  const languageSwitcherElement: React.ReactElement = (
    <LanguageSwitcher
      lang={i18n.language || I18nSupportedLanguage.fr}
      onLangChange={handleLangChange}
      small
      style={[styles.languageSwitcher, languageSwitcherStyle]}
    />
  );

  if (onyLanguageSwitcher) {
    return languageSwitcherElement;
  }

  return (
    <SubscriptionHeaderUi
      title={title}
      style={style}
      actionWidth={80}
      onPressArrow={handlePressArrow}
      rightZone={languageSwitcherElement}
    />
  );
};
