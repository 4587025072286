import { IconName, StepStatus } from '@masteos/aphrodite';

import { TransactionStatus } from '@app/libs/apollo/introspection';
import { ProjectCategory } from '@app/features/project/Project.types';

const statusMapper = {
  [TransactionStatus.Ongoing]: StepStatus.Ongoing,
  [TransactionStatus.Finished]: StepStatus.Finished,
};

export const getStepStatus: (status: TransactionStatus) => StepStatus = status =>
  statusMapper[status] || StepStatus.Incoming;

export const getIconIndicator: (category: ProjectCategory) => IconName = category => {
  switch (category) {
    case ProjectCategory.Transaction:
      return 'Invest';
    case ProjectCategory.Renovation:
      return 'Works';
    case ProjectCategory.Renting:
      return 'Key';
    default:
      return null;
  }
};
