import React from 'react';

import { Button, useResponsive } from '@masteos/aphrodite';

import { ProjectTabScreen } from '@app/features/project/ProjectTabScreen/ProjectTabScreen';
import { ProjectCategory } from '@app/features/project/Project.types';
import { ProjectTabsViewProps } from '@app/features/project/ProjectTabsView/ProjectTabsView.type';
import { TabView } from '@app/shared/components/TabView/TabView';
import { useTranslation } from '@app/services/translations/translations';
import { projectTabsViewStyles as styles } from '@app/features/project/ProjectTabsView/ProjectTabsView.styles';
import { GetProjectQuery } from '@app/libs/apollo/introspection';

export const ProjectTabsView: React.FC<ProjectTabsViewProps> = ({
  navigateToRealEstate,
  onExpandSubSteps,
  onExpandSubStepsDetails,
  onTabPress,
  projectSteps,
  realEstate,
  stageRefs,
  stepActive,
}) => {
  const { t } = useTranslation();
  const { isLowerThanDesktop } = useResponsive();
  const tabViewIndex = stepActive - 1;

  const tabsContent = Object.entries(projectSteps).map(
    ([category, steps]: [ProjectCategory, GetProjectQuery['project']['transaction']]) => ({
      component: () => (
        <ProjectTabScreen
          realEstate={realEstate}
          projectCategory={category}
          projectSteps={steps}
          onExpandSubSteps={onExpandSubSteps}
          onExpandSubStepsDetails={onExpandSubStepsDetails}
          stageRefs={stageRefs}
        />
      ),
      key: category,
      title: t(`project.${category}.tab.name`),
    })
  );
  const onIndexChange = index => onTabPress?.(index + 1);

  return (
    <TabView
      header={<></>}
      index={tabViewIndex}
      onIndexChange={onIndexChange}
      tabs={tabsContent}
      tabsRightElementWeb={
        !isLowerThanDesktop && (
          <Button
            onPress={navigateToRealEstate}
            size="m"
            testID="realEstateLink--press"
            variant="secondary"
            wrapperStyle={styles.button}
          >
            {t('project.realEstateLink')}
          </Button>
        )
      }
    />
  );
};
