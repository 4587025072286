import { TFunction } from 'react-i18next';
import { startCase, capitalize } from 'lodash';
import { DeepPartial } from 'react-hook-form';

import { PropertyKind, RealEstate } from '@app/libs/apollo/introspection';
import { useTranslation } from '@app/services/translations/translations';

export const formatType = (t: TFunction, type: PropertyKind): string =>
  t(`propertyDescription.${type}`);

export const formatSurface = (t: TFunction, surface: number): string =>
  surface ? `${surface} ${t('shared.m2')}` : '';

export const formatLots = (t: TFunction, type: PropertyKind, lots: number): string =>
  startCase(type.toLowerCase()) === PropertyKind.Building && lots
    ? `${lots} ${t('shared.lot', { count: lots })}`
    : '';

export const formatBedrooms = (t: TFunction, type: PropertyKind, rooms: number): string => {
  if (startCase(type.toLowerCase()) === PropertyKind.Building) {
    return '';
  }

  const bedrooms = rooms - 1;

  return bedrooms > 0
    ? t('propertyPreview.room', { count: bedrooms })
    : t('propertyPreview.roomZero');
};

export interface RealEstateChips {
  city: string;
  surface: string;
  lots: string;
  bedrooms: string;
  type: string;
}

export const useRealEstateChips = ({
  building: { totalLots, address },
  surface,
  type,
  roomCount,
}: DeepPartial<RealEstate>): RealEstateChips => {
  const { t } = useTranslation();

  return {
    bedrooms: formatBedrooms(t, type, roomCount),
    city: capitalize(address?.city),
    lots: formatLots(t, type, totalLots),
    surface: formatSurface(t, surface),
    type: formatType(t, type),
  };
};
