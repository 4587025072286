import React, { useEffect, useMemo } from 'react';

import { Platform, View } from 'react-native';
import { RouteProp, useRoute } from '@react-navigation/native';
import { SafeAreaView } from 'react-native-safe-area-context';

import { LoaderMasteos, useResponsive, useTheme } from '@masteos/aphrodite';

import { Project } from '@app/features/project/Project';
import { RealEstate, useGetProjectQuery } from '@app/libs/apollo/introspection';
import { PlatformEnum } from '@app/types/platform';
import { trackChangePage } from '@app/services/tracking/pageTracking';
import { trackChangeScreen } from '@app/services/tracking/screenTracking';
import { ProjectNavigatorRoutes, ProjectsStackParamList } from '@app/navigation/types/routes';
import { getProjectViewStyles } from '@app/views/project/ProjectView.styles';

type ProjectViewProps = RouteProp<ProjectsStackParamList, ProjectNavigatorRoutes.Project>;

export const ProjectView: React.FC = () => {
  const { params } = useRoute<ProjectViewProps>();
  const { data, loading } = useGetProjectQuery({
    fetchPolicy: 'cache-and-network',
    variables: { id: params.id },
  });
  const theme = useTheme();
  const { isLowerThanLaptop } = useResponsive();
  const styles = useMemo(
    () => getProjectViewStyles(isLowerThanLaptop, theme),
    [isLowerThanLaptop, theme]
  );

  useEffect(() => {
    if (!data?.project) {
      return;
    }

    const trackProps = {
      project: data.project,
      property: data.project.realEstate as RealEstate,
    };

    if (Platform.OS === PlatformEnum.Web) {
      trackChangePage(ProjectNavigatorRoutes.Project, trackProps);
    } else {
      trackChangeScreen(ProjectNavigatorRoutes.Project, trackProps);
    }
    // Send tracking only when we arrived at the page
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.project?.id]);

  if (loading) {
    return (
      <View style={styles.loadingWrapper}>
        <LoaderMasteos variant="inLight" />
      </View>
    );
  }

  return (
    <SafeAreaView style={styles.container} edges={['top']}>
      <Project project={data.project} />
    </SafeAreaView>
  );
};

// eslint-disable-next-line import/no-default-export
export default ProjectView;
