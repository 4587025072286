import { get } from 'lodash';

import { Customer, GetCustomerQuery } from '@app/libs/apollo/introspection';

export const getSortedCustomerPref = (
  customer: GetCustomerQuery['me']
): Customer['preferences'] => {
  if (!customer?.preferences) {
    return undefined;
  }

  return {
    ...customer?.preferences,
    regions: [...(get(customer, 'preferences.regions') ?? [])].sort(),
  };
};
