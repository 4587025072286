import { StyleSheet } from 'react-native';

import { Theme } from '@masteos/aphrodite';

export const getStyleSheet = ({
  isGreaterThanTablet,
  isLowerThanDesktop,
  isMobile,
  theme,
}: {
  isGreaterThanTablet: boolean;
  isLowerThanDesktop: boolean;
  isMobile: boolean;
  theme: Theme;
}) =>
  StyleSheet.create({
    headerContainer: { alignItems: 'center', width: '100%' },
    headerWrapper: {
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
    rightElement: {
      alignItems: 'flex-end',
      justifyContent: 'center',
      width: '60%',
    },
    tabsBorder: {
      borderBottomColor: theme.palette.neutral[200],
      borderBottomWidth: 1,
      flexDirection: 'row',
      width: '100%',
    },
    tabsContainer: { width: isLowerThanDesktop ? '100%' : '40%' },
    tabsWrapper: {
      alignItems: 'center',
      backgroundColor: theme.palette.base.white,
      flexDirection: 'row',
      justifyContent: 'space-between',
      maxWidth: 1120,
      paddingHorizontal: isMobile
        ? theme.spacing.SIZE_00
        : isGreaterThanTablet
        ? theme.spacing.SIZE_09
        : theme.spacing.SIZE_15,
      position: 'sticky' as 'relative',
      top: 0,
      width: '100%',
      zIndex: 10,
    },
  });
