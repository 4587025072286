import { User, Appointment, Transaction, Document, InvestBudget } from '@masteos/agora';

import type { Nullable } from './base';

export interface FrontAdvisor {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string;
  avatarS3ID?: string;
  calendlyName?: string;
}

export interface ExtraData {
  advisor?: FrontAdvisor;
  appointments: Appointment[];
  documents: Document[];
  isSubscribeNewsletter?: boolean;
  lang?: string;
  transactions: Transaction[];
  transactionsCount: number;
}

export interface UpdatePasswordUserProps {
  currentPassword: string;
  password: string;
}

export type FrontUser = User & ExtraData;

export interface OnboardingUser {
  advisor: User;
  email: string;
  firstName: string;
  hasBorrowingCapacity: boolean;
  investBudget: InvestBudget;
  lastName: string;
  password: string;
}

export type PropertyRoomCount = 'One' | 'Two' | 'Three' | 'Four' | 'Five' | 'SixOrMore';

export type PropertyKind = 'Apartment' | 'Building' | 'House';

export enum PropertyKindEnum {
  APARTMENT = 'Apartment',
  BUILDING = 'Building',
  HOUSE = 'House',
}

export interface RealEstatesPreferences {
  budgetMax: number;
  budgetMin: number;
  sharedProperty: Nullable<boolean>;
  propertyOnTheGroundFloor: Nullable<boolean>;
  propertyRoomCount: PropertyRoomCount[];
  regions?: string[];
  hasNeedRenovation: Nullable<boolean>[];
  strategy?: string[];
  propertyKind: PropertyKind[];
  propertyRented: Nullable<boolean>;
}

export enum MandateStatus {
  Expired = 'EXPIRED',
  Draft = 'DRAFT',
  Pending = 'PENDING',
  Active = 'ACTIVE',
  Associated = 'ASSOCIATED',
}
