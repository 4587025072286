import React, { useMemo } from 'react';

import { Platform, ScrollView, View } from 'react-native';
import { Trans } from 'react-i18next';

import {
  Button,
  Icon,
  IconName,
  RoundedBox,
  Spacer,
  Spacing,
  useResponsive,
  useTheme,
  Text,
} from '@masteos/aphrodite';

import { useTranslation } from '@app/services/translations/translations';
import { FlexGrid } from '@app/shared/components/Grid';
import { getStyles } from '@app/shared/components/Modals/in-app-sign-in/overview/overviewModal.styles';
import { OverviewModalProps } from '@app/shared/components/Modals/in-app-sign-in/InAppSignInModal.types';
import { PlatformEnum } from '@app/types/platform';

import { blocks, COLUMN_NUMBER_DESKTOP, COLUMN_NUMBER_MOBILE } from '../InAppSignInModal.constants';

/**
 * @description Step 1/3 of Sign in process
 */
export const OverviewModal: React.FC<OverviewModalProps> = ({
  advisorFullName,
  handleNextStep,
}) => {
  const theme = useTheme();
  const { isLowerThanLaptop } = useResponsive();
  const { t } = useTranslation();
  const isWeb = Platform.OS === PlatformEnum.Web;

  const styles = useMemo(() => getStyles({ theme }), [theme]);
  const Blocks = useMemo(
    () =>
      blocks.map(block => (
        <FlexGrid.Cell key={block.icon}>
          <View style={styles.blocksItem}>
            <RoundedBox style={styles.iconWrapper}>
              <Icon
                size={theme.spacing.SIZE_05}
                name={block.icon as IconName}
                color={theme.palette.tint.turquoise['500']}
              />
            </RoundedBox>
            <View style={styles.blocksItemText}>
              <Text textStyle="Headline3Medium">{t(block.title)}</Text>
              <Spacer height={Spacing.SIZE_03} />
              <Text textStyle="Body2">{t(block.text)}</Text>
            </View>
          </View>
        </FlexGrid.Cell>
      )),
    [t, styles, theme]
  );

  return (
    <>
      <ScrollView
        style={styles.content}
        testID="overview-modal"
        showsVerticalScrollIndicator={false}
      >
        <Spacer height={theme.spacing.SIZE_04} />
        <Text textStyle="Body2">
          <Trans
            i18nKey="modalMandateSignIn.text"
            values={{ advisor: advisorFullName }}
            components={{ strong: <Text textStyle="Body2Medium" /> }}
          />
        </Text>
        <Spacer height={theme.spacing.SIZE_06} />
        <FlexGrid
          col={isLowerThanLaptop ? COLUMN_NUMBER_MOBILE : COLUMN_NUMBER_DESKTOP}
          gap={theme.spacing.SIZE_06}
        >
          {Blocks}
        </FlexGrid>
        <Spacer height={theme.spacing.SIZE_07} />
      </ScrollView>
      <Button onPress={handleNextStep}>{t('modalMandateSignIn.button')}</Button>
      {!isWeb && <Spacer height={theme.spacing.SIZE_04} />}
    </>
  );
};
