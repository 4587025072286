import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Platform, ScrollView, View } from 'react-native';
import { isEqual } from 'lodash';
import { useNavigation } from '@react-navigation/native';
import { SafeAreaView } from 'react-native-safe-area-context';

import { Button, Spacer, useTheme } from '@masteos/aphrodite';

import { useTranslation } from '@app/services/translations/translations';
import {
  CustomerNotificationInput,
  CustomerNotificationPreferencesInput,
} from '@app/libs/apollo/introspection';
import { useMe } from '@app/shared/hooks/useMe';
// eslint-disable-next-line max-len
import { ProfileNotificationPreference } from '@app/features/profile/compounds/profileNotificationPreferences/ProfileNotificationPreference';
// eslint-disable-next-line max-len
import { NotificationPreferencesCategory } from '@app/features/profile/compounds/profileNotificationPreferences/constants';
import { PlatformEnum } from '@app/types/platform';
import { omitTypenameDeep } from '@app/libs/apollo/utils';
import { saveButtonStyle } from '@app/features/profile/profile.styles.web';
// eslint-disable-next-line max-len
import { useUpdateCustomerNotificationSettings } from '@app/features/profile/hooks/useUpdateCustomerNotificationSettings.hook';
import { NavigationHeader } from '@app/features/navigation/NavigationHeader';
import { ProfileNotificationCalloutNavigationProp } from '@app/features/profile/profile.types';
import { useFeatureFlags } from '@app/shared/hooks/useFeatureFlag';
import { categoriesToCheckboxTileGroups } from '@app/features/profile/utils/formatNotificationPreferences.utils';

import { ProfileNotificationCallout } from './ProfileNotificationCallout';
import { getStyles } from './profileNotificationPreferences.styles';

export const ProfileNotificationPreferences: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    customer: { notificationPreferences, isNotificationEnabled, hasValidSearchMandate },
  } = useMe();
  const navigation = useNavigation<ProfileNotificationCalloutNavigationProp>();
  const [preferencesPrevValue, setPreferencesPrevValue] = useState(null);
  const [customerNotifPreferences, setCustomerNotifPreferences] = useState(
    omitTypenameDeep<CustomerNotificationPreferencesInput>(notificationPreferences)
  );
  const [isLoading, setIsLoading] = useState(false);
  const updateNotificationPreferences = useUpdateCustomerNotificationSettings(setIsLoading);
  const { isEnabled } = useFeatureFlags();

  const isWeb = Platform.OS === PlatformEnum.Web;

  const styles = useMemo(() => getStyles(theme), [theme]);

  const notificationPreferenceOnChange = useCallback(
    (category: string, preferences: CustomerNotificationInput) => {
      setCustomerNotifPreferences({ ...customerNotifPreferences, [category]: preferences });
    },
    [setCustomerNotifPreferences, customerNotifPreferences]
  );

  useEffect(() => {
    const shouldUpdate =
      !isWeb &&
      preferencesPrevValue !== null &&
      !isEqual(preferencesPrevValue, customerNotifPreferences);

    if (shouldUpdate) {
      updateNotificationPreferences(customerNotifPreferences, isNotificationEnabled);
    }

    setPreferencesPrevValue(customerNotifPreferences);
  }, [
    customerNotifPreferences,
    isWeb,
    updateNotificationPreferences,
    preferencesPrevValue,
    isNotificationEnabled,
  ]);

  const getCheckboxTileGroupList = useMemo(() => {
    return categoriesToCheckboxTileGroups({
      categories: Object.values(NotificationPreferencesCategory),
      customerNotifPreferences,
      metadata: { hasValidSearchMandate },
      notificationPreferenceOnChange,
    });
  }, [notificationPreferenceOnChange, customerNotifPreferences, hasValidSearchMandate, isEnabled]);

  const onSave = useCallback(
    () => updateNotificationPreferences(customerNotifPreferences, isNotificationEnabled),
    [updateNotificationPreferences, customerNotifPreferences, isNotificationEnabled]
  );

  return (
    <SafeAreaView style={styles.safeAreaContainer} edges={['top']}>
      <ScrollView>
        {!isWeb && (
          <>
            <NavigationHeader
              onBack={navigation.goBack}
              title={t('profile.notificationPreferences.title')}
            />
            <ProfileNotificationCallout />
            <Spacer height={theme.spacing.SIZE_07} />
          </>
        )}

        <View style={styles.preferencesContainer} testID="profile-notification-preferences">
          {getCheckboxTileGroupList.map(item => (
            <ProfileNotificationPreference
              key={item.category}
              testID={`notification-group-${item.category}`}
              {...item}
            />
          ))}
        </View>
        {!!isWeb && (
          <>
            <Spacer height={theme.spacing.SIZE_05} />
            <Button
              isLoading={isLoading}
              onPress={onSave}
              wrapperStyle={saveButtonStyle}
              testID="notif-preferences-save"
            >
              {t('shared.save')}
            </Button>
          </>
        )}
      </ScrollView>
    </SafeAreaView>
  );
};
