import { StyleSheet, ViewStyle } from 'react-native';

export const getStyle = (): { container: ViewStyle } => {
  return StyleSheet.create({
    container: {
      padding: 20,
      width: '100%',
    },
  });
};
