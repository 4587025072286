import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Spacing, MediaQuery } from '@masteos/aphrodite';

interface DataBlockWrapperProps {
  $columns?: 2 | 3;
}

export const DataBlockWrapper = styled.div<DataBlockWrapperProps>`
  width: 100%;
  display: grid;
  grid-gap: 12px;
  grid-template-columns: repeat(2, 1fr);
  padding-bottom: ${Spacing.SIZE_06}px;

  @media ${MediaQuery.laptop}, ${MediaQuery.desktop}, ${MediaQuery.wide}, ${MediaQuery.ultraWide} {
    grid-gap: 20px;
    padding-bottom: ${Spacing.SIZE_08}px;
  }

  ${({ $columns }) =>
    $columns === 3 &&
    css`
      @media ${MediaQuery.laptop},
        ${MediaQuery.desktop},
        ${MediaQuery.wide},
        ${MediaQuery.ultraWide} {
        grid-template-columns: repeat(3, 1fr);
      }
    `}
`;
