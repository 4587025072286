import {
  FinancialModelComputationProps,
  financialModelEngine as getFinancialModel,
} from '@masteos/agora';

import { realEstateToFinancialModelCompProps } from '@app/shared/hooks/useFinancialModel';
import { useMe } from '@app/shared/hooks/useMe';
import { trackPropertyInterestActionEffect } from '@app/features/propertyActions/property-interest-action.effect';
import * as ErrorMonitoring from '@app/services/errorMonitoring';
import { useQueryFinancialModelTemplates } from '@app/shared/hooks/useQueryFinancialModelTemplates';
import { RealEstate } from '@app/libs/apollo/introspection';
import { TrackingOrigin } from '@app/services/tracking/trackTracking';

export interface UsePropertyLikeReturn {
  onLike: (isFavorite: boolean) => void;
  onError: (error: any) => void;
}

export const usePropertyLike = (
  realEstate: RealEstate,
  currentTab?: TrackingOrigin
): UsePropertyLikeReturn => {
  const { customer } = useMe();
  const { templates } = useQueryFinancialModelTemplates();

  const onLike = (isFavorite: boolean) => {
    const financialModel = getFinancialModel({
      ...realEstateToFinancialModelCompProps(realEstate),
      templates,
    } as FinancialModelComputationProps);

    trackPropertyInterestActionEffect({
      action: isFavorite ? 'UNBOOKMARK' : 'BOOKMARK',
      computedFM: financialModel,
      currentTab,
      property: realEstate,
    });
  };

  const onError = (error: string | Error) => {
    ErrorMonitoring.logError(error, { customerId: customer.id, realEstate });
  };

  return {
    onError,
    onLike,
  };
};
