import { Platform, StyleSheet } from 'react-native';

import { PlatformEnum } from '@app/types/platform';

export const getStyles = theme =>
  StyleSheet.create({
    forgotPasswordLink: {
      textDecorationLine: 'underline',
    },
    formView: {
      width: '100%',
    },
    root: {
      alignItems: 'center',
      flex: 1,
      justifyContent: 'center',
      marginHorizontal: 'auto',
      maxWidth: Platform.OS === PlatformEnum.Web ? 335 : '100%',
      paddingHorizontal: theme.spacing.SIZE_06,
      paddingVertical: theme.spacing.SIZE_08,
      width: '100%',
    },
  });
