import React from 'react';

import { StyleSheet, View } from 'react-native';
import { upperFirst, isNil } from 'lodash';

import { palette, Spacing } from '@masteos/aphrodite';
import { FinancialModel } from '@masteos/agora';

import { useTranslation } from '@app/services/translations/translations';
import { currencyFormatter } from '@app/utils/currency-formatter';
import { propertyFloorToString } from '@app/utils/property';
import { LocationType, PropertyKind, RealEstate } from '@app/libs/apollo/introspection';

import { PropertyAttribute } from './PropertyAttribute';

interface PropertyAttributesListProps {
  property: RealEstate;
  financialModel: FinancialModel;
}
const styles = StyleSheet.create({
  container: {
    borderColor: palette.neutral[300],
    borderRadius: 4,
    borderWidth: 1,
    paddingBottom: Spacing.SIZE_04,
    paddingHorizontal: Spacing.SIZE_06,
    paddingTop: Spacing.SIZE_03,
    width: '100%',
  },
});

export const PropertyAttributesList: React.FC<PropertyAttributesListProps> = ({
  property,
  financialModel,
}) => {
  const { t } = useTranslation();

  if (!property || !financialModel) {
    return null;
  }

  const isCondominium = property.building.condominium ? t('shared.yes') : t('shared.no');
  const { type, surface } = property;
  const netSeller = currencyFormatter(financialModel.salePrice, 0);
  const pricePerMeter = currencyFormatter(financialModel.pricePerMeter, 0);
  const renovation = currencyFormatter(property.project.renovation.amount, 0);
  const furnituresAmount = currencyFormatter(property.project.furnishing.amount, 0);
  const annualRent = currencyFormatter(financialModel.annualTotalIncome, 0);
  const isColocation = property.project.renting.type === LocationType.Colocation;
  const shouldShowFloor =
    !isNil(property.floor) && ['OFFICE', PropertyKind.Apartment].includes(property.type);
  const floor = propertyFloorToString(property.floor, t('propertyDescription.groundFloor'));
  const propertyAttributes = [
    { label: t('propertyDescription.type'), value: t<string>(`propertyDescription.${type}`) },
    {
      label: t('shared.colocation'),
      value: upperFirst(t(`shared.${isColocation ? 'yes' : 'no'}`)),
    },
    { label: t('propertyDescription.coOwnership'), value: upperFirst(isCondominium) },
    shouldShowFloor && { label: t('propertyDescription.floor'), value: floor },
    { label: t('propertyDescription.surface'), value: `${surface} ${t('shared.m2')}` },
    { label: t('propertyDescription.sellingPrice'), value: netSeller },
    { label: t('propertyDescription.pricePerMeter'), value: pricePerMeter },
    { label: t('propertyDescription.renovation'), value: renovation },
    { label: t('propertyDescription.furniture'), value: furnituresAmount },
    { label: t('propertyDescription.annualRent'), value: annualRent },
  ].filter(Boolean);

  return (
    <View style={styles.container}>
      {propertyAttributes.map(({ label, value }, index) => (
        <PropertyAttribute key={index} label={label} value={value} isOdd={index % 2 !== 0} />
      ))}
    </View>
  );
};
