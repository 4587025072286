import { ApolloClient, from, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { apolloDevToolsInit } from 'react-native-apollo-devtools-client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { Platform } from 'react-native';
import { createUploadLink } from 'apollo-upload-client';

import { storage } from '@app/services/storage/storage';
import { config } from '@app/config';
import * as ErrorMonitoring from '@app/services/errorMonitoring';
import { getLastVersion } from '@app/utils/getLastWebVersion';

import { apolloInMemoryCache } from './utils/apolloInMemoryCache.utils';

const cache = new InMemoryCache(apolloInMemoryCache);

export const authLink = setContext(async (_, { headers }) => {
  let token = await storage.readString('token');
  if (!token) {
    token = await storage.readString('feathers-jwt');
    !!token && (await storage.saveString('token', token));
  }
  return {
    headers: {
      ...headers,
      'App-Version': getLastVersion(),
      Platform: Platform.OS,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

export const getApolloClient = (): ApolloClient<NormalizedCacheObject> => {
  /*   persistCache({
    cache,
    storage: new AsyncStorageWrapper(AsyncStorage),
  }); */

  const client = new ApolloClient({ cache });

  const uploadLink = createUploadLink({ uri: `${config.APOLLON_API_URL}/graphql` });

  const errorLink = onError(({ graphQLErrors, operation, networkError }) => {
    if (graphQLErrors) {
      ErrorMonitoring.logError(
        `[ApolloClientContext] Query "${operation.operationName}" failed to resolve`,
        { graphQLErrors }
      );
    }

    if (networkError) {
      ErrorMonitoring.logMessage('[ApolloClientContext] network error encountered', {
        networkError,
      });
    }
  });

  const links = from([authLink, errorLink, uploadLink]);
  client.setLink(links);

  if (config.isDev) {
    apolloDevToolsInit(client);
  }

  return client;
};
