import React, { useCallback, useMemo } from 'react';

import { View, ViewStyle } from 'react-native';

import { Spacer, Spacing, useResponsive, useTheme } from '@masteos/aphrodite';

import { getStyles } from '@app/features/propertyInfo/propertyInfo.styles.web';
import { RealEstate } from '@app/libs/apollo/introspection';

import { PropertyDescriptionMapPreview } from './PropertyDescriptionMapPreview';
import { GeoMap } from './GeoMap.web';

interface PropertyDescriptionMapProps {
  property: RealEstate;
  openMapModal: () => void;
}

export const PropertyDescriptionMap: React.FC<PropertyDescriptionMapProps> = ({
  property,
  openMapModal,
}) => {
  const theme = useTheme();
  const { isMobile, isGreaterThanTablet } = useResponsive();
  const styles = useMemo(() => getStyles({ theme }), [theme]);

  const defaultValue = {
    center: {
      lat: property?.building.address?.lat,
      lng: property?.building.address?.lng,
    },
    zoom: 14,
  };

  const Wrapper = useCallback(
    ({ children, style }: { children: React.ReactNode; style?: ViewStyle }) => (
      <View style={[styles.contentWrapper, style]}>
        {children}
        <Spacer height={isGreaterThanTablet ? Spacing.SIZE_11 : Spacing.SIZE_09} />
      </View>
    ),
    [isGreaterThanTablet]
  );

  return isMobile ? (
    <Wrapper>
      <PropertyDescriptionMapPreview
        onPress={openMapModal}
        coord={{ lat: property.building.address?.lat, lng: property.building.address?.lng }}
      />
    </Wrapper>
  ) : (
    <Wrapper style={styles.webWrapper}>
      <GeoMap coord={defaultValue.center} />
    </Wrapper>
  );
};
