export enum I18nSupportedLanguage {
  fr = 'fr',
  frBE = 'fr-BE',
  es = 'es',
  nl = 'nl',
  nlBE = 'nl-BE',
  en = 'en',
}

export const I18nDefaultLanguage = I18nSupportedLanguage.en;
