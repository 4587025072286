import { QueryResult } from '@apollo/client';

import { GetMyProjectsQuery, useGetMyProjectsQuery } from '@app/libs/apollo/introspection';

export const useMyProjectsList = (): Partial<QueryResult<GetMyProjectsQuery>> => {
  const { data, loading } = useGetMyProjectsQuery({
    fetchPolicy: 'no-cache',
  });
  return { data, loading };
};
