import React, { useMemo } from 'react';

import { Platform, View } from 'react-native';

import {
  ActionButton,
  Button,
  Card,
  Chip,
  ChipList,
  Spacer,
  Text,
  useResponsive,
  useTheme,
} from '@masteos/aphrodite';

import { fromS3ID } from '@app/utils/from-s3id';
import { useTranslation } from '@app/services/translations/translations';
import { currencyFormatter } from '@app/utils/currency-formatter';
import { responsiveHeaderStyles as styles } from '@app/features/project/ProjectHeader/ProjectHeader.styles';
import { AdvisorSelector } from '@app/shared/components/advisor-selector/AdvisorSelector';
import { HORIZONTAL_PADDING } from '@app/features/project/Project.styles';
import { AdvisorSourcePage } from '@app/services/tracking/constants';

import { chipsProps, ProjectHeaderProps } from './ProjectHeader.const';

export const ProjectResponsiveHeader: React.FC<ProjectHeaderProps> = ({
  address,
  chips,
  imageCover,
  onBack,
  onButtonPress,
  projectAmount,
  status,
  title,
}) => {
  const { t } = useTranslation();
  const { isMobile, isLowerThanTablet } = useResponsive();
  const theme = useTheme();
  const formattedProjectAmount = useMemo(
    () => currencyFormatter(projectAmount, 0),
    [projectAmount]
  );

  const chipList = [
    { text: chips.type },
    { text: chips.bedrooms },
    { text: chips.lots },
    { text: chips.surface },
  ];

  const buttonWrapperStyle = Platform.select({
    native: { width: '100%' },
    web: { width: isMobile ? '100%' : `calc(100% - ${HORIZONTAL_PADDING.DESKTOP}px)` },
  });

  return (
    <View testID="project-responsive-header">
      <View style={styles.image}>
        <Card.Image imageUri={fromS3ID(imageCover, 'w-500')} />
      </View>
      <Spacer height={theme.spacing.SIZE_07} />

      <View style={styles.status}>
        <ActionButton
          rightIconName="ArrowLeft"
          onPress={onBack}
          size="s"
          testID="on-back-btn"
          withSolidBorder={!isMobile}
        />
        <AdvisorSelector source={AdvisorSourcePage.PROJECT_PAGE} />
      </View>

      <View style={styles.content}>
        <View>
          <Chip
            text={t(`project.transactionStatus.${status.toLowerCase()}`)}
            size="medium"
            {...chipsProps[status]}
          />
        </View>
        <Spacer height={theme.spacing.SIZE_07} />
        <View style={styles.heading}>
          <Text textStyle="Headline1Medium">{title}</Text>
          <Spacer height={theme.spacing.SIZE_04} />
          <Text textStyle="Body3">{address}</Text>
        </View>

        <View style={styles.separator}>
          <Spacer height={2} separator />
          <Spacer height={theme.spacing.SIZE_06} />
        </View>

        <View style={styles.realEstateOverview}>
          <ChipList chips={chipList} />
          <Text textStyle="Body2Medium">{formattedProjectAmount}</Text>
        </View>

        {/* Style isn't applied if used from styles variable */}
        <Button
          onPress={onButtonPress}
          testID="realEstateLink--press"
          variant="secondary"
          wrapperStyle={buttonWrapperStyle}
        >
          {t('project.realEstateLink')}
        </Button>
      </View>

      {!isLowerThanTablet && <Spacer height={theme.spacing.SIZE_03} />}
    </View>
  );
};
