import { ListItemUnionProps } from '@masteos/aphrodite';

import { SearchEngineSortStrategy } from './SearchEngineSort.types';

export {
  FILTER_NAME_TRACKING_KEY,
  getSingleValue,
} from '../search-engine-filters-horizontal/SearchEngineFiltersHorizontal.utils';

export const getSortStrategyList = (t: (arg: string) => string): ListItemUnionProps[] => {
  const list = Object.values(SearchEngineSortStrategy).map(
    v =>
      ({
        key: v,
        label: t(`searchEngine.sort.strategy.${v}`),
      } as ListItemUnionProps)
  );

  return list;
};
