import { I18nSupportedLanguage } from '@app/libs/i18n/i18n.types';

const MASTEOS_URLS = {
  [I18nSupportedLanguage.es]: 'https://www.masteos.es/',
  [I18nSupportedLanguage.fr]: 'https://www.masteos.fr/',
  [I18nSupportedLanguage.en]: 'https://en.masteos.es/',
};

export const getWebsiteUrlByLang = (userLang: string): string => {
  return MASTEOS_URLS[userLang] || MASTEOS_URLS[I18nSupportedLanguage.fr];
};
