import React from 'react';

import { createStackNavigator } from '@react-navigation/stack';

import { SuggestionsStackParamList, SuggestionNavigatorRoutes } from '@app/navigation/types/routes';
import { SCREEN_OPTIONS } from '@app/navigation/constants/navigation-options';
import { RealEstateView } from '@app/views/realEstate/RealEstateView';
import { Suggestions } from '@app/features/suggestions/Suggestions';

const Stack = createStackNavigator<SuggestionsStackParamList>();

export const SuggestionsNavigator: React.FC = () => (
  <Stack.Navigator
    initialRouteName={SuggestionNavigatorRoutes.SuggestionList}
    screenOptions={SCREEN_OPTIONS}
  >
    <Stack.Screen name={SuggestionNavigatorRoutes.SuggestionList} component={Suggestions} />
    <Stack.Screen name={SuggestionNavigatorRoutes.RealEstate} component={RealEstateView} />
  </Stack.Navigator>
);
