import React, { ReactNode } from 'react';

import { View, SafeAreaView, Platform } from 'react-native';

import { Text, useResponsive } from '@masteos/aphrodite';

import { useTranslation } from '@app/services/translations/translations';
import { PlatformEnum } from '@app/types/platform';

import { getStyles } from './layoutSideText.styles';

interface LayoutSideTextProps {
  translateKey?: string;
  listLength?: number;
  children?: ReactNode;
  content?: JSX.Element;
}

export const LayoutSideText: React.FC<LayoutSideTextProps> = ({
  translateKey = 'register',
  listLength = 4,
  children,
  content,
}) => {
  const { t } = useTranslation();
  const { isMobile, isGreaterThanTablet, isLowerThanDesktop } = useResponsive();

  const sideTextNumberFont = isMobile ? 'Headline2Medium' : 'Title3Medium';
  const styles = getStyles({ isGreaterThanTablet, isLowerThanDesktop, translateKey });

  return (
    <SafeAreaView style={styles.safeAreaWrapper}>
      <View style={styles.landingWrapper}>
        {Platform.OS === PlatformEnum.Web && isGreaterThanTablet ? (
          <View style={styles.sideWrapper}>
            <View style={styles.sideContent}>
              <View style={styles.sideTextContainer}>
                <Text textStyle="Title2Medium" style={styles.header}>
                  {t(`${translateKey}.header`)}
                </Text>
                <View style={styles.sideText}>
                  {content
                    ? content
                    : Array.from(Array(listLength).keys()).map(numb => (
                        <View key={numb} style={styles.rowContainer}>
                          <Text textStyle={sideTextNumberFont} style={styles.sideTextNumber}>
                            {numb + 1}
                          </Text>
                          <Text textStyle="Body2" style={styles.sideTextListItem}>
                            {t(`${translateKey}.list.${numb + 1}`)}
                          </Text>
                        </View>
                      ))}
                </View>
              </View>
            </View>
          </View>
        ) : null}

        <View style={styles.mainWrapper}>{children}</View>
      </View>
    </SafeAreaView>
  );
};
