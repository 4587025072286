import React, { useCallback, useMemo } from 'react';

import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { View } from 'react-native';
import { useReactiveVar } from '@apollo/client';

import { LoaderDefault } from '@masteos/aphrodite';

import { useMe } from '@app/shared/hooks/useMe';
import { fromS3ID } from '@app/utils/from-s3id';
import { MainMenuNavigatorRoutes, SuggestionNavigatorRoutes } from '@app/navigation/types/routes';
import { useGetCustomerSuggestionsQuery } from '@app/libs/apollo/introspection';
import { RealEstateItem } from '@app/shared/components/real-estate-card/realEstateCard.type';
import { useBookmark } from '@app/shared/hooks/useBookmark';
import { useTranslation } from '@app/services/translations/translations';
import { useToastContext } from '@app/shared/contexts/toast/Toast';
import { useNotificationsStatus } from '@app/shared/hooks/useNotificationsStatus.hook';
import {
  PropertyPage,
  TrackingOrigin,
  trackSuggestionsOpenSearchPage,
} from '@app/services/tracking/trackTracking';

import { SuggestionsTemplate } from './Suggestions.template';
import { SuggestionsNavigationProps } from './Suggestions.types';
import { getLoaderStyle } from './Suggestions.styles';
import { usePositioningActions } from './hooks/usePositioningActions';
import { formatSuggestions, suggestionsCallLaunched } from './Suggestions.utils';

export const Suggestions: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const { customer, isAssetManagementConsultant, loading: customerLoading } = useMe();
  const navigation = useNavigation<SuggestionsNavigationProps>();
  const { data, loading, refetch } = useGetCustomerSuggestionsQuery();
  const { setValidMsg } = useToastContext();
  const launchedCall = useReactiveVar(suggestionsCallLaunched);

  useFocusEffect(
    useCallback(() => {
      refetch();
    }, [refetch])
  );

  const {
    handleApprovePositioning,
    handleCancelPositioning,
    handleNeedCallAfterConfirmPositioning,
  } = usePositioningActions();

  const { status: notificationStatus } = useNotificationsStatus();

  const callbackOnBookmark = () => {
    setValidMsg({
      icon: 'HeartFilled',
      title: t('searchEngine.toast.favorite.add'),
      withClose: true,
    });
  };

  const callbackUnBookmark = () => {
    setValidMsg({
      icon: 'Heart',
      title: t('searchEngine.toast.favorite.remove'),
      withClose: true,
    });
  };

  const { toggleBookmark } = useBookmark({
    callbackOnBookmark,
    callbackUnBookmark,
  });

  const advisor = useMemo(
    () => ({
      avatar: fromS3ID(customer?.advisor?.avatarS3ID, `w-300`),
      name: customer?.advisor?.firstName,
    }),
    [customer?.advisor?.avatarS3ID, customer?.advisor?.firstName]
  );

  const suggestions = useMemo(() => formatSuggestions(data, launchedCall), [data, launchedCall]);

  const handleItemClick = useCallback(
    (item: RealEstateItem): (() => void) =>
      () => {
        navigation.navigate(SuggestionNavigatorRoutes.RealEstate, {
          id: item.id,
        });
      },
    [navigation]
  );

  const handleItemBookmark = useCallback(
    (item: RealEstateItem): (() => void) =>
      () =>
        toggleBookmark(item, {
          currentTab: TrackingOrigin.PREVIEW,
          page: PropertyPage.SUGGESTIONS,
        }),
    [toggleBookmark]
  );

  const gotoSearch = useCallback(() => {
    trackSuggestionsOpenSearchPage();
    navigation.navigate(MainMenuNavigatorRoutes.RealEstates);
  }, [navigation]);

  if (customerLoading || loading) {
    return (
      <View testID="loader" style={getLoaderStyle()}>
        <LoaderDefault variant="inLight" />
      </View>
    );
  }

  if (!customer || !customer.advisor || isAssetManagementConsultant) {
    navigation.navigate(MainMenuNavigatorRoutes.RealEstates);
    return null;
  }

  return (
    <SuggestionsTemplate
      advisor={advisor}
      gotoSearch={gotoSearch}
      suggestions={suggestions}
      handleApprovePositioning={handleApprovePositioning}
      handleNeedCallAfterConfirmPositioning={handleNeedCallAfterConfirmPositioning}
      handleCancelPositioning={handleCancelPositioning}
      handleItemClick={handleItemClick}
      handleItemBookmark={handleItemBookmark}
      notificationStatus={notificationStatus}
    />
  );
});

Suggestions.displayName = 'Suggestions';
