import React, { useMemo } from 'react';

import { View } from 'react-native';

import { Spacer, Text, useResponsive, useTheme } from '@masteos/aphrodite';

import { useTranslation } from '@app/services/translations/translations';
import { getInvestmentWarningStyles } from '@app/features/investmentWarning/InvestmentWarning.styles';

export const InvestmentWarning: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { isMobile, isLowerThanLaptop } = useResponsive();
  const styles = useMemo(() => getInvestmentWarningStyles(theme), [theme]);

  return (
    <View style={styles.container}>
      <Text style={styles.textColor} textStyle={isLowerThanLaptop ? 'Caption1' : 'Body3'}>
        {t('investmentWarning.warningMessage')}
      </Text>
      <Spacer height={isMobile ? theme.spacing.SIZE_12 : theme.spacing.SIZE_08} />
    </View>
  );
};
