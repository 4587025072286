import { StyleSheet, TextStyle, ViewStyle } from 'react-native';

import { Theme } from '@masteos/aphrodite';

export const getStyles = (
  isLowerThanLaptop: boolean,
  theme: Theme
): Record<string, ViewStyle | TextStyle> => {
  return StyleSheet.create({
    advisorAndDescriptionWrapper: {
      display: 'flex',
      flexDirection: 'row',
      marginRight: isLowerThanLaptop ? theme.spacing.NONE : theme.spacing.SIZE_03,
      width: isLowerThanLaptop ? '100%' : '45%',
    },
    avatarWrapper: {
      marginRight: theme.spacing.SIZE_05,
      marginTop: theme.spacing.SIZE_03,
    },
    button3dWrapper: {
      backgroundColor: theme.palette.base.white,
      borderRadius: theme.spacing.SIZE_02,
      height: 25,
      justifyContent: 'center',
      opacity: 0.8,
      paddingLeft: theme.spacing.SIZE_03,
      paddingRight: theme.spacing.SIZE_03,
    },
    closeButtonWrapper: { margin: theme.spacing.SIZE_04 },
    contentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 876,
      width: isLowerThanLaptop ? '100%' : '95%',
    },
    descriptionWrapper: {
      maxHeight: isLowerThanLaptop ? 200 : 100,
      maxWidth: 301,
      overflow: 'scroll',
      textAlign: 'left',
    },
    grow: { flexGrow: 1 },
    headerWrapper: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: isLowerThanLaptop ? 'space-between' : 'flex-start',
      width: '100%',
    },
    imageStyle: {
      backgroundColor: theme.palette.neutral[900],
      maxHeight: '90vh',
      width: '100%',
    },
    imagesCount: {
      backgroundColor: theme.palette.system.secondary[400],
      borderRadius: theme.spacing.SIZE_02,
      color: theme.palette.base.white,
      display: 'flex',
      justifyContent: 'center',
      marginRight: isLowerThanLaptop ? 0 : theme.spacing.SIZE_02,
      minWidth: 59,
      opacity: 0.8,
      paddingHorizontal: theme.spacing.SIZE_03,
      textAlign: 'center',
    },
    modalWrapper: {
      alignItems: 'center',
      backgroundColor: theme.palette.base.black,
      height: '100vh',
    },
    swiperButtonWrapper: {
      alignSelf: 'center',
      paddingHorizontal: theme.spacing.SIZE_03,
    },
    swiperTitleStyle: {
      color: theme.palette.base.white,
      textAlign: isLowerThanLaptop ? 'center' : 'right',
    },
    swiperWrapper: {
      display: 'flex',
      flexDirection: 'row',
    },
    tag3dAndCountWrapper: {
      display: 'flex',
      flexDirection: 'row',
    },
    tagWrapper: {
      display: isLowerThanLaptop ? 'flex' : 'none',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginBottom: theme.spacing.SIZE_04,
    },
    textStyle: {
      color: theme.palette.base.white,
    },

    thumbsStyle: {
      height: 100,
      width: 122,
    },
    thumbsSwiperAndAdvisorDescriptionWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      paddingHorizontal: isLowerThanLaptop ? theme.spacing.SIZE_03 : theme.spacing.SIZE_09,
      width: '100%',
    },
    thumbsSwiperWrapper: {
      display: isLowerThanLaptop ? 'none' : 'flex',
      flexDirection: 'row',
      width: '55%',
    },

    titleAndNumberImagesWrapper: {
      paddingHorizontal: isLowerThanLaptop ? theme.spacing.SIZE_06 : theme.spacing.SIZE_09,
    },
    topWrapper: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: isLowerThanLaptop ? theme.spacing.SIZE_14 : theme.spacing.SIZE_05,
      marginTop: isLowerThanLaptop ? theme.spacing.SIZE_05 : theme.spacing.SIZE_08,
      width: '100%',
    },
    visit3DandImageCountWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: theme.spacing.SIZE_07,
      paddingHorizontal: theme.spacing.NONE,
      width: '100%',
    },
  });
};
