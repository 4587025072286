import React from 'react';

import GoogleMapReact, { MapOptions, Maps } from 'google-map-react';

import { LocationMarkerIconWithZoom } from '@masteos/aphrodite';

import { config } from '@app/config';

interface GeoMapProps {
  coord: { lat: number; lng: number };
  options?: MapOptions | ((maps: Maps) => MapOptions);
}

const DEFAULT_ZOOM = 14;

export const GeoMap: React.FC<GeoMapProps> = ({ coord, options }) => {
  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: config.GMAPS_API_KEY }}
      defaultCenter={coord}
      defaultZoom={DEFAULT_ZOOM}
      yesIWantToUseGoogleMapApiInternals
      options={options}
    >
      <LocationMarkerIconWithZoom lat={coord.lat} lng={coord.lng} />
    </GoogleMapReact>
  );
};
