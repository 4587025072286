import { StyleSheet } from 'react-native';

export const getContainerPageStyle = ({
  maxWidth,
  isLowerThanTablet,
}: {
  maxWidth: number;
  isLowerThanTablet: boolean;
}) =>
  StyleSheet.create({
    container: {
      maxWidth: maxWidth,
      padding: isLowerThanTablet ? 20 : 40,
      width: '100%',
    },
  });
