import React, { useMemo } from 'react';

import { IconName, IconWithLabelSelector } from '@masteos/aphrodite';

import { useTranslation } from '@app/services/translations/translations';
import { extractAdvisorInfo } from '@app/utils/extractAdvisorInfo';
import { useMe } from '@app/shared/hooks/useMe';

import { AdvisorSelectorProps } from './AdvisorSelector.types';

export const AdvisorSelector: React.FC<AdvisorSelectorProps> = ({ source }) => {
  const { t, i18n } = useTranslation();
  const { isAssetManagementConsultant, customer } = useMe();

  const { onCallClick, onMailClick, onWhatsappClick } = extractAdvisorInfo({
    customer,
    i18n,
    source,
    t,
  });

  const options = useMemo(
    () => [
      {
        iconName: 'Phone' as IconName,
        key: 'phone',
        label: t('advisor.callSectionTitle'),
        onSelect: closeList => {
          onCallClick();
          closeList();
        },
      },
      {
        iconName: 'Whatsapp' as IconName,
        key: 'Whatsapp',
        label: t('advisor.whatsAppSectionTitle'),
        onSelect: closeList => {
          onWhatsappClick();
          closeList();
        },
      },
      {
        iconName: 'Mail' as IconName,
        key: 'mail',
        label: t('advisor.mailSectionTitle'),
        onSelect: closeList => {
          onMailClick();
          closeList();
        },
      },
    ],
    [onCallClick, onMailClick, onWhatsappClick, t]
  );

  if (!customer || isAssetManagementConsultant) {
    return null;
  }

  return (
    <IconWithLabelSelector
      iconName="Phone"
      options={options}
      label={t('advisor.contactMyAdvisor')}
    />
  );
};
