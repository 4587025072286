import { useCallback, useMemo } from 'react';

import * as yup from 'yup';
import { Control, useForm, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PN from 'awesome-phonenumber';
import { Platform } from 'react-native';
import { isValid as isIbanValid } from 'iban';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { useMe } from '@app/shared/hooks/useMe';
import { combinePhone, parsePhoneNumber } from '@app/utils/phone';
import { useTranslation } from '@app/services/translations/translations';
import { useToastContext } from '@app/shared/contexts/toast/Toast';
import * as ErrorMonitoring from '@app/services/errorMonitoring';
import { PlatformEnum } from '@app/types/platform';
import { CustomerAddress } from '@app/libs/apollo/introspection';
import { SEPA_COUNTRY_CODES } from '@app/constants/constants';
import { ProfileNavigatorRoutes, ProfileStackParamList } from '@app/navigation/types/routes';
import { fromS3ID } from '@app/utils/from-s3id';

export interface ProfileInformationForm {
  address: CustomerAddress;
  avatarS3ID: string;
  email: string;
  firstName: string;
  job: string;
  lastName: string;
  phonePrefix: string;
  phoneSuffix: string;
  phoneNumber: string;
  promoCode: string;
  iban: string;
  ibanHolder: string;
}

interface UseProfileInformationOutput {
  control: Control<ProfileInformationForm, unknown>;
  getValues: UseFormGetValues<ProfileInformationForm>;
  setValue: UseFormSetValue<ProfileInformationForm>;
  onSubmit: () => Promise<void>;
  isSaving: boolean;
  getIbanHint: (value: string) => string;
  isAssetManagementConsultant: boolean;
}

type UseProfileInformationNavigationProp = StackNavigationProp<
  ProfileStackParamList,
  ProfileNavigatorRoutes.ProfileInformation
>;

export const useProfileInformation = (): UseProfileInformationOutput => {
  const { t } = useTranslation();
  const navigation = useNavigation<UseProfileInformationNavigationProp>();
  const { customer, updateInformations, updateInformationsLoading, isAssetManagementConsultant } =
    useMe();
  const { setValidMsg, setErrorMsg } = useToastContext();
  const { phonePrefix: phonePrefixInit, phoneSuffix: phoneSuffixInit } = useMemo(
    () => parsePhoneNumber(customer.phoneNumber),
    [customer.phoneNumber]
  );

  const schemaRequiredOrNullable = useMemo(
    () => ({
      is: (v: string) => !v,
      otherwise: schema => schema.required(t('register.requiredField')),
      then: schema => schema.nullable(),
    }),
    [t]
  );

  const getIbanHint = useCallback(
    (value: string) => {
      const codeLengthFound = Object.values(SEPA_COUNTRY_CODES).find(
        ({ code }) => code === value?.slice(0, 2)
      )?.codeLength;

      return !codeLengthFound
        ? t('profile.iban.hint')
        : t('profile.iban.hintByCountry', { codeLength: codeLengthFound });
    },
    [t]
  );

  const validationSchema = yup.object().shape(
    {
      firstName: yup.string().required(t('register.requiredField')).label(t('shared.firstName')),
      iban: yup
        .string()
        .when('ibanHolder', schemaRequiredOrNullable)
        .test({
          message: 'profile.iban.formatError',
          test: (v: string) =>
            !v ||
            (Object.values(SEPA_COUNTRY_CODES)
              .map(({ code }) => code)
              .includes(v?.slice(0, 2)) &&
              isIbanValid(v)),
        }),
      ibanHolder: yup.string().when('iban', schemaRequiredOrNullable),
      lastName: yup.string().required(t('register.requiredField')).label(t('shared.lastName')),
      phoneSuffix: yup
        .string()
        .required(t('register.requiredField'))
        .test('phoneSuffix', t('profile.phoneNumber'), (phoneSuffix, ctx) =>
          new PN(ctx.parent.phonePrefix + phoneSuffix).isValid()
        ),
    },
    [['iban', 'ibanHolder']]
  );

  const { control, handleSubmit, getValues, setValue } = useForm<ProfileInformationForm>({
    defaultValues: {
      address: customer.address,
      avatarS3ID: fromS3ID(customer.account.avatarS3ID),
      email: customer.email,
      firstName: customer.firstName,
      iban: customer.iban ?? '',
      ibanHolder: customer.ibanHolder ?? '',
      job: customer.job ?? '',
      lastName: customer.lastName,
      phonePrefix: phonePrefixInit,
      phoneSuffix: phoneSuffixInit,
      promoCode: customer.account.promoCode ?? '',
    },
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data: ProfileInformationForm) => {
    try {
      const {
        phonePrefix,
        phoneSuffix,
        iban,
        address: { __typename, ...address },
        ...form
      } = data;
      await updateInformations({
        ...form,
        address,
        iban: iban.replace(new RegExp(' ', 'g'), ''),
        phoneNumber: combinePhone(phonePrefix, phoneSuffix),
      });
      setValidMsg({ icon: 'Check', title: t('profile.updateProfileMessage'), withClose: true });
      if (Platform.OS !== PlatformEnum.Web) {
        navigation.navigate(ProfileNavigatorRoutes.ProfileRoot);
      }
    } catch (e) {
      ErrorMonitoring.logError(e);
      setErrorMsg({
        description: t('profile.updateProfileErrorMessage'),
        title: t('shared.genericError'),
      });
    }
  };

  return {
    control,
    getIbanHint,
    getValues,
    isAssetManagementConsultant,
    isSaving: updateInformationsLoading,
    onSubmit: handleSubmit(onSubmit),
    setValue,
  };
};
