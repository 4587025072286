import { useRef } from 'react';

import { forEach, transform, size } from 'lodash';
import { Platform } from 'react-native';

import { PlatformEnum } from '@app/types/platform';
import { CustomerUtmInput } from '@app/libs/apollo/introspection';

const UTM_FIELD_MAP = {
  utm_campaign: 'campaign',
  utm_content: 'content',
  utm_medium: 'medium',
  utm_source: 'source',
  utm_term: 'term',
} as const;

export type UtmValues = Partial<
  Record<
    (typeof UTM_FIELD_MAP)[keyof typeof UTM_FIELD_MAP] &
      keyof CustomerUtmInput /* Guarantee that the fields exist in CustomerUtmInput */,
    string
  >
>;

const getUtmValuesFromLocation = (search: string): UtmValues | null => {
  const thisPageUrlParams = new URLSearchParams(search);
  const utmValues = transform(
    UTM_FIELD_MAP,
    (obj, fieldName, urlParamName) => {
      if (thisPageUrlParams.has(urlParamName)) {
        obj[fieldName] = thisPageUrlParams.get(urlParamName);
      }
    },
    {}
  );

  if (size(utmValues) === 0) {
    return null;
  }

  return utmValues;
};

const getUtmValuesFromLocalStorage = (): UtmValues | null => {
  const utmValues = transform(
    UTM_FIELD_MAP,
    (obj, fieldName) => {
      const value = window.localStorage?.getItem(fieldName);

      if (value) {
        obj[fieldName] = value;
      }
    },
    {}
  );

  if (size(utmValues) === 0) {
    return null;
  }

  return utmValues;
};

const storeUtmValuesToLocalStorage = (utmValues: UtmValues): void => {
  forEach(utmValues, (value, key) => {
    if (value !== undefined) {
      window.localStorage?.setItem(key, value);
    } else {
      window.localStorage?.removeItem(key);
    }
  });
};

export const useUtmValues = (): UtmValues | null => {
  const utmValuesRef = useRef<UtmValues>(null);
  const utmValuesDeterminedRef = useRef(false);

  if (Platform.OS !== PlatformEnum.Web) {
    return {};
  }

  if (utmValuesDeterminedRef.current) {
    // UTM values have already been determined.
    return utmValuesRef.current;
  }

  // Let's determine UTM values...
  const search = window?.location.search || '';
  const utmValues = getUtmValuesFromLocation(search) || getUtmValuesFromLocalStorage();

  if (utmValues) {
    utmValuesRef.current = utmValues;
    storeUtmValuesToLocalStorage(utmValues);
  }

  utmValuesDeterminedRef.current = true;

  return utmValues;
};
