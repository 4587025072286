import { palette, Spacing } from '@masteos/aphrodite';

const defaultButtonWidth = 250;
const parentSpacing = 40;
const parentMobileSpacing = 20;
const navbarBottomHeight = 48;

export const getStyle = ({
  columnsNb,
  isLowerThanTablet,
}: {
  columnsNb: number;
  isLowerThanTablet: boolean;
}) => ({
  container: {
    width: '100%',
  },
  enlightenmentContainer: {
    marginLeft: isLowerThanTablet ? -parentMobileSpacing : -parentSpacing,
    marginRight: isLowerThanTablet ? -parentMobileSpacing : -parentSpacing,
    marginTop: -400 - Spacing.SIZE_02,
    paddingTop: Spacing.SIZE_02,
  },
  enlightenmentWrapper: {
    backgroundImage: `linear-gradient(rgba(255,255,255,0), ${palette.base.white}, ${palette.base.white} 66%)`,
    paddingBottom: isLowerThanTablet ? navbarBottomHeight : 0,
    paddingLeft: isLowerThanTablet ? parentMobileSpacing : 0,
    paddingRight: isLowerThanTablet ? parentMobileSpacing : 0,
  },
  loaderContainer: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    minHeight: 48,
  },
  noResultContainer: {
    marginBottom: Spacing.SIZE_11,
  },
  seeMoreButton: {
    maxWidth: columnsNb === 1 ? 'none' : defaultButtonWidth,
    width: '100%',
  },
  seeMoreWrapper: {
    alignItems: 'center',
    marginBottom: 68,
    paddingTop: Spacing.SIZE_09,
  },
  specificCard: { alignItems: 'center', alignSelf: 'center' },
  wrapper: {
    display: 'grid',
    gap: Spacing.SIZE_07,
    gridAutoFlow: 'dense',
    gridTemplateColumns: `repeat(${columnsNb}, minmax(0, 1fr))`,
    overflow: 'visible',
    width: '100%',
  },
});
