/* eslint-disable max-lines */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Platform, StyleSheet, View } from 'react-native';
import { ParamListBase, useIsFocused, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import {
  ActionButton,
  LoaderDefault,
  Spacer,
  Spacing,
  Toast,
  useResponsive,
} from '@masteos/aphrodite';

import { trackChangePage } from '@app/services/tracking/pageTracking';
import { PropertyDescription } from '@app/features/propertyDescription/PropertyDescription.web';
import { PropertyRent } from '@app/features/propertyRent/PropertyRent.web';
import { PropertyRenovation } from '@app/features/propertyRenovation/PropertyRenovation';
import { PropertyFinances } from '@app/features/propertyFinances/PropertyFinances.web';
import { ControlledSwiperModal } from '@app/shared/components/SwiperModal/ControlledSwiperModal.web';
import {
  PropertyPageTypeGallery,
  trackDPEButtonClick,
  trackGalleryVisit,
  trackGESButtonClick,
  TrackingOrigin,
  trackOpenMapFullScreen,
  trackPropertyChangeTab,
  trackVirtualVisit,
} from '@app/services/tracking/trackTracking';
import { useLocationInfoPermission } from '@app/shared/hooks/useLocationInfoPermission';
import { realEstateToFields, usePropertyTitle } from '@app/shared/hooks/usePropertyTitle';
import { useFinancialModel } from '@app/shared/hooks/useFinancialModel';
import { RealEstate, Role } from '@app/libs/apollo/introspection';
import { useMe } from '@app/shared/hooks/useMe';
import { useRealEstateTranslation } from '@app/shared/hooks/useRealEstateTranslations';
import { TabView } from '@app/shared/components/TabView/TabView.web';
import { PropertyRenovationGESModal } from '@app/features/propertyRenovation/PropertyRenovationGESModal';
import { PropertyRenovationDPEModal } from '@app/features/propertyRenovation/PropertyRenovationDPEModal';
import { PropertyDescriptionGoogleMapModal } from '@app/features/propertyMap/PropertyDescriptionGoogleMapModal';
import { getHeaderSliderImages, shouldDisplayTransactionToast } from '@app/utils/property';
import { useMyProjectsList } from '@app/features/projectList/useProjectList.hook';
import { PublicNavigatorRoutes, RealEstateNavigatorRoutes } from '@app/navigation/types/routes';
import { navigationPushFromRef } from '@app/navigation/navigationRef';
import { PlatformEnum } from '@app/types/platform';

import { PropertyInfoBottomButtons } from './PropertyInfoBottomButtons';
import { PropertyTitle } from './PropertyTitle.web';
import { PropertyTopImages } from './PropertyTopImages.web';
import { getToastWrapperStyles, HeaderWrapper, LeftButtonBox } from './propertyInfo.styles.web';
import { getFormattedAddressFromProperty } from './PropertyInfo.utils.web';
import { currentSearchEngineScrollPosition } from '../search-engine/utils/scrollTo.utils';

const RolesThatCanSeeTheHunter: Role[] = [
  Role.Advisor,
  Role.Hunter,
  Role.HunterManager,
  Role.Admin,
];

interface PropertyInfoProps {
  property: RealEstate;
  propertyPosition: number;
  isFromSearchEngine?: boolean;
  currentScrollPosition?: number;
}

const getStyles = (isGreaterThanTablet: boolean) =>
  StyleSheet.create({
    buttonBottom: {
      minWidth: 240,
      width: 'auto',
    },
    buttonBottomLarge: {
      width: '100%',
    },
    container: {
      alignItems: 'center',
      flex: 1,
      flexDirection: 'column',
      position: 'relative',
    },
    loadingWrapper: {
      alignItems: 'center',
      height: '50%',
      justifyContent: 'center',
    },
    tabViewHeader: {
      maxWidth: 1120,
      paddingHorizontal: isGreaterThanTablet ? 96 : 0,
      width: '100%',
    },
    tabWrapper: {
      flexDirection: 'column',
      maxWidth: 680,
      paddingTop: Spacing.SIZE_05,
      width: '100%',
    },
  });

type RealEstateNavigationProp = StackNavigationProp<
  ParamListBase,
  RealEstateNavigatorRoutes.RealEstate
>;

export const PropertyInfo: React.FC<PropertyInfoProps> = ({
  property,
  propertyPosition,
  isFromSearchEngine,
  currentScrollPosition,
}) => {
  const { t } = useRealEstateTranslation();
  const isFocused = useIsFocused();

  const navigation = useNavigation<RealEstateNavigationProp>();

  const propertyTitle = usePropertyTitle(realEstateToFields(property));

  const [slide, setSlide] = useState<number>(0);
  const [isVisibleDPEModal, setIsVisibleDPEModal] = useState(false);
  const [isVisibleGESModal, setIsVisibleGESModal] = useState(false);
  const [isVisibleGoogleMapModal, setIsVisibleGoogleMapModal] = useState(false);

  const { isGreaterThanTablet, isLowerThanDesktop, isLowerThanLaptop } = useResponsive();
  const styles = useMemo(() => getStyles(isGreaterThanTablet), [isGreaterThanTablet]);
  const { defaultFinancialModel: financialModel, loading } = useFinancialModel(property);
  const { customer, stateOfCustomer } = useMe();
  const { data: { myProjects } = { myProjects: [] }, loading: loadingProjects } =
    useMyProjectsList();

  const ref = React.createRef<View>();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [isHeaderButtonsVisible, setIsHeaderButtonsVisible] = useState(false);
  const [footerOffset, setFooterOffset] = useState(0);

  const observer = useRef(
    'IntersectionObserver' in window
      ? new IntersectionObserver(([entry]) => setIsHeaderButtonsVisible(entry.isIntersecting))
      : null
  );
  const isLocationInformationVisible = useLocationInfoPermission(property?.id);
  const headerSliderImages = useMemo(
    () => getHeaderSliderImages(property, isLocationInformationVisible, stateOfCustomer),
    [isLocationInformationVisible, property, stateOfCustomer]
  );

  /** Handle tracking on property page */
  useEffect(() => {
    if (!isFocused) {
      return;
    }

    if (property && !!financialModel && !loading) {
      trackChangePage(RealEstateNavigatorRoutes.RealEstate, {
        financialModel,
        isFromSearchEngine,
        property,
        propertyPosition,
      });
    }
  }, [isFocused, loading, financialModel, property, propertyPosition, isFromSearchEngine]);

  const { address, addressGoogleMapModal } = getFormattedAddressFromProperty(
    property,
    t,
    isLowerThanLaptop
  );

  const coordGoogleMapModal = {
    lat: property?.building.address?.lat,
    lng: property?.building.address?.lng,
  };
  const isHunterNameVisible =
    customer?.account.roles &&
    RolesThatCanSeeTheHunter.some(role => customer?.account.roles.includes(role));

  const tabContent = [
    {
      component: () => (
        <PropertyDescription
          isPublicProperty={!isLocationInformationVisible}
          property={property}
          openMapModal={toggleMapModal}
        />
      ),
      key: 'general',
      title: t('propertyInfo.general'),
      trackingLabel: TrackingOrigin.GENERAL,
    },
    {
      component: () => (
        <PropertyRenovation
          property={property}
          openModalGES={toggleGESModal}
          openModalDPE={toggleDPEModal}
          isPublicProperty={!isLocationInformationVisible}
        />
      ),
      key: 'renovation',
      title: t('propertyInfo.renovation'),
      trackingLabel: TrackingOrigin.RENOVATION,
    },
    {
      component: () => <PropertyRent property={property} />,
      key: 'rent',
      title: t('propertyInfo.rent'),
      trackingLabel: TrackingOrigin.RENTAL,
    },
    {
      component: () => <PropertyFinances property={property} />,
      key: 'financial',
      title: t('propertyInfo.financial'),
      trackingLabel: TrackingOrigin.FINANCES,
    },
  ];

  useEffect(() => {
    if (!observer.current) {
      return () => {
        // Do nothing
      };
    }

    if (ref.current) {
      observer.current.observe(ref.current as unknown as Element);
    }

    return () => {
      observer.current.disconnect();
    };
  }, [ref]);

  const [displayedModalImage, setDisplayedModalImage] = useState(false);

  const openModalImage = (index: number) => {
    trackGalleryVisit({
      countGalleryImages: headerSliderImages.length,
      propertyId: property.id,
      typeGallery: PropertyPageTypeGallery.MAIN,
    });
    setSlide(index);
    setDisplayedModalImage(true);
  };

  const toggleGESModal = () => {
    if (!isVisibleGESModal) {
      trackGESButtonClick(property.id);
    }
    setIsVisibleGESModal(!isVisibleGESModal);
  };

  const toggleDPEModal = () => {
    if (!isVisibleDPEModal) {
      trackDPEButtonClick(property.id);
    }
    setIsVisibleDPEModal(!isVisibleDPEModal);
  };

  const handleFooterOffsetOnLayout = useCallback(({ nativeEvent }) => {
    setFooterOffset(nativeEvent.layout.height);
  }, []);

  if (!property || loading) {
    return (
      <View style={styles.loadingWrapper}>
        <LoaderDefault variant="inLight" />
      </View>
    );
  }

  const handleTrackingVirtual = () =>
    trackVirtualVisit({ isInsideGallery: true, propertyId: property.id });

  const handleGoBack = () => {
    if (navigation.canGoBack()) {
      Platform.OS === PlatformEnum.Web && currentSearchEngineScrollPosition(currentScrollPosition);
      navigation.goBack();
    } else if (customer?.id) {
      navigationPushFromRef(navigation.getState().routeNames[0]);
    } else {
      navigation.navigate(PublicNavigatorRoutes.Auth);
    }
  };

  const toggleMapModal = () => {
    if (!isVisibleGoogleMapModal) {
      trackOpenMapFullScreen({ financialModel, property });
    }
    setIsVisibleGoogleMapModal(!isVisibleGoogleMapModal);
  };

  const onIndexChange = (index: number) => {
    trackPropertyChangeTab({ propertyId: property.id, tabName: tabContent[index].trackingLabel });
    setTabIndex(index);
  };

  return (
    <View testID="property-info">
      <View style={styles.container}>
        {isGreaterThanTablet ? (
          <>
            <Spacer height={Spacing.SIZE_09} />
            <LeftButtonBox>
              <ActionButton
                leftIconName="ArrowLeft"
                size="m"
                onPress={handleGoBack}
                testID="go-back-action-btn"
                withSolidBorder={isGreaterThanTablet}
              />
            </LeftButtonBox>
          </>
        ) : null}

        <TabView
          header={
            <View style={styles.tabViewHeader}>
              <HeaderWrapper>
                <PropertyTitle
                  property={property}
                  isAddressVisible={isLocationInformationVisible}
                  isHunterNameVisible={isHunterNameVisible}
                  address={address}
                  currentTab={tabContent[tabIndex].trackingLabel}
                />

                <PropertyTopImages
                  property={property}
                  imageList={headerSliderImages}
                  isPrivateProperty={!!customer?.id}
                  ref={ref}
                  openModalImage={openModalImage}
                  currentScrollPosition={currentScrollPosition}
                />
              </HeaderWrapper>
            </View>
          }
          index={0}
          onIndexChange={onIndexChange}
          tabs={tabContent}
          tabsRightElementWeb={
            !isHeaderButtonsVisible && !isLowerThanDesktop ? (
              <PropertyInfoBottomButtons property={property} />
            ) : null
          }
        />

        {!!shouldDisplayTransactionToast(property, myProjects, loadingProjects) && (
          <>
            <Spacer height={isLowerThanLaptop ? Spacing.SIZE_08 : Spacing.SIZE_04} />
            <View
              style={
                getToastWrapperStyles(isLowerThanDesktop, isLowerThanLaptop, footerOffset)
                  .inTransactionToast
              }
              testID="property-inTransaction-alert"
            >
              <Toast
                label={t('propertyInfo.alert.inTransactionDesc')}
                icon="Info"
                numberOfLines={0}
                width="100%"
              />
            </View>
          </>
        )}
      </View>

      {isLowerThanDesktop && !isHeaderButtonsVisible ? (
        <PropertyInfoBottomButtons
          handleOnLayout={handleFooterOffsetOnLayout}
          property={property}
        />
      ) : null}

      <Spacer height={Spacing.SIZE_09} />

      <ControlledSwiperModal
        propertyId={property.id}
        isOpen={displayedModalImage}
        swiperTitle={t('shared.gallery')}
        closeModalImage={() => setDisplayedModalImage(false)}
        currentSlideIndex={slide}
        setCurrentSlideIndex={setSlide}
        externalItem={
          isLocationInformationVisible && property.url3D
            ? {
                icon: 'Cube',
                title: t('propertyInfo.3DLink'),
                tracking: handleTrackingVirtual,
                url: property.url3D,
              }
            : null
        }
        imageList={headerSliderImages}
        advisor={customer?.advisor}
        isHeaderGallery
      />
      <PropertyDescriptionGoogleMapModal
        coord={coordGoogleMapModal}
        title={propertyTitle}
        coverImageURL={property.coverImage}
        address={addressGoogleMapModal}
        isVisibleGoogleMapModal={isVisibleGoogleMapModal}
        setIsVisibleGoogleMapModal={toggleMapModal}
      />
      <PropertyRenovationDPEModal
        value={property.dpe}
        setIsVisibleDPEModal={toggleDPEModal}
        isVisibleDPEModal={isVisibleDPEModal}
      />
      <PropertyRenovationGESModal
        value={property.ges}
        setIsVisibleGESModal={toggleGESModal}
        isVisibleGESModal={isVisibleGESModal}
      />
    </View>
  );
};
