import { formatCurrency } from '@masteos/agora';

import { MAX_GRAPHQL_INT } from '@app/constants/constants';

import { BudgetRules } from '../searchEngineFilters.type';

export const getBudgetMinRules = (getValues, t): BudgetRules => ({
  validate: {
    lessThanMax: v =>
      v === null ||
      getValues('budgetMax') === null ||
      v <= getValues('budgetMax') ||
      t('propertiesPreferences.formErrors.budgetMinSupMax'),
    positive: v => v === null || v >= 0 || t('searchEngine.filter.errorMessage.mustBePositive'),
  },
});

export const getBudgetMaxRules = (capacity: number, t): BudgetRules => ({
  validate: {
    lessThanCapacity: v =>
      !capacity ||
      v <= capacity ||
      t('propertiesPreferences.formErrors.budgetMaxSupPostMandate', {
        budget: formatCurrency(capacity),
      }),
    maximum: v => v <= MAX_GRAPHQL_INT || t('searchEngine.filter.errorMessage.mustBeValid'),
    positive: v => v === null || v >= 0 || t('searchEngine.filter.errorMessage.mustBePositive'),
  },
});
