import { CSSProperties } from 'react';

import { Platform, StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native';

import { palette, Spacing } from '@masteos/aphrodite';

import { PlatformEnum } from '@app/types/platform';

export interface OnboardingStylesProps {
  isFixed?: boolean;
  isGreaterThanTablet?: boolean;
  isWeb?: boolean;
}

interface ScrollbarViewStyle extends ViewStyle {
  ['&::-webkit-scrollbar']: CSSProperties;
  msOverflowStyle?: string;
  scrollbarWidth?: string;
  overflowY?: string;
}

const scrollViewContainer: ScrollbarViewStyle = {
  '&::-webkit-scrollbar': {
    display: 'none' /* Chrome, Safari and Opera */,
  },
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
};

export const getStyles = ({
  isFixed = false,
  isGreaterThanTablet = false,
}: OnboardingStylesProps): Record<string, StyleProp<ViewStyle | TextStyle>> => {
  return StyleSheet.create({
    bottomContainer: {
      backgroundColor: palette.base.white,
      bottom: isGreaterThanTablet || !isFixed ? Spacing.NONE : Spacing.SIZE_06,
      marginTop: Spacing.SIZE_04,
    },

    buttonWrapper: {
      marginBottom: isGreaterThanTablet || !isFixed ? Spacing.NONE : Spacing.SIZE_06,
      paddingHorizontal: isGreaterThanTablet ? Spacing.SIZE_06 : Spacing.NONE,
    },

    flex1: {
      flex: 1,
    },

    flexView: {
      flex: 1,
      width: 335,
    },

    formWrapper: {
      alignItems: 'center',
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      height: Platform.OS === PlatformEnum.Web ? '100vh' : '100%',
      maxWidth: 400,
      paddingHorizontal: Spacing.SIZE_06,
      paddingVertical: Platform.OS === PlatformEnum.Web ? Spacing.SIZE_08 : Spacing.SIZE_00,
      width: '100%',
    },

    input: {
      marginBottom: Spacing.SIZE_04,
      width: '100%',
    },

    inputGroup: {
      marginBottom: 'auto',
      marginTop: isGreaterThanTablet ? 'auto' : Spacing.NONE,
      width: '100%',
    },

    link: {
      textDecorationColor: palette.base.black,
      textDecorationLine: 'underline',
      textDecorationStyle: 'solid',
    },

    mainContent: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      maxHeight: '100%',
      position: 'relative',
      width: '100%',
    },

    mainWrapper: {
      backgroundColor: palette.base.white,
      height: '100%',
      position: 'relative',
    },

    safeAreaWrapper: {
      backgroundColor: palette.base.white,
      flex: 1,
    },

    safeMainWrapper: {
      flex: 1,
    },

    scrollView: {
      flex: 1,
      height: '100%',
    },

    scrollViewContainer,

    scrollViewContainerWeb: {
      flexGrow: isGreaterThanTablet ? 1 : undefined,
      justifyContent: 'center',
    },
  });
};

export const submitButtonStyles: CSSProperties = {
  width: '100%',
};
