import React, { useMemo, useState } from 'react';

import { View, ImageStyle, ScrollView } from 'react-native';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Thumbs, Virtual, Pagination } from 'swiper';

import 'swiper/css/bundle';

import { useTranslation } from 'react-i18next';

import {
  IconName,
  useResponsive,
  Text,
  IconButton,
  Avatar,
  Image,
  Spacer,
  TextWithIcon,
  useTheme,
  TranslatedContent,
  Pressable,
  ModalFullScreen,
} from '@masteos/aphrodite';

import { TRANSLATION_SERVICE } from '@app/constants/constants';
import { SliderImageObject } from '@app/utils/property';
import { CustomerAdvisor } from '@app/libs/apollo/introspection';
import { fromS3ID } from '@app/utils/from-s3id';
import { StateOfCustomer, useMe } from '@app/shared/hooks/useMe';
import { PropertyGalleryImageAppointment } from '@app/features/propertyInfo/PropertyGalleryImageAppointment';

import { getStyles } from './swiperModal.style';

export interface SwiperModalProps {
  closeModalImage: () => void;
  isHeaderGallery?: boolean;
  isOpen: boolean;
  currentSlideIndex: number;
  setCurrentSlideIndex: (index: number) => void;
  swiperTitle: string;
  propertyId: string;
  externalItem?: {
    url: string;
    title: string;
    icon: IconName;
    tracking?: () => void;
  };
  imageList: SliderImageObject[];
  advisor?: CustomerAdvisor;
}

const MAX_HEIGHT_OFFSET = 100;

export const SwiperModal: React.FC<SwiperModalProps> = ({
  closeModalImage,
  currentSlideIndex,
  isHeaderGallery = false,
  setCurrentSlideIndex,
  swiperTitle,
  externalItem,
  isOpen,
  imageList,
  advisor,
  propertyId,
}) => {
  const { t } = useTranslation();
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperCore>(null);
  const { stateOfCustomer } = useMe();
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const [hadAlreadyChangeSlide, setHadAlreadyChangeSlide] = useState(false);
  const { isLowerThanLaptop } = useResponsive();
  const currentSlide = imageList[currentSlideIndex] || imageList[0];
  const theme = useTheme();
  const styles = getStyles(isLowerThanLaptop, theme);
  const maxImageHeight = window.innerHeight - MAX_HEIGHT_OFFSET;
  const isPreAppointment = useMemo(
    () =>
      stateOfCustomer
        ? isHeaderGallery && stateOfCustomer === StateOfCustomer.PRE_APPOINTMENT
        : true,
    [stateOfCustomer]
  );

  const opacityStyle: ImageStyle = { opacity: currentSlide.title ? 1 : 0 };

  const onSlideChange = (mySwiper: SwiperCore): void => {
    if (!hadAlreadyChangeSlide) {
      setHadAlreadyChangeSlide(true);
      return;
    }

    setCurrentSlideIndex(mySwiper.realIndex);
  };

  const iconProps = externalItem && {
    name: externalItem.icon,
    size: 16,
    style: { marginRight: theme.spacing.SIZE_02 },
  };

  const handleClickExternalLink = () => {
    if (externalItem.tracking !== undefined) {
      externalItem.tracking();
    }

    window.open(externalItem.url, '_blank');
  };

  const imageSliderStyle: React.CSSProperties = {
    backgroundColor: theme.palette.base.black,
    display: 'flex',
    justifyContent: 'center',
    width: 796,
  };

  const swiperStyle = useMemo(
    () => ({
      height: isLowerThanLaptop ? '275px' : '55vh',
      marginBottom: theme.spacing.SIZE_08,
    }),
    [isLowerThanLaptop, theme]
  );

  const downloadedImages = useMemo(
    () => imageList.map(({ url, ...image }) => ({ url: fromS3ID(url, 'q-100'), ...image })),
    [imageList]
  );
  const blurredCoverImage = useMemo(() => fromS3ID(imageList[0].url, 'bl-20:q-50'), [imageList]);

  return (
    <ModalFullScreen isVisible={isOpen} onPressClose={closeModalImage} hideCross noPadding>
      <View style={styles.modalWrapper}>
        <View style={styles.contentWrapper}>
          <View style={styles.topWrapper}>
            <View style={styles.headerWrapper}>
              <View style={styles.closeButtonWrapper}>
                <IconButton
                  aria-label="button icon close"
                  iconName="ArrowLeft"
                  iconSize="m"
                  dark={true}
                  onPress={closeModalImage}
                />
              </View>
              <Text textStyle="Title3Medium" style={styles.swiperTitleStyle}>
                {swiperTitle}
              </Text>
              <Spacer width={theme.spacing.SIZE_10} />
            </View>
          </View>
          <View style={styles.titleAndNumberImagesWrapper}>
            <View style={styles.tagWrapper}>
              {!!externalItem && (
                <Pressable style={styles.button3dWrapper} onPress={() => handleClickExternalLink()}>
                  <TextWithIcon
                    icon={{
                      name: iconProps.name,
                      size: iconProps.size,
                    }}
                    text={{
                      font: 'Body2',
                      style: { fontWeight: '500' },
                    }}
                  >
                    {externalItem.title}
                  </TextWithIcon>
                </Pressable>
              )}
            </View>
            <View style={styles.visit3DandImageCountWrapper}>
              <Text textStyle="Headline2Medium" style={[styles.textStyle, opacityStyle]}>
                {(currentSlide.title !== 'coverImage' && t(currentSlide.title)) || ' '}
              </Text>
              <View style={styles.tag3dAndCountWrapper}>
                <View style={styles.imagesCount}>
                  <Text textStyle="Body2" style={{ color: theme.palette.base.white }}>
                    {currentSlideIndex + 1}/ {isPreAppointment ? 2 : imageList.length}
                  </Text>
                </View>
                {externalItem && !isLowerThanLaptop ? (
                  <Pressable
                    style={styles.button3dWrapper}
                    onPress={() => handleClickExternalLink()}
                  >
                    <TextWithIcon
                      icon={{
                        name: iconProps.name,
                        size: iconProps.size,
                      }}
                      text={{
                        font: 'Body2',
                        style: { fontWeight: '500' },
                      }}
                    >
                      {externalItem.title}
                    </TextWithIcon>
                  </Pressable>
                ) : null}
              </View>
            </View>
          </View>

          <View style={styles.swiperWrapper}>
            {!isLowerThanLaptop && (
              <View style={styles.swiperButtonWrapper}>
                <IconButton
                  buttonRef={navigationPrevRef}
                  aria-label="button previous image"
                  iconName="ChevronLeft"
                  iconSize="m"
                  dark
                />
              </View>
            )}
            <Swiper
              modules={[Navigation, Thumbs, Virtual, Pagination]}
              centeredSlides={false}
              initialSlide={currentSlideIndex}
              loop
              zoom
              navigation={{
                nextEl: navigationNextRef.current,
                prevEl: navigationPrevRef.current,
              }}
              thumbs={{ swiper: thumbsSwiper }}
              style={swiperStyle}
              onSlideChange={onSlideChange}
            >
              {downloadedImages.map(item => (
                <SwiperSlide zoom key={item.url} style={imageSliderStyle}>
                  <Image
                    source={{ uri: item.url }}
                    style={styles.imageStyle as ImageStyle}
                    resizeMode="contain"
                  />
                </SwiperSlide>
              ))}

              {!!isPreAppointment && (
                <SwiperSlide zoom style={imageSliderStyle}>
                  <PropertyGalleryImageAppointment
                    propertyId={propertyId}
                    imageUrl={blurredCoverImage}
                    onPress={closeModalImage}
                  />
                </SwiperSlide>
              )}
            </Swiper>
            {!isLowerThanLaptop && (
              <View style={styles.swiperButtonWrapper}>
                <IconButton
                  buttonRef={navigationNextRef}
                  aria-label="button next image"
                  iconName="ChevronRight"
                  iconSize="m"
                  dark
                />
              </View>
            )}
          </View>

          <View style={styles.thumbsSwiperAndAdvisorDescriptionWrapper}>
            {currentSlide.description ? (
              <View style={styles.advisorAndDescriptionWrapper}>
                {!!advisor && (
                  <View style={styles.avatarWrapper}>
                    <Avatar isAdvisor avatarUrl={advisor.avatarS3ID} size={32} />
                  </View>
                )}
                <ScrollView
                  style={styles.descriptionWrapper}
                  showsVerticalScrollIndicator={false}
                  showsHorizontalScrollIndicator={false}
                >
                  {!!advisor && (
                    <Text textStyle="Body2Medium" style={styles.textStyle}>
                      {advisor.firstName} {advisor.lastName}
                    </Text>
                  )}
                  <Spacer height={theme.spacing.SIZE_02} />
                  <Text textStyle="Body3" style={styles.textStyle}>
                    {currentSlide.translation ? (
                      <TranslatedContent
                        dark
                        translation={currentSlide.translation}
                        translatedBy={t('shared.translatedBy', {
                          providerName: TRANSLATION_SERVICE,
                        })}
                        showOriginal={t('shared.showOriginal')}
                        showTranslated={t('shared.showTranslated')}
                      />
                    ) : (
                      <>{currentSlide.description}</>
                    )}
                  </Text>
                </ScrollView>
              </View>
            ) : (
              <View style={styles.grow} />
            )}

            {!isPreAppointment && (
              <View style={styles.thumbsSwiperWrapper}>
                <Swiper
                  modules={[Thumbs]}
                  onSwiper={setThumbsSwiper}
                  loop
                  slidesPerView={Math.min(imageList.length, 3)}
                  watchSlidesProgress
                  spaceBetween={theme.spacing.NONE}
                >
                  {imageList.map(item => (
                    <SwiperSlide key={item.url}>
                      <Image
                        source={{
                          uri: fromS3ID(item.url, `w-1500,h-${maxImageHeight},c-at_max,q-50 1500w`),
                        }}
                        style={styles.thumbsStyle as ImageStyle}
                        resizeMode="cover"
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </View>
            )}
          </View>
        </View>
      </View>
    </ModalFullScreen>
  );
};
