import styled from '@emotion/styled';
import { StyleSheet } from 'react-native';

import { palette, Spacing } from '@masteos/aphrodite';

export const FormWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 335px;
  padding: ${Spacing.SIZE_08}px ${Spacing.SIZE_06}px;
`;

export const Separator = styled.div`
  flex-grow: 5;
  height: 82px;
`;

export const InputError = styled.p`
  color: ${palette.alert.error};
  text-align: center;
`;

export const FormStyleSheets = StyleSheet.create({
  button: {
    alignSelf: 'center',
    marginBottom: Spacing.SIZE_05,
    minWidth: 225,
  },
  input: {
    marginBottom: 32,
    width: '100%',
  },
});
