import React, { CSSProperties } from 'react';

import ReactSelect, { Props } from 'react-select';
import AsyncSelect from 'react-select/async';

import { TEXT_STYLES, useTheme, Theme } from '@masteos/aphrodite';

const customStyles = (theme: Theme, s: CSSProperties, hasError: boolean) => ({
  container: (styles: CSSProperties) => ({
    ...styles,
    ...s,
  }),
  control: () => ({
    ...s,
    border: 0,
    borderBottom: `1px solid ${hasError ? theme.palette.alert.error : theme.palette.base.black}`,
    borderRadius: 0,
    color: theme.palette.base.black,
    cursor: 'text',
    flexGrow: 1,
    fontSize: TEXT_STYLES.Body2.fontSize,
    height: theme.spacing.SIZE_08,
    padding: 0,
    paddingBottom: theme.spacing.SIZE_02,
    zIndex: 2,
  }),
  indicatorsContainer: (styles: CSSProperties) => ({
    ...s,
    ...styles,
    display: 'none',
  }),
  input: () => ({
    ...s,
  }),
  menuList: (styles: CSSProperties) => ({
    ...s,
    ...styles,
  }),
  option: (styles: CSSProperties) => ({
    ...styles,
    backgroundColor: theme.palette.base.white,
    color: s.color || theme.palette.base.black,
  }),
  placeholder: () => ({
    color: theme.palette.neutral[600],
    position: 'absolute',
  }),
  valueContainer: (styles: CSSProperties) => ({
    ...s,
    ...styles,
    padding: 0,
  }),
});

// eslint-disable-next-line no-undef
interface SelectProps extends Omit<Props, 'styles'> {
  styles?: React.CSSProperties;
}

export const Select: React.FC<SelectProps> = ({ async, styles, error, ...selectProps }) => {
  const theme = useTheme();
  const Selector: any = async ? AsyncSelect : ReactSelect;

  const selectStyles = customStyles(theme, styles || {}, error);

  return <Selector {...selectProps} classNamePrefix="button-select" styles={selectStyles} />;
};
