import React, { useEffect, useState } from 'react';

import { StyleSheet, View } from 'react-native';
import { useDebouncedCallback } from 'use-debounce';
import { useApolloClient } from '@apollo/client';

import { useTranslation } from '@app/services/translations/translations';
import { Select } from '@app/shared/components/Select.web';
import { AddressAutocompleteResults, GetPlacesDocument } from '@app/libs/apollo/introspection';

import { GoogleMapAddress } from './profile.types';

interface LocationSetterProps {
  defaultValue?: string;
  country?: string;
  setAddress: (address: GoogleMapAddress) => void;
  limit: number;
  placeholder?: string;
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    display: 'flex',
    zIndex: 1,
  },
  select: { width: '100%' },
});

const DEBOUNCE_TIME = 500;
const LOCATION_RESULT_LIMIT = 20;

export const ProfileLocationSelect: React.FC<LocationSetterProps> = ({
  defaultValue,
  country,
  setAddress,
  limit = LOCATION_RESULT_LIMIT,
  placeholder,
}) => {
  const { t } = useTranslation();
  const client = useApolloClient();

  const fetchAutocompleteAddress = async (address: string) => {
    const results = await client.query({
      query: GetPlacesDocument,
      variables: { language: country || 'fr', term: address },
    });
    return limitResults(results.data);
  };

  const limitResults = (fullResults: { places: AddressAutocompleteResults }) => {
    return fullResults?.places?.results.slice(0, limit).map(({ address: addr, region, ...d }) => {
      return {
        label: addr,
        value: {
          ...d,
          lat: Number(d.lat),
          lng: Number(d.lng),
        },
      };
    });
  };

  const debouncedSearch = useDebouncedCallback(
    async (inputValue, cb) => {
      const results = await fetchAutocompleteAddress(inputValue);
      cb(results);
      return results;
    },
    DEBOUNCE_TIME,
    { maxWait: 2000 }
  );

  const [defaultOptions, setDefaultOptions] = useState([]);

  useEffect(() => {
    defaultValue && debouncedSearch(defaultValue, setDefaultOptions);
  }, [defaultValue, debouncedSearch]);

  return (
    <View style={styles.container} testID="address-autocomplete-select">
      <Select
        async
        className="data-e2e-select-city"
        defaultInputValue={defaultValue}
        styles={styles.select}
        defaultOptions={defaultOptions}
        loadOptions={debouncedSearch}
        loadingMessage={() => t('profile.loadingMessage')}
        noOptionsMessage={() => t('profile.noOptionsMessage')}
        onChange={d => setAddress(d.value)}
        placeholder={placeholder}
      />
    </View>
  );
};
