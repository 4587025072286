import { StyleSheet } from 'react-native';

import { Theme } from '@masteos/aphrodite';

export const getStyles = ({ theme }: { theme: Theme }) =>
  StyleSheet.create({
    container: {
      alignItems: 'center',
      backgroundColor: theme.palette.neutral[50],
      borderRadius: 20,
      flex: 1,
      justifyContent: 'center',
      minHeight: 178,
    },
  });
