import React, { useMemo } from 'react';

import { Platform, StyleSheet, useWindowDimensions, View } from 'react-native';

import { Modal } from '@masteos/aphrodite';

import { GeoMap } from './GeoMap';
import { PropertyDescriptionGoogleMapInfo } from './PropertyDescriptionGoogleMapInfo';

interface PropertyDescriptionGoogleMapModalProps {
  coord: {
    lat: number;
    lng: number;
  };
  title: string;
  address: string;
  coverImageURL: string;
  isVisibleGoogleMapModal: boolean;
  setIsVisibleGoogleMapModal: () => void;
}

export const PropertyDescriptionGoogleMapModal: React.FC<
  PropertyDescriptionGoogleMapModalProps
> = ({
  coord,
  isVisibleGoogleMapModal,
  setIsVisibleGoogleMapModal,
  title,
  address,
  coverImageURL,
}) => {
  const { height } = useWindowDimensions();

  const styles = useMemo(() => {
    return StyleSheet.create({
      mapWrapper: {
        alignItems: 'center',
        height: Platform.OS === 'web' ? '100vh' : height,
        justifyContent: 'flex-end',
        width: '100%',
      },
    });
  }, [height]);

  // TODO: move into a stack....
  return (
    <Modal
      visible={isVisibleGoogleMapModal}
      onPressClose={setIsVisibleGoogleMapModal}
      position="fullscreen"
      noContainerPadding
      crossSecondary
      leftPositionCross
      withSafeAreaView
    >
      <View style={styles.mapWrapper}>
        <GeoMap
          coord={coord}
          options={() => ({
            fullscreenControl: false,
            gestureHandling: 'greedy',
            zoomControl: false,
          })}
        />
      </View>

      <PropertyDescriptionGoogleMapInfo
        title={title}
        address={address}
        coverImageURL={coverImageURL}
      />
    </Modal>
  );
};
