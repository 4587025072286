import React from 'react';

import { View } from 'react-native';

import { Spacer } from '@masteos/aphrodite';

import { getStyle } from './SuggestionCardListSeparator.styles';

export const SuggestionCardListSeparator: React.FC = () => {
  const styles = getStyle();

  return (
    <View style={styles.container} testID="suggestion-card-list-separator">
      <Spacer height={1} flex={1} separator />
    </View>
  );
};
