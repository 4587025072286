import React, { JSXElementConstructor, ReactElement } from 'react';

import { Platform } from 'react-native';

import { ErrorScreen } from '@app/features/errorScreen/ErrorScreen';
import { useTranslation } from '@app/services/translations/translations';
import { PlatformEnum } from '@app/types/platform';

interface ConfigErrorBoundaryProps {
  children: ReactElement<unknown, string | JSXElementConstructor<unknown>>;
  configError?: string;
}

export const ConfigErrorBoundary: React.FC<ConfigErrorBoundaryProps> = ({
  children,
  configError,
}) => {
  const isWeb = Platform.OS === PlatformEnum.Web;

  const { t } = useTranslation();

  const handleRestartApp = () => location.reload();

  if (configError) {
    return (
      <ErrorScreen
        message={t('errorScreen.configError')}
        resetError={isWeb ? handleRestartApp : null}
      />
    );
  }

  return children;
};
