import { ListItemUnionProps } from '@masteos/aphrodite';

import { PropertyKind } from '@app/libs/apollo/introspection';
import { SelectorFormatOptionArgsProps, SELECTOR_ALL_KEY } from '@app/utils/selector.utils';
import { SearchEngineSource } from '@app/features/search-engine/searchEngine.types';

export const FILTER_NAME_TRACKING_KEY = {
  budget: SearchEngineSource.BUDGET_QF,
  country: SearchEngineSource.COUNTRY_QF,
  regionList: SearchEngineSource.REGION_QF,
  typology: SearchEngineSource.PROPERTY_TYPE_QF,
};

export const ICON_FOR_PROPERTY_TYPE = {
  Apartment: 'Flat',
  Building: 'Building',
  House: 'Home',
};

export const getPropertyTypeList = (t: (arg: string) => string): ListItemUnionProps[] => {
  const list = Object.values(PropertyKind).map(
    v =>
      ({
        iconName: ICON_FOR_PROPERTY_TYPE[v],
        key: v,
        label: t(`shared.propertyKind.${v}`),
      } as ListItemUnionProps)
  );

  return [
    {
      iconName: 'Projects',
      key: SELECTOR_ALL_KEY,
      label: t('propertiesPreferences.propertyType.all'),
    },
    ...list,
  ];
};

export const getSingleValue = (
  value: string,
  optionList: ListItemUnionProps[]
): SelectorFormatOptionArgsProps['value'] => {
  return value && value.length && optionList.length
    ? [optionList.find(opt => opt.key === value).label]
    : [];
};
