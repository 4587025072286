import React, { useCallback, useEffect, useMemo } from 'react';

import { Platform } from 'react-native';
import { StackScreenProps } from '@react-navigation/stack';

import { useSlider } from '@masteos/aphrodite';

import { useMe } from '@app/shared/hooks/useMe';
import { trackChangePage, WebPageName } from '@app/services/tracking/pageTracking';
import { NativeScreenName, trackChangeScreen } from '@app/services/tracking/screenTracking';
import { PlatformEnum } from '@app/types/platform';
import {
  TakeAppointmentLaterTrackSource,
  trackOnboardingNextStep,
  trackOnboardingPrevStep,
  trackTakeAdvisorAppointmentClick,
  trackTakeAdvisorAppointmentLaterClick,
} from '@app/services/tracking/trackTracking';
import { useHideOnboardingMutation } from '@app/libs/apollo/introspection';
import {
  AdvisorNavigatorRoutes,
  MainMenuNavigatorRoutes,
  PrivateNavigatorRoutes,
  PrivateStackParamList,
} from '@app/navigation/types/routes';
import { notificationModalBlocked } from '@app/shared/components/notification-provider/notificationProvider.utils';

import { getOnboardingPagesScreen } from './utils/getOnboardingPagesScreen.utils';
import { ActionType } from './Onboarding.types';
import { OnboardingTemplate } from './Onboarding.template';

export type OnboardingType = StackScreenProps<
  PrivateStackParamList,
  PrivateNavigatorRoutes.Onboarding
>;

export const Onboarding: React.FC<OnboardingType> = ({ navigation }) => {
  const { customer } = useMe();
  const [hideOnboardingMutation] = useHideOnboardingMutation();

  const onboardingPagesScreen = useMemo(
    () => getOnboardingPagesScreen(customer.firstName),
    [customer.firstName]
  );

  const nbSlide = onboardingPagesScreen.length;

  const trackCurrentSlide = useCallback(
    (currentStep: number) => {
      if (Platform.OS === PlatformEnum.Web) {
        trackChangePage(WebPageName.ONBOARDING, {
          eventName: onboardingPagesScreen[currentStep].pageTrackingEvent,
        });
      } else {
        trackChangeScreen(NativeScreenName.ONBOARDING, {
          eventName: onboardingPagesScreen[currentStep].pageTrackingEvent,
        });
      }
    },
    [onboardingPagesScreen]
  );

  const onStepCallback = useCallback(
    ({ lastStep, step }) => {
      if (step > lastStep) {
        // onNext
        step === nbSlide - 1 && hideOnboardingMutation();
        trackOnboardingNextStep({
          name: onboardingPagesScreen[lastStep].key,
          order: lastStep + 1,
        });
      } else if (step < lastStep) {
        // onPrev
        trackOnboardingPrevStep({ source: 'step ' + (lastStep + 1) });
      }
      trackCurrentSlide(step);
    },
    [hideOnboardingMutation, nbSlide, onboardingPagesScreen, trackCurrentSlide]
  );

  const gotoAdvisorAppointment = useCallback(() => {
    trackTakeAdvisorAppointmentClick({ source: 'onboarding' });

    navigation.reset({
      index: 0,
      routes: [
        {
          name: PrivateNavigatorRoutes.MainMenu,
          params: {
            params: {
              screen: AdvisorNavigatorRoutes.AdvisorAppointment,
            },
            screen: MainMenuNavigatorRoutes.Advisor,
          },
        },
      ],
    });
    notificationModalBlocked(false);
  }, [navigation]);

  const gotoHome = useCallback(() => {
    trackTakeAdvisorAppointmentLaterClick(TakeAppointmentLaterTrackSource.onboarding);

    navigation.reset({
      index: 0,
      routes: [
        {
          name: PrivateNavigatorRoutes.MainMenu,
          params: {
            screen: MainMenuNavigatorRoutes.RealEstates,
          },
        },
      ],
    });
    notificationModalBlocked(false);
  }, [navigation]);

  const { prev, next, carouselProps } = useSlider({
    gesturesEnabled: false,
    numberOfSlides: nbSlide,
    onStep: onStepCallback,
  });

  const actions = useMemo(
    () => ({
      [ActionType.ADVISOR]: gotoAdvisorAppointment,
      [ActionType.HOME]: gotoHome,
      [ActionType.NEXT]: next,
    }),
    [gotoAdvisorAppointment, gotoHome, next]
  );

  useEffect(() => {
    trackCurrentSlide(0);
    notificationModalBlocked(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <OnboardingTemplate
      carouselProps={carouselProps}
      onPrev={prev}
      onboardingPagesScreen={onboardingPagesScreen}
      actions={actions}
      nbSlide={nbSlide}
    />
  );
};
