import React, { ReactNode } from 'react';

import { Platform, useWindowDimensions } from 'react-native';

import { IconButton, IconName, useResponsive } from '@masteos/aphrodite';
import { Dpe as DpeAgora, Ges as GesAgora } from '@masteos/agora';

import { currencyFormatter } from '@app/utils/currency-formatter';
import { Dpe, EstimatedWorks, Ges, RealEstate } from '@app/libs/apollo/introspection';
import { useRealEstateTranslation } from '@app/shared/hooks/useRealEstateTranslations';

interface Rows {
  icon: IconName;
  title: string;
  data: string;
  testID: string;
  suffix?: ReactNode;
  onSuffixPressed?: () => void;
}

export interface PropertyRenovationOutput {
  rows: Rows[];
  width: number;
  shouldDisplayFurniture: boolean;
  shouldDisplayPlan: boolean;
}

const DATA_BLOCK_WIDTH_ON_MOBILE = 180;
const DATA_BLOCK_WIDTH = 234;

export const usePropertyRenovation = (
  property: RealEstate,
  openModalDPE: () => void,
  openModalGES: () => void
): PropertyRenovationOutput => {
  const { t } = useRealEstateTranslation();
  const countryCode = property?.building?.address?.countryCode;
  const { isLowerThanLaptop } = useResponsive();
  const { width: windowWidth } = useWindowDimensions();

  const width = Platform.select({
    native: windowWidth / 2.4,
    web: isLowerThanLaptop ? DATA_BLOCK_WIDTH_ON_MOBILE : DATA_BLOCK_WIDTH,
  });
  const hasDPE = property?.dpe && property?.dpe !== Dpe.NoDiag;
  const hasGES = property?.ges && property?.ges !== Ges.NoDiag;

  const shouldDisplayPlan =
    (Array.isArray(property.plans?.images) && property.plans.images.length > 0) ||
    !!property.plans.translation.translatedText ||
    !!property.plans.translation.sourceText;

  const shouldDisplayFurniture = property.project.furnishing?.amount > 0;

  const rows: Rows[] = [
    ...(property.project.renovation.workload !== EstimatedWorks.None
      ? [
          {
            data: t<string>(`propertyRenovation.${property.project.renovation.workload}`),
            icon: 'Works' as IconName,
            testID: 'property-renovation-data-block-furniture-amount',
            title: t('propertyRenovation.renovationType'),
          },
        ]
      : []),
    {
      data: currencyFormatter(property.project.renovation.amount, 0),
      icon: 'Hammer',
      testID: t('shared.financialTool.renovationType'),
      title: t('propertyRenovation.renovate'),
    },
    {
      data: currencyFormatter(property.project.furnishing.amount, 0),
      icon: 'Chair',
      testID: 'property-renovation-data-block-renovation-amount',
      title: t('propertyRenovation.furniture'),
    },

    {
      data: hasDPE
        ? t<string>('propertyRenovation.DPE.data', { value: DpeAgora[property.dpe] })
        : t<string>('propertyRenovation.DPE.inProgress'),
      icon: 'Zap',
      suffix:
        countryCode === 'FR' ? (
          <IconButton iconName="Help" onPress={openModalDPE} iconSize="s" />
        ) : null,
      testID: 'property-renovation-data-block-dpe',
      title: t('propertyRenovation.DPE.title'),
    },
    ...(['FR', 'ES'].includes(countryCode)
      ? [
          {
            data: hasGES
              ? t<string>('propertyRenovation.GES.data', { value: GesAgora[property.ges] })
              : t<string>('propertyRenovation.GES.inProgress'),
            icon: 'Cloud' as IconName,
            suffix:
              countryCode === 'FR' ? (
                <IconButton iconName="Help" onPress={openModalGES} iconSize="s" />
              ) : null,
            testID: 'property-renovation-data-block-ges',
            title: t('propertyRenovation.GES.title'),
          },
        ]
      : []),
  ];

  return { rows, shouldDisplayFurniture, shouldDisplayPlan, width };
};
