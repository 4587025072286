import { StyleProp, StyleSheet, ViewStyle } from 'react-native';

import { Theme } from '@masteos/aphrodite';

export const getStyles = ({
  isLowerThanLaptop,
}: {
  theme: Theme;
  isLowerThanLaptop: boolean;
}): Record<string, StyleProp<ViewStyle>> =>
  StyleSheet.create({
    container: {
      flex: 1,
      width: 756,
      ...(isLowerThanLaptop && { width: 'auto' }),
    },
    content: { flexGrow: 1 },
  });
