import React, { useRef } from 'react';

import { View } from 'react-native';

import { CardInformation, IconName } from '@masteos/aphrodite';

import { useHoverDelay } from '@app/shared/hooks/useHoverDelay';

interface RealEstateCardInformation {
  value: string;
  tooltip: any;
  onTooltipHover: () => void;
  icon?: IconName;
  text: string;
}

export const RealEstateCardInformation: React.FC<RealEstateCardInformation> = ({
  value,
  tooltip,
  onTooltipHover,
  icon,
  text,
}) => {
  const refHover = useRef();
  useHoverDelay({ callback: onTooltipHover, ref: refHover });

  return (
    <View ref={refHover}>
      <CardInformation icon={icon} value={value} popover={tooltip} text={text} />
    </View>
  );
};
