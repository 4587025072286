import React, { useMemo, useRef, useState } from 'react';

import { ScrollView, View } from 'react-native';

import { useResponsive, useTheme } from '@masteos/aphrodite';

import { getStyleSheet } from './TabView.styles.web';
import { Tab } from './TabView.type';
import { TabAnimationContextProvider, TabAnimationContext } from './TabViewContext';
import { TabBar } from './TabBar';
import { TabContent } from './TabContent.web';

interface TabViewProps {
  header: React.ReactNode;
  index: number;
  onIndexChange: (index: number) => void;
  swipeToNavigate?: boolean;
  tabs: Tab[];
  tabsRightElementWeb?: React.ReactNode;
}

export const TabView: React.FC<TabViewProps> = ({
  header,
  tabs,
  onIndexChange,
  index,
  swipeToNavigate,
  tabsRightElementWeb,
}) => {
  const scrollViewRef = useRef<ScrollView>(null);
  const wrapperRef = useRef<View>(null);
  const [height, setHeight] = useState(0);
  const theme = useTheme();
  const { isMobile, isLowerThanDesktop, isGreaterThanTablet } = useResponsive();
  const styles = useMemo(
    () =>
      getStyleSheet({
        isGreaterThanTablet,
        isLowerThanDesktop,
        isMobile,
        theme,
      }),
    [isGreaterThanTablet, isLowerThanDesktop, isMobile, theme]
  );

  const onLayout = () => {
    wrapperRef?.current &&
      wrapperRef.current.measureInWindow((x: number, y: number, w: number, h: number) => {
        setHeight(h);
      });
  };

  return (
    <TabAnimationContextProvider index={index}>
      <TabAnimationContext.Consumer>
        {ctx => {
          const setIndex = (n: number) => {
            if (height > 0) {
              scrollViewRef?.current?.scrollTo({ animated: false, y: height });
            }

            ctx.setIndex(n);
            onIndexChange(n);
          };

          return (
            <View onLayout={onLayout} style={styles.headerWrapper}>
              {header ? (
                <View ref={wrapperRef} style={styles.headerContainer}>
                  {header}
                </View>
              ) : null}
              <View style={styles.tabsWrapper}>
                <View style={styles.tabsBorder}>
                  <View style={styles.tabsContainer}>
                    <TabBar setIndex={setIndex} index={ctx.index} tabs={tabs} />
                  </View>
                  {tabsRightElementWeb ? (
                    <View style={styles.rightElement}>{tabsRightElementWeb}</View>
                  ) : null}
                </View>
              </View>

              <TabContent
                swipeToNavigate={swipeToNavigate}
                setIndex={setIndex}
                tabs={tabs}
                index={ctx.index}
              />
            </View>
          );
        }}
      </TabAnimationContext.Consumer>
    </TabAnimationContextProvider>
  );
};
