import React from 'react';

import { Platform, StyleSheet, View } from 'react-native';

import {
  Icon,
  IconName,
  LoaderDefault,
  palette,
  Pressable,
  Text,
  Theme,
  useTheme,
} from '@masteos/aphrodite';

interface MenuItemProps {
  icon: IconName;
  title: string;
  onPress?: () => void;
  subtitle?: string;
  hasTopRadius?: boolean;
  hasBottomRadius?: boolean;
  iconColor?: string;
  subBottomText?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
}

const getStyles = ({
  theme,
  hasBottomRadius,
  hasTopRadius,
  isDisabled,
  isWeb,
}: {
  theme: Theme;
  hasBottomRadius: boolean;
  hasTopRadius: boolean;
  isDisabled: boolean;
  isWeb: boolean;
}) =>
  StyleSheet.create({
    container: {
      backgroundColor: theme.palette.base.white,
      borderBottomLeftRadius: hasBottomRadius ? 15 : 0,
      borderBottomRightRadius: hasBottomRadius ? 15 : 0,
      borderTopLeftRadius: hasTopRadius ? 15 : 0,
      borderTopRightRadius: hasTopRadius ? 15 : 0,
      justifyContent: 'center',
      opacity: isDisabled ? 0.3 : 1,
      padding: isWeb ? 0 : 15,
      width: '100%',
    },
    empty: { height: 20, width: 20 },
    textWrapper: { marginHorizontal: 20 },
    wrapper: {
      alignItems: 'center',
      flexDirection: 'row',
    },
  });

export const MenuItem = ({
  title,
  onPress,
  subtitle,
  icon,
  hasTopRadius,
  hasBottomRadius,
  subBottomText,
  iconColor,
  isDisabled = false,
  isLoading,
}: MenuItemProps): JSX.Element => {
  const isWeb = Platform.OS === 'web';
  const theme = useTheme();
  const styles = getStyles({ hasBottomRadius, hasTopRadius, isDisabled, isWeb, theme });
  return (
    <Pressable onPress={onPress}>
      <View style={styles.container}>
        <View style={styles.wrapper}>
          {isLoading ? (
            <LoaderDefault variant="inLight" />
          ) : icon ? (
            <Icon name={icon} size={20} color={iconColor ?? palette.system.primary[500]} />
          ) : (
            <View style={styles.empty} />
          )}
          <View style={styles.textWrapper}>
            <Text
              textStyle="Paragraph3Demi"
              style={{ color: palette.neutral[900] }}
              ellipsizeMode="tail"
              numberOfLines={1}
            >
              {title}
            </Text>

            {!!subtitle && (
              <Text
                textStyle="Paragraph3"
                style={{ color: palette.neutral[500] }}
                ellipsizeMode="tail"
                numberOfLines={1}
              >
                {subtitle}
              </Text>
            )}

            {!!subBottomText && (
              <Text
                textStyle="Paragraph3"
                style={{ color: palette.neutral[500] }}
                ellipsizeMode="tail"
                numberOfLines={1}
              >
                {subBottomText}
              </Text>
            )}
          </View>
        </View>
      </View>
    </Pressable>
  );
};
