import React from 'react';

import {
  RadioItem as AphroditeRadioItem,
  RadioItemProps as AphroditeRadioItemProps,
} from '@masteos/aphrodite';

import { useFormContext } from './FormContext.web';

interface RadioItemProps extends AphroditeRadioItemProps {
  name: string;
}

export const RadioItem: React.FC<RadioItemProps> = ({ name, ...radioItemProps }) => {
  const formContext = useFormContext();

  const isChecked = formContext.getValue(name) === radioItemProps.value;

  if (!formContext) {
    console.warn(
      `<RadioItem name="${name}" /> must be used inside a Form component. RadioItem has been rendered but the name props have been  ignored`
    );
    return <AphroditeRadioItem value={formContext.getValue(name)} {...radioItemProps} />;
  }

  return (
    <AphroditeRadioItem
      checked={isChecked}
      {...radioItemProps}
      {...formContext.mapFieldProps(name)}
      value={radioItemProps.value}
    />
  );
};
