import React from 'react';

import { Platform, StyleSheet, View } from 'react-native';

import { palette, Text, Theme, useTheme } from '@masteos/aphrodite';

interface DataProps {
  label: string;
  value: string | number;
  isVertical?: boolean;
  isDisabled?: boolean;
}

const getStyles = ({
  theme,
  isMobile,
  isDisabled,
  isVertical,
}: {
  theme: Theme;
  isMobile: boolean;
  isDisabled: boolean;
  isVertical: boolean;
}) =>
  StyleSheet.create({
    container: {
      alignItems: isVertical ? 'flex-start' : 'center',
      flexDirection: isVertical ? 'column' : 'row',
      justifyContent: 'space-between',
    },
    wrapper: {
      backgroundColor: theme.palette.base.white,
      borderRadius: 15,
      opacity: isDisabled ? 0.5 : 1,
      padding: isMobile ? 20 : 0,
      width: '100%',
    },
  });

export const Data = ({ label, value, isVertical, isDisabled }: DataProps): React.ReactElement => {
  const isMobile = Platform.OS !== 'web';
  const theme = useTheme();

  const styles = getStyles({ isDisabled, isMobile, isVertical, theme });
  return (
    <View style={styles.wrapper} testID="wrapper">
      <View style={styles.container} testID="container">
        <Text textStyle="Paragraph3Demi" style={{ color: palette.neutral[700] }}>
          {label}
        </Text>
        <Text textStyle="Paragraph3Demi" style={{ color: palette.neutral[900] }}>
          {value}
        </Text>
      </View>
    </View>
  );
};
