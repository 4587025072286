import type { FrontUser } from '@app/types/user';
import { Role } from '@app/libs/apollo/introspection';

enum FinancialLetterStatus {
  NONE = 'none',
  VALID = 'valid',
  TOCHECK = 'toCheck',
}

/**
 * @deprecated use customer.hasOngoingProject instead
 */
export const hasActiveTransaction = (user: FrontUser): boolean =>
  user?.transactions?.some(tr => tr.status === 'ONGOING');

/**
 * @deprecated use customer.searchMandate?.valid instead
 */
export const hasSearchMandate = (user: FrontUser): boolean => user?.searchSettings?.active;

/**
 * @deprecated use customer.financingLetterStatus instead
 */
export const getFinancingLetterStatus = (user: FrontUser): string => {
  if (!user) {
    return FinancialLetterStatus.NONE;
  }

  const document = user.documents.find(doc => doc.category === 'FINANCING_CERTIFICATE');

  if (document) {
    return document.metadata?.toVerif ? FinancialLetterStatus.TOCHECK : FinancialLetterStatus.VALID;
  }
  return FinancialLetterStatus.NONE;
};

/**
 * @deprecated
 * @TODO When Agora Types will be removed
 * @TODO Exchange with `return user?.roles?.includes(Role.AssetManagementConsultant)`
 * @param {FrontUser} user
 * @return {boolean}
 */
export const isProAccountUser = (user: FrontUser): boolean => {
  return !!user?.roles?.find(
    role =>
      typeof role === 'string' &&
      role.toLowerCase() === Role.AssetManagementConsultant.toLowerCase()
  );
};

export const isProAccount = (roles: Role[]): boolean =>
  roles?.includes(Role.AssetManagementConsultant);
