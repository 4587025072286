import React, { FC, PropsWithChildren, useCallback, useReducer } from 'react';

import { ModalContextProps } from './modalManager.types';
import { ModalManagerActions, ModalManagerState } from './state/actions';
import { initialState, modalManagerReducer } from './state/reducer';

export const ModalContext = React.createContext<ModalContextProps>({
  isVisible: false,
});

export const ModalManagerProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(modalManagerReducer, initialState);
  const { isVisible, position, title, component, callbackClose, excludedModals, webSize } = state;

  const openModal = useCallback(
    (key, modalComponent, options) => {
      if (excludedModals.includes(key) || isVisible) {
        return;
      }

      dispatch({
        payload: {
          callbackClose: options?.callbackClose,
          component: modalComponent,
          excludedModals: options?.excludedModals || [],
          isVisible: true,
          modalKey: key,
          position: options?.position || 'center',
          title: options?.title,
          webSize: options?.webSize || 'l',
        } as ModalManagerState,
        type: ModalManagerActions.OPEN_MODAL,
      });
    },
    [excludedModals, isVisible]
  );

  const setTitle = useCallback(newTitle => {
    dispatch({
      payload: {
        title: newTitle,
      },
      type: ModalManagerActions.SET_TITLE,
    });
  }, []);

  const clearExcludes = useCallback(() => {
    dispatch({ type: ModalManagerActions.CLEAR_EXCLUDED_MODALS });
  }, []);

  const closeModal = useCallback(
    params => {
      callbackClose?.(params);
      dispatch({ type: ModalManagerActions.CLOSE_MODAL });
    },
    [callbackClose]
  );

  const value = {
    clearExcludes,
    closeModal,
    component,
    isVisible,
    openModal,
    position,
    setTitle,
    title,
    webSize,
  };

  return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>;
};
