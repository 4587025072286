import { StyleSheet } from 'react-native';

export const getStyles = ({ isLowerThanLaptop }: { isLowerThanLaptop: boolean }) =>
  StyleSheet.create({
    advisorBlock: {
      alignItems: 'center',
      justifyContent: 'center',
      paddingBottom: 30,
    },
    body: {
      alignItems: 'center',
      justifyContent: 'center',
    },
    header: {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: isLowerThanLaptop ? 'space-between' : 'flex-end',
      width: '100%',
    },
    title: {
      paddingTop: 16,
      textAlign: 'center',
    },
  });

export const getLoaderStyle = () =>
  StyleSheet.create({
    loader: { alignItems: 'center', flex: 1, justifyContent: 'center', width: '100%' },
  }).loader;
