import React from 'react';

import { StyleSheet, View } from 'react-native';

import { palette, Spacing, Expandable, Spacer } from '@masteos/aphrodite';
import { FinancialModel } from '@masteos/agora';

import {
  trackRentalManagement,
  trackTooltip,
  trackFinancialToggle,
  trackFinancialUntoggle,
} from '@app/services/tracking/trackTracking';
import { currencyFormatter } from '@app/utils/currency-formatter';
import { RealEstate } from '@app/libs/apollo/introspection';
import { useRealEstateTranslation } from '@app/shared/hooks/useRealEstateTranslations';

import { Importance, PropertyAttribute } from '../propertyDescription/PropertyAttribute';
import { PropertyFinancialHeader } from './propertyFinancialHeader';
import { areEqual, differentFuncParameters } from './PropertyFinances.utils';

const styles = StyleSheet.create({
  border: {
    borderBottomColor: palette.neutral[300],
    borderBottomWidth: 1,
    borderStyle: 'solid',
    width: '100%',
  },
  container: {
    borderColor: palette.neutral[300],
    borderRadius: 4,
    borderWidth: 1,
    paddingBottom: Spacing.SIZE_04,
    paddingHorizontal: Spacing.SIZE_06,
    paddingTop: Spacing.SIZE_03,
    width: '100%',
  },
});

export interface PropertyFinancialAnnualChargesProps {
  property: RealEstate;
  financialModel: FinancialModel;
  setWithPropertyManagement: (v: boolean) => void;
  withPropertyManagement: boolean;
}

export const PropertyFinancialAnnualCharges: React.FC<PropertyFinancialAnnualChargesProps> =
  React.memo(
    ({ property, financialModel, setWithPropertyManagement, withPropertyManagement }) => {
      const { t } = useRealEstateTranslation();
      const annualPropertyManagement = currencyFormatter(
        financialModel.annualPropertyManagement,
        0
      );
      const totalAnnualCharges = currencyFormatter(financialModel.annualTotalExpenses, 0);
      const annualCurrentExpenses = currencyFormatter(property.building.annual.expenses, 0);
      const annualPropertyTax = currencyFormatter(financialModel.annualPropertyTax, 0);
      const annualHomeOwnerInsurance = currencyFormatter(
        financialModel.annualHomeOwnerInsurance,
        0
      );

      const leasingCost = currencyFormatter(financialModel.leasingCost, 0);
      const rentalManagementFees = currencyFormatter(financialModel.rentalManagementFees, 0);

      const annualCharges = property.annual.charges;
      const tenantCharges = property.building.annual.expenses - annualCharges;

      const handleWithPropertyManagement = (value: boolean) => {
        trackRentalManagement({ isChecked: value, propertyId: property.id });
        setWithPropertyManagement(value);
      };

      const trackToggle = ({ expanded }) => {
        expanded
          ? trackFinancialUntoggle({ propertyId: property.id, sectionName: 'charges' })
          : trackFinancialToggle({ propertyId: property.id, sectionName: 'charges' });
      };
      const trackTooltipAnnualCurrentExpenses = () =>
        trackTooltip({ propertyId: property.id, tooltipType: 'Charges de copropriété' });
      const trackTooltipAnnualHomeOwnerInsurance = () =>
        trackTooltip({ propertyId: property.id, tooltipType: 'Assurance PNO' });
      const trackTooltipAnnualPropertyManagement = () =>
        trackTooltip({ propertyId: property.id, tooltipType: 'Gestion locative' });

      return (
        <View style={styles.container}>
          <Expandable onPress={trackToggle} id="financesExpandable">
            <Expandable.Header>
              <Expandable.Context.Consumer>
                {({ expanded }) => (
                  <PropertyFinancialHeader
                    title={t(`shared.financialTool.expenses`)}
                    icon="Home"
                    hasChevron
                    chevronOpen={expanded}
                    amount={!expanded && totalAnnualCharges}
                  />
                )}
              </Expandable.Context.Consumer>
            </Expandable.Header>
            <Expandable.Content>
              <PropertyAttribute
                label={t('shared.financialTool.annualCurrentExpenses')}
                value={annualCurrentExpenses}
                isOdd
                importance={Importance.LOW}
                testID="property-financial-line-annual-current-expenses"
                tooltip={{
                  description: t(`shared.financialTool.tooltip.annualCurrentExpensesDescription`, {
                    annualCharges,
                    tenantCharges,
                  }),
                  onOpen: trackTooltipAnnualCurrentExpenses,
                  title: t(`shared.financialTool.tooltip.annualCurrentExpenses`),
                }}
              />
              <PropertyAttribute
                label={t('shared.financialTool.annualPropertyTax')}
                value={annualPropertyTax}
                importance={Importance.LOW}
                testID="property-financial-line-annual-property-tax"
              />
              <PropertyAttribute
                label={t('shared.financialTool.annualHomeOwnerInsurance')}
                value={annualHomeOwnerInsurance}
                importance={Importance.LOW}
                isOdd
                tooltip={{
                  description: t(
                    `shared.financialTool.tooltip.annualHomeOwnerInsuranceDescription`
                  ),
                  onOpen: trackTooltipAnnualHomeOwnerInsurance,
                  title: t(`shared.financialTool.tooltip.annualHomeOwnerInsurance`),
                }}
                testID="property-financial-line-annual-home-owner-insurance"
              />
              <PropertyAttribute
                label={t('shared.financialTool.annualPropertyManagement')}
                value={annualPropertyManagement}
                importance={Importance.LOW}
                tooltip={{
                  description: t(
                    `shared.financialTool.tooltip.annualPropertyManagementDescription`,
                    {
                      context: `v${property.financialModelVersion}`,
                      leasingCost,
                      rentalManagementFees,
                    }
                  ),
                  onOpen: trackTooltipAnnualPropertyManagement,
                  title: t(`shared.financialTool.tooltip.annualPropertyManagement`),
                }}
                inputSwitch={{
                  setValue: handleWithPropertyManagement,
                  value: withPropertyManagement,
                }}
                testID="property-financial-line-annual-property-management"
              />

              <Spacer height={Spacing.SIZE_04} />

              <View style={styles.border} />

              <Spacer height={Spacing.SIZE_04} />

              <PropertyAttribute
                label={t('shared.financialTool.expenses')}
                value={totalAnnualCharges}
                importance={Importance.HIGH}
                testID="property-financial-line-total-annual-charges"
              />
            </Expandable.Content>
          </Expandable>
        </View>
      );
    },
    areEqual<PropertyFinancialAnnualChargesProps>(
      ['withPropertyManagement'],
      (
        prevState: PropertyFinancialAnnualChargesProps,
        nextState: PropertyFinancialAnnualChargesProps
      ) => {
        return differentFuncParameters(prevState, nextState, [
          'annualPropertyManagement',
          'annualTotalExpenses',
          'annualHomeOwnerInsurance',
          'annualPropertyTax',
        ]);
      }
    )
  );

PropertyFinancialAnnualCharges.displayName = 'PropertyFinancialAnnualCharges';
