import { StyleSheet } from 'react-native';

export const getStyles = () => {
  return StyleSheet.create({
    allFilter: {
      flexShrink: 0,
    },
    container: {
      justifyContent: 'space-between',
      marginTop: 17,
      paddingBottom: 16,
      paddingTop: 8,
    },
    selector: {
      flexBasis: 'auto',
      flexGrow: 0,
      flexShrink: 1,
      maxWidth: 250,
      minWidth: 100,
    },
  });
};
