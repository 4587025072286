import React from 'react';

import { Button, ModalTitle, Spacer, Text, useTheme } from '@masteos/aphrodite';

import { useTranslation } from '@app/services/translations/translations';

import { CancelPositioningThanksStepProps } from './CancelPositioningThanksStep.types';

export const CancelPositioningThanksStep: React.FC<CancelPositioningThanksStepProps> = ({
  isSuggestion,
  onClose,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const descriptionTranslationKey = isSuggestion
    ? 'suggestions.actions.notInterested.feedback.descriptionForSuggestion'
    : 'suggestions.actions.notInterested.feedback.description';

  return (
    <>
      <ModalTitle title={t('suggestions.actions.notInterested.feedback.title')} />
      <Text textStyle="Body3">{t(descriptionTranslationKey)}</Text>
      <Spacer height={theme.spacing.SIZE_08} />
      <Button
        variant="primary"
        onPress={onClose}
        testID="suggestions-actions-not-interested-feedback-button"
      >
        {t('suggestions.actions.notInterested.feedback.button')}
      </Button>
    </>
  );
};
