import React, { useEffect } from 'react';

export const SplashScreen: React.FC = () => {
  useEffect(() => {
    document.getElementById('splash-screen').style.display = 'flex';

    return () => {
      document.getElementById('splash-screen').style.display = 'none';
    };
  }, []);

  return null;
};
