import React from 'react';

import { View } from 'react-native';

import { Button, Spacer, Text, useResponsive, useTheme } from '@masteos/aphrodite';

import { SearchEngineNoResultType } from '@app/features/search-engine/compounds/search-engine-no-result/searchEngineNoResult.type';
import { useTranslation } from '@app/services/translations/translations';
import { getStyle } from '@app/features/search-engine/compounds/search-engine-no-result/searchEngineNoResult.styles';

export const SearchEngineNoResult: React.FC<SearchEngineNoResultType> = ({
  section,
  onFilterShow,
  onFilterReset,
}) => {
  // FIXME: use translation (re-rendering issue)
  const { t } = useTranslation();
  const { isLowerThanLaptop } = useResponsive();
  const theme = useTheme();
  const styles = getStyle(isLowerThanLaptop, theme);

  return (
    <View style={styles.noResultContainer}>
      <Text style={styles.italic} testID={`search-engine-no-result-${section}--text`}>
        {t(`searchEngine.noResult.${section}.text`)}
      </Text>
      {section === 'all' && (
        <View style={styles.buttonContainer}>
          <Button onPress={onFilterShow} testID="search-engine-no-result-edit--button">
            {t('searchEngine.noResult.editSearchButton')}
          </Button>
          {!!isLowerThanLaptop && <Spacer height={theme.spacing.SIZE_05} />}
          <Text style={styles.orText}>{t('shared.or')}</Text>
          {!!isLowerThanLaptop && <Spacer height={theme.spacing.SIZE_05} />}
          <Button
            onPress={onFilterReset}
            testID="search-engine-no-result-reset--button"
            variant="tertiary"
          >
            {t('searchEngine.noResult.resetAllFiltersButton')}
          </Button>
        </View>
      )}
    </View>
  );
};
