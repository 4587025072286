import React, { PropsWithChildren, useMemo } from 'react';

import { Platform, SafeAreaView, ScrollView, View } from 'react-native';

import { HeaderPageContainer, useResponsive, useTheme } from '@masteos/aphrodite';

import { getStyles } from './PageLayout.styles';
import { PageLayoutProps } from './PageLayout.types';

export { PageLayoutProps };

const DEFAULT_MAX_WIDTH = 1280;

export const PageLayout: React.FC<PropsWithChildren<PageLayoutProps>> = ({
  header,
  footer,
  maxWidth = DEFAULT_MAX_WIDTH,
  children,
  delegateNativeScroll = false,
  withoutSafeAreaView = false,
}) => {
  const theme = useTheme();
  const { isLowerThanTablet } = useResponsive();
  const isNative = Platform.OS !== 'web';

  const styles = getStyles({ isLowerThanTablet, isNative, maxWidth, theme });

  const Container = useMemo(
    () => (isNative && !delegateNativeScroll ? ScrollView : ({ children: child }) => <>{child}</>),
    [delegateNativeScroll, isNative]
  );

  const SafeAreaContainer = useMemo(
    () => (withoutSafeAreaView ? ({ children: child }) => <>{child}</> : SafeAreaView),
    [withoutSafeAreaView]
  );

  const content = useMemo(
    () =>
      React.Children.map(children, (Child: JSX.Element) => {
        return Child.type.displayName === 'PageContainer'
          ? React.cloneElement(Child, {
              maxWidth,
            })
          : Child;
      }),
    [children, maxWidth]
  );

  return (
    <SafeAreaContainer style={styles.page} testID="page-layout-safe-area">
      {!!header && <HeaderPageContainer style={styles.headerPage}>{header}</HeaderPageContainer>}
      <Container testID="page-layout-container">
        <View style={styles.body} testID="page-layout-body">
          {content}
          {!!footer && (
            <View style={styles.footer} testID="page-layout-footer">
              {footer}
            </View>
          )}
        </View>
      </Container>
    </SafeAreaContainer>
  );
};
