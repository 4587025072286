import { FinancingLetterStatus, GetCustomerQuery } from '@app/libs/apollo/introspection';

import { PositioningRequiredDocuments } from './MissingDocumentModal';

export const getMissingDocumentList = (
  customer: GetCustomerQuery['me']
): PositioningRequiredDocuments[] => {
  const list: PositioningRequiredDocuments[] = [];
  if (!customer?.hasValidSearchMandate) {
    list.push(PositioningRequiredDocuments.SearchMandate);
  }
  if (customer?.financingLetterStatus === FinancingLetterStatus.None) {
    list.push(PositioningRequiredDocuments.FinancingCertificate);
  }
  return list;
};
