import React from 'react';

import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import { Spacer, useTheme } from '@masteos/aphrodite';

import { Input, SubmitButton } from '@app/shared/contexts/form/index.web';

import { getStyles } from '../authBridge.styles';

interface AuthBridgeFormProps {
  onSubmit: (e: unknown) => void;
  isLoading?: boolean;
}

export const AuthBridgeForm: React.FC<AuthBridgeFormProps> = ({ isLoading, onSubmit }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = getStyles(theme);

  return (
    <View style={styles.bottomContainer}>
      <Input
        autoCapitalize="none"
        autoComplete="email"
        autoCorrect={false}
        keyboardType="email-address"
        label={t('shared.email')}
        name="email"
        onSubmitEditing={onSubmit}
        placeholder={t('register.emailPlaceholder')}
        testID="auth-email-input"
        textContentType="emailAddress"
      />
      <Spacer height={theme.spacing.SIZE_07} />
      <SubmitButton
        variant="primary"
        testID="auth-submit-button"
        isLoading={isLoading}
        onPress={onSubmit}
      >
        {t('authTunnel.emailAuthButton')}
      </SubmitButton>
    </View>
  );
};
