import { Linking, Platform } from 'react-native';

import { navigationRef } from '../navigationRef';
import { RealEstateNavigatorRoutes } from '../types/routes';

export const getParamsFromUrl = (url: string): string => {
  const [, params] = url.split('/property/');

  if (params) {
    const [propertyId] = params.split('?');
    return propertyId;
  }
  return;
};

export function subscribe(listener: (deeplink: string) => void): () => void {
  if (Platform.OS === 'web') {
    return null;
  }

  const onReceiveURL = ({ url }: { url: string }) => {
    if (url) {
      const propertyId = getParamsFromUrl(url);

      if (propertyId && propertyId !== '' && navigationRef.isReady()) {
        setTimeout(() => {
          navigationRef.navigate(RealEstateNavigatorRoutes.RealEstate, { id: propertyId });
        }, 2000);
      }
    }
    listener(url);
  };

  Linking.getInitialURL().then(url => {
    onReceiveURL({ url });
  });
  const subscription = Linking.addEventListener('url', onReceiveURL);
  return () => {
    subscription.remove();
  };
}
