import React from 'react';

import { CardBanner, useTheme, IconPopoverOrModal } from '@masteos/aphrodite';

import { trackTooltip } from '@app/services/tracking/trackTracking';
import { useTranslation } from '@app/services/translations/translations';

import { BannerProps, RealEstateCardBannerType } from './realEstateCardBanner.type';
import { getBannerProps } from './realEstateCardBanner.utils';

export const RealEstateCardBanner: React.FC<RealEstateCardBannerType> = ({
  isInTransaction,
  positioningStatus,
  propertyId,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  let banner: BannerProps;
  /** Retro compatibility for the RealEstate in transaction that have non positioned customers */
  if (isInTransaction) {
    banner = {
      color: theme.palette.system.secondary[600],
      testID: 'real-estate-card-banner-inTransaction',
      text: 'propertyPreview.banner.transactionTitle',
      tooltip: {
        content: 'propertyPreview.banner.tooltipTransactionDesc',
        title: 'propertyPreview.banner.tooltipTransactionTitle',
        tooltipType: 'Bien en transaction',
      },
    };
  } else {
    if (positioningStatus) {
      banner = getBannerProps(theme)[positioningStatus];
    }
  }

  const trackTooltipBanner = () =>
    trackTooltip({ propertyId, tooltipType: banner.tooltip.tooltipType });

  return (
    !!banner && (
      <CardBanner color={banner.color} testID={banner.testID} text={t(banner.text)}>
        {!!banner.tooltip && (
          <IconPopoverOrModal
            title={t(banner.tooltip.title)}
            content={t(banner.tooltip.content)}
            iconName="Info"
            variant="inDark"
            iconColor={theme.palette.base.white}
            onOpen={trackTooltipBanner}
          />
        )}
      </CardBanner>
    )
  );
};
