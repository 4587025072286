import React from 'react';

import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

import { ActionButton, Spacing, useResponsive } from '@masteos/aphrodite';

import { Visit3DButtonProps } from './withVisit3D';

const getStyles = (isLowerThanTablet: boolean) =>
  StyleSheet.create({
    container: {
      bottom: Spacing.SIZE_04,
      position: 'absolute',
      right: isLowerThanTablet ? Spacing.SIZE_02 : Spacing.SIZE_04,
    },
  });

export const VirtualVisitButton: React.FC<Visit3DButtonProps> = ({ onPress }) => {
  const { isLowerThanTablet } = useResponsive();
  const styles = getStyles(isLowerThanTablet);
  const { t } = useTranslation();

  return (
    <View style={styles.container}>
      <ActionButton
        leftIconName="Cube"
        onPress={onPress}
        testID="virtual-visit-button"
        label={t('shared.virtualVisitLabel')}
        size="m"
      />
    </View>
  );
};
