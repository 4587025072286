import React, { useCallback, useMemo } from 'react';

import { Platform, StyleProp, StyleSheet, TextStyle, View } from 'react-native';

import { palette, Spacing, Switch, Text, TextProps, IconPopoverOrModal } from '@masteos/aphrodite';

import { PlatformEnum } from '@app/types/platform';

export enum Importance {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
}

export interface PropertyAttributeProps {
  label?: string;
  value?: string | number;
  isOdd?: boolean;
  tooltip?: { title?: string; description: string; onOpen: () => void };
  inputSwitch?: {
    value: boolean;
    setValue: (v: boolean) => void;
    disabled?: boolean;
    testID?: string;
  };
  importance?: Importance;
  testID?: string;
}
const styles = StyleSheet.create({
  commonText: {
    flexShrink: 1,
    flexWrap: 'wrap',
  },
  container: {
    flex: 1,
    paddingBottom: Spacing.SIZE_04,
    paddingTop: Spacing.SIZE_04,
  },
  lineLabel: {
    alignItems: 'center',
    flexDirection: 'row',
    flexGrow: 1,
    width: 0,
  },
  tooltipWrapper: {
    paddingHorizontal: Spacing.SIZE_03,
    paddingVertical: Spacing.SIZE_02,
  },
  valueWrapper: { alignItems: 'flex-end', justifyContent: 'flex-end', marginLeft: Spacing.SIZE_09 },
  wrapper: {
    alignItems: 'flex-start',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

export const PropertyAttribute: React.FC<PropertyAttributeProps> = ({
  label,
  value,
  isOdd,
  tooltip,
  inputSwitch,
  importance = Importance.MEDIUM,
  testID,
}) => {
  const getTextProps: () => {
    labelProps: {
      textStyle: TextProps['textStyle'];
      style?: StyleProp<TextStyle>;
    };
    valueProps: {
      textStyle: TextProps['textStyle'];
      style?: StyleProp<TextStyle>;
    };
  } = useCallback(() => {
    if (importance === Importance.LOW) {
      return {
        labelProps: {
          textStyle: 'Body2',
        },
        valueProps: {
          textStyle: 'Body2',
        },
      };
    }

    if (importance === Importance.MEDIUM) {
      return {
        labelProps: {
          style: { color: palette.neutral[900] },
          textStyle: 'Body2',
        },
        valueProps: {
          style: { color: palette.neutral[900] },
          textStyle: 'Body2Medium',
        },
      };
    }

    return {
      labelProps: {
        style: { color: palette.neutral[900] },
        textStyle: Platform.select({ native: 'Body2Medium', web: 'Headline3Medium' }),
      },
      valueProps: {
        style: { color: palette.neutral[900] },
        textStyle: 'Body2Medium',
      },
    };
  }, [importance]);

  const { labelProps, valueProps } = useMemo(() => getTextProps(), [getTextProps]);

  return useMemo(() => {
    if (!label && !value) {
      return null;
    }

    return (
      <View style={styles.container}>
        <View
          style={[
            {
              backgroundColor:
                isOdd && Platform.OS === PlatformEnum.Web
                  ? palette.neutral[50]
                  : palette.base.white,
            },
            styles.wrapper,
          ]}
        >
          <View style={styles.lineLabel}>
            {inputSwitch ? (
              <Switch
                checked={inputSwitch.value}
                onCheck={inputSwitch.setValue}
                disabled={inputSwitch.disabled ?? false}
                label={label}
                testID={inputSwitch.testID}
              />
            ) : (
              !!label && (
                <Text {...labelProps} style={[labelProps?.style, styles.commonText]}>
                  {label}
                </Text>
              )
            )}
            {!!tooltip && (
              <View style={styles.tooltipWrapper}>
                <IconPopoverOrModal
                  title={tooltip?.title}
                  content={tooltip.description}
                  iconName="Info"
                  iconSize="s"
                  onOpen={tooltip.onOpen}
                />
              </View>
            )}
          </View>

          <View style={styles.valueWrapper}>
            {value ? (
              <Text {...valueProps} testID={testID}>
                {value}
              </Text>
            ) : null}
          </View>
        </View>
      </View>
    );
  }, [label, value, inputSwitch]);
};
