import { Platform, StyleSheet } from 'react-native';

import { Theme } from '@masteos/aphrodite';

export const getStyleSheet = (focused: boolean, isLowerThanDesktop: boolean, theme: Theme) =>
  StyleSheet.create({
    animatedBorder: {
      backgroundColor: theme.palette.system.primary[500],
      height: 1,
      left: 0,
      position: 'absolute',
      right: 0,
      top: Platform.select({ native: 46, web: isLowerThanDesktop ? 47 : 64 }),
    },
    label: {
      color: focused ? theme.palette.system.primary[500] : theme.palette.neutral[900],
      padding: theme.spacing.SIZE_01,
    },
    tabLabel: {
      alignItems: 'center',
      flex: 1,
      justifyContent: 'center',
      overflow: 'hidden',
      position: 'relative',
    },
  });
