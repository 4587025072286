import { StyleSheet } from 'react-native';

import { Theme } from '@masteos/aphrodite';

export const getStyles = (theme: Theme): ReturnType<typeof StyleSheet.create> =>
  StyleSheet.create({
    descriptionContainer: {
      flexDirection: 'column',
      wordBreak: 'break-word',
    },
    synthesisContainer: {
      borderRadius: theme.borderRadius.SIZE_06,

      flexDirection: 'column',
      padding: theme.spacing.SIZE_07,
    },
  });
