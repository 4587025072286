import { useEffect } from 'react';

import { Platform } from 'react-native';

import { useUtmValues } from '@app/shared/hooks/useUtmValues.web';
import { useQueryParam } from '@app/shared/hooks/useQueryParams';
import { storage } from '@app/services/storage/storage';
import { useOrigin } from '@app/shared/hooks/useOrigin';
import { PlatformEnum } from '@app/types/platform';

export const useAuthBridgeInit = (): void => {
  useUtmValues();
  useOrigin();

  const sponsorEmail = useQueryParam('sponsor');

  useEffect(() => {
    if (sponsorEmail && Platform.OS === PlatformEnum.Web) {
      storage.saveString('sponsorEmail', sponsorEmail.replace(' ', ''));
    }
  }, [sponsorEmail]);
};
