import React from 'react';

import { SwiperModal, SwiperModalProps } from './SwiperModal.web';

interface ControlledSwiperModalProps extends SwiperModalProps {
  isOpen: boolean;
}

export const ControlledSwiperModal: React.FC<ControlledSwiperModalProps> = ({
  isOpen,
  isHeaderGallery,
  ...props
}) => {
  if (!isOpen) {
    return null;
  }

  return <SwiperModal {...props} isOpen={isOpen} isHeaderGallery={isHeaderGallery} />;
};
