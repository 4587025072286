import React from 'react';

import { Platform, StyleSheet, View } from 'react-native';

import { Icon, palette, Pressable, Spacer, Spacing, Text } from '@masteos/aphrodite';

interface DebugMenuHeaderProps {
  title: string;
  onPress?: () => void;
  subtitle?: string;
  withIcon?: boolean;
  onIconPress?: () => void;
}

const getStyles = (isMobile: boolean) =>
  StyleSheet.create({
    title: { alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' },
    wrapper: { marginTop: 20, paddingHorizontal: isMobile ? 20 : 0 },
  });

export const DebugMenuHeader = ({
  title,
  onPress,
  subtitle,
  withIcon,
  onIconPress,
}: DebugMenuHeaderProps): JSX.Element => {
  const isMobile = Platform.OS === 'ios' || Platform.OS === 'android';
  const styles = getStyles(isMobile);

  return (
    <View style={styles.wrapper}>
      {!!isMobile && (
        <Pressable onPress={onPress} testID="touchable">
          <Icon name="ArrowLeft" size={20} color={palette.base.black} />
        </Pressable>
      )}

      <Spacer height={Spacing.SIZE_03} />
      <View style={styles.title}>
        <Text textStyle="Headline1Demi" style={{ color: palette.base.black }}>
          {title}
        </Text>
        {!!withIcon && (
          <Pressable onPress={onIconPress}>
            <Icon name="Info" opacity={0.5} size={17} />
          </Pressable>
        )}
      </View>

      <Text textStyle={isMobile ? 'Caption1' : 'Body1Demi'} style={{ color: palette.neutral[700] }}>
        {subtitle}
      </Text>

      <Spacer height={Spacing.SIZE_05} />
    </View>
  );
};
