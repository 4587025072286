import React, { useCallback, useMemo } from 'react';

import { GestureResponderEvent, View } from 'react-native';

import {
  useTheme,
  Card,
  Image,
  ChipList,
  CardInformationType,
  BaseLink,
  useContainerQueriesHook,
  Gap,
  LikeButton,
  Text,
  Chip,
} from '@masteos/aphrodite';

import { formatRate } from '@app/utils/format-rate';
import { RealEstateCardCardType } from '@app/shared/components/real-estate-card/realEstateCard.type';
import { useRealEstateChips } from '@app/shared/hooks/useRealEstateChips';
import { useRegionTranslation } from '@app/shared/hooks/useRegionTranslations';
import { currencyFormatter } from '@app/utils/currency-formatter';
import { getStatusRealEstate } from '@app/shared/components/real-estate-card/utils/get-status-realEstate';

import { getStyle } from './realEstateCard.styles';
import { RealEstateCardBanner } from './real-estate-card-banner/RealEstateCardBanner';
import { RealEstateCardInformation } from './real-estate-card-information/RealEstateCardInformation';

const query = { smallCard: 265 };

export const RealEstateCard: React.FC<RealEstateCardCardType> = ({
  id,
  image,
  location,
  isViewed,
  isNew,
  isExclusive,
  type,
  bedrooms,
  lots,
  surface,
  netReturnRate,
  grossReturnRate,
  price,
  region,
  version,
  isBookmarked,
  positioningStatus,
  isInTransaction,
  onClick,
  onBookmark,
  onTooltipHover,
}) => {
  const theme = useTheme();
  // FIXME: useTranslation (re-rendering issue)
  const { t } = useRegionTranslation(region);
  const realEstateChips = useRealEstateChips({
    building: { address: { city: location }, totalLots: lots },
    roomCount: bedrooms,
    surface,
    type,
  });
  const {
    matches: { smallCard },
    onLayout,
  } = useContainerQueriesHook({ query });

  const styles = useMemo(() => getStyle({ isNew, isViewed, theme }), [isViewed, isNew, theme]);

  const chipList = useMemo(
    () => [
      { text: realEstateChips.type },
      { text: realEstateChips.bedrooms },
      { text: realEstateChips.lots },
      { text: realEstateChips.surface },
    ],
    [realEstateChips]
  );

  const netReturnTooltip: CardInformationType['tooltip'] = useMemo(
    () => ({
      content: t('propertyPreview.tooltip.netReturnDescription', {
        context: `v${version}`,
      }),
      testID: 'real-estate-card-net-return-tooltip',
      title: t('propertyPreview.tooltip.netReturn'),
    }),
    [t, version]
  );

  const grossReturnTooltip: CardInformationType['tooltip'] = useMemo(
    () => ({
      content: t('propertyPreview.tooltip.grossReturnDescription', {
        context: `v${version}`,
      }),
      testID: 'real-estate-card-gross-return-tooltip',
      title: t('propertyPreview.grossReturn'),
    }),
    [t, version]
  );

  const handleActionPress = useCallback(
    (e: GestureResponderEvent) => {
      e?.preventDefault();
      onBookmark();
    },
    [onBookmark]
  );

  const onTooltipHoverNetReturnRate = () => onTooltipHover('netReturn');
  const onTooltipHoverGrossReturnRate = () => onTooltipHover('grossReturn');

  const rightChip = useMemo(() => {
    const chip = getStatusRealEstate({ isExclusive, isNew });
    return chip ? (
      <Chip
        style={styles.chipItemRight}
        text={t(chip.text)}
        tint={chip.tint}
        iconName={chip.iconName}
      />
    ) : null;
  }, [isNew, isExclusive, styles.chipItemRight, t]);

  return (
    <View onLayout={onLayout} testID="RealEstateCard">
      <BaseLink href={`property/${id}`} onClick={onClick}>
        <Card testID="real-estate-card--container">
          <Card.Header>
            <View style={styles.chipContainer}>
              <Chip style={styles.chipItemLeft} text={location} tint="white" iconName="Location" />
              {rightChip}
            </View>

            <Image source={{ uri: image }} style={styles.cardContainer} />

            <RealEstateCardBanner
              propertyId={id}
              isInTransaction={isInTransaction}
              positioningStatus={positioningStatus}
            />
          </Card.Header>
          <Card.Content noSpacer>
            <ChipList chips={chipList} />
            <Card.CardList>
              <RealEstateCardInformation
                onTooltipHover={onTooltipHoverNetReturnRate}
                text={t('propertyPreview.grossReturn')}
                value={formatRate(grossReturnRate)}
                tooltip={grossReturnTooltip}
              />
              <RealEstateCardInformation
                onTooltipHover={onTooltipHoverGrossReturnRate}
                text={t('propertyPreview.netReturn')}
                value={formatRate(netReturnRate)}
                tooltip={netReturnTooltip}
              />
            </Card.CardList>
            <View style={styles.footer}>
              <Gap style={styles.footerPrice}>
                <Text textStyle="Headline3Medium">{currencyFormatter(price, 0)}</Text>
                {!smallCard ? (
                  <Text textStyle="Caption1" style={styles.textInfo}>
                    {t('propertyPreview.projectCost')}
                  </Text>
                ) : null}
              </Gap>
              <View style={styles.footerBookmark}>
                <LikeButton
                  liked={isBookmarked}
                  onPress={handleActionPress}
                  testID="real-estate-card-bookmark--button"
                  size="s"
                />
              </View>
            </View>
          </Card.Content>
        </Card>
      </BaseLink>
    </View>
  );
};
