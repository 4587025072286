import { SearchEngineFilterForm } from '@app/features/search-engine/searchEngine.types';

import { SearchEngineSortStrategy } from '../search-engine-sort/SearchEngineSort.types';

export const getDefaultFormValues = (
  opts: {
    capacity?: number;
    sortStrategy?: SearchEngineSortStrategy;
  } = {}
): SearchEngineFilterForm => {
  return {
    alreadyRented: null,
    atGroundFloor: null,
    budgetMax: opts.capacity ?? null,
    budgetMin: 0,
    country: null,
    flatSharing: null,
    regionList: [],
    roomCount: [],
    sortStrategy: opts.sortStrategy ?? null,
    typology: [],
    withRenovation: null,
  };
};
