import { useCallback, useMemo } from 'react';

import { ListItemUnionProps } from '@masteos/aphrodite';

import { useTranslation } from '@app/services/translations/translations';
import { AvailableCountryCode } from '@app/libs/apollo/introspection';

import { getBudgetMaxRules, getBudgetMinRules } from '../utils/get-budget-rules';
import { getRoomList } from '../utils/get-room-list';
import { getCountryOptionList } from '../utils/get-country-option-list';
import { getRegionOptionList } from '../utils/get-region-option-list';
import { getRegionListValue } from '../utils/get-region-list-value';
import { getAllRegionOption } from '../utils/get-all-region-option';
import { BudgetRules, RadioLabels, Room } from '../searchEngineFilters.type';

interface UseSearchEngineFormFilterProps {
  budgetMaxRules: BudgetRules;
  budgetMinRules: BudgetRules;
  countryOptionList: ListItemUnionProps[];
  radioLabels: RadioLabels;
  regionListValue: (
    value: string[],
    regionOptionList: ListItemUnionProps[]
  ) => ListItemUnionProps[];
  regionOptionList: ListItemUnionProps[];
  roomList: Room[];
}

export const useSearchEngineFormFilter = (
  countryList: AvailableCountryCode[],
  regionListByCountry: Record<string, string[]>,
  capacity: number,
  getValues,
  watch
): UseSearchEngineFormFilterProps => {
  const { t } = useTranslation();

  const budgetMinRules = useMemo(() => getBudgetMinRules(getValues, t), [getValues, t]);
  const budgetMaxRules = useMemo(() => getBudgetMaxRules(capacity, t), [capacity, t]);

  const roomList = useMemo(() => getRoomList(t), [t]);

  const countryOptionList = useMemo<ListItemUnionProps[]>(
    () => getCountryOptionList(countryList, t),
    [countryList, t]
  );

  const allRegionOption = useMemo<ListItemUnionProps>(() => getAllRegionOption(t), [t]);
  const watchCountry = watch('country');

  const regionOptionList = useMemo<ListItemUnionProps[]>(
    () => getRegionOptionList(watchCountry, regionListByCountry, allRegionOption),
    [allRegionOption, regionListByCountry, watchCountry]
  );

  const regionListValue = useCallback(getRegionListValue, [regionOptionList]);

  const radioLabels = {
    false: t('propertiesPreferences.no'),
    null: t('propertiesPreferences.noMatter'),
    true: t('propertiesPreferences.yes'),
  };

  return {
    budgetMaxRules,
    budgetMinRules,
    countryOptionList,
    radioLabels,
    regionListValue,
    regionOptionList,
    roomList,
  };
};
