import { Platform, StyleProp, StyleSheet, ViewStyle } from 'react-native';

import { HORIZONTAL_PADDING } from '@app/features/project/Project.styles';

const CONTENT_PADDING = {
  DEFAULT: 32,
  DESKTOP: 40,
};
const TABVIEW_CONTENT_PADDING = 16;

export const getProjectTabScreenStyles: ({
  isLowerThanTablet,
  isLowerThanLaptop,
}: {
  isLowerThanTablet: boolean;
  isLowerThanLaptop: boolean;
}) => Record<string, StyleProp<ViewStyle>> = ({ isLowerThanTablet, isLowerThanLaptop }) =>
  StyleSheet.create({
    container: {
      paddingHorizontal: Platform.select({
        native: isLowerThanTablet ? HORIZONTAL_PADDING.MOBILE : 0,
      }),
      paddingTop:
        (isLowerThanLaptop ? CONTENT_PADDING.DEFAULT : CONTENT_PADDING.DESKTOP) -
        TABVIEW_CONTENT_PADDING,
    },
  });
