import React from 'react';

import { TranslationContentGroup } from '@masteos/aphrodite';

import { TRANSLATION_SERVICE } from '@app/constants/constants';
import { useTranslation } from '@app/services/translations/translations';

interface TranslatableWithSwitchWrapperProps {
  hasTranslatedText: boolean;
  children: React.ReactNode;
}

export const TranslatableWithSwitchWrapper: React.FC<TranslatableWithSwitchWrapperProps> = ({
  children,
  hasTranslatedText,
}) => {
  const { t } = useTranslation();

  return (
    <TranslationContentGroup initialState={{ showTranslation: hasTranslatedText }}>
      {children}
      {!!hasTranslatedText && (
        <TranslationContentGroup.Switch
          translatedBy={t('shared.translatedBy', { providerName: TRANSLATION_SERVICE })}
          showOriginal={t('shared.showOriginal')}
          showTranslated={t('shared.showTranslated')}
        />
      )}
    </TranslationContentGroup>
  );
};
