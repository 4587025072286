import { FinancialModel, ID } from '@masteos/agora';

import * as segment from '@app/libs/segment/segment';
import { RealEstate } from '@app/libs/apollo/introspection';
import { SignInMethod } from '@app/features/authBridge/authBridge.constants';
import {
  AdvisorNavigatorRoutes,
  DebugMenuNavigatorRoutes,
  ForgotPasswordNavigatorRoutes,
  MainMenuNavigatorRoutes,
  ProfileNavigatorRoutes,
  ProjectNavigatorRoutes,
  PublicNavigatorRoutes,
  RealEstateNavigatorRoutes,
  Routes,
  SuggestionNavigatorRoutes,
} from '@app/navigation/types/routes';

import { getRealEstateBadgeType } from './utils';

/** List of screens not controlled by navigation */
export enum NativeScreenName {
  VIEW_PROFILE_DOCUMENT = '/profile/document',
  ERROR = '/error',
  FAVORITES = '/properties/favorites',
  PRESELECTION = '/properties/selected',
  FORGOT_PASSWORD_CODESTEP = '/forgot-password/codeStep',
  FORGOT_PASSWORD_NEW_PASSWORD = '/forgot-password/newPassword',
  PROJECT = 'projectPage',
  PROJECT_URL = '/projects',
  PROJECTS = 'projectListPage',
  LOGIN = '/login',
  AUTH = '/auth',
  ONBOARDING = 'welcome',
}

export const trackChangeScreen = (
  pageId: Routes | NativeScreenName,

  params: {
    property?: RealEstate;
    method?: SignInMethod;
    financialModel?: FinancialModel;
    document?: { id: string; category: string };
    count?: number;
    isFromNotifications?: boolean;
    isFromSearchEngine?: boolean;
    id?: ID;
    propertyPosition?: number;
    project?: { id: string; status?: string };
    projectNumber?: number;
    eventName?: string;
  } = {}
): void => {
  switch (pageId) {
    case ProfileNavigatorRoutes.ProfileRoot:
      segment.screen(`View profile page`);
      break;

    case AdvisorNavigatorRoutes.AdvisorRoot:
      segment.screen(`View advisor page`);
      break;

    case PublicNavigatorRoutes.Register:
      segment.screen('View registration', { method: params.method });
      break;

    case RealEstateNavigatorRoutes.RealEstateList:
      segment.screen(`View matching page`);
      break;

    case RealEstateNavigatorRoutes.RealEstate:
      if (params.property && params.financialModel) {
        segment.screen('View property page', {
          annualGrossReturn: params.financialModel.grossReturn,
          annualRevaluationRate: params.property.project.renting.annual.revaluationRate,
          city: params.property.building.address.city,
          investStrategy: params.property.project.strategy,
          isARecommandation: !!params.property?.metadata?.positioning?.isSuggestion,
          isFromNotifications: params.isFromNotifications,
          locationType: params.property.project.renting.type,
          projectAmount: params.financialModel.projectAmount,
          propertyId: params.property.id,
          propertyPosition: params.propertyPosition,
          region: params.property?.building.address?.regionName,
          renovationAmount: params.property.project.renovation.amount,
          type: params.property.type,
          ...(params.isFromSearchEngine && {
            badgeType: getRealEstateBadgeType(params.property.isNew, params.property.isExclusive),
          }),
        });
      }
      break;

    case ProfileNavigatorRoutes.ProfileDocumentsList:
      segment.screen('View profile document');
      break;

    case ProfileNavigatorRoutes.ProfilePassword:
      segment.screen('View profile password');
      break;

    case NativeScreenName.VIEW_PROFILE_DOCUMENT:
      segment.screen('View a document', {
        documentCategory: params.document?.category,
        documentId: params.document?.id,
      });
      break;

    case ProfileNavigatorRoutes.ProfileSponsorship:
      segment.screen('View profile sponsorship');
      break;

    case NativeScreenName.ERROR:
      segment.screen('View error page');
      break;

    case NativeScreenName.FAVORITES:
      segment.screen('View favorites page', { count: params.count || 0 });
      break;

    case ProfileNavigatorRoutes.ProfileInformation:
      segment.screen('View profile information');
      break;

    case PublicNavigatorRoutes.ForgotPassword:
      segment.screen('View forgotten password');
      break;

    case ForgotPasswordNavigatorRoutes.ForgotPasswordCodeStep:
      segment.screen('View forgotten code');
      break;

    case ForgotPasswordNavigatorRoutes.ForgotPasswordNewPasswordStep:
      segment.screen('View forgotten confirmed password');
      break;

    case ProjectNavigatorRoutes.Project:
      segment.screen('Project Detail', {
        projectId: params.project?.id,
        projectStatus: params.project?.status,
        propertyId: params.property?.id,
      });
      break;

    case MainMenuNavigatorRoutes.Projects:
      segment.screen('Project Menu page', {
        projectNumber: params.projectNumber,
      });
      break;

    case PublicNavigatorRoutes.Login:
      segment.screen('View login form page');
      break;

    case PublicNavigatorRoutes.Auth:
      segment.screen('View auth form page');
      break;
    case NativeScreenName.ONBOARDING:
      params.eventName && segment.screen(params.eventName);
      break;
    case ProfileNavigatorRoutes.ProfileNotificationPreferences:
      segment.screen('View profile notification settings');
      break;

    case SuggestionNavigatorRoutes.SuggestionList:
      segment.screen('View suggestions page');
      break;

    case MainMenuNavigatorRoutes.RealEstates:
    case AdvisorNavigatorRoutes.AdvisorAppointment:
    case DebugMenuNavigatorRoutes.DebugMenuRoot:
      return;
    default:
      // we hide console on production thanks to "babel-plugin-transform-remove-console"
      console.log(`${pageId} [Tracking] route doesn't exist`, { pageId, params });
      break;
  }
};
