import React, { useMemo } from 'react';

import { ListItem } from '@masteos/aphrodite';

import { useTranslation } from '@app/services/translations/translations';

import { PositioningCancelReasonListProps } from './PositioningCancelReasonList.types';
import { getSortedPositioningCancelReasons } from './PositioningCancelReasonList.utils';

export const PositioningCancelReasonList: React.FC<PositioningCancelReasonListProps> = ({
  selectedReasons,
  handlePressReason,
}) => {
  const { t } = useTranslation();

  const reasons = useMemo(() => {
    return getSortedPositioningCancelReasons().map(key => ({
      description: t(`positioning.customerReasons.${key}.description`),
      key,
      label: t(`positioning.customerReasons.${key}.label`),
    }));
  }, [t]);

  return (
    <>
      {reasons.map(({ key, label, description }) => (
        <ListItem
          key={key}
          checkbox
          checked={selectedReasons.has(key)}
          label={label}
          description={description}
          onPress={() => handlePressReason(key)}
        />
      ))}
    </>
  );
};
