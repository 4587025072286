import React from 'react';

import { createStackNavigator } from '@react-navigation/stack';

import { ProfileDocument } from '@app/features/profile/compounds/profileDocuments/ProfileDocument.web';
import { Profile } from '@app/features/profile/Profile';

import { ProfileNavigatorRoutes, ProfileStackParamList } from './types/routes';
import { SCREEN_OPTIONS } from './constants/navigation-options';

const Stack = createStackNavigator<ProfileStackParamList>();
export const ProfileNavigator: React.FC = () => (
  <Stack.Navigator
    initialRouteName={ProfileNavigatorRoutes.ProfileRoot}
    screenOptions={SCREEN_OPTIONS}
  >
    <Stack.Screen name={ProfileNavigatorRoutes.ProfileRoot} component={Profile} />
    <Stack.Screen name={ProfileNavigatorRoutes.ProfileDocument} component={ProfileDocument} />
  </Stack.Navigator>
);
