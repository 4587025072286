import React from 'react';

import { View } from 'react-native';

import { Section, SectionItem, Spacing, useResponsive } from '@masteos/aphrodite';

import { useTranslation } from '@app/services/translations/translations';

interface AdvisorCallToActionProps {
  onCallClick: () => void;
  onMailClick: () => void;
  onWhatsappClick: () => void;
  phoneNumber: string | null;
}

export const AdvisorCallToAction: React.FC<AdvisorCallToActionProps> = ({
  onCallClick,
  onMailClick,
  onWhatsappClick,
  phoneNumber,
}) => {
  const { t } = useTranslation();
  const { isLowerThanLaptop } = useResponsive();
  const paddingHorizontalValue = isLowerThanLaptop ? Spacing.SIZE_06 : Spacing.NONE;
  return (
    <View>
      <Section>
        {phoneNumber ? (
          <>
            <SectionItem
              isFirst
              contentStyle={{
                paddingHorizontal: paddingHorizontalValue,
              }}
              icon="Phone"
              title={t('advisor.callSectionTitle')}
              onPress={onCallClick}
            />
            <SectionItem
              contentStyle={{ paddingHorizontal: paddingHorizontalValue }}
              icon="Chat"
              title={t('advisor.whatsAppSectionTitle')}
              onPress={onWhatsappClick}
            />
          </>
        ) : null}
        <SectionItem
          contentStyle={{ paddingHorizontal: paddingHorizontalValue }}
          icon="Mail"
          title={t('advisor.mailSectionTitle')}
          onPress={onMailClick}
        />
      </Section>
    </View>
  );
};
