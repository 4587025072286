import React, { useState } from 'react';

import { StyleSheet, View } from 'react-native';
import { StackScreenProps } from '@react-navigation/stack';

import { Button, Input, Spacing, Text } from '@masteos/aphrodite';

import { useTranslation } from '@app/services/translations/translations';
import { isValidEmail } from '@app/utils/validators';
import {
  ForgotPasswordNavigatorRoutes,
  ForgotPasswordStackParamList,
} from '@app/navigation/types/routes';
import { trackForgotPasswordGetCode } from '@app/services/tracking/trackTracking';
import { identityClient } from '@app/libs/identity/identityClient';

import { FormStyleSheets } from '../forgotPassword.styles.web';
import { ForgotPasswordStepWrapper } from './ForgotPasswordStepWrapper.web';

type EmailStepScreenProp = StackScreenProps<
  ForgotPasswordStackParamList,
  ForgotPasswordNavigatorRoutes.ForgotPasswordEmailStep
>;

const styles = StyleSheet.create({
  button: {
    bottom: 0,
    position: 'absolute',
  },
  container: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
  text: {
    paddingBottom: Spacing.SIZE_10,
    textAlign: 'center',
  },
});

export const EmailStep: React.FC<EmailStepScreenProp> = ({ route, navigation }) => {
  const emailFromParams = route.params?.email?.toLowerCase().trim();
  const [email, setEmail] = useState(emailFromParams || '');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>(undefined);
  const { t, i18n } = useTranslation();
  const language = i18n.resolvedLanguage;

  const submit = () => {
    setLoading(true);
    identityClient
      .forgotPassword({ email, language })
      .then(() => {
        trackForgotPasswordGetCode(email);
        navigation.navigate(ForgotPasswordNavigatorRoutes.ForgotPasswordCodeStep, { email });
      })
      .catch(() => setError('forgotPassword.error.email'))
      .finally(() => {
        setLoading(false);
      });
  };

  const emailIsNotValid = !isValidEmail(email);

  return (
    <ForgotPasswordStepWrapper>
      <View style={styles.container}>
        <Text textStyle="Body1" style={styles.text}>
          {t('forgotPassword.passwordText')}
        </Text>
        <Input
          data-e2e="input-email"
          onChangeText={setEmail}
          placeholder={t('forgotPassword.emailPlaceholder')}
          value={email}
          label={t('forgotPassword.emailLabel')}
          wrapperStyle={FormStyleSheets.input}
          error={t(error)}
          onSubmitEditing={submit}
        />

        <View style={styles.button}>
          <Button
            variant="primary"
            onPress={submit}
            style={FormStyleSheets.button}
            isLoading={loading}
            disabled={emailIsNotValid}
          >
            {t('forgotPassword.sendButton')}
          </Button>
        </View>
      </View>
    </ForgotPasswordStepWrapper>
  );
};
