import React, { useContext, useMemo, useState } from 'react';

import { Platform, View } from 'react-native';
import { Trans } from 'react-i18next';
import { startCase } from 'lodash';

import { Button, LoaderDefault, Text, useResponsive, useTheme } from '@masteos/aphrodite';

import { WebView } from '@app/shared/components/WebView/WebView';
import { trackMandateSignInSigned } from '@app/services/tracking/trackTracking';
import { storage } from '@app/services/storage/storage';
import { SignInModalProps } from '@app/shared/components/Modals/in-app-sign-in/InAppSignInModal.types';
import * as ErrorMonitoring from '@app/services/errorMonitoring';
import { ModalContext } from '@app/shared/contexts/modal-manager/ModalManagerProvider';
import { useTranslation } from '@app/services/translations/translations';
import { PlatformEnum } from '@app/types/platform';
import { useUpdateSearchMandateStatusMutation } from '@app/libs/apollo/introspection';
import { getApolloClient } from '@app/libs/apollon/apollonClient';
import { useMe } from '@app/shared/hooks/useMe';

import { getStyles } from './signInModal.styles';

// @todo: Hack to handle message from iframe => webView, to remove when Pandadoc will fix the issue
const injectedJs = `
  window.addEventListener("message", receiveMessage, false);
  function receiveMessage(event) {
    window.ReactNativeWebView.postMessage(JSON.stringify(event.data));
  };
`;

/**
 * @description Step 2/3 of Sign in process
 */
export const SignInModal: React.FC<SignInModalProps> = ({ createPandadocLink, handleNextStep }) => {
  const [updateSearchMandateStatus] = useUpdateSearchMandateStatusMutation();
  const [canShownButton, setCanShownButton] = useState<boolean>(false);
  const theme = useTheme();
  const { cache } = getApolloClient();
  const { customer } = useMe();
  const { isLowerThanLaptop } = useResponsive();
  const { setTitle } = useContext(ModalContext);
  const { t } = useTranslation();

  const firstName = startCase(customer.firstName);
  const isWeb = Platform.OS === PlatformEnum.Web;

  const styles = useMemo(
    () => getStyles({ canShownButton, isLowerThanLaptop, isWeb, theme }),
    [canShownButton, isLowerThanLaptop, isWeb, theme]
  );

  const onMessage = async pandaDocData => {
    const { type, payload } = pandaDocData ?? {};

    switch (type) {
      case 'session_view.document.completed':
        handleNextStep();

        await updateSearchMandateStatus();
        cache.modify({
          fields: { searchMandate: mandate => ({ ...mandate, valid: true }) },
          id: cache.identify({ __typename: 'Customer', id: customer.id }),
        });

        trackMandateSignInSigned();

        await storage.clear('signMandateReminder');

        setTitle(
          <Text textStyle="Title3Medium">
            <Trans
              i18nKey="modalMandateSignInCongrats.title"
              values={{ firstName }}
              components={{
                color: <Text textStyle="Title3Medium" style={styles.titleColor} />,
              }}
            />
          </Text>
        );

        setCanShownButton(true);
        break;
      case 'session_view.document.exception':
        ErrorMonitoring.logError(new Error('Session_view document exception'), { payload });
    }
  };

  return (
    <>
      {createPandadocLink?.sessionId ? (
        <WebView
          withLoader={false}
          onMessage={onMessage}
          uri={`https://app.pandadoc.com/s/${createPandadocLink?.sessionId}`}
          testID="sign-in-search-mandate-web-view"
          styles={styles.webView}
          injectedJavaScript={injectedJs}
        />
      ) : (
        <View style={styles.loaderWrapper}>
          <LoaderDefault variant="inLight" />
        </View>
      )}
      {!!canShownButton && (
        <Button testID="continue-btn-testID" onPress={handleNextStep} style={styles.button}>
          {t('shared.continue')}
        </Button>
      )}
    </>
  );
};
