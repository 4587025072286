/* eslint-disable max-lines */
import React, { useCallback, useMemo, useState } from 'react';

import { Platform, View } from 'react-native';
import { Controller } from 'react-hook-form';

import {
  ActionButton,
  DROPDOWN_CLOSE_OVERLAY,
  FilterBudgetDropdown,
  FilterListSelector,
  Gap,
  Input,
} from '@masteos/aphrodite';

import { useTranslation } from '@app/services/translations/translations';
import { inputToNumber, numberToInput } from '@app/utils/input-formatter';
import { formatOption, formatSelectorValues, selectValue } from '@app/utils/selector.utils';
import { PlatformEnum } from '@app/types/platform';
import { SearchEngineFilterForm } from '@app/features/search-engine/searchEngine.types';

import { getStyles } from './SearchEngineFiltersHorizontal.styles';
import { useSearchEngineFormFilter } from '../hooks/useSearchEngineFormFilter';
import {
  FILTER_NAME_TRACKING_KEY,
  getSingleValue,
  getPropertyTypeList,
} from './SearchEngineFiltersHorizontal.utils';
import {
  SearchEngineFilterFormHorizontal,
  SearchEngineFiltersHorizontalProps,
} from './SearchEngineFiltersHorizontal.types';
import { SearchEngineSort } from '../search-engine-sort/SearchEngineSort';

export const SearchEngineFiltersHorizontal: React.FC<SearchEngineFiltersHorizontalProps> = ({
  state,
  countryList,
  regionListByCountry,
  onSubmit,
  onSubmitError,
  onFilterShow,
  form,
  defaultValues,
  filterCount,
  capacity,
}) => {
  const [hasBudgetError, setHasBudgetError] = useState<boolean>(false);
  const styles = getStyles();

  const { t } = useTranslation();
  const countryDisabled = state === 'postSignedMandate';

  const {
    control,
    handleSubmit,
    formState: { errors, dirtyFields },
    getValues,
    reset,
    watch,
    setValue,
    resetField,
    trigger,
    setError,
    clearErrors,
  } = form;

  const { budgetMinRules, budgetMaxRules, regionOptionList, countryOptionList, regionListValue } =
    useSearchEngineFormFilter(countryList, regionListByCountry, capacity, getValues, watch);

  const valueMin = watch('budgetMin');
  const valueMax = watch('budgetMax');

  const budgetValue = useMemo(
    () => ({
      max: { label: t('propertiesPreferences.max'), value: valueMax ? valueMax.toString() : null },
      min: { label: t('propertiesPreferences.min'), value: valueMin ? valueMin.toString() : null },
    }),
    [t, valueMax, valueMin]
  );

  const submit = useCallback(
    (closeList?: () => void, field?: SearchEngineFilterFormHorizontal, resetMode = false) => {
      handleSubmit(
        v =>
          Promise.resolve(onSubmit(v, FILTER_NAME_TRACKING_KEY[field], resetMode))
            .catch(() => reset(defaultValues))
            .finally(() => {
              if (Platform.OS === PlatformEnum.Web) {
                window.scrollTo({ behavior: 'smooth', top: 0 });
              }
            }),
        v => onSubmitError?.(v)
      )();

      closeList?.();
    },
    [defaultValues, handleSubmit, onSubmit, onSubmitError, reset]
  );

  const cancel = useCallback(
    (closeList, field) => {
      setValue(field, []);
      if (field === 'country') {
        setValue('regionList', []);
      }
      submit(closeList, field, true);
    },
    [setValue, submit]
  );

  const propertyTypeList = useMemo(() => getPropertyTypeList(t), [t]);

  const onCallbackClose = useCallback(
    (source, field) => {
      const hasError = errors && Object.keys(errors).length;
      const canSubmit =
        source === DROPDOWN_CLOSE_OVERLAY && Object.keys(dirtyFields).length && !hasError;

      if (canSubmit) {
        submit(undefined, field);
      } else if (hasError) {
        Object.keys(errors).forEach((key: keyof SearchEngineFilterForm) => {
          resetField(key, { defaultValue: defaultValues[key] });
        });
      }
    },
    [defaultValues, dirtyFields, errors, resetField, submit]
  );

  const commonFilterProps = {
    buttonLabel: {
      ghost: t('propertiesPreferences.erase'),
      primary: t('shared.show'),
    },
    onPrimaryClick: submit,
  };

  const getActionProps = useCallback(
    (field: SearchEngineFilterFormHorizontal) => {
      return {
        onCallbackClose: sourceClose => onCallbackClose(sourceClose, field),
        onGhostClick: closeList => cancel(closeList, field),
        onPrimaryClick: closeList => submit(closeList, field),
      };
    },
    [submit, onCallbackClose, cancel]
  );

  const checkBudget = useCallback(async () => {
    const isValidBudgetMin = await trigger('budgetMin');
    const isValidBudgetMax = await trigger('budgetMax');

    return isValidBudgetMin && isValidBudgetMax && !hasBudgetError;
  }, [hasBudgetError, trigger]);

  const cancelBudget = useCallback(
    closeList => {
      setValue('budgetMin', null);
      setValue('budgetMax', capacity ?? null);

      submit(closeList, SearchEngineFilterFormHorizontal.BUDGET, true);
    },
    [capacity, setValue, submit]
  );

  const onPrimaryClickBudget = useCallback(
    async closeList => {
      const validBudget = await checkBudget();
      validBudget && submit(closeList, SearchEngineFilterFormHorizontal.BUDGET);
    },
    [checkBudget, submit]
  );

  const onCallbackCloseBudget = useCallback(
    async source => {
      if (source !== DROPDOWN_CLOSE_OVERLAY) {
        return;
      }

      const validBudget = await checkBudget();

      if (validBudget) {
        submit(undefined, SearchEngineFilterFormHorizontal.BUDGET);
        return;
      }

      resetField('budgetMin', { defaultValue: defaultValues['budgetMin'] });
      resetField('budgetMax', { defaultValue: defaultValues['budgetMax'] });
    },
    [checkBudget, defaultValues, resetField, submit]
  );

  /** When one of the fields is in error, it rerenders but it doesn't rerender the whole component,
   * so the dropdown doesn't have the information that one of its fields is in error.
   * So you have to pass this information in the state to start a rerender*/
  const checkBudgetError = useCallback(() => {
    const hasError = !!(errors.budgetMax || errors.budgetMin);
    hasError != hasBudgetError && setHasBudgetError(hasError);
  }, [errors.budgetMax, errors.budgetMin, hasBudgetError]);

  const onChangeBudgetMax = (v, onChange) => {
    const newValue = inputToNumber(v);

    const budgetMin = watch('budgetMin');

    if (newValue < budgetMin) {
      setError('budgetMin', {
        message: t('propertiesPreferences.formErrors.budgetMinSupMax'),
        type: 'custom',
      });
    } else {
      clearErrors('budgetMin');
    }
    return onChange(newValue);
  };

  return (
    <Gap gap={6} direction="horizontal" style={styles.container}>
      <Gap gap={6} direction="horizontal">
        <View style={styles.selector}>
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <FilterListSelector
                  {...commonFilterProps}
                  placeholder={t('searchEngine.filter.country.placeholder')}
                  values={getSingleValue(value, countryOptionList)}
                  options={formatOption({
                    optionList: countryOptionList,
                    value,
                  })}
                  {...getActionProps(SearchEngineFilterFormHorizontal.COUNTRY)}
                  onSelectValue={item => {
                    selectValue({ item, list: countryOptionList, onChange, value });
                    setValue('regionList', []);
                  }}
                  disabled={countryDisabled}
                />
              );
            }}
            name="country"
          />
        </View>

        <View style={styles.selector}>
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <FilterListSelector
                {...commonFilterProps}
                placeholder={t('searchEngine.filter.region.label')}
                values={formatSelectorValues(regionListValue(value, regionOptionList), value)}
                options={formatOption({ optionList: regionOptionList, value })}
                {...getActionProps(SearchEngineFilterFormHorizontal.REGION_LIST)}
                onSelectValue={item =>
                  selectValue({ item, list: regionOptionList, onChange, value })
                }
                disabled={!regionOptionList.length}
                multiple
              />
            )}
            name="regionList"
          />
        </View>

        <View style={styles.selector}>
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <FilterListSelector
                {...commonFilterProps}
                placeholder={t('propertyDescription.type')}
                values={formatSelectorValues(propertyTypeList, value)}
                options={formatOption({ optionList: propertyTypeList, value })}
                {...getActionProps(SearchEngineFilterFormHorizontal.TYPOLOGY)}
                onSelectValue={item =>
                  selectValue({
                    item,
                    list: propertyTypeList,
                    onChange,
                    value,
                  })
                }
                multiple
              />
            )}
            name="typology"
          />
        </View>

        <View style={styles.selector}>
          <FilterBudgetDropdown
            placeholder={t('propertyModals.budget')}
            {...commonFilterProps}
            onCallbackClose={onCallbackCloseBudget}
            onPrimaryClick={!hasBudgetError ? onPrimaryClickBudget : null}
            onGhostClick={closeList => cancelBudget(closeList)}
            values={budgetValue}
            minField={
              <Controller
                control={control}
                rules={budgetMinRules}
                render={({ field: { onChange, onBlur, value } }) => {
                  checkBudgetError();
                  return (
                    <Input
                      testID="search-engine-filter-budget-min--input"
                      errorMessageTestID="search-engine-filter-budget-min--input-error"
                      label={t('propertiesPreferences.minimalBudget')}
                      onBlur={onBlur}
                      onChangeText={v => onChange(inputToNumber(v))}
                      value={numberToInput(value)}
                      error={errors.budgetMin?.message}
                      keyboardType="numeric"
                    />
                  );
                }}
                name="budgetMin"
              />
            }
            maxField={
              <Controller
                control={control}
                rules={budgetMaxRules}
                render={({ field: { onChange, onBlur, value } }) => {
                  checkBudgetError();
                  return (
                    <Input
                      testID="search-engine-filter-budget-max--input"
                      errorMessageTestID="search-engine-filter-budget-max--input-error"
                      label={t('propertiesPreferences.maximalBudget')}
                      onBlur={onBlur}
                      onChangeText={v => onChangeBudgetMax(v, onChange)}
                      value={numberToInput(value)}
                      error={
                        errors.budgetMax?.message ||
                        (!!errors.budgetMin && errors.budgetMin.type === 'lessThanMax' && ' ')
                      }
                      keyboardType="numeric"
                    />
                  );
                }}
                name="budgetMax"
              />
            }
          />
        </View>

        <View style={[styles.selector, styles.allFilter]}>
          <ActionButton
            disabled={false}
            leftIconName="Filter"
            onPress={onFilterShow}
            testID="search-engine-filter-horizontal-filter-button"
            label={t('propertiesPreferences.allFilters')}
            size="m"
            active={!!filterCount}
            withSolidBorder
          />
        </View>
      </Gap>
      <SearchEngineSort
        onSubmit={onSubmit}
        onSubmitError={onSubmitError}
        form={form}
        defaultValues={defaultValues}
      />
    </Gap>
  );
};
