import React from 'react';

import { palette, Text, TextProps } from '@masteos/aphrodite';

import { useFormContext } from './FormContext.web';

interface ErrorMessageProps {
  message?: string;
  name: string;
  textStyleName?: TextProps['textStyle'];
}

export const ErrorMessage: React.FC<ErrorMessageProps> = ({ message, name, textStyleName }) => {
  const { getError } = useFormContext();

  if (!getError(name)) {
    return null;
  }

  return (
    <Text textStyle={textStyleName || 'Caption1'} style={{ color: palette.alert.error }}>
      {message || getError(name)}
    </Text>
  );
};
