import React from 'react';

import { NavigationState, ParamListBase, useNavigationState } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';

import { GetCustomerQuery } from '@app/libs/apollo/introspection';

import { ProfileNavigator } from './ProfileNavigator';
import { AdvisorNavigator } from './AdvisorNavigator';
import { RealEstateNavigator } from './RealEstateNavigator';
import { ProjectNavigator } from './ProjectNavigator';
import {
  MainMenuNavigatorRoutes,
  MainMenuStackParamList,
  PublicNavigatorRoutes,
  RealEstateNavigatorRoutes,
} from './types/routes';
import { SCREEN_OPTIONS } from './constants/navigation-options.web';
import { getHomePage } from './helpers/getHomePage.utils';
import { SuggestionsNavigator } from './SuggestionsNavigator';

type MainMenuNavigatorType = {
  customer: GetCustomerQuery['me'];
};

const Stack = createStackNavigator<MainMenuStackParamList>();

export const MainMenuNavigator: React.FC<MainMenuNavigatorType> = ({ customer }) => {
  const originRoute = useNavigationState(
    (state: NavigationState<ParamListBase>) => state?.routes?.[0]
  );

  let initialRealEstatesParams = {};

  let homepage = getHomePage(customer);

  if (originRoute && originRoute.name === PublicNavigatorRoutes.RealEstate) {
    homepage = MainMenuNavigatorRoutes.RealEstates;
    initialRealEstatesParams = {
      params: originRoute.params,
      screen: RealEstateNavigatorRoutes.RealEstate,
    };
  }

  return (
    <Stack.Navigator initialRouteName={homepage} screenOptions={SCREEN_OPTIONS}>
      <Stack.Screen name={MainMenuNavigatorRoutes.Advisor} component={AdvisorNavigator} />
      <Stack.Screen
        name={MainMenuNavigatorRoutes.RealEstates}
        initialParams={initialRealEstatesParams}
        component={RealEstateNavigator}
      />
      <Stack.Screen name={MainMenuNavigatorRoutes.Projects} component={ProjectNavigator} />
      <Stack.Screen name={MainMenuNavigatorRoutes.Profile} component={ProfileNavigator} />
      <Stack.Screen name={MainMenuNavigatorRoutes.Suggestions} component={SuggestionsNavigator} />
    </Stack.Navigator>
  );
};
