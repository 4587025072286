import React from 'react';

import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { Text, Button, Icon, Spacer, useResponsive, useTheme } from '@masteos/aphrodite';

import { useTranslation } from '@app/services/translations/translations';

import { Visit3DButtonProps } from './withVisit3D';

interface PropertyVirtualVisitCardProps extends Visit3DButtonProps {
  style?: StyleProp<ViewStyle>;
}

export const VirtualVisitCard: React.FC<PropertyVirtualVisitCardProps> = ({ style, onPress }) => {
  const { t } = useTranslation();
  const { isGreaterThanLaptop } = useResponsive();
  const theme = useTheme();
  const styles = StyleSheet.create({
    block: {
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing.SIZE_04,
    },
    buttonWrapper: {
      height: theme.spacing.SIZE_08,
    },
    text: {
      textAlign: 'center',
    },
  });

  return (
    <View testID="virtual-visit-card" style={[styles.block, style]}>
      <Icon name="Cube" size={15}></Icon>

      <Spacer height={isGreaterThanLaptop ? theme.spacing.SIZE_04 : theme.spacing.SIZE_03} />
      <Text textStyle={isGreaterThanLaptop ? 'Body3' : 'Body2'} style={styles.text}>
        {t('propertyInfo.3DDescription')}
      </Text>
      <Spacer height={isGreaterThanLaptop ? theme.spacing.SIZE_02 : theme.spacing.SIZE_03} />
      <View style={styles.buttonWrapper}>
        <Button
          testID="virtual-visit-button"
          onPress={onPress}
          variant="tertiary"
          style={{ minHeight: theme.spacing.SIZE_08 }}
        >
          {t('propertyInfo.3DButton')}
        </Button>
      </View>
    </View>
  );
};
