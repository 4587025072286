import React, { useCallback, useEffect, useState } from 'react';

import { StyleSheet, View } from 'react-native';

import {
  BorderRadius,
  Button,
  LocationMarkerIcon,
  palette,
  Spacing,
  Image,
  ShadowContainer,
} from '@masteos/aphrodite';

import { config } from '@app/config';
import { useTranslation } from '@app/services/translations/translations';

const styles = StyleSheet.create({
  buttonContainer: {
    borderRadius: BorderRadius.SIZE_05,
  },
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    borderRadius: BorderRadius.SIZE_02,
    height: 300,
    width: '100%',
  },
  markerWrapper: {
    alignItems: 'center',
    borderRadius: 50,
    height: 50,
    justifyContent: 'center',
    width: 50,
  },
  overlay: {
    alignItems: 'center',
    bottom: 0,
    justifyContent: 'center',
    left: 0,
    padding: Spacing.SIZE_03,
    position: 'absolute',
    right: 0,
    top: -50,
  },
  overlayButton: {
    alignItems: 'center',
    bottom: Spacing.SIZE_05,
    justifyContent: 'center',
    left: 0,
    padding: Spacing.SIZE_03,
    position: 'absolute',
    right: 0,
  },
  text: {
    color: palette.base.white,
    padding: Spacing.SIZE_04,
    textAlign: 'center',
  },
});

interface PropertyDescriptionMapPreviewProps {
  coord: {
    lat: number;
    lng: number;
  };
  onPress: () => void;
}

export const PropertyDescriptionMapPreview: React.FC<PropertyDescriptionMapPreviewProps> = ({
  coord,
  onPress,
}) => {
  const { t } = useTranslation();
  const [mapPreviewUrl, setMapPreviewUrl] = useState<string>();

  useEffect(() => {
    getPreviewUrl();
  }, []);

  const width = 480;
  const height = 300;
  const getPreviewUrl = useCallback(() => {
    if (coord) {
      setMapPreviewUrl(
        `https://maps.googleapis.com/maps/api/staticmap?&center=${coord.lat},${coord.lng}&zoom=14&size=${width}x${height}&key=${config.GMAPS_API_KEY}`
      );
    }
  }, []);

  return mapPreviewUrl ? (
    <View style={styles.container}>
      <Image resizeMode="cover" style={styles.image} source={{ uri: mapPreviewUrl }} />
      <View style={styles.overlay}>
        <View style={styles.markerWrapper}>
          <LocationMarkerIcon />
        </View>
      </View>
      <View style={styles.overlayButton}>
        <ShadowContainer style={styles.buttonContainer}>
          <Button variant="primary" onPress={onPress}>
            {t('propertyMap.seeMap')}
          </Button>
        </ShadowContainer>
      </View>
    </View>
  ) : null;
};
