import { format } from 'date-fns';
import * as localeList from 'date-fns/locale';
import { Platform } from 'react-native';
import { i18n as i18nType } from 'i18next';

import { PlatformEnum } from '@app/types/platform';
import {
  callTelephone,
  sendEmailMessage,
  sendWhatsappMessage,
} from '@app/services/deepLinking/deepLinking';
import {
  trackContactAdvisorMail,
  trackContactAdvisorPhone,
  trackContactAdvisorWhatsapp,
} from '@app/services/tracking/trackTracking';
import { getFullName } from '@app/utils/getFullName';
import { GetCustomerQuery } from '@app/libs/apollo/introspection';
import { AdvisorSourcePage } from '@app/services/tracking/constants';

type ExtractAdvisorInfoReturnType =
  | {
      advisorAvatar: string;
      advisorFullName: string;
      appointmentFormattedDate: string;
      onCallClick: () => void;
      onMailClick: () => void;
      onWhatsappClick: () => void;
      phoneNumber: string | null;
    }
  | Record<string, never>;

export const extractAdvisorInfo = ({
  source,
  customer,
  i18n,
  t,
}: {
  customer: GetCustomerQuery['me'];
  t: (text: string) => string;
  i18n: i18nType;
  source: AdvisorSourcePage;
}): ExtractAdvisorInfoReturnType => {
  if (!customer || !customer.advisor) {
    return {};
  }

  const { advisor } = customer;

  const nextAppointmentDate: Date | undefined =
    customer.nextAppointment?.startAt && new Date(customer.nextAppointment.startAt);
  const locale = localeList[i18n.resolvedLanguage];

  const getFormattedTime = (date: Date) => ({
    day: format(date, 'do MMMM', { locale }),
    fullDate: format(date, 'PPPp', { locale }),
    hour: format(date, 'H', { locale }),
    minutes: format(date, 'mm', { locale }),
  });

  const appointmentFormattedDate = nextAppointmentDate
    ? getFormattedTime(new Date(nextAppointmentDate)).fullDate
    : t('advisor.noAppointmentSchedule');

  const onCallClick = () => {
    if (Platform.OS === PlatformEnum.Web) {
      window.location.href = `tel:${advisor.phoneNumber}`;
    } else {
      callTelephone(advisor, t('shared.telephoneUnavailable'), t('advisor.phone'));
    }

    trackContactAdvisorPhone(customer.email, source);
  };

  const onWhatsappClick = () => {
    sendWhatsappMessage(advisor, t('shared.whatsappUnavailable'), t('shared.whatsapp'));
    trackContactAdvisorWhatsapp(customer.email, source);
  };

  const onMailClick = () => {
    if (Platform.OS === PlatformEnum.Web) {
      window.location.href = `mailto:${advisor.email}`;
    } else {
      sendEmailMessage(advisor, t('advisor.emailUnavailable'), t('shared.email'));
    }

    trackContactAdvisorMail(customer.email, source);
  };

  return {
    advisorAvatar: advisor.avatarS3ID,
    advisorFullName: getFullName(advisor),
    appointmentFormattedDate,
    onCallClick,
    onMailClick,
    onWhatsappClick,
    phoneNumber: advisor.phoneNumber,
  };
};
