import { ListItemUnionProps } from '@masteos/aphrodite';

import { SELECTOR_ALL_KEY } from '@app/utils/selector.utils';

export const getAllRegionOption: (t) => ListItemUnionProps = t => ({
  checkbox: true,
  key: SELECTOR_ALL_KEY,
  label: t('searchEngine.filter.allRegion'),
  value: null,
});
