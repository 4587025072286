import React from 'react';

import {
  Checkbox as AphroditeCheckbox,
  CheckboxProps as AphroditeCheckboxProps,
} from '@masteos/aphrodite';

import { useFormContext } from './FormContext.web';

interface CheckboxProps extends AphroditeCheckboxProps {
  name: string;
}

export const Checkbox: React.FC<CheckboxProps> = ({ name, ...checkboxProps }) => {
  const formContext = useFormContext();
  const isChecked = formContext.getValue(name);

  if (!formContext) {
    console.warn(
      `<Checkbox name="${name}" /> must be used inside a Form component. Checkbox has been rendered but the name props have been  ignored`
    );

    return <AphroditeCheckbox {...checkboxProps} checked={checkboxProps.checked} />;
  }

  return (
    <AphroditeCheckbox
      onChange={value => formContext.setValue(name, value)}
      {...checkboxProps}
      errorMessage={formContext.mapFieldProps(name)?.error}
      checked={isChecked}
    />
  );
};
