import { Fields, FormFieldError } from './types.web';

export const getTouchedFieldErrors = async (
  fields: Fields,
  validate: (key: string) => Promise<FormFieldError>
): Promise<FormFieldError[]> => {
  const errors: FormFieldError[] = [];
  for (const key in fields) {
    if (fields.hasOwnProperty.call(fields, key)) {
      const field = fields[key];
      if (field.touched) {
        errors.push(await validate(key));
      }
    }
  }
  return errors;
};
