import React, { useCallback, useMemo } from 'react';

import { Platform, View } from 'react-native';

import {
  Text,
  ActionButton,
  palette,
  Spacing,
  useResponsive,
  Spacer,
  useTheme,
  Gap,
} from '@masteos/aphrodite';

import { useTranslation } from '@app/services/translations/translations';
import { SearchEngineSection } from '@app/features/search-engine/searchEngine.types';

import { SearchEngineHeaderType, TitleProps } from './searchEngineHeader.type';
import { getStyles } from './searchEngineHeader.styles';
import { SearchEngineSort } from '../search-engine-filters/search-engine-sort/SearchEngineSort';

enum sectionToTrad {
  all = 'All',
  bookmarked = 'Favorites',
}

const pluralTrad = (val: number) => (val > 1 ? '_other' : '_one');

interface FilterButtonProps {
  count: number;
  hasOnlyIcon?: boolean;
  onPress: () => void;
  section: SearchEngineSection;
}

export const FilterButton: React.FC<FilterButtonProps> = ({
  count,
  hasOnlyIcon = false,
  section,
  onPress,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  return (
    <ActionButton
      disabled={section !== 'all'}
      leftIconName="Filter"
      onPress={onPress}
      testID="search-engine-header-filter--button"
      label={hasOnlyIcon ? null : t('navigation.filters')}
      size={Platform.OS === 'web' && !isMobile ? 'm' : 's'}
      count={count}
      withSolidBorder
    />
  );
};

const Title: React.FC<TitleProps> = ({ title }) => {
  return (
    <Text textStyle="Headline1Medium" style={{ color: palette.neutral[900] }}>
      {title}
    </Text>
  );
};

export const SearchEngineHeader: React.FC<SearchEngineHeaderType> = React.memo(
  ({
    section,
    state,
    loading,
    resultCount,
    bookmarkedCount,
    filterCount,
    onSectionChange,
    onFilterShow,
    hideFilterButton,
    onSubmit,
    onSubmitError,
    defaultValues,
    form,
  }) => {
    const { t } = useTranslation();

    const theme = useTheme();
    const { isMobile, isLowerThanDesktop } = useResponsive();
    const styles = useMemo(() => getStyles({ isMobile, theme }), [isMobile, theme]);

    const title =
      section === SearchEngineSection.BOOKMARKED
        ? t('navigation.myFavorites')
        : t('navigation.mySearch');

    const count = useMemo<Record<SearchEngineSection, number>>(
      () => ({
        all: resultCount,
        bookmarked: bookmarkedCount,
      }),
      [resultCount, bookmarkedCount]
    );

    const handleActionPress = useCallback(
      (nextSection: SearchEngineSection) => () => {
        onSectionChange(nextSection);
      },
      [onSectionChange]
    );

    const handleFilterPress = useCallback(() => {
      onFilterShow();
    }, [onFilterShow]);

    if (state === 'inTransaction') {
      return (
        <View style={styles.container}>
          <View style={styles.titleContainer}>
            <Title title={title} />
          </View>
        </View>
      );
    }

    return (
      <View style={styles.container}>
        <View style={styles.titleContainer}>
          <Title title={title} />
          {!isMobile ? (
            !loading && (
              <>
                <Spacer width={Spacing.SIZE_03} />
                <Text testID="search-engine-header-count--text">
                  {t(`properties.result${sectionToTrad[section] + pluralTrad(count[section])}`, {
                    count: count[section],
                  })}
                </Text>
              </>
            )
          ) : (
            <FilterButton
              count={filterCount}
              hasOnlyIcon
              onPress={handleFilterPress}
              section={section}
            />
          )}
        </View>

        {!!isMobile && <View style={styles.horizontalLine} />}
        <Gap style={styles.buttonsContainer} gap={Spacing.SIZE_03} direction="horizontal">
          <Gap gap={Spacing.SIZE_03} direction="horizontal">
            <ActionButton
              active={section === SearchEngineSection.ALL}
              testID="search-engine-header-all--button"
              onPress={handleActionPress(SearchEngineSection.ALL)}
              label={t('navigation.all')}
              size="m"
              withSolidBorder
            >
              {t('navigation.all')}
            </ActionButton>
            <ActionButton
              active={section === SearchEngineSection.BOOKMARKED}
              testID="search-engine-header-bookmarked--button"
              onPress={handleActionPress(SearchEngineSection.BOOKMARKED)}
              leftIconName="Heart"
              label={
                isLowerThanDesktop
                  ? `(${bookmarkedCount})`
                  : `${t('navigation.favorites')} (${bookmarkedCount})`
              }
              size="m"
              withSolidBorder
            >
              {`${t('navigation.favorites')} (${bookmarkedCount})`}
            </ActionButton>
            {!isMobile && !hideFilterButton ? (
              <>
                <FilterButton count={filterCount} onPress={handleFilterPress} section={section} />
              </>
            ) : null}
          </Gap>
          {!hideFilterButton ? (
            <>
              <SearchEngineSort
                onSubmit={onSubmit}
                onSubmitError={onSubmitError}
                form={form}
                defaultValues={defaultValues}
              />
            </>
          ) : null}
        </Gap>

        {!!isMobile && (
          <View style={styles.resultMobileContainer}>
            <Text testID="search-engine-header-count--text">
              {t(`properties.result${sectionToTrad[section] + pluralTrad(count[section])}`, {
                count: count[section],
              })}
            </Text>
          </View>
        )}
      </View>
    );
  }
);
