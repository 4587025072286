import React, { createContext, useContext, useState, useCallback, PropsWithChildren } from 'react';

import { IconName, ToastVariant } from '@masteos/aphrodite';

import { useTranslation } from '@app/services/translations/translations';

export const ToastContext = createContext<ToastContextProps>(null);

export interface ToastMsgProps {
  isBottom?: boolean;
  icon?: IconName;
  timeout?: number;
  title?: string;
  withClose?: boolean;
  variant?: ToastVariant;
}

export interface ToastContextProps {
  clearMsg: () => void;
  setErrorMsg: (ToastMsgProps?) => void;
  setValidMsg: (ToastMsgProps) => void;
  toastMsg: ToastMsgProps;
}

export const ToastProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation();
  const [toastMsg, setToastMsg] = useState<ToastMsgProps | null>(null);

  const setMsg = useCallback(msg => {
    setToastMsg(msg);
  }, []);

  const setErrorMsg = useCallback(
    msg => {
      setToastMsg({
        ...msg,
        title: msg?.title || t('shared.errors.common'),
        variant: ToastVariant.ERROR,
      });
    },
    [t]
  );

  const clearMsg = useCallback(() => {
    setToastMsg(null);
  }, []);

  const value = {
    clearMsg,
    setErrorMsg,
    setValidMsg: setMsg,
    toastMsg,
  } as ToastContextProps;

  return <ToastContext.Provider value={value}>{children}</ToastContext.Provider>;
};

export function useToastContext(): ToastContextProps {
  return useContext(ToastContext);
}
