import { SearchRealEstatesQueryVariables } from '@app/libs/apollo/introspection';
// eslint-disable-next-line max-len
import { SearchEngineSortStrategy } from '@app/features/search-engine/compounds/search-engine-filters/search-engine-sort/SearchEngineSort.types';
import { MyRealEstatesCachedResult } from '@app/libs/apollon/types/myRealEstatesCachedResult';

export const isIncomingGreaterThanExisting = (
  incomingCursor: MyRealEstatesCachedResult['cursor'],
  existingCursor: MyRealEstatesCachedResult['cursor'],
  { sortStrategy }: SearchRealEstatesQueryVariables['filters']
): boolean => {
  const incomingNetReturn = incomingCursor.nextRealEstateNetReturn;
  const existingNetReturn = existingCursor.nextRealEstateNetReturn;
  const incomingLastPublishingDate = new Date(incomingCursor.nextRealEstatePublishingDate);
  const existingLastPublishingDate = new Date(existingCursor.nextRealEstatePublishingDate);

  const isNewRealEstateNetReturn =
    sortStrategy === SearchEngineSortStrategy.NetReturnDesc &&
    (incomingNetReturn > existingNetReturn ||
      (incomingNetReturn === existingNetReturn &&
        incomingLastPublishingDate > existingLastPublishingDate));

  const isNewRealEstateByPublishedDate =
    sortStrategy === SearchEngineSortStrategy.FirstPublishedAtDesc &&
    incomingLastPublishingDate > existingLastPublishingDate;

  return isNewRealEstateNetReturn || isNewRealEstateByPublishedDate;
};
