import React from 'react';

import { Spacer, Spacing } from '@masteos/aphrodite';

import { config } from '@app/config';

import { MenuItem } from './compounds/MenuItem';
import { DebugMenuFeatureFlags } from './DebugMenuFeatureFlags';
import { DebugMenuTranslations } from './DebugMenuTranslations';
import { DebugMenuEnvironmentVariables } from './DebugMenuEnvironmentVariables';
import { DebugMenuWrapper } from './compounds/DebugMenuWrapper';
import { DebugMenuHeader } from './compounds/DebugMenuHeader';

type DebugMenuType = {
  testID: string;
};

export const DebugMenu: React.FC<DebugMenuType> = ({ testID }) => {
  return (
    <DebugMenuWrapper testID={testID}>
      <DebugMenuHeader title="API URLs" />

      <MenuItem icon="Paperclip" title="Apollon API URL" subtitle={config.APOLLON_API_URL} />
      <MenuItem
        icon="Cookie"
        title="Identity API URL"
        hasBottomRadius
        subtitle={config.IDENTITY_API_URL ?? 'Not set'}
      />

      <Spacer height={Spacing.SIZE_08} />
      <DebugMenuFeatureFlags />
      <Spacer height={Spacing.SIZE_08} />
      <DebugMenuTranslations />
      <Spacer height={Spacing.SIZE_08} />
      <DebugMenuEnvironmentVariables />
      <Spacer height={Spacing.SIZE_08} />
    </DebugMenuWrapper>
  );
};
