import { Platform, StyleSheet } from 'react-native';

import { Theme } from '@masteos/aphrodite';

const TAB_BAR_HEIGHT = {
  DESKTOP: 65,
  MOBILE: 48,
};

export const getStyleSheet = (theme: Theme, isLowerThanDesktop: boolean) =>
  StyleSheet.create({
    tabBar: {
      alignItems: Platform.select({
        default: 'center',
        web: 'stretch',
      }),
      backgroundColor: theme.palette.base.white,
      elevation: 0,
      flexDirection: 'row',
      height: isLowerThanDesktop ? TAB_BAR_HEIGHT.MOBILE : TAB_BAR_HEIGHT.DESKTOP,
      shadowOpacity: 0,
      ...Platform.select({
        default: {
          borderBottomColor: theme.palette.neutral[200],
          borderBottomWidth: 1,
          width: '100%',
        },
        web: { borderBottomWidth: 0 },
      }),
    },
    tabBarContainer: {
      paddingHorizontal: Platform.select({
        default: theme.spacing.SIZE_06,
        web: theme.spacing.NONE,
      }),
    },
  });
