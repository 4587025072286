import React from 'react';

import { Spacer, Text, useTheme } from '@masteos/aphrodite';

import { TranslatableWithSwitchWrapper } from '@app/shared/components/TranslatableWithSwithWrapper/TransalatableWithSwitchWrapper';
import { RealEstateSynthesisItem } from '@app/libs/apollo/introspection';
import { useTranslation } from '@app/services/translations/translations';

interface PropertySynthesisProps {
  synthesis: RealEstateSynthesisItem[];
  children?: React.ReactNode;
}

export const PropertySynthesisWrapper: React.FC<PropertySynthesisProps> = ({
  synthesis,
  children,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const hasTranslatedText = synthesis.some(({ translation }) => !!translation?.translatedText);

  return (
    <>
      {!!synthesis.length && (
        <TranslatableWithSwitchWrapper hasTranslatedText={hasTranslatedText}>
          <>
            <Text textStyle="Title3Medium">{t('propertyDescription.propertyPros')}</Text>
            <Spacer height={theme.spacing.SIZE_07} />
            {children}
          </>
        </TranslatableWithSwitchWrapper>
      )}
    </>
  );
};
