import React from 'react';

import { ModalPosition } from '@masteos/aphrodite';

export enum ModalKey {
  SignInAppMandate = 'SignInAppMandate',
  CongratsSignInAppMandate = 'CongratsSignInAppMandate',
  FirstAppointment = 'FirstAppointment',
  Positioning = 'Positioning',
  MissingDocument = 'MissingDocument',
  EnableNotifications = 'EnableNotifications',
  NotificationsSettings = 'NotificationsSettings',
  ApprovePositioning = 'ApprovePositioning',
  CancelPositioning = 'CancelPositioning',
}

export type ModalContextProps = {
  openModal?: (
    key: ModalKey,
    Component: React.ReactNode,
    options?: {
      excludes?: ModalKey[];
      title?: string | React.ReactNode;
      position?: ModalPosition;
      webSize?: 's' | 'm' | 'l';
      callbackClose?: (params?: unknown) => void;
    }
  ) => void;
  closeModal?: (params?: unknown) => void;
  clearExcludes?: () => void;
  isVisible?: boolean;
  position?: ModalPosition;
  webSize?: 's' | 'm' | 'l';
  component?: React.ReactNode;
  setTitle?: (params?: unknown) => void;
  title?: string | React.ReactNode;
};
