import { S3ID } from '@masteos/agora';
import { TranslationContentValue } from '@masteos/aphrodite/lib/typescript/atoms/translation-content/TranslationContent.types';

export enum PropertyCategory {
  INSIDE = 'inside',
  OUTSIDE = 'outside',
  COMMON = 'common',
  NEIGHBORHOOD = 'neighborhood',
  PLANS = 'plans',
  FURNITURE = 'furniture',
  ACCESS = 'access',
}

export interface DescriptionWithImages {
  images?: S3ID[];
  description?: string;
  translation?: TranslationContentValue;
}
