import { StackNavigationProp } from '@react-navigation/stack';

import { CarouselType, OnboardingScreenType, UseSlideTypeResponse } from '@masteos/aphrodite';

import { RealEstatesStackParamList } from '@app/navigation/types/routes';

export enum ActionType {
  'NEXT' = 'NEXT',
  'HOME' = 'HOME',
  'ADVISOR' = 'ADVISOR',
}

export type OnboardingPagesScreenType = (Omit<
  OnboardingScreenType,
  'primaryAction' | 'secondaryAction'
> & {
  primaryAction?: ActionType;
  secondaryAction?: ActionType;
  key: string;
  pageTrackingEvent: string;
})[];

export type OnboardingTemplateType = Omit<CarouselType, 'children' | 'currentStep'> & {
  onboardingPagesScreen: OnboardingPagesScreenType;
  actions: { [key in ActionType]: () => void };
  nbSlide: number;
  carouselProps: UseSlideTypeResponse['carouselProps'];
  //step;
};

export type OnboardingNavigationProp = StackNavigationProp<RealEstatesStackParamList>;
