/* eslint-disable max-lines */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Platform } from 'react-native';
import { StackScreenProps } from '@react-navigation/stack';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useFocusEffect } from '@react-navigation/native';

import { useTheme } from '@masteos/aphrodite';

import { useTranslation } from '@app/services/translations/translations';
import {
  SearchEngineCardsProps,
  SearchEngineLocationItem,
  SearchEngineRealEstatesLoadingType,
  SearchEngineSection,
  SearchEngineType,
} from '@app/features/search-engine/searchEngine.types';
import { realEstateToItemMapper } from '@app/features/search-engine/utils/real-estate-to-item.mapper';
import { AvailableCountryCode } from '@app/libs/apollo/introspection';
import { StateOfCustomer, useMe } from '@app/shared/hooks/useMe';
import { PlatformEnum } from '@app/types/platform';
import {
  TakeAppointmentSource,
  TooltipTarget,
  trackBookmarksFilterClick,
  trackFilterCountryClick,
  trackFilterShow,
  trackTakeAdvisorAppointmentClick,
  trackTooltip,
} from '@app/services/tracking/trackTracking';
import { SearchEngineListType } from '@app/features/search-engine/compounds/search-engine-list/searchEngineList.type';
import {
  AdvisorNavigatorRoutes,
  RealEstateNavigatorRoutes,
  RealEstatesStackParamList,
  MainMenuNavigatorRoutes,
} from '@app/navigation/types/routes';
import { getContainerStyle } from '@app/features/search-engine/searchEngine.styles';
import { RealEstateItem } from '@app/shared/components/real-estate-card/realEstateCard.type';

import { SearchEngineTemplate } from './SearchEngineTemplate';
import {
  addAppointmentCardOnList,
  clearReinsuranceCardList,
} from './utils/add-information-card-on-list';
import { FiltersStateProps, useGetSearchEngineDatas } from './hooks/useGetSearchEngineDatas';
import { useSearchEngineFilters } from './hooks/useSearchEngineFilters';
import {
  clearSearchEngineScrollPosition,
  currentSearchEngineScrollPosition,
} from './utils/scrollTo.utils';
import { DOM_BODY_CONTAINER_ID } from '../navigation/navigation.constants';

type SearchEngineProps = StackScreenProps<
  RealEstatesStackParamList,
  RealEstateNavigatorRoutes.RealEstateList
>;

export const SearchEngine = React.memo(
  ({ navigation, route }: SearchEngineProps) => {
    const { stateOfCustomer } = useMe();
    const isWeb = Platform.OS === PlatformEnum.Web;
    const { position = 0, section: paramSection = SearchEngineSection.ALL } = route.params || {};
    const { t } = useTranslation();
    const theme = useTheme();
    const [listWithInformationCard, setListWithInformationCard] = useState<SearchEngineCardsProps>(
      []
    );
    const [filters, setFilters] = useState<FiltersStateProps | null>(null);
    const { container } = getContainerStyle(theme);

    const {
      cursor,
      bookmarkedRealEstates,
      realEstates,
      totalBookmarks,
      locations,
      filterLoading,
      toggleBookmark,
      myFavoritesRefetch,
      next: nextMyRealEstates,
      loading: realEstatesLoading,
      loadingType: realEstatesLoadingType,
      totalRealEstates,
    } = useGetSearchEngineDatas({
      filters,
    });

    const {
      form,
      filter,
      filterCount,
      updateSearchEngineFilters,
      resetSearchEngineFilters,
      clearForm,
    } = useSearchEngineFilters(setFilters, filters);

    const handleLoadMoreRealEstates = useCallback(() => {
      nextMyRealEstates();
    }, [nextMyRealEstates]);

    const [section, setSection] = useState<SearchEngineSection>(paramSection);

    const countryList = useMemo<AvailableCountryCode[]>(
      () => locations.map(loc => loc.countryCode) || [],
      [locations]
    );
    const regionsListByCountry = useMemo<Record<string, string[]>>(
      () =>
        locations.reduce((acc, loc) => {
          acc[loc.countryCode] = loc.regions;
          return acc;
        }, {}) || {},
      [locations]
    );

    const isShowMandateCard = useMemo(
      () => stateOfCustomer === StateOfCustomer.PRE_SIGNED_MANDATE,
      [stateOfCustomer]
    );

    const isShowAppointmentCard = useMemo(
      () => stateOfCustomer === StateOfCustomer.PRE_APPOINTMENT,
      [stateOfCustomer]
    );

    const searchEngineList = useMemo(() => {
      return realEstates.map(realEstateToItemMapper) || [];
    }, [realEstates]);

    const list = useMemo(() => {
      switch (section) {
        case SearchEngineSection.ALL:
          return searchEngineList;
        case SearchEngineSection.BOOKMARKED:
          return [...bookmarkedRealEstates.map(realEstateToItemMapper)];
      }
    }, [section, searchEngineList, bookmarkedRealEstates]);

    const handleSectionChange = useCallback(
      nextSection => {
        if (nextSection === SearchEngineSection.BOOKMARKED) {
          myFavoritesRefetch();
          trackBookmarksFilterClick(bookmarkedRealEstates.length);
        }

        setSection(nextSection);
        clearReinsuranceCardList();
      },
      [bookmarkedRealEstates, myFavoritesRefetch]
    );

    const header = useMemo<SearchEngineType['header']>(
      () => ({
        bookmarkedCount: totalBookmarks,
        filterCount,
        onSectionChange: nextSection => handleSectionChange(nextSection),
        resultCount: totalRealEstates,
        section,
        state: stateOfCustomer,
      }),
      [totalBookmarks, filterCount, handleSectionChange, section, stateOfCustomer, totalRealEstates]
    );

    const handleItemBookmark = useCallback(
      (item: RealEstateItem) => {
        toggleBookmark(item);
      },
      [toggleBookmark]
    );

    const handleItemClick = useCallback(
      (item: RealEstateItem, itemInfo: SearchEngineLocationItem) => {
        navigation.navigate(RealEstateNavigatorRoutes.RealEstate, {
          currentScrollPosition: isWeb
            ? document.getElementById(DOM_BODY_CONTAINER_ID).scrollTop
            : undefined,
          id: item.id,
          isFromSearchEngine: true,
          position: itemInfo.position,
        });
      },
      [isWeb, navigation]
    );

    const handleItemTooltipHover = useCallback<SearchEngineListType['onItemTooltipHover']>(
      target => {
        trackTooltip(TooltipTarget[target] ?? target);
      },
      []
    );

    // FIXME: move outside component (no deps)
    const handleFilterCountryClick = useCallback(disabled => {
      trackFilterCountryClick({ disabled });
    }, []);

    // FIXME: move outside component (no deps)
    const handleFilterShow = useCallback(source => {
      trackFilterShow({ source });
    }, []);

    const navigateToAdvisor = useCallback(
      (info: string) => {
        trackTakeAdvisorAppointmentClick({ source: info });

        if (Platform.OS === PlatformEnum.Web) {
          navigation.getParent().navigate(MainMenuNavigatorRoutes.Advisor, {
            screen: AdvisorNavigatorRoutes.AdvisorAppointment,
          });
        } else {
          navigation.navigate(RealEstateNavigatorRoutes.AdvisorAppointment);
        }
      },
      [navigation]
    );

    const handleInformationCardPress = useCallback(
      (key: number, type: string) => {
        const info =
          stateOfCustomer === StateOfCustomer.IN_TRANSACTION
            ? TakeAppointmentSource.newSearchPage
            : type +
              TakeAppointmentSource.cardSearchPageInformationcards +
              '.' +
              key.toString() +
              '.title';

        navigateToAdvisor(info);
      },
      [navigateToAdvisor, stateOfCustomer]
    );

    useEffect(() => {
      return () => {
        clearReinsuranceCardList();
        clearSearchEngineScrollPosition();
      };
    }, []);

    useEffect(() => {
      const hasItemInList = Array.isArray(list) && list.length > 0;

      if (hasItemInList) {
        if (isShowAppointmentCard) {
          setListWithInformationCard(addAppointmentCardOnList(list, t));
        }
      } else {
        clearReinsuranceCardList();
        setListWithInformationCard([]);
      }
    }, [list, isShowAppointmentCard, isShowMandateCard, t]);

    useFocusEffect(() => {
      const restorePos = currentSearchEngineScrollPosition();
      if (!realEstatesLoading && restorePos && isWeb) {
        setTimeout(() => {
          document
            .getElementById(DOM_BODY_CONTAINER_ID)
            .scrollTo({ behavior: 'smooth', top: restorePos });
          clearSearchEngineScrollPosition();
        }, 1000);
      }
    });

    if (searchEngineList === null || stateOfCustomer === null) {
      return null;
    }

    return (
      <SafeAreaView edges={['top']} style={container}>
        <SearchEngineTemplate
          list={listWithInformationCard.length === 0 ? list : listWithInformationCard}
          header={header}
          state={stateOfCustomer}
          filter={filter}
          resetForm={clearForm}
          position={position}
          countryList={countryList}
          regionListByCountry={regionsListByCountry}
          hasMore={section === SearchEngineSection.BOOKMARKED ? false : !!cursor}
          listLoadingIsRefresh={
            !!realEstatesLoading &&
            realEstatesLoadingType === SearchEngineRealEstatesLoadingType.Refetch
          }
          listLoading={!!realEstatesLoading}
          filterLoading={filterLoading}
          onFilterCountryClick={handleFilterCountryClick}
          onFilterReset={resetSearchEngineFilters}
          onFilterShow={handleFilterShow}
          onFilterUpdate={updateSearchEngineFilters}
          onInformationCardPress={handleInformationCardPress}
          onItemBookmark={handleItemBookmark}
          onItemClick={handleItemClick}
          onItemTooltipHover={handleItemTooltipHover}
          onLoadMoreRealEstates={handleLoadMoreRealEstates}
          form={form}
        />
      </SafeAreaView>
    );
  },
  (prev, next) => {
    // FIXME: Contexts are rendering this component 4 times.
    return prev.route.params === next.route.params && prev.navigation === next.navigation;
  }
);

SearchEngine.displayName = 'SearchEngine';

// INFO: this is also imported by React.lazy which needs to be default.
// eslint-disable-next-line import/no-default-export
export default SearchEngine;
