import React, { PropsWithChildren, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { trackGalleryVisit } from '@app/services/tracking/trackTracking';
import { PropertyCategory } from '@app/types/property';
import { LogLevel, log } from '@app/utils/logs/log';

import { Gallery } from './Gallery/Gallery';
import { GalleryBlockContextProps as GalleryContextProps, GalleryImagesProps } from './types';

export const GalleryContext = React.createContext<GalleryContextProps>({
  openGallery: () => {
    log('Default open method', LogLevel.debug);
  },
});

interface RealEstateProviderProps {
  propertyId: string;
}

export const GalleryProvider: React.FC<PropsWithChildren<RealEstateProviderProps>> = ({
  children,
  propertyId,
}) => {
  const [activeGallery, setActiveGallery] = useState<GalleryImagesProps>(null);
  const onClose = () => setActiveGallery(null);
  const { t } = useTranslation();

  const openGallery = (gallery: GalleryImagesProps): void => {
    setActiveGallery(gallery);
    trackGalleryVisit({
      countGalleryImages: gallery.images.length,
      propertyId,
      typeGallery: gallery.slug as PropertyCategory,
    });
  };

  return (
    <>
      <GalleryContext.Provider value={{ openGallery }}>{children}</GalleryContext.Provider>

      {activeGallery ? (
        <Gallery
          images={activeGallery.images}
          onClose={onClose}
          isOpen={!!activeGallery}
          category={activeGallery.slug as PropertyCategory}
          description={activeGallery.description}
          title={activeGallery.title}
          sectionTitles={{ [activeGallery.slug]: t(activeGallery.name) }}
          propertyId={propertyId}
        />
      ) : null}
    </>
  );
};
