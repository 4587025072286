import { StyleSheet, TextStyle, ViewStyle } from 'react-native';

import { Theme } from '@masteos/aphrodite';

export const getStyles = ({
  canShownButton,
  isWeb,
  isLowerThanLaptop,
  theme,
}: {
  canShownButton: boolean;
  isWeb: boolean;
  isLowerThanLaptop: boolean;
  theme: Theme;
}): Record<string, ViewStyle | TextStyle> => {
  const contentHeight = isWeb
    ? { height: `calc(100vh - 160px - ${isLowerThanLaptop ? '100px' : '50px'})` }
    : { flexGrow: 1 };
  return StyleSheet.create({
    button: { marginTop: 20 },
    loaderWrapper: {
      ...contentHeight,
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
    titleColor: { color: theme.palette.system.primary[500] },
    webView: canShownButton ? contentHeight : {},
  });
};
