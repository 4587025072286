import React from 'react';

import { SliderInputProps, SliderInput } from '@masteos/aphrodite';

// TODO: Modify Aphrodite so it can descrimitate between number and array and return appropriate type
interface SliderInputNumberOutputProps extends Omit<SliderInputProps, 'setSliderValue'> {
  /**
   * value to be set on thumb move
   *
   * @memberof SliderInputProps
   */
  setSliderValue?: (current: number) => void;
}

/**
 * Bug fix force slider output to be number and not number[]
 * @param WrappedComponent
 * @returns
 */
export const SliderInputNumberOutput: React.FC<SliderInputNumberOutputProps> = props => {
  const { setSliderValue, ...others } = props;
  const handleSetSliderValue = (value: number | number[]) => {
    setSliderValue(Array.isArray(value) ? value?.[0] : value);
  };
  return <SliderInput setSliderValue={handleSetSliderValue} {...others} />;
};
