import { Platform, StyleSheet } from 'react-native';

import { Theme } from '@masteos/aphrodite';

import { PlatformEnum } from '@app/types/platform';

export const getStyles = (theme: Theme) =>
  StyleSheet.create({
    alignCenter: {
      textAlign: 'center',
    },
    bottomContainer: {
      bottom: theme.spacing.SIZE_06,
      marginTop: theme.spacing.SIZE_04,
      width: '100%',
    },
    buttonContainer: {
      height: theme.spacing.SIZE_10,
      width: '100%',
    },
    googleButton: {
      width: '100%',
    },
    googleButtonWrapper: {
      width: '100%',
    },
    languageSwitcher: {
      alignItems: 'flex-end',
      marginTop: 24,
      paddingRight: 24,
      width: '100%',
    },
    orText: {
      color: theme.palette.neutral[700],
      marginBottom: theme.spacing.SIZE_07,
      marginTop: theme.spacing.SIZE_04,
    },
    root: {
      alignItems: 'center',
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      marginHorizontal: 'auto',
      marginTop: 30,
      maxWidth: Platform.OS === PlatformEnum.Web ? 335 : '100%',
      paddingHorizontal: theme.spacing.SIZE_06,
      paddingVertical: theme.spacing.SIZE_08,
      width: '100%',
    },
    subtitle: {
      marginBottom: theme.spacing.SIZE_11,
      marginTop: theme.spacing.SIZE_04,
      textAlign: 'center',
    },
  });
