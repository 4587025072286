import React from 'react';

import * as yup from 'yup';
import { ErrorResponse } from '@apollo/client/link/error';

import { Spacer, useTheme } from '@masteos/aphrodite';

import * as ErrorMonitoring from '@app/services/errorMonitoring';
import { useTranslation } from '@app/services/translations/translations';
import { Form, Input, SubmitButton } from '@app/shared/contexts/form/index.web';
import { useToastContext } from '@app/shared/contexts/toast/Toast';
import { useUpdateCustomerPasswordMutation } from '@app/libs/apollo/introspection';
import * as S from '@app/features/profile/profile.styles.web';

export const ProfilePassword: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [updatePassword] = useUpdateCustomerPasswordMutation();
  const { setValidMsg } = useToastContext();
  const formProps = {
    initialValues: {
      currentPassword: '',
      newPassword: '',
      newPasswordConfirmation: '',
    },
    onSubmit: async (data, { setError }) => {
      try {
        await updatePassword({
          variables: {
            passwordsInput: {
              currentPassword: data.currentPassword.trim(),
              password: data.newPassword.trim(),
            },
          },
        });
        setValidMsg({ timeout: 3000, title: t('profile.updatePasswordMessage') });
      } catch (error) {
        // TODO: remove this when refacto with react hook form
        // Error won't show without these first setError calls
        setError('currentPassword', '');
        setError('newPassword', '');
        const { graphQLErrors } = error as ErrorResponse;
        if (!graphQLErrors) {
          ErrorMonitoring.logError(error);
          setError('currentPassword', t('profile.updatePasswordErrorMessage.default'));
          return;
        }
        const [{ message }] = graphQLErrors;
        if (message === 'Bad Request') {
          setError('currentPassword', t('profile.updatePasswordErrorMessage.badPassword'));
        } else if (message === 'Bad Request Exception') {
          setError('newPassword', t('profile.updatePasswordErrorMessage.samePassword'));
        }
      }
    },
    schema: yup.object().shape({
      currentPassword: yup
        .string()
        .required(t('register.requiredField'))
        .trim()
        .label(t('shared.defaultErrorFieldName')),
      newPassword: yup
        .string()
        .required(t('register.requiredField'))
        .trim()
        .min(6, t('profile.newPasswordLengthError', { value: 6 }))
        .label(t('shared.defaultErrorFieldName')),
      newPasswordConfirmation: yup
        .string()
        .required(t('register.requiredField'))
        .trim()
        .oneOf([yup.ref('newPassword'), null], t('profile.newPasswordConfirmationError'))
        .label(t('shared.defaultErrorFieldName')),
    }),
  };

  return (
    <S.FormWrapper>
      <Form {...formProps}>
        <S.Form>
          <Input isPassword label={t('profile.oldPasswordLabel')} name="currentPassword" />
          <Spacer height={theme.spacing.SIZE_04} />

          <Input isPassword label={t('shared.newPassword')} name="newPassword" />
          <Spacer height={theme.spacing.SIZE_04} />

          <Input
            isPassword
            label={t('profile.newPasswordConfirmationLabel')}
            name="newPasswordConfirmation"
          />
          <Spacer height={theme.spacing.SIZE_07} />
          <SubmitButton>{t('shared.save')}</SubmitButton>
        </S.Form>
      </Form>
    </S.FormWrapper>
  );
};
