import React from 'react';

import { RouteProp } from '@react-navigation/native';

import { AuthBridge } from '@app/features/authBridge/AuthBridge';
import { PublicNavigatorRoutes, PublicStackParamList } from '@app/navigation/types/routes';

type AuthBridgeRouteProp = RouteProp<PublicStackParamList, PublicNavigatorRoutes.Auth>;

export const AuthBridgeView: React.FC<AuthBridgeRouteProp> = ({ params }) => {
  return <AuthBridge deletedAccount={params?.deletedAccount} />;
};

// eslint-disable-next-line import/no-default-export
export default AuthBridgeView;
