export const TRANSLATION_SERVICE = 'DeepL';

export const INFINITY_MAX_BUDGET = 999999999;

export const TERMS_URL = 'https://www.masteos.com/cgu';

export const LINKS_MASTEOS = 'https://links.masteos.com';

export const PRIVACY_POLICY = 'https://www.masteos.com/confidentialite';

export const DESIGN_BETA_TESTERS_TYPEFORM_URL = 'https://masteos.typeform.com/to/vHBSxyjj';

export const LocalStorageValues = {
  DATE: 'date',
  REGISTER_LOCAL_STORAGE_KEY: 'masteos.register',
} as const;

export enum TranslateContentType {
  Source = 'SOURCE',
  Target = 'TARGET',
}

export const SEPA_COUNTRY_CODES = {
  ALAND_ISLANDS: { code: 'FI', codeLength: 18 },
  ANDORRA: { code: 'AD', codeLength: 24 },
  AUSTRIA: { code: 'AT', codeLength: 20 },
  AZORES: { code: 'PT', codeLength: 25 },
  BELGIUM: { code: 'BE', codeLength: 16 },
  BULGARIA: { code: 'BG', codeLength: 22 },
  CANARY_ISLAND: { code: 'ES', codeLength: 24 },
  CROATIA: { code: 'HR', codeLength: 21 },
  CYPRUS: { code: 'CY', codeLength: 28 },
  CZECH_REPUBLIC: { code: 'CZ', codeLength: 24 },
  DENMARK: { code: 'DK', codeLength: 18 },
  ESTONIA: { code: 'EE', codeLength: 20 },
  FINLAND: { code: 'FI', codeLength: 18 },
  FRANCE: { code: 'FR', codeLength: 27 },
  GERMANY: { code: 'DE', codeLength: 22 },
  GIBRALTAR: { code: 'GI', codeLength: 23 },
  GREECE: { code: 'GR', codeLength: 22 },
  HUNGARY: { code: 'HU', codeLength: 28 },
  ICELAND: { code: 'IS', codeLength: 26 },
  IRELAND: { code: 'IE', codeLength: 22 },
  ITALY: { code: 'IT', codeLength: 27 },
  LATVIA: { code: 'LV', codeLength: 21 },
  LIECHTENSTEIN: { code: 'LI', codeLength: 21 },
  LITHUANIA: { code: 'LT', codeLength: 20 },
  LUXEMBOURG: { code: 'LU', codeLength: 20 },
  MADEIRA: { code: 'PT', codeLength: 25 },
  MALTA: { code: 'MT', codeLength: 31 },
  MONACO: { code: 'MC', codeLength: 27 },
  NETHERLANDS: { code: 'NL', codeLength: 18 },
  NORWAY: { code: 'NO', codeLength: 15 },
  POLAND: { code: 'PL', codeLength: 28 },
  PORTUGAL: { code: 'PT', codeLength: 25 },
  ROMANIA: { code: 'RO', codeLength: 24 },
  SAN_MARINO: { code: 'SM', codeLength: 27 },
  SLOVAKIA: { code: 'SK', codeLength: 24 },
  SLOVENIA: { code: 'SI', codeLength: 19 },
  SPAIN: { code: 'ES', codeLength: 24 },
  SWEDEN: { code: 'SE', codeLength: 24 },
  SWITZERLAND: { code: 'CH', codeLength: 21 },
  UK: { code: 'GB', codeLength: 22 },
  VATICAN: { code: 'VA', codeLength: 22 },
};

// Max 32 bits value
export const MAX_GRAPHQL_INT = 2147483647;
