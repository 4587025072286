import { Platform, StyleSheet } from 'react-native';

import { palette, Spacing, Theme } from '@masteos/aphrodite';

import { PlatformEnum } from '@app/types/platform';

export const getStyle = ({
  isMobile,
  isLowerThanTablet,
}: {
  isMobile: boolean;
  isLowerThanTablet: boolean;
}) =>
  StyleSheet.create({
    bodyContainer: {
      flex: 1,
      flexDirection: 'row',
      marginTop: Spacing.SIZE_04,
      ...(Platform.OS === PlatformEnum.Web
        ? { marginHorizontal: isLowerThanTablet ? Spacing.SIZE_06 : Spacing.SIZE_09 }
        : {}),
    },
    container: Platform.OS === PlatformEnum.Web ? { paddingBottom: '60px' } : { flex: 1 },
    drawerContainer: {
      flex: 1,
      width: !isMobile ? '480px' : '100%',
    },
    headerContainer: {
      backgroundColor: palette.base.white,
      paddingBottom: !isMobile
        ? Spacing.SIZE_07
        : Platform.OS === PlatformEnum.Web
        ? Spacing.SIZE_04
        : Spacing.NONE,
      paddingTop: !isMobile ? Spacing.SIZE_07 : Spacing.SIZE_04,
      top: 0,
      zIndex: 4,
      ...(Platform.OS === PlatformEnum.Web
        ? { position: 'sticky' as 'relative' }
        : { position: 'relative' }),
      ...(!isMobile ? { paddingHorizontal: Spacing.SIZE_09 } : {}),
    },
    transactionCardContainer: {
      alignItems: !isMobile ? 'baseline' : 'center',
      flex: 1,
      marginLeft: isMobile ? Spacing.NONE : Spacing.SIZE_08,
      marginTop: Spacing.SIZE_04,
    },
    transactionCardWrapper: { maxWidth: 320 },
  });

export const getContainerStyle = (theme: Theme) =>
  StyleSheet.create({ container: { backgroundColor: theme.palette.base.white, flex: 1 } });
