import React, { useCallback } from 'react';

import { Platform, StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { ParamListBase, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { ActionButton, ActionButtonWithTip, useResponsive } from '@masteos/aphrodite';

import { useMe } from '@app/shared/hooks/useMe';
import { config } from '@app/config';
import { RealEstate } from '@app/libs/apollo/introspection';
import { useTranslation } from '@app/services/translations/translations';
import { trackClickToShare } from '@app/services/tracking/trackTracking';
import { LikePropertyActionButton } from '@app/shared/components/LikePropertyActionButton/LikePropertyActionButton';
import { usePropertyLike } from '@app/shared/hooks/usePropertyLike';
import { onShare } from '@app/utils/share';
import { PublicNavigatorRoutes, RealEstateNavigatorRoutes } from '@app/navigation/types/routes';
import { navigationPushFromRef } from '@app/navigation/navigationRef';
import { PlatformEnum } from '@app/types/platform';

import { currentSearchEngineScrollPosition } from '../search-engine/utils/scrollTo.utils';

const styles = StyleSheet.create({
  rightElement: { flexDirection: 'row' },
  rightElementSM: {
    flexDirection: 'column',
  },
  wrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});
interface PropertyToolboxProps {
  property: RealEstate;
  style?: StyleProp<ViewStyle>;
  currentScrollPosition?: number;
}

type PropertyToolboxNavigationProp = StackNavigationProp<
  ParamListBase,
  RealEstateNavigatorRoutes.RealEstate
>;

export const PropertyToolbox: React.FC<PropertyToolboxProps> = ({
  property,
  style,
  currentScrollPosition,
}) => {
  const { id, title } = property;
  const baseUrl = config.isProduction
    ? 'https://links.masteos.com'
    : `${config.WEB_APP_URL}/property`;
  const { t } = useTranslation();
  const url = `${baseUrl}/${id}`;

  const navigation = useNavigation<PropertyToolboxNavigationProp>();

  const { isLowerThanLaptop, isLowerThanDesktop, isMobile } = useResponsive();
  const { customer } = useMe();
  const { onLike, onError } = usePropertyLike(property);
  const isSmallScreen = isLowerThanDesktop && !isLowerThanLaptop;
  const sharingContent = { title: title, url: url };

  const handleGoBack = () => {
    if (navigation.canGoBack()) {
      Platform.OS === PlatformEnum.Web && currentSearchEngineScrollPosition(currentScrollPosition);
      navigation.goBack();
    } else if (customer?.id) {
      navigationPushFromRef(navigation.getState().routeNames[0]);
    } else {
      navigation.navigate(PublicNavigatorRoutes.Auth);
    }
  };

  const onPressShare = useCallback(async () => {
    await onShare(sharingContent, true);

    trackClickToShare({ propertyId: property.id });
  }, [property.id]);

  const shouldUseShare =
    Platform.OS === 'ios' ||
    Platform.OS === 'android' ||
    (Platform.OS === 'web' && isMobile && navigator.canShare?.(sharingContent));

  return (
    <View style={[styles.wrapper, style]}>
      {isLowerThanLaptop ? (
        <View>
          <ActionButton
            rightIconName="ArrowLeft"
            onPress={handleGoBack}
            size="m"
            testID="property-close-button"
            withSolidBorder={!isLowerThanLaptop}
          />
        </View>
      ) : null}
      <View style={isSmallScreen ? styles.rightElementSM : styles.rightElement}>
        {shouldUseShare ? (
          <ActionButton
            onPress={onPressShare}
            withSolidBorder={!isLowerThanLaptop}
            leftIconName="Share"
          />
        ) : (
          <ActionButtonWithTip
            tooltipText={t('shared.copiedToClipboard')}
            onPress={onPressShare}
            withSolidBorder={!isLowerThanLaptop}
            leftIconName="Link"
          />
        )}

        {!!customer?.id && (
          <LikePropertyActionButton
            realEstateId={property.id}
            isFavorite={property.metadata.isFavorites}
            onLike={onLike}
            onError={onError}
          />
        )}
      </View>
    </View>
  );
};
