import React, { PropsWithChildren } from 'react';

import { View } from 'react-native';

import { useResponsive } from '@masteos/aphrodite';

import { PageContainerProps } from './PageContainer.types';
import { getContainerPageStyle } from './PageContainer.styles';

export const PageContainer: React.FC<PropsWithChildren<PageContainerProps>> = ({
  children,
  maxWidth,
}) => {
  const { isLowerThanTablet } = useResponsive();
  const styles = getContainerPageStyle({ isLowerThanTablet, maxWidth });

  return (
    <View style={styles.container} testID="page-container">
      {children}
    </View>
  );
};

// Do not remove displayName, its required for PageLayout check of child
PageContainer.displayName = 'PageContainer';
