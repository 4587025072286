export enum InAppSignInModalCloseReason {
  CrossOrOutsideClick = 'CrossOrOutsideClick',
  NextStep = 'NextStep',
  CompleteStep = 'SearchRedirectionButton',
}

export enum SignInModalState {
  Overview = '1.Start',
  SignIn = '2.StartSignIn',
  Signed = '3.Signed',
  Congrats = '4.End',
}

export type OverviewModalProps = {
  advisorFullName: string;
  handleNextStep: () => void;
};

export type SignInModalProps = {
  createPandadocLink: { sessionId: string };
  handleNextStep: () => void;
};

export type CongratsModalProps = { handleNextStep: () => void };
