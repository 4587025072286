import React from 'react';

import { Platform } from 'react-native';

import { ProjectList } from '@app/features/projectList/ProjectList';
import { ScreenWrapper } from '@app/core/screenWrapper/ScreenWrapper';

export const ProjectListView: React.FC = () => {
  const isMobile = Platform.OS !== 'web';
  return (
    <ScreenWrapper withoutScrollView withoutPadding={isMobile}>
      <ProjectList />
    </ScreenWrapper>
  );
};

// eslint-disable-next-line import/no-default-export
export default ProjectListView;
