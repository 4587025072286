import { Platform, StyleSheet } from 'react-native';

import { Theme } from '@masteos/aphrodite';

export const getStyles = (
  theme: Theme,
  userExist: boolean,
  isNativeOrLowerThanDesktop: boolean,
  isLowerThanLaptop: boolean,
  isNativeOrResponsive: boolean
) =>
  StyleSheet.create({
    buttonMobileFullWidthWrapperStyle: {
      ...(isNativeOrLowerThanDesktop && { flex: 1 }),
    },
    buttonNotInterestedTextStyle: {
      marginRight: -8,
    },
    buttonsContainerWrapper: {
      alignItems: 'center',
      backgroundColor: theme.palette.base.white,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      width: '100%',
      ...(isNativeOrLowerThanDesktop && { paddingHorizontal: 0, paddingVertical: 16 }),
    },
    container: {
      bottom: userExist && isLowerThanLaptop && Platform.OS === 'web' ? 48 : 0,
      justifyContent: 'center',
      ...(Platform.OS === 'web' ? { position: 'sticky' as 'absolute' } : { position: 'relative' }),
      backgroundColor: theme.palette.base.white,
      borderTopColor: theme.palette.neutral[200],
      borderTopWidth: isNativeOrLowerThanDesktop ? 1 : 0,
      paddingHorizontal: isNativeOrLowerThanDesktop ? 20 : 0,
      width: '100%',
    },
    preAppointment: {
      alignSelf: 'flex-end',
      maxWidth: isNativeOrResponsive ? '100%' : '50%',
    },
  });
