import React from 'react';

import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { Button, Gap, Spacing } from '@masteos/aphrodite';

import { useTranslation } from '@app/services/translations/translations';
import { PublicNavigatorRoutes, PublicStackParamList } from '@app/navigation/types/routes';

type PropertyLoginButtonsNavigationProp = StackNavigationProp<PublicStackParamList>;

export const PropertyLoginButtons: React.FC = () => {
  const { t } = useTranslation();
  const navigation = useNavigation<PropertyLoginButtonsNavigationProp>();
  const styles = {
    button: {
      height: '48px',
      margin: Spacing.SIZE_03,
    },
    wrapper: {
      justifyContent: 'center',
      width: '100%',
    },
  } as const;

  const navigateToAuth = () => navigation.navigate(PublicNavigatorRoutes.Auth);
  const navigateToRegister = () => navigation.navigate(PublicNavigatorRoutes.Register);

  return (
    <Gap gap={Spacing.SIZE_03} data-testId="property-login-btn" style={styles.wrapper}>
      <Button variant="secondary" onPress={navigateToAuth} style={styles.button}>
        {t('shared.login')}
      </Button>
      <Button style={styles.button} onPress={navigateToRegister}>
        {t('shared.register')}
      </Button>
    </Gap>
  );
};
