import React, { useCallback, useMemo, useState } from 'react';

import { LayoutChangeEvent, Platform, ScrollView, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { SafeAreaView } from 'react-native-safe-area-context';

import {
  ActionButton,
  Carousel,
  DotSize,
  DotsList,
  OnboardingScreen,
  useResponsive,
  useTheme,
} from '@masteos/aphrodite';

import { PlatformEnum } from '@app/types/platform';

import { getStyles } from './Onboarding.styles';
import { OnboardingTemplateType } from './Onboarding.types';

export const OnboardingTemplate: React.FC<OnboardingTemplateType> = ({
  onboardingPagesScreen,
  actions,
  onPrev,
  nbSlide,
  carouselProps,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { isMobile, isLowerThanLaptop, isGreaterThanTablet } = useResponsive();
  const [height, setHeight] = useState<number>(0);

  const styles = getStyles({
    heightCarousel: height,
    isGreaterThanTablet,
    isLowerThanLaptop,
    isMobile,
    theme,
  });

  /* Need to check the size of the parent to assign it to the scroll 
     view because since it's crap it doesn't handle 100% height
  */
  const onLayout = useCallback(
    (event: LayoutChangeEvent) => {
      const { layout } = event.nativeEvent;
      layout.height !== height && setHeight(layout.height);
    },
    [height, setHeight]
  );

  const onboardingPage = useMemo(
    () =>
      onboardingPagesScreen.map(
        ({
          primaryAction,
          secondaryAction,
          subTitle,
          key,
          description,
          primaryTitle,
          secondaryTitle,
          title,
          pageTrackingEvent,
          ...page
        }) => {
          return (
            <ScrollView
              key={key}
              contentContainerStyle={styles.containerScreen}
              testID="OnboardingTemplateScreenContainer"
            >
              <OnboardingScreen
                description={t(description)}
                subTitle={t(subTitle)}
                title={t(title)}
                primaryTitle={t(primaryTitle)}
                secondaryTitle={t(secondaryTitle)}
                primaryAction={actions[primaryAction]}
                secondaryAction={actions[secondaryAction]}
                {...page}
              />
            </ScrollView>
          );
        }
      ),
    [actions, onboardingPagesScreen, styles.containerScreen, t]
  );

  const Wrapper = Platform.OS === PlatformEnum.Web ? React.Fragment : SafeAreaView;

  return (
    <Wrapper>
      <View style={styles.wrapper} testID="OnboardingTemplateWrapper">
        {!!onPrev && (
          <View style={styles.backContainer} testID="OnboardingTemplateBackContainer">
            <ActionButton
              leftIconName="ChevronLeft"
              onPress={onPrev}
              size="s"
              testID="OnboardingTemplateBackButton"
            />
          </View>
        )}
        <View
          style={styles.carouselContainer}
          testID="OnboardingTemplateCarouselContainer"
          onLayout={onLayout}
        >
          <Carousel {...carouselProps}>{onboardingPage}</Carousel>
        </View>
        <View style={styles.dotsContainer} testID="OnboardingTemplateDotsContainer">
          <DotsList nbDot={nbSlide} activeIndex={carouselProps.currentStep} size={DotSize.M} />
        </View>
      </View>
    </Wrapper>
  );
};
