import React, { useCallback, useMemo } from 'react';

import { View } from 'react-native';

import { Spacer, StepStatus, Text, useResponsive, useTheme } from '@masteos/aphrodite';

import { useTranslation } from '@app/services/translations/translations';
import { getStageStatus } from '@app/utils/project';
import { ProjectDetailStage } from '@app/features/project/ProjectDetail/ProjectDetailStage';
import { ProjectDetailStageListWithStatus } from '@app/features/project/ProjectDetail/ProjectDetailStageListWithStatus';
import { getProjectTabScreenStyles } from '@app/features/project/ProjectTabScreen/ProjectTabScreen.styles';
import { ProjectCategory } from '@app/features/project/Project.types';

import { detailSteps, ProjectTabScreenProps } from './ProjectTabScreen.type';

export const ProjectTabScreen: React.FC<ProjectTabScreenProps> = ({
  realEstate,
  projectCategory,
  projectSteps,
  onExpandSubSteps,
  onExpandSubStepsDetails,
  stageRefs,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { isLowerThanTablet, isLowerThanLaptop } = useResponsive();
  const styles = useMemo(
    () => getProjectTabScreenStyles({ isLowerThanLaptop, isLowerThanTablet }),
    [isLowerThanTablet, isLowerThanLaptop]
  );
  const getStagesByStatus = useCallback(
    (stepStatus: StepStatus) =>
      detailSteps[projectCategory]['fr-FR'].filter(
        (_, index) => getStageStatus(index + 1, projectSteps?.currentStage) === stepStatus
      ),
    [projectCategory, projectSteps?.currentStage]
  );

  const { incomingStages, ongoingStages, finishedStages } = useMemo(
    () => ({
      finishedStages: getStagesByStatus(StepStatus.Finished),
      incomingStages: getStagesByStatus(StepStatus.Incoming),
      ongoingStages: getStagesByStatus(StepStatus.Ongoing),
    }),
    [getStagesByStatus]
  );

  const isViewWithStatus =
    realEstate.building.address?.countryCode === 'FR' &&
    projectCategory === ProjectCategory.Transaction;

  return (
    <View style={styles.container}>
      <Text textStyle="Headline1Medium">{t(`project.${projectCategory}.detailSteps.title`)}</Text>
      <Spacer height={theme.spacing.SIZE_05} />

      {isViewWithStatus ? (
        <>
          <ProjectDetailStageListWithStatus
            status={StepStatus.Ongoing}
            stages={ongoingStages}
            onExpandSubSteps={onExpandSubSteps}
            onExpandSubStepsDetails={onExpandSubStepsDetails}
            stageRefs={stageRefs}
            projectCategory={projectCategory}
          />

          <ProjectDetailStageListWithStatus
            status={StepStatus.Incoming}
            stages={incomingStages}
            onExpandSubSteps={onExpandSubSteps}
            onExpandSubStepsDetails={onExpandSubStepsDetails}
            stageRefs={stageRefs}
            projectCategory={projectCategory}
          />

          <ProjectDetailStageListWithStatus
            status={StepStatus.Finished}
            stages={finishedStages}
            onExpandSubSteps={onExpandSubSteps}
            onExpandSubStepsDetails={onExpandSubStepsDetails}
            stageRefs={stageRefs}
            projectCategory={projectCategory}
          />
        </>
      ) : (
        detailSteps[projectCategory]['fr-FR'].map((stage, index) => (
          <View key={stage.title}>
            <ProjectDetailStage
              onExpandSubSteps={onExpandSubSteps(index + 1)}
              onExpandSubStepsDetails={onExpandSubStepsDetails(index + 1)}
              stageNumber={index + 1}
              status={StepStatus.Incoming}
              stageRefs={stageRefs}
              projectCategory={projectCategory}
              {...stage}
            />
            <Spacer height={theme.spacing.SIZE_05} />
          </View>
        ))
      )}
    </View>
  );
};
