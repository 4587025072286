import { useEffect, useState } from 'react';

import axios from 'axios';
import { Platform } from 'react-native';

import { initialize as initializeNumbersFormatter } from '@app/services/numbersFormatter/numbersFormatter';
import { AppEnvironment, config, setConfig } from '@app/config';
import { PlatformEnum } from '@app/types/platform';

import { LoadedConfig } from './config.types';

export const useGetConfig = (): LoadedConfig => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        // TODO : to remove when Heroku is shutdown
        const isWeb = Platform.OS === PlatformEnum.Web;
        if (isWeb && process.env.ENVIRONMENT !== AppEnvironment.Development) {
          const { data } = await axios.get('/config.json');
          setConfig(data);
        }

        const { data } = await axios.get(`${config.APOLLON_API_URL}/config?appName=Apollon`);
        setConfig(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoaded(true);
        initializeNumbersFormatter();
      }
    })();
  }, []);

  return { error, isLoaded };
};
