import { Trilean } from '@masteos/aphrodite/lib/typescript/molecules/trilean-controller/TrileanController.types';
import { SvgName } from '@masteos/aphrodite/lib/typescript/molecules/reinsurance/Reinsurance.types';

import { SearchEngineHeaderType } from '@app/features/search-engine/compounds/search-engine-header/searchEngineHeader.type';
import {
  AvailableCountryCode,
  PositioningStatus,
  Project,
  ProjectRenting,
  PropertyKind,
  RealEstateBuilding,
  RealEstateTrending,
  RoomCountFilter,
} from '@app/libs/apollo/introspection';
import { SearchEngineListType } from '@app/features/search-engine/compounds/search-engine-list/searchEngineList.type';
import { SearchEngineFiltersDrawerType } from '@app/features/search-engine/compounds/search-engine-filters/search-engine-filters-drawer/searchEngineFiltersDrawer.type';
import { StateOfCustomer } from '@app/shared/hooks/useMe';
import { RealEstateItem } from '@app/shared/components/real-estate-card/realEstateCard.type';

import { UseSearchEngineFiltersProps } from './hooks/useSearchEngineFilters';
import { SearchEngineSortStrategy } from './compounds/search-engine-filters/search-engine-sort/SearchEngineSort.types';

export type Maybe<T> = T | Partial<Record<keyof T, undefined>>;

export enum SearchEngineSection {
  ALL = 'all',
  BOOKMARKED = 'bookmarked',
}

export enum SearchEngineSource {
  DRAWER = 'drawer',
  NO_RESULT = 'noResult',
  BUDGET_QF = 'budgetQF',
  COUNTRY_QF = 'countryQF',
  REGION_QF = 'regionQF',
  PROPERTY_TYPE_QF = 'propertyTypeQF',
  SORT_STRATEGY = 'sortStrategy',
}

export const informationCardTypeArray = ['seduction', 'repeche', 'introduction', 'signature'];
export type InformationCardType = (typeof informationCardTypeArray)[number];

export interface Card {
  position: number[];
  type: InformationCardType;
}

export interface AppointmentCardRules {
  cards: Card[];
  range: Array<number | string>;
}

export interface InformationCardItem {
  buttonText?: string;
  image: SvgName;
  key: number;
  text: string;
  title: string;
  type: InformationCardType;
}

export const isSearchEngineItem = (
  item: InformationCardItem | RealEstateItem
): item is RealEstateItem => {
  return !!(item as RealEstateItem)?.id;
};

export type SearchEngineLocationItem = {
  threshold?: number;
  position: number;
};

export type SearchEngineLocationState = {
  searchEngine?: SearchEngineLocationItem & {
    section: SearchEngineSection;
  };
};

export type SearchEngineFilterFormProps = {
  alreadyRented?: Trilean;
  atGroundFloor?: Trilean;
  budgetMax?: number | null;
  budgetMin?: number | null;
  capacity?: number | null;
  country?: AvailableCountryCode | null;
  flatSharing?: Trilean;
  regionList?: string[];
  roomCount?: RoomCountFilter[];
  typology?: PropertyKind[];
  withRenovation?: Trilean;
  sortStrategy?: SearchEngineSortStrategy;
};

export enum SearchEngineRealEstatesLoadingType {
  FetchMore = 'fetchmore',
  Refetch = 'refetch',
}

export type SearchEngineFilterForm = Required<Omit<SearchEngineFilterFormProps, 'capacity'>>;
export type SearchEngineCardsProps = Array<RealEstateItem | InformationCardItem>;

export type SearchEngineType = {
  state: StateOfCustomer;
  hasMore?: boolean;
  list: SearchEngineCardsProps;
  header: Pick<
    SearchEngineHeaderType,
    | 'section'
    | 'state'
    | 'resultCount'
    | 'bookmarkedCount'
    | 'filterCount'
    | 'onSectionChange'
    | 'hideFilterButton'
  >;
  filter: SearchEngineFilterFormProps & {
    defaultValues: SearchEngineFilterFormProps;
  };
  sortStrategy?: SearchEngineSortStrategy;
  position?: number;
  defaultThreshold?: number;
  countryList: SearchEngineFiltersDrawerType['countryList'];
  regionListByCountry: SearchEngineFiltersDrawerType['regionListByCountry'];
  listLoading: boolean;
  listLoadingIsRefresh?: boolean;
  filterLoading: boolean;
  onFilterShow: (source: string) => Promise<void> | void;
  onFilterReset: UseSearchEngineFiltersProps['resetSearchEngineFilters'];
  onFilterUpdate: (
    filter: SearchEngineFilterForm,
    source: SearchEngineSource
  ) => Promise<void> | void;
  onFilterCountryClick: SearchEngineFiltersDrawerType['onCountryClick'];
  onInformationCardPress: (key: number, type: string) => Promise<void> | void;
  onItemClick: (item: RealEstateItem, info: SearchEngineLocationItem) => Promise<void> | void;
  onItemBookmark: (item: RealEstateItem) => Promise<void> | void;
  onItemTooltipHover: SearchEngineListType['onItemTooltipHover'];
  onLoadMoreRealEstates?: () => void;
  form: any;
  resetForm: () => void;
};

export type realEstateToItemMapperProps = {
  id: string;
  type?: PropertyKind;
  coverImage: string;
  surface?: number;
  roomCount?: number;
  annual: { netReturn?: number; grossReturn?: number };
  building?: RealEstateBuilding;
  status: string;
  isNew: boolean;
  isExclusive: boolean;
  project?: Pick<Project, 'strategy' | 'projectAmount'> & {
    renovation: Pick<Project['renovation'], 'amount'>;
    renting: Pick<ProjectRenting, 'type'>;
  };
  metadata?: {
    isFavorites: boolean;
    isViewed: boolean;
    positioning?: {
      id: string;
      status: PositioningStatus;
      isSuggestion: boolean;
    };
  };
  trending?: RealEstateTrending;
  financialModelVersion: number;
};
