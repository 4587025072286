import React from 'react';

import { FinancialModel } from '@masteos/agora';
import { IconName, IconPopoverOrModal, useResponsive } from '@masteos/aphrodite';

import { currencyFormatter, percentFormatter } from '@app/utils/currency-formatter';
import { useFinancialModel } from '@app/shared/hooks/useFinancialModel';
import { RealEstate } from '@app/libs/apollo/introspection';
import { useMe } from '@app/shared/hooks/useMe';
import { useRealEstateTranslation } from '@app/shared/hooks/useRealEstateTranslations';
import { useLocationInfoPermission } from '@app/shared/hooks/useLocationInfoPermission';

interface Rows {
  icon: IconName;
  title: string;
  data: string;
  testID: string;
  hint?: React.ReactNode;
}

export interface PropertyDescriptionOutput {
  financialModel: FinancialModel;
  hasMandate: boolean;
  rows: Rows[];
  width: number | string;
  trendingUp: number;
  isLocationInformationVisible: boolean;
}

const DATA_BLOCK_WIDTH_ON_MOBILE = '100%';
const DATA_BLOCK_WIDTH = 234;

export const usePropertyDescription = (property: RealEstate): PropertyDescriptionOutput => {
  const { t } = useRealEstateTranslation();
  const { isLowerThanLaptop } = useResponsive();
  const { customer } = useMe();
  const { defaultFinancialModel: financialModel, loading } = useFinancialModel(property);
  const trendingUp = property.trending?.appreciation?.evaluationRate * 100;
  const hasMandate = customer?.searchMandate?.valid;
  const isLocationInformationVisible = useLocationInfoPermission(property.id);

  const width = isLowerThanLaptop ? DATA_BLOCK_WIDTH_ON_MOBILE : DATA_BLOCK_WIDTH;

  let rows: Rows[] = [];

  if (!loading) {
    rows = [
      {
        data: currencyFormatter(financialModel?.projectAmount, 0),
        icon: 'Wallet',
        testID: 'property-general-data-block-project-amount',
        title: t('shared.financialTool.totalBudget'),
      },
      {
        data: percentFormatter(financialModel?.grossReturn, 1),
        hint: (
          <IconPopoverOrModal
            title={t('propertySynthese.tooltip.grossReturnTitle')}
            content={t('propertySynthese.tooltip.grossReturnDescription', {
              context: `v${property.financialModelVersion}`,
            })}
            iconName="Info"
            iconSize="s"
          />
        ),
        icon: 'Chart',
        testID: 'property-general-data-block-gross-return',
        title: t('shared.financialTool.grossReturn'),
      },
    ];

    if (trendingUp > 0) {
      rows.push({
        data: percentFormatter(trendingUp, 1),
        icon: 'TrendingUp',
        testID: 'property-general-data-block-annual-revalorisation',
        title: `${t('propertyDescription.annualRevalorisation')}*`,
      });
    }

    rows.push({
      data: percentFormatter(financialModel?.netReturn, 1),
      hint: (
        <IconPopoverOrModal
          title={t('propertySynthese.tooltip.netReturnTitle')}
          content={t('propertySynthese.tooltip.netReturnDescription', {
            context: `v${property.financialModelVersion}`,
          })}
          iconName="Info"
          iconSize="s"
        />
      ),
      icon: 'Chart',
      testID: 'property-general-data-block-net-return',
      title: t('shared.financialTool.netReturn'),
    });
  }

  return { financialModel, hasMandate, isLocationInformationVisible, rows, trendingUp, width };
};
