import React from 'react';

import { Ges } from '@masteos/agora';
import { BottomSheet, PerformanceIndicator } from '@masteos/aphrodite';

import { useTranslation } from '@app/services/translations/translations';

interface PropertyRenovationGESModalProps {
  value: string;
  isVisibleGESModal: boolean;
  setIsVisibleGESModal: () => void;
}

export const PropertyRenovationGESModal: React.FC<PropertyRenovationGESModalProps> = ({
  value,
  isVisibleGESModal,
  setIsVisibleGESModal,
}) => {
  const { t } = useTranslation();
  const data = [
    {
      color: '#63C7FF',
      description: t('shared.performanceIndicator.GES.aDescription'),
      label: t('shared.performanceIndicator.GES.aLabel'),
      value: Ges.A,
    },
    {
      color: '#2EB4FF',
      description: t('shared.performanceIndicator.GES.bDescription'),
      label: t('shared.performanceIndicator.GES.bLabel'),
      value: Ges.B,
    },

    {
      color: '#00A0FA',
      description: t('shared.performanceIndicator.GES.cDescription'),
      label: t('shared.performanceIndicator.GES.cLabel'),
      value: Ges.C,
    },
    {
      color: '#4C7993',
      description: t('shared.performanceIndicator.GES.dDescription'),
      label: t('shared.performanceIndicator.GES.dLabel'),
      value: Ges.D,
    },
    {
      color: '#919191',
      description: t('shared.performanceIndicator.GES.eDescription'),
      label: t('shared.performanceIndicator.GES.eLabel'),
      value: Ges.E,
    },
    {
      color: '#5E5E5E',
      description: t('shared.performanceIndicator.GES.fDescription'),
      label: t('shared.performanceIndicator.GES.fLabel'),
      value: Ges.F,
    },
    {
      color: '#454545',
      description: t('shared.performanceIndicator.GES.gDescription'),
      label: t('shared.performanceIndicator.GES.gLabel'),
      value: Ges.G,
    },
  ];

  return (
    <BottomSheet visible={isVisibleGESModal} onPressClose={setIsVisibleGESModal}>
      <PerformanceIndicator
        title={t('shared.performanceIndicator.GES.title')}
        caption={t('shared.performanceIndicator.GES.caption')}
        labelLowest={t('shared.performanceIndicator.GES.labelLowest')}
        labelHighest={t('shared.performanceIndicator.GES.labelHighest')}
        value={value}
        data={data}
      />
    </BottomSheet>
  );
};
