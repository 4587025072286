import { GetCustomerQuery } from '@app/libs/apollo/introspection';
import { identify } from '@app/libs/segment/segment';
import { isProAccount } from '@app/utils/computedUsers';

export const trackIdentify = (customer: GetCustomerQuery['me']): void => {
  identify?.(customer.id, {
    advisorEmail: customer.advisor?.email,
    countryCode: customer.preferences.countryCode,
    financingLetterStatus: customer.financingLetterStatus,
    firstName: customer.firstName,
    hasASearchMandate: customer.searchMandate?.valid ?? false,
    hasAnActiveTransaction: customer.hasOngoingProject,
    isProAccount: isProAccount(customer.account.roles),
    language: customer.lang,
    lastName: customer.lastName,
    maxBudget: customer.preferences.budgetMax,
    phoneNumber: customer.phoneNumber,
    searchRegions: customer.preferences.regions,
    userEmail: customer.email,
  });
};

export const trackSimpleIdentify = (userId: string, userEmail: string): void => {
  identify(userId, {
    userEmail,
  });
};
