import React from 'react';

import { palette, Spacing } from '@masteos/aphrodite';

import { ControlledDrawer } from '@app/shared/components/Drawer/ControlledDrawer.web';

import { UniversalDrawerProps } from './UniversalDrawer.types';

export const UniversalDrawer: React.FC<UniversalDrawerProps> = ({
  isOpen,
  direction,
  showCloseBtn,
  onClickCrossBtn,
  onClickBackdrop,
  children,
}) => {
  const styles: Record<string, React.CSSProperties> = {
    drawerStyle: {
      background: palette.base.white,
      overflow: 'auto',
      position: 'fixed',
      right: 0,
      top: 0,
      zIndex: 4,
    },
    filterButton: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingRight: Spacing.SIZE_09,
      paddingTop: Spacing.SIZE_09,
    },
  };

  return (
    <ControlledDrawer
      isOpen={isOpen}
      direction={direction}
      showCloseBtn={showCloseBtn}
      onClickCrossBtn={onClickCrossBtn}
      onClickBackdrop={onClickBackdrop}
      style={styles.drawerStyle}
    >
      {children}
    </ControlledDrawer>
  );
};
