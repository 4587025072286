import { useEffect } from 'react';

import { ApolloError, ApolloQueryResult, useLazyQuery } from '@apollo/client';
import { isNil } from 'lodash';

import { ID } from '@masteos/agora';

import { useTranslation } from '@app/services/translations/translations';
import { PublicRealEstate, RealEstate } from '@app/libs/apollo/introspection';
import queryRealEstate from '@app/shared/graphql/get-real-estate.graphql';
import queryPublicRealEstate from '@app/shared/graphql/get-public-real-estate.graphql';
import { FeatureFlags } from '@app/constants/feature-flags';
import { getAPILang } from '@app/libs/i18n/helper';
import { I18nSupportedLanguage } from '@app/libs/i18n/i18n.types';

import { useFeatureFlags } from './useFeatureFlag';
import { useMe } from './useMe';

type UseQueryRealEstateProps = {
  id: ID;
};

type ReturnType = {
  loading: boolean;
  error: ApolloError;
  realEstate?: RealEstate | PublicRealEstate | null;
  refetch?: () => Promise<ApolloQueryResult<unknown>>;
};

export const useQueryRealEstate = ({ id: realEstateId }: UseQueryRealEstateProps): ReturnType => {
  const { customer } = useMe();
  const { isEnabled } = useFeatureFlags();
  const { i18n } = useTranslation();

  const isTranslationEnabled = isEnabled(FeatureFlags.translation);
  const apiLang = getAPILang(i18n.resolvedLanguage as I18nSupportedLanguage);
  const [getRealEstate, { loading, error, data, refetch }] = useLazyQuery<{
    realEstate: RealEstate;
  }>(queryRealEstate);
  const [
    getPublicRealEstate,
    {
      loading: publicLoading,
      error: publicError,
      data: publicData,
      refetch: refetchPublicRealEstate,
    },
  ] = useLazyQuery(queryPublicRealEstate);

  useEffect(() => {
    if (isNil(isTranslationEnabled)) {
      return;
    }

    const variables = { id: realEstateId, ...(isTranslationEnabled ? { lang: apiLang } : {}) };
    if (customer?.id) {
      getRealEstate({ variables });
    } else {
      getPublicRealEstate({ variables });
    }
  }, [apiLang, customer?.id, isTranslationEnabled, realEstateId]);

  if (customer?.id) {
    return { error, loading, realEstate: data?.realEstate, refetch };
  }
  return {
    error: publicError,
    loading: publicLoading,
    realEstate: publicData?.publicRealEstate,
    refetch: refetchPublicRealEstate,
  };
};
