import React from 'react';

import { ModalPosition } from '@masteos/aphrodite';

import { ModalKey } from '../modalManager.types';

export enum ModalManagerActions {
  CLEAR_EXCLUDED_MODALS = 'CLEAR_EXCLUDED_MODALS',
  OPEN_MODAL = 'OPEN_MODAL',
  CLOSE_MODAL = 'CLOSE_MODAL',
  SET_EXCLUDED = 'SET_EXCLUDED',
  SET_TITLE = 'SET_TITLE',
}

export interface ModalManagerState {
  component: React.ReactNode;
  excludedModals: string[];
  isVisible: boolean;
  modalKey?: ModalKey;
  position?: ModalPosition;
  webSize?: 's' | 'm' | 'l';
  title?: string | React.ReactNode;
  callbackClose?: (params?: unknown) => void;
}

export interface ExcludedModalsState {
  excludedModals: string[];
}

export interface SetTitleModalState {
  title: string | React.ReactNode;
}

interface ActionOpenModal {
  type: ModalManagerActions.OPEN_MODAL;
  payload?: ModalManagerState;
}

interface ActionCloseModal {
  type: ModalManagerActions.CLOSE_MODAL;
}

interface ActionClearExcludedModals {
  type: ModalManagerActions.CLEAR_EXCLUDED_MODALS;
}

interface ActionSectExcludedModals {
  payload?: ExcludedModalsState;
  type: ModalManagerActions.SET_EXCLUDED;
}

interface ActionSetTitleModal {
  payload?: SetTitleModalState;
  type: ModalManagerActions.SET_TITLE;
}

export type ModalManagerAction =
  | ActionOpenModal
  | ActionClearExcludedModals
  | ActionCloseModal
  | ActionSectExcludedModals
  | ActionSetTitleModal;
