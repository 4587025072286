import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
export type { Extras } from '@sentry/types';

import { config } from '@app/config';

export const { init, captureMessage, captureException } = Sentry;

export const buildInitOptions = (): Sentry.BrowserOptions => ({
  autoSessionTracking: true,
  dsn: config.SENTRY_DSN,
  // see https://forum.sentry.io/t/resizeobserver-loop-limit-exceeded/8402/3
  ignoreErrors: [
    'ResizeObserver loop limit exceeded', // CHROME
    'ResizeObserver loop completed with undelivered notifications', // FIREFOX
    /Loading chunk [0-9]* failed/, // webpack chunk unavailable after new release
    /window.lintrk/, // gtm producing errors that we don't care about
  ],
  integrations: [new Integrations.BrowserTracing()],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});
