import AsyncStorage from '@react-native-async-storage/async-storage';

import * as ErrorMonitoring from '@app/services/errorMonitoring';

export type StorageKey =
  | '@storage-last-opened-tab'
  | 'All_cursor'
  | 'All_pageYPosition'
  | 'appointment_just_booked'
  | 'campaign'
  | 'content'
  | 'cursor_All'
  | 'cursor_Favorites'
  | 'cursor_Selected'
  | 'customer'
  | 'date'
  | 'deleted_account'
  | 'Favorites_cursor'
  | 'Favorites_pageYPosition'
  | 'feathers-jwt' // FIXME
  | 'i18nextLng'
  | 'masteos.onboarding'
  | 'masteos.register'
  | 'medium'
  | 'Selected_cursor'
  | 'Selected_pageYPosition'
  | 'signMandateReminder'
  | 'site_origin'
  | 'source'
  | 'sponsorEmail'
  | 'user' // FIXME
  | 'term'
  | 'searchEngine'
  | 'token'
  | 'isEnableNotificationsModalAlreadyDisplayed';

async function readString(storageKey: StorageKey): Promise<string | null> {
  try {
    return await AsyncStorage.getItem(storageKey);
  } catch (error) {
    if (error instanceof Error) {
      onAsyncStorageError(error);
    }
    return null;
  }
}

async function saveString(storageKey: StorageKey, value: string): Promise<void> {
  if (!value) {
    throw Error(`Aucune valeur à sauvegarder`);
  }
  try {
    await AsyncStorage.setItem(storageKey, value);
  } catch (error) {
    if (error instanceof Error) {
      onAsyncStorageError(error);
    }
  }
}

async function readObject<ObjectType>(storageKey: StorageKey): Promise<ObjectType | null> {
  try {
    const stringifiedObject = await AsyncStorage.getItem(storageKey);
    if (stringifiedObject) {
      return JSON.parse(stringifiedObject);
    }
    return null;
  } catch (error) {
    if (error instanceof Error) {
      onAsyncStorageError(error);
    }
    return null;
  }
}

async function saveObject(storageKey: StorageKey, value: unknown): Promise<void> {
  try {
    const stringifiedValue = JSON.stringify(value);
    await AsyncStorage.setItem(storageKey, stringifiedValue);
  } catch (error) {
    if (error instanceof Error) {
      onAsyncStorageError(error);
    }
  }
}

async function clear(storageKey: StorageKey): Promise<void> {
  try {
    await AsyncStorage.removeItem(storageKey);
  } catch (error) {
    if (error instanceof Error) {
      onAsyncStorageError(error);
    }
  }
}

function onAsyncStorageError(error: Error | undefined): void {
  ErrorMonitoring.logError(error);
}

export const storage = {
  clear,
  readObject,
  readString,
  saveObject,
  saveString,
};
