import React, { forwardRef, ForwardRefRenderFunction, useImperativeHandle } from 'react';

import { useTranslation } from 'react-i18next';
import { StyleProp, ViewStyle } from 'react-native';

import { Button } from '@masteos/aphrodite';

import { trackGoogleAuthButtonClicked } from '@app/services/tracking/trackTracking';
import { SSOProfile } from '@app/features/authBridge/authBridge.types';

import { useGoogleSignIn } from './useGoogleSignIn';

interface GoogleSignInProps {
  isLoading?: boolean;
  onSignInSuccess: (idToken: string, userInfo: SSOProfile) => void;
  onSignInFailure: () => void;
}

const buttonStyles: Record<string, StyleProp<ViewStyle>> = {
  wrapper: {
    width: '100%',
  },
};

export const GoogleSignInWithRef: ForwardRefRenderFunction<unknown, GoogleSignInProps> = (
  { isLoading, onSignInSuccess, onSignInFailure },
  ref
) => {
  const { t } = useTranslation();
  const signIn = useGoogleSignIn(onSignInSuccess, onSignInFailure);

  const handleSignIn = () => {
    trackGoogleAuthButtonClicked();
    signIn();
  };

  useImperativeHandle(ref, () => ({
    signIn: handleSignIn,
  }));

  return (
    <Button
      variant="tertiary"
      isLoading={isLoading}
      onPress={handleSignIn}
      wrapperStyle={buttonStyles.wrapper}
      iconName="Google"
      iconPlacement="left"
      testID="GoogleSignInButton"
    >
      {t('authTunnel.googleAuthButton')}
    </Button>
  );
};

export const GoogleSignIn = forwardRef(GoogleSignInWithRef);
