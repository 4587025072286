import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { isEmpty } from 'lodash';

import { Spacer, Spacing } from '@masteos/aphrodite';

import { config } from '@app/config';
import { storage } from '@app/services/storage/storage';
import { useMe } from '@app/shared/hooks/useMe';
import { WebView } from '@app/shared/components/WebView/WebView';
import { useTranslation } from '@app/services/translations/translations';
import {
  TakeAppointmentStep,
  trackTakeAppointmentIframe,
  trackToGTM,
} from '@app/services/tracking/trackTracking';

import { webViewEvents } from './advisorAppointment.constants';

type AdvisorAppointmentProps = { callBackAppointmentBooked: () => void };

export const AdvisorAppointment: React.FC<AdvisorAppointmentProps> = ({
  callBackAppointmentBooked,
}) => {
  const { customer, refetch } = useMe();
  const { i18n } = useTranslation();
  const [canGoBack, setCanGoBack] = useState(false);

  useEffect(
    () => () => {
      if (canGoBack) {
        refetch();
      }
    },
    [canGoBack]
  );

  const trackMeetEvent = () => {
    const EVENT_NAME = 'meet';
    trackToGTM(EVENT_NAME, customer.id);
  };

  const onMessage = useCallback(
    data => {
      if (data.event_id === webViewEvents.preQualificationForm && !!data.formSubmitted) {
        trackTakeAppointmentIframe({ step: TakeAppointmentStep.qualificationSubmitted });
      }
      if (data.event_id === webViewEvents.meeting && !!data.meetingBooked) {
        trackMeetEvent();
        storage.saveString('appointment_just_booked', new Date().toISOString());
        !canGoBack && setCanGoBack(true);
        callBackAppointmentBooked(); // Change goBack action on AdvisorAppointmentView
      }
      if (data.event_id === webViewEvents.meeting && !!data.hasAdvisor) {
        trackTakeAppointmentIframe({ hasAdvisor: true, step: TakeAppointmentStep.iframeLoaded });
      }
      if (data.event_id === webViewEvents.form && !!data.formId) {
        trackTakeAppointmentIframe({ hasAdvisor: false, step: TakeAppointmentStep.iframeLoaded });
      }
    },
    [canGoBack]
  );

  const webViewUri = config.HUBSPOT_ADVISOR_APPOINTMENT_URI;

  const uri = useMemo(() => {
    const { email, lastName, firstName, advisor } = customer;
    const { account, phoneNumber } = customer;
    const advisorUrlParams = !isEmpty(advisor)
      ? `&advisorFirstName=${encodeURIComponent(
          advisor.firstName.trim()
        )}&advisorLastName=${encodeURIComponent(advisor.lastName.trim())}`
      : '';
    const phoneNumberParam = phoneNumber ? `&phone=${phoneNumber}` : '';
    const affiliateParam = account?.affiliateEmail
      ? `&contact_origin=${encodeURIComponent(account.affiliateEmail)}`
      : '';
    return `${webViewUri}?email=${encodeURIComponent(email)}&firstname=${encodeURIComponent(
      firstName
    )}&lastname=${encodeURIComponent(
      lastName
    )}${advisorUrlParams}${phoneNumberParam}${affiliateParam}&hs_language=${i18n.resolvedLanguage}`;
  }, [customer, i18n.resolvedLanguage, webViewUri]);

  return (
    <>
      <Spacer height={Spacing.SIZE_07} />
      <WebView uri={uri} onMessage={onMessage} testID="advisor-appointment-web-view" />
    </>
  );
};
