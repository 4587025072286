import { StateOfCustomer } from '@app/shared/hooks/useMe';

export const LIMITS_PAGE_ONE = 17;
export const LIMITS_PAGE_TWO = 19;
export const LIMITS_PAGE_THREE = 19;
export const LIMITS_PAGE_OTHERS = 20;
const NUMBER_SPECIFIC_LIMITS = 3;
const THREE_PAGE_RESULTS = LIMITS_PAGE_ONE + LIMITS_PAGE_TWO + LIMITS_PAGE_THREE;

interface PaginationInfoResult {
  limit: number;
  loadMoreButton: number;
}

export const getInfoPerTotalResult = (
  stateOfCustomer: StateOfCustomer,
  totalResults: number
): PaginationInfoResult => {
  if (stateOfCustomer !== StateOfCustomer.PRE_APPOINTMENT) {
    return {
      limit: LIMITS_PAGE_OTHERS,
      loadMoreButton: totalResults / LIMITS_PAGE_OTHERS,
    };
  }

  switch (totalResults) {
    case undefined:
      return {
        limit: LIMITS_PAGE_ONE,
        loadMoreButton: 0,
      };
    case LIMITS_PAGE_ONE:
      return { limit: LIMITS_PAGE_TWO, loadMoreButton: 1 };
    case LIMITS_PAGE_ONE + LIMITS_PAGE_TWO:
      return { limit: LIMITS_PAGE_THREE, loadMoreButton: 2 };
    default:
      return {
        limit: LIMITS_PAGE_OTHERS,
        loadMoreButton:
          (totalResults - THREE_PAGE_RESULTS) / LIMITS_PAGE_OTHERS + NUMBER_SPECIFIC_LIMITS,
      };
  }
};
