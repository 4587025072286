import { Linking } from 'react-native';
import { BrowserResult } from 'react-native-inappbrowser-reborn';

import { trackRenovationExampleClick } from '@app/services/tracking/trackTracking';
import { fromS3ID } from '@app/utils/from-s3id';

const MASTEOS_REALISATIONS = 'https://www.masteos.fr/realisations';
const TEASER_IMAGE = 'masteosapp/project/edf871cc2eec6687c51aa0538baeecfd.jpg';

export const teaserImageCardAction: (
  propertyId: string,
  openUrlCallback: Linking['openURL'] | ((url: string) => Promise<void | BrowserResult>)
) => Promise<void> = async (propertyId, openUrlCallback) => {
  trackRenovationExampleClick({ propertyId });
  await openUrlCallback(MASTEOS_REALISATIONS);
};

export const getTeaserImage: (param: string) => string = param => fromS3ID(TEASER_IMAGE, param);
