import { ListItemUnionProps } from '@masteos/aphrodite';
import { ChosenOption } from '@masteos/aphrodite/lib/typescript/organisms/select/Select.types';

export const stringToSelect = (str: string, label = str): ListItemUnionProps => ({
  key: str,
  label,
  value: str,
});

export const selectToString = (item: ListItemUnionProps): string =>
  item.value === null ? null : item.value?.toString() ?? item.key;

export const handleSelectChange: (
  onChange: (nextState: string) => void
) => (target: ListItemUnionProps) => void = onChange => target => onChange(selectToString(target));

export const handleMultipleSelectChange: (
  onChange: (nextList: string[]) => void,
  list: ListItemUnionProps[]
) => (target: ChosenOption | ChosenOption[]) => void = (onChange, list) => target => {
  if (Array.isArray(target)) {
    return onChange(target.map(selectToString));
  }

  const value = target.value.toString();
  const nextList = list.map(selectToString);
  onChange(nextList.includes(value) ? nextList.filter(v => v !== value) : [...nextList, value]);
};
