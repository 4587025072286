import React from 'react';

import { Linking, Platform, StyleSheet, View } from 'react-native';

import { Button, Spacer, Spacing, Text } from '@masteos/aphrodite';

import { trackProfileBetaTestersButtonClicked } from '@app/services/tracking/trackTracking';
import { DESIGN_BETA_TESTERS_TYPEFORM_URL } from '@app/constants/constants';
import { useTranslation } from '@app/services/translations/translations';

const styles = StyleSheet.create({
  buttonWrapper: {
    alignItems: 'center',
    width: '100%',
  },
});
export const ProfileBetaTestersContent: React.FC = () => {
  const isWeb = Platform.OS === 'web';
  const { t } = useTranslation();
  const handleJoinBetaTesters = () => {
    trackProfileBetaTestersButtonClicked();
    Linking.openURL(DESIGN_BETA_TESTERS_TYPEFORM_URL);
  };
  return (
    <>
      <Text textStyle="Body1Medium">{t('profile.betaTesters.how')}</Text>
      <Spacer height={Spacing.SIZE_03} />
      <Text textStyle="Body3">{t('profile.betaTesters.description1')}</Text>
      <Text textStyle="Body3">{t('profile.betaTesters.description2')}</Text>
      <Spacer height={Spacing.SIZE_05} />
      <View style={!!isWeb && styles.buttonWrapper}>
        <Button onPress={handleJoinBetaTesters} testID="beta-testers-btn">
          {t('profile.betaTesters.button')}
        </Button>
      </View>
    </>
  );
};
