import { useCallback } from 'react';

import { useAddBookmarkMutation, useDeleteBookmarkMutation } from '@app/libs/apollo/introspection';
import { captureException } from '@app/libs/sentry/sentry';
import {
  PropertyLikeType,
  PropertyPage,
  TrackingOrigin,
  trackBookmark,
  trackUnbookmark,
} from '@app/services/tracking/trackTracking';
import { realEstateToTrackedProps } from '@app/features/search-engine/utils/real-estate-to-tracked-props';

import { useToastContext } from '../contexts/toast/Toast';
import { RealEstateItem } from '../components/real-estate-card/realEstateCard.type';

export type UseBookMarkProps = {
  callbackOnBookmark?: () => void;
  callbackUnBookmark?: () => void;
};

export type UseBookMarkResponseProps = {
  toggleBookmark: (
    item: RealEstateItem,
    source: { currentTab: TrackingOrigin; page: PropertyPage }
  ) => void;
};

const getOptimisticResponse = (realEstate, isFavorites) => ({
  realEstate: {
    id: realEstate.id,
    metadata: {
      ...realEstate.metadata,
      isFavorites,
    },
  },
});

export const useBookmark = ({
  callbackOnBookmark,
  callbackUnBookmark,
}: UseBookMarkProps): UseBookMarkResponseProps => {
  const { setErrorMsg } = useToastContext();
  const [addBookmark] = useAddBookmarkMutation();
  const [deleteBookmark] = useDeleteBookmarkMutation();

  const onUnBookmark = useCallback(
    (id, realEstateOptimisticResponse, realEstate, source) => {
      deleteBookmark({
        optimisticResponse: {
          deleteBookmark: getOptimisticResponse(realEstateOptimisticResponse, false),
        },
        variables: { realEstateId: id },
      })
        .catch(e => {
          setErrorMsg();
          captureException(`[Bookmark]: Remove Failed: ${e.message}`);
        })
        .then(() => {
          trackUnbookmark({
            ...source,
            ...realEstateToTrackedProps(realEstate),
          });
          callbackUnBookmark && callbackUnBookmark();
        });
    },
    [callbackUnBookmark, deleteBookmark, setErrorMsg]
  );

  const onBookmark = useCallback(
    (id, realEstateOptimisticResponse, realEstate, source) => {
      addBookmark({
        optimisticResponse: {
          addBookmark: getOptimisticResponse(realEstateOptimisticResponse, true),
        },
        variables: { isPositionedOn: false, realEstateId: id },
      })
        .catch(e => {
          setErrorMsg();
          captureException(`[Bookmark]: Add Failed: ${e.message}`);
        })
        .then(() => {
          trackBookmark({
            ...source,
            likeType: PropertyLikeType.FAVORITE,
            ...realEstateToTrackedProps(realEstate),
          });
          callbackOnBookmark && callbackOnBookmark();
        });
    },
    [addBookmark, callbackOnBookmark, setErrorMsg]
  );

  const toggleBookmark = useCallback(
    async (item: RealEstateItem, source) => {
      const realEstateOptimisticResponse = {
        __typename: 'RealEstate' as const,
        id: item.id,
      };

      item.isBookmarked && onUnBookmark(item.id, realEstateOptimisticResponse, item, source);
      !item.isBookmarked && onBookmark(item.id, realEstateOptimisticResponse, item, source);
    },
    [onBookmark, onUnBookmark]
  );

  return {
    toggleBookmark,
  };
};
