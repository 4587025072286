import React, { useState, useEffect } from 'react';

import { isEqual, mapValues } from 'lodash';
import * as yup from 'yup';

import { Field, Fields, FormFieldError, FormProps, Values } from './types.web';
import { FormContext } from './FormContext.web';
import { getTouchedFieldErrors } from './Form.utils.web';

export const Form: React.FC<FormProps> = ({ children, initialValues, schema, onSubmit }) => {
  const [fields, setFields] = useState<Fields>(
    mapValues(initialValues, value => ({
      error: null,
      touched: false,
      value,
    }))
  );
  const [previousFieldValues, setPreviousFieldValues] = useState<Values>(initialValues);
  const [previousTouchedFields, setPreviousTouchedFields] = useState<Values>({});
  const [useValidation, setUseValidation] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [mounted, setMounted] = useState<boolean>(false);

  useEffect(() => {
    setMounted(true);

    return () => setMounted(false);
  }, []);

  useEffect(() => {
    if (!isEqual(initialValues, previousFieldValues)) {
      setFields({
        ...mapValues(initialValues, value => ({
          error: null,
          touched: false,
          value,
        })),
        ...fields,
      });
    }
  }, [initialValues]);

  useEffect(() => {
    if (!(schema instanceof yup.ObjectSchema)) {
      console.warn(
        'Schema prop is not an instance of Yup (expected to be an instance of ObjectSchema). No validation will be performed'
      );
      setUseValidation(false);

      return;
    }

    setUseValidation(true);
  }, [schema]);

  useEffect(() => {
    let active = true;

    (async () => {
      const currentState = {
        fields: getFields(),
        touched: getTouchedFields(),
      };

      if (
        !isEqual(currentState.fields, previousFieldValues) ||
        !isEqual(currentState.touched, previousTouchedFields)
      ) {
        const errors = await getTouchedFieldErrors(fields, validate);
        if (active) {
          setInBulk(errors);
          setPreviousFieldValues(currentState.fields);
          setPreviousTouchedFields(currentState.touched);
        }
      }
    })();

    return () => {
      active = false;
    };
  }, [fields]);

  const get = (key: string) => {
    const field = fields[key];

    if (!field) {
      console.warn(`Cannot get "${key}" value as no initial value was provided`);

      return {} as Field;
    }

    return field;
  };

  const set = (key: string, patch: Partial<Field>) =>
    setFields(prevState => ({
      ...prevState,
      [key]: {
        ...get(key),
        ...patch,
      },
    }));
  /*
   * GETTERS / SETTERS
   */
  const _get = (fieldKey: string, key: string) => get(fieldKey)[key];
  const _set = (fieldKey: string, key: string, value?: any) =>
    typeof value === 'undefined'
      ? (val: any) => set(fieldKey, { [key]: val })
      : set(fieldKey, { [key]: value });
  const getValue = (key: string): any => _get(key, 'value');
  const setValue = (key: string, value?: any) => _set(key, 'value', value);
  const getError = (key: string): string => _get(key, 'error');
  const setError = (key: string, value?: Field['error']) => _set(key, 'error', value);

  /**
   * Set error for multiple field in bulk
   *
   * @param {{key: string, error:Field['error']}[]} patches
   */
  const setInBulk = (
    patches: {
      key: string;
      error?: Field['error'];
      touched?: Field['touched'];
    }[]
  ): void => {
    const _fields = { ...fields };

    for (const { key, error, touched } of patches) {
      typeof error !== 'undefined' && (_fields[key].error = error);
      typeof touched !== 'undefined' && (_fields[key].touched = touched);
    }

    setFields(_fields);
  };

  const getTouched = (key: string): boolean => _get(key, 'touched');
  const setTouched = (key: string, value?: boolean) => _set(key, 'touched', value);

  /*
   * UTILS
   */

  const mapFieldProps = (key: string) => ({
    error: getError(key),
    onBlur: handleBlur(key),
    onChangeText: setValue(key),
    value: getValue(key),
  });
  const getTouchedFields = () =>
    Object.fromEntries(Object.entries(fields).map(entry => [entry[0], entry[1].touched]));
  const getFields = () =>
    Object.fromEntries(Object.entries(fields).map(entry => [entry[0], entry[1].value]));
  const getState = () => fields;

  const validate = async (key: string): Promise<FormFieldError> => {
    if (!useValidation) {
      return { error: null, key };
    }

    if (!(key in schema.getDefaultFromShape())) {
      return { error: null, key };
    }

    const values = getFields();
    let error;

    try {
      await schema.validateAt(key, values, {
        context: { exist: false, setError },
      });
    } catch (err) {
      error = err;
    }

    return { error: error?.message || null, key };
  };

  const mapValuesAsync = (obj, asyncFn) => {
    const promises = Object.entries(obj).map(([key, value], index) => {
      return asyncFn(value, key, index, obj).then(resolved => [key, resolved]);
    });

    return Promise.all(promises).then(Object.fromEntries);
  };

  /*
   * EVENT HANDLERS
   */

  const handleBlur = (key: string) => {
    return () => {
      setTouched(key, true);
    };
  };

  const handleSubmit = async e => {
    e.preventDefault();

    const errors = Object.values(await mapValuesAsync(fields, (_, key) => validate(key)));

    setInBulk(errors.map(x => ({ ...(x as any), touched: true })));

    const isValid = errors.every(({ error }) => error === null);

    if (!isValid) {
      return;
    }

    setIsSaving(true);
    await onSubmit(getFields(), ctx);
    setIsSaving(false);
  };

  /*
   * RETROCOMPATIBILITY
   */

  const getFieldProps = (key: string) => {
    console.warn(
      '{...getFieldProps(key)} has been deprecated in favor of {...mapFieldProps(key)}\nYou can also use the Input component with the name prop.'
    );

    return mapFieldProps(key);
  };

  const ctx = {
    getError,
    getFieldProps,
    getFields,
    getState,
    getTouched,
    getValue,
    handleBlur,
    handleSubmit,
    isSaving,
    mapFieldProps,
    setError,
    setInBulk,
    setIsSaving,
    setTouched,
    setValue,
  };

  if (!mounted) {
    return <FormContext.Provider value={ctx} />;
  }

  return (
    <FormContext.Provider value={ctx}>
      {typeof children === 'function' ? (children as any)(ctx) : children}
    </FormContext.Provider>
  );
};
