import React from 'react';

import { View, StyleSheet } from 'react-native';

import { DataBlock, Spacing, useResponsive, IconPopoverOrModal } from '@masteos/aphrodite';

import { currencyFormatter } from '@app/utils/currency-formatter';
import { useRealEstateTranslation } from '@app/shared/hooks/useRealEstateTranslations';
import { trackTooltip } from '@app/services/tracking/trackTracking';

import { areEqual } from './PropertyFinances.utils';

const getStyles = (isLowerThanDesktop: boolean) =>
  StyleSheet.create({
    container: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      width: '100%',
    },
    element: {
      marginBottom: Spacing.SIZE_06,
      width: isLowerThanDesktop ? '100%' : `calc(100% / 3 - ${Spacing.SIZE_04}px)`,
    },
  });

interface PropertyFinancialSummaryProps {
  resellingYearCashFlow: number;
  resellingYear: number;
  enrichmentCoeficient: number;
  enrichmentResellingYear: number;
  resellingCumulativeCashFlow: number;
  propertyId: string;
}

export const PropertyEnrichmentProjectionSummary: React.FC<PropertyFinancialSummaryProps> =
  React.memo(
    ({
      resellingYearCashFlow,
      resellingYear,
      enrichmentCoeficient,
      enrichmentResellingYear,
      resellingCumulativeCashFlow,
      propertyId,
    }) => {
      const { t } = useRealEstateTranslation();
      const { isLowerThanDesktop } = useResponsive();
      const formattedCashflowResellingYear = currencyFormatter(resellingYearCashFlow, 0);
      const formattedNetCashflowResellingYear = currencyFormatter(enrichmentResellingYear, 0);
      const formattedInvestCapitalMultiplier = 'x ' + Math.abs(enrichmentCoeficient).toFixed(1);
      const styles = getStyles(isLowerThanDesktop);
      const netCashFlowResellingYearKey =
        resellingCumulativeCashFlow > 0
          ? 'enrichmentResellingYearPositive'
          : 'enrichmentResellingYearNegative';
      const trackTooltipInvestCapitalMultiplier = () =>
        trackTooltip({ propertyId, tooltipType: "Coefficient d'enrichissement" });
      const trackTooltipResellingYearNetCashFlow = () =>
        trackTooltip({ propertyId, tooltipType: 'Enrichissement net à 10 ans' });
      const trackTooltipResellingYearCashFlow = () =>
        trackTooltip({ propertyId, tooltipType: "Trésorerie disponible l'année de revente" });

      return (
        <View style={styles.container}>
          <DataBlock
            style={styles.element}
            title={t('shared.financialTool.resellingYearCashFlow')}
            data={formattedCashflowResellingYear}
            hint={
              <IconPopoverOrModal
                title=""
                content={t('shared.financialTool.tooltip.resellingYearCashFlow')}
                iconName="Info"
                iconSize="s"
                onOpen={trackTooltipResellingYearCashFlow}
              />
            }
          />

          <DataBlock
            style={styles.element}
            title={t('shared.financialTool.resellingYearNetCashFlow', { year: resellingYear })}
            data={formattedNetCashflowResellingYear}
            hint={
              <IconPopoverOrModal
                title=""
                content={t(`shared.financialTool.tooltip.${netCashFlowResellingYearKey}`, {
                  year: resellingYear,
                })}
                iconName="Info"
                iconSize="s"
                onOpen={trackTooltipResellingYearNetCashFlow}
              />
            }
          />

          <DataBlock
            style={styles.element}
            title={t('shared.financialTool.investCapitalMultiplier')}
            data={formattedInvestCapitalMultiplier}
            hint={
              <IconPopoverOrModal
                title=""
                content={t('shared.financialTool.tooltip.investCapitalMultiplier')}
                iconName="Info"
                onOpen={trackTooltipInvestCapitalMultiplier}
                iconSize="s"
              />
            }
          />
        </View>
      );
    },
    areEqual<PropertyFinancialSummaryProps>([
      'resellingCumulativeCashFlow',
      'resellingYear',
      'enrichmentCoeficient',
      'resellingYearCashFlow',
      'enrichmentResellingYear',
    ])
  );

PropertyEnrichmentProjectionSummary.displayName = 'PropertyEnrichmentProjectionSummary';
