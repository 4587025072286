import React from 'react';

import { Linking } from 'react-native';

import { RealEstate } from '@app/libs/apollo/introspection';
import { trackVirtualVisit } from '@app/services/tracking/trackTracking';
import { useRealEstate } from '@app/shared/contexts/realEstate/RealEstateProvider';
import { useLocationInfoPermission } from '@app/shared/hooks/useLocationInfoPermission';

export interface Visit3DButtonProps {
  onPress: () => void;
}

export const withVisit3D = (
  Component: React.FC<Visit3DButtonProps>
): React.FC<{ style?: unknown }> => {
  const WithVisit3DHOC: React.FC<{ style?: unknown }> = props => {
    const { realEstate } = useRealEstate();
    const { url3D } = realEstate as RealEstate;
    const shouldVisit3D = useLocationInfoPermission(realEstate.id) && url3D;

    if (!shouldVisit3D) {
      return null;
    }

    const handleVisit3D = () => {
      trackVirtualVisit({ isInsideGallery: false, propertyId: realEstate.id });
      Linking.openURL(url3D);
    };

    return <Component onPress={handleVisit3D} {...props} />;
  };
  WithVisit3DHOC.displayName = 'Visit3DHOC';
  return WithVisit3DHOC;
};
