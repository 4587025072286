import { StyleProp, StyleSheet, ViewStyle } from 'react-native';

import { Theme } from '@masteos/aphrodite';

export const getStyles = ({ theme }: { theme: Theme }): Record<string, StyleProp<ViewStyle>> =>
  StyleSheet.create({
    blocksItem: {
      alignItems: 'flex-start',
      flexDirection: 'row',
    },
    blocksItemText: {
      maxWidth: '100%',
      paddingLeft: 16,
      paddingRight: 32,
      paddingTop: 10,
    },
    content: { flexGrow: 1 },
    iconWrapper: {
      backgroundColor: theme.palette.tint.turquoise[50],
      borderRadius: 100,
    },
  });
