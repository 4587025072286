import { StyleSheet } from 'react-native';

import { Theme } from '@masteos/aphrodite';

export const getStyles = (theme: Theme) =>
  StyleSheet.create({
    body: {
      backgroundColor: theme.palette.base.white,
      flexBasis: 'auto',
      flexGrow: 1,
      flexShrink: 1,
      overflow: 'auto' as 'visible',
    },
    container: {
      flexDirection: 'column',
      height: '100%',
    },
    mobileMenu: {
      zIndex: 3,
    },
  });
