import React, { useCallback, useContext, useEffect } from 'react';

import { startCase } from 'lodash';
import { Trans } from 'react-i18next';
import { StyleSheet } from 'react-native';

import { Text, useResponsive, useTheme } from '@masteos/aphrodite';

import {
  trackMandateSignInBannerClosed,
  trackMandateSignInModalDisplayed,
} from '@app/services/tracking/trackTracking';
import { storage } from '@app/services/storage/storage';
import { ModalKey } from '@app/shared/contexts/modal-manager/modalManager.types';
import { ModalContext } from '@app/shared/contexts/modal-manager/ModalManagerProvider';
import { InAppSignInModal } from '@app/shared/components/Modals/in-app-sign-in/InAppSignInModal';
import { MandateStatus } from '@app/types/user';
import { BannerContext } from '@app/shared/contexts/banner-manager/BannerManagerProvider';
import { BannerKey } from '@app/shared/contexts/banner-manager/BannerManager.types';
import { Banner } from '@app/shared/components/Banner/Banner';

import { useMe } from '../useMe';
import { SignMandateReminderType } from './useSignMandateState.hook.types';
import { shouldDisplayBanner, shouldDisplayModal } from './useSignMandateState.hook.utils';

const WEB_BANNER_HEIGHT = 72;
const MOBILE_BANNER_HEIGHT = 112;

export const useSignMandateStateHook = (): void => {
  const theme = useTheme();
  const { titleColor } = StyleSheet.create({
    titleColor: { color: theme.palette.system.primary[500] },
  });

  const { customer } = useMe();
  const { openModal } = useContext(ModalContext);
  const { openBanner, closeBanner, isDisplayed: isBannerVisible } = useContext(BannerContext);

  const { isLowerThanLaptop } = useResponsive();

  const handleOpenModal = useCallback(
    (source: string) => () => {
      const firstName = startCase(customer?.firstName);

      openModal(ModalKey.SignInAppMandate, <InAppSignInModal />, {
        title: (
          <Text textStyle="Title3Medium">
            <Trans
              i18nKey="modalMandateSignIn.title"
              values={{ firstName }}
              components={{
                color: <Text textStyle="Title3Medium" style={titleColor} />,
              }}
            />
          </Text>
        ),
        webSize: 'l',
      });

      trackMandateSignInModalDisplayed(source);
    },
    [customer?.firstName, openModal, titleColor]
  );

  const handleOnBannerClose = useCallback(async () => {
    const data = await storage.readObject<SignMandateReminderType>('signMandateReminder');
    await storage.saveObject('signMandateReminder', { ...data, banner: new Date().toISOString() });

    closeBanner();

    trackMandateSignInBannerClosed();
  }, [closeBanner]);

  const initOpen = useCallback(() => {
    if (!customer?.searchMandate) {
      return;
    }

    const cantBeShown = customer.searchMandate?.status !== MandateStatus.Pending;
    if (cantBeShown) {
      isBannerVisible && closeBanner();
      return;
    }

    const bannerHeight = isBannerVisible
      ? isLowerThanLaptop
        ? MOBILE_BANNER_HEIGHT
        : WEB_BANNER_HEIGHT
      : 0;

    storage.readObject<SignMandateReminderType>('signMandateReminder').then(signMandateReminder => {
      shouldDisplayModal(signMandateReminder) && handleOpenModal('default')();

      shouldDisplayBanner(signMandateReminder) &&
        openBanner(
          BannerKey.SignInAppMandate,
          <Banner
            bannerHeight={bannerHeight}
            onClose={handleOnBannerClose}
            handleBannerOpenModal={handleOpenModal('banner')}
          />
        );
    });
  }, [
    customer,
    isBannerVisible,
    isLowerThanLaptop,
    closeBanner,
    handleOpenModal,
    openBanner,
    handleOnBannerClose,
  ]);

  useEffect(() => {
    initOpen();
    // We need initOpen is launched when customer is loading
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer?.searchMandate]);
};
