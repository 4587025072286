// DO NOT DELETE EVEN IF EMPTY
export enum FeatureFlags {
  translation = 'feature-flag-translation',
  translationEN = 'feature-flag-translation-en',
  translationES = 'feature-flag-translation-es',
  translationFR_BE = 'feature-flag-translation-fr-be',
  translationNL = 'feature-flag-translation-nl',
  translationNL_BE = 'feature-flag-translation-nl-be',
  monthlyEnrichment = 'feature-flag-monthly-enrichment',
  hubspotChat = 'feature-flag-hubspot-chat',
  legacyApi = 'feature-flag-legacy-api',
  ibanForm = 'feature-flag-iban-form',
  searchEnginePagination = 'search-engine-pagination',
  positioning = 'feature-flag-positioning',
}
