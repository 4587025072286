import React, { useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { AvatarSelector } from '@masteos/aphrodite';
import { fromS3ID } from '@masteos/agora';

import { useMe } from '@app/shared/hooks/useMe';
import { MainMenuNavigatorRoutes } from '@app/navigation/types/routes';
import { trackLogout } from '@app/services/tracking/trackTracking';
import { resetTracking } from '@app/services/tracking/resetTracking';
import { useLogout } from '@app/shared/hooks/useLogout';
import { openUrl } from '@app/utils/openUrl.utils';
import { useToastContext } from '@app/shared/contexts/toast/Toast';

import { getWebsiteUrlByLang } from './customerDropdown.utils';
import { CustomerDropdownProps } from './CustomerDropdown.types';

export { CustomerDropdownProps };

export const CustomerDropdown: React.FC<CustomerDropdownProps> = ({ navigation }) => {
  const { customer } = useMe();
  const { logout } = useLogout();
  const { t } = useTranslation();
  const { setErrorMsg } = useToastContext();

  const customerAvatar = useMemo(
    () => fromS3ID(customer?.account?.avatarS3ID, `w-64`),
    [customer?.account?.avatarS3ID]
  );

  const logOut = useCallback(async () => {
    trackLogout();
    resetTracking();
    await logout();
  }, [logout]);

  const redirectPage = useCallback(
    (path: MainMenuNavigatorRoutes) => () => {
      navigation.reset({
        index: 0,
        routes: [{ name: path }],
      });
    },
    [navigation]
  );

  const options = useMemo(
    () => [
      {
        hasRightArrow: true,
        key: 'viewSite',
        label: t('navigation.topBar.viewSite'),
        onSelect: async closeList => {
          closeList();
          await openUrl(getWebsiteUrlByLang(customer?.lang), () =>
            setErrorMsg({
              title: t('authTunnel.apiErrorMessage'),
            })
          );
        },
      },
      {
        key: 'viewMyProfil',
        label: t('navigation.topBar.viewMyProfil'),
        onSelect: closeList => {
          redirectPage(MainMenuNavigatorRoutes.Profile)();
          closeList();
        },
      },
      {
        key: 'logout',
        label: t('navigation.logout'),
        onSelect: async closeList => {
          closeList();
          await logOut();
        },
      },
    ],
    [customer?.lang, logOut, redirectPage, setErrorMsg, t]
  );

  return <AvatarSelector avatarUrl={customerAvatar} options={options} />;
};
