import React, { useCallback, useMemo, useState } from 'react';

import { Platform, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { Image, Text, useResponsive, Spacer, useTheme, Button } from '@masteos/aphrodite';

import { useTranslation } from '@app/services/translations/translations';
import {
  TakeAppointmentSource,
  trackTakeAdvisorAppointmentClick,
} from '@app/services/tracking/trackTracking';
import { PlatformEnum } from '@app/types/platform';
import {
  AdvisorNavigatorRoutes,
  MainMenuNavigatorRoutes,
  RealEstateNavigatorRoutes,
  RealEstatesStackParamList,
} from '@app/navigation/types/routes';

import { getStyles } from './propertyImage.style';

type PropertyGalleryImageAppointmentNavigationProp = StackNavigationProp<
  RealEstatesStackParamList,
  RealEstateNavigatorRoutes.RealEstate
>;

export interface PropertyTopImagesAppointmentProps {
  imageUrl: string;
  onPress: () => void;
  propertyId: string;
}

export const PropertyGalleryImageAppointment: React.FC<PropertyTopImagesAppointmentProps> = ({
  imageUrl,
  onPress,
  propertyId,
}) => {
  const navigation = useNavigation<PropertyGalleryImageAppointmentNavigationProp>();
  const { t } = useTranslation();
  const { device, isMobile } = useResponsive();
  const theme = useTheme();
  const styles = useMemo(() => getStyles(theme, device), [theme, device]);
  const [imageLoaded, setImageLoaded] = useState(false);

  const onLoad = () => setImageLoaded(true);

  const handleOnPress = useCallback(() => {
    onPress();
    trackTakeAdvisorAppointmentClick({
      propertyId,
      source: TakeAppointmentSource.propertyPageGallery,
    });
    if (Platform.OS === PlatformEnum.Web) {
      navigation.getParent().navigate(MainMenuNavigatorRoutes.Advisor, {
        screen: AdvisorNavigatorRoutes.AdvisorAppointment,
      });
    } else {
      navigation.navigate(RealEstateNavigatorRoutes.AdvisorAppointment);
    }
  }, [navigation, onPress, propertyId]);

  return (
    <Image testID="PropertyGalleryImageAppointment" source={{ uri: imageUrl }} onLoad={onLoad}>
      {!!imageLoaded && (
        <>
          <View style={styles.imageMask} />
          <View style={styles.container}>
            <Text style={styles.text} textStyle={isMobile ? 'Body1Medium' : 'Headline2Medium'}>
              {t('propertyInfo.takeAppointmentToSeeMorePic')}
            </Text>
            <Spacer height={theme.spacing.SIZE_04} />
            <Button
              iconName="Phone"
              iconPlacement="left"
              testID="property-gallery-appointment"
              onPress={handleOnPress}
            >
              {t('propertyActions.meetMyAdvisor')}
            </Button>
            <Spacer height={theme.spacing.SIZE_04} />
            <Text style={styles.text} textStyle="Body3">
              {t('propertyInfo.freeWithoutCommitment')}
            </Text>
          </View>
        </>
      )}
    </Image>
  );
};
