import React, { useCallback, useRef } from 'react';

import { NavigationContainer } from '@react-navigation/native';
import { StyleSheet, View } from 'react-native';

import { LoaderMasteos } from '@masteos/aphrodite';

import { useMe } from '@app/shared/hooks/useMe';
import { useAfterLogin } from '@app/shared/hooks/useAfterLogin';

import { privateLinking, publicLinking } from './constants/linking';
import { PrivateNavigator } from './PrivateNavigator';
import { PublicNavigator } from './PublicNavigator';
import { DOCUMENT_TITLE_OPTIONS } from './constants/document-title';
import { navigationRef } from './navigationRef';
import { launchTracking } from './utils/trackChangePage.utils';

const styles = StyleSheet.create({
  loader: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    width: '100%',
  },
});

export const RootNavigator: React.FC = () => {
  const { customer, loading } = useMe();
  const routeNameRef = useRef<string>();
  useAfterLogin(customer, loading);

  const onStateChange = useCallback(() => {
    launchTracking(routeNameRef, navigationRef);
    routeNameRef.current = navigationRef.getCurrentRoute().name;
  }, []);

  if (loading) {
    return (
      <View style={styles.loader}>
        <LoaderMasteos />
      </View>
    );
  }

  return (
    <NavigationContainer
      linking={customer ? privateLinking : publicLinking}
      ref={navigationRef}
      documentTitle={DOCUMENT_TITLE_OPTIONS}
      onReady={onStateChange}
      onStateChange={onStateChange}
      fallback={<LoaderMasteos />}
    >
      {customer ? <PrivateNavigator customer={customer} /> : <PublicNavigator />}
    </NavigationContainer>
  );
};
