import { config } from '@app/config';

export enum LogLevel {
  error,
  warn,
  info,
  debug,
  log,
}

export const log = (...args: [...unknown[], LogLevel | unknown]): void => {
  let level: LogLevel;
  if (args.length > 1 && Object.values(LogLevel).includes(args[args.length - 1] as LogLevel)) {
    level = args.pop() as LogLevel;
  }
  if (!config.isProduction) {
    switch (level) {
      case LogLevel.error:
        console.error(`❌`, ...args);
        break;
      case LogLevel.warn:
        console.warn(`⚠️ `, ...args);
        break;
      case LogLevel.info:
        console.info(`ℹ `, ...args);
        break;
      case LogLevel.debug:
        console.debug(`🚸 `, ...args);
        break;
      default:
        console.log(...args);
    }
  }
};
