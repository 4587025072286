import React, { useEffect, useRef } from 'react';

import { Animated, FlexStyle, Platform, StyleSheet } from 'react-native';

import { Toast } from '@masteos/aphrodite';

import { useToastContext } from '@app/shared/contexts/toast/Toast';

export const TOAST_DURATION = 3000;
export const TOAST_MARGIN = 20;
export const TOAST_HEIGHT = 48;
export const IOS_MARGIN = 20;

const getStyles = (isBottom: boolean) =>
  StyleSheet.create({
    wrapper: {
      ...StyleSheet.absoluteFillObject,
      alignItems: 'center',
      bottom: 'auto',
      height: Platform.select({ native: TOAST_HEIGHT, web: 0 }),
      position: Platform.select({
        native: 'absolute',
        web: 'fixed' as FlexStyle['position'],
      }),
      top: 'auto',
      [isBottom ? 'bottom' : 'top']: TOAST_MARGIN,
    },
  });

export const ToastManager: React.FC = () => {
  const { toastMsg, clearMsg } = useToastContext();
  const { isBottom, title, withClose, icon, variant } = toastMsg || {};
  const styles = getStyles(isBottom);

  const toValue = !isBottom
    ? Platform.select({
        default: TOAST_MARGIN,
        ios: TOAST_MARGIN + IOS_MARGIN,
      })
    : Platform.select({
        default: TOAST_MARGIN + TOAST_HEIGHT,
        ios: TOAST_MARGIN + TOAST_HEIGHT + IOS_MARGIN,
      });

  const popAnim = useRef(new Animated.Value(-TOAST_MARGIN)).current;

  const animation = ({ endValue, callback = () => undefined }) => {
    Animated.timing(popAnim, {
      duration: 150,
      toValue: endValue,
      useNativeDriver: false,
    }).start(callback);
  };

  useEffect(() => {
    if (!toastMsg) {
      return;
    }

    animation({ endValue: toValue });

    if (toastMsg.timeout !== Infinity) {
      setTimeout(
        () => animation({ callback: clearMsg, endValue: -toValue }),
        toastMsg.timeout || TOAST_DURATION
      );
    }
  }, [toastMsg]);

  const onClose = () => withClose && animation({ callback: clearMsg, endValue: -toValue });

  const otherProps = { testID: 'toast' };

  return title ? (
    <Animated.View style={[styles.wrapper, { [isBottom ? 'bottom' : 'top']: popAnim }]}>
      <Toast label={title} onClose={onClose} icon={icon} variant={variant} {...otherProps} />
    </Animated.View>
  ) : null;
};
