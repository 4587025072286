import { StyleSheet } from 'react-native';

import { Theme, ZINDEX } from '@masteos/aphrodite';

export const getStyles = ({
  isLowerThanTablet,
  isNative,
  theme,
  maxWidth,
}: {
  isLowerThanTablet: boolean;
  isNative: boolean;
  theme: Theme;
  maxWidth: number;
}) =>
  StyleSheet.create({
    body: {
      alignItems: 'center',
      justifyContent: 'center',
    },
    footer: {
      maxWidth: isLowerThanTablet ? '100%' : maxWidth,
      paddingHorizontal: isLowerThanTablet ? 0 : 40,
      width: '100%',
    },
    headerPage: {
      position: isNative ? 'relative' : ('sticky' as 'absolute'),
      top: 0,
      zIndex: ZINDEX.sticky,
    },
    page: {
      backgroundColor: theme.palette.base.white,
      ...(isNative ? { flex: 1 } : {}),
      paddingBottom: isNative ? 0 : 40,
    },
  });
