import React from 'react';

import { StyleSheet, View } from 'react-native';

import { palette, Spacing } from '@masteos/aphrodite';
import { FinancialModel } from '@masteos/agora';

import { currencyFormatter } from '@app/utils/currency-formatter';
import { useRealEstateTranslation } from '@app/shared/hooks/useRealEstateTranslations';

import { Importance, PropertyAttribute } from '../propertyDescription/PropertyAttribute';
import { PropertyFinancialHeader } from './propertyFinancialHeader';
import { areEqual, differentFuncParameters } from './PropertyFinances.utils';

const styles = StyleSheet.create({
  border: {
    borderBottomColor: palette.neutral[300],
    borderBottomWidth: 1,
    borderStyle: 'solid',
    marginBottom: Spacing.SIZE_04,
    marginTop: Spacing.SIZE_04,
    width: '100%',
  },
  container: {
    borderColor: palette.neutral[300],
    borderRadius: 4,
    borderWidth: 1,
    paddingBottom: Spacing.SIZE_04,
    paddingHorizontal: Spacing.SIZE_06,
    paddingTop: Spacing.SIZE_03,
    width: '100%',
  },
});

export interface PropertyFinancialResultsProps {
  financialModel: FinancialModel;
}

export const PropertyFinancialResults: React.FC<PropertyFinancialResultsProps> = React.memo(
  ({ financialModel }) => {
    const { t } = useRealEstateTranslation();
    const annualTotalIncome = currencyFormatter(financialModel.annualTotalIncome, 0);
    const annualCashflow = currencyFormatter(financialModel.monthlyCashflow * 12, 0);
    const monthlyCashflow = currencyFormatter(financialModel.monthlyCashflow, 0);
    const annualLoanPayments = currencyFormatter(-financialModel.annualLoanPayments, 0);
    const sumCharges = currencyFormatter(-financialModel.annualTotalExpenses, 0);
    const monthlyCashflowKey =
      financialModel.monthlyCashflow >= 0 ? 'monthlyCashflow' : 'cumultiveSavingsBeforeTax';
    const annualCashflowKey =
      financialModel.monthlyCashflow * 12 >= 0
        ? 'annualCashflow'
        : 'annualCumultiveSavingsBeforeTax';

    return (
      <View style={styles.container}>
        <PropertyFinancialHeader title={t(`shared.financialTool.result`)} icon="TrendingUp" />
        <PropertyAttribute
          label={t('shared.financialTool.annualTotalIncome2')}
          value={annualTotalIncome}
          importance={Importance.LOW}
          isOdd
        />
        <PropertyAttribute
          label={t('shared.financialTool.sumCharges')}
          value={sumCharges}
          importance={Importance.LOW}
        />
        <PropertyAttribute
          label={t('shared.financialTool.annualLoanPayments')}
          value={annualLoanPayments}
          importance={Importance.LOW}
          isOdd
          testID="property-financial-line-annual-loan-payments"
        />

        <View style={styles.border} />

        <PropertyAttribute
          label={t(`shared.financialTool.${annualCashflowKey}`)}
          value={annualCashflow}
          importance={Importance.HIGH}
        />
        <PropertyAttribute
          label={t(`shared.financialTool.${monthlyCashflowKey}`)}
          value={monthlyCashflow}
          importance={Importance.LOW}
        />
      </View>
    );
  },
  areEqual(
    [],
    (prevState: PropertyFinancialResultsProps, nextState: PropertyFinancialResultsProps) => {
      return differentFuncParameters(prevState, nextState, [
        'annualTotalIncome',
        'annualTotalExpenses',
        'monthlyCashflow',
        'annualLoanPayments',
      ]);
    }
  )
);

PropertyFinancialResults.displayName = 'PropertyFinancialResults';
