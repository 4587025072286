import React, { useState } from 'react';

import { Alert, Platform, ScrollView, StyleSheet } from 'react-native';
import { useNavigation } from '@react-navigation/native';

import { palette, Spacer, Spacing, Text } from '@masteos/aphrodite';

import * as Constant from '@app/constants/feature-flags';
import { useFeatureFlags } from '@app/shared/hooks/useFeatureFlag';
import { PlatformEnum } from '@app/types/platform';
import { useToggleMutation } from '@app/libs/apollo/introspection';

import { MenuItem } from './compounds/MenuItem';
import { DebugMenuHeader } from './compounds/DebugMenuHeader';
import { DebugMenuWrapper } from './compounds/DebugMenuWrapper';
import { DebugMenuContentWrapper } from './compounds/DebugMenuContentWrapper';

const getStyles = () =>
  StyleSheet.create({
    error: {
      color: palette.alert.error,
    },
  });

const formatFeatureFlagName = name => {
  const formattedName: string = name.replace('feature-flag', '').split('-').join(' ').trim();

  return formattedName.charAt(0).toUpperCase() + formattedName.slice(1);
};

export const DebugMenuFeatureFlags: React.FC = () => {
  const navigation = useNavigation();

  const styles = getStyles();
  const { isEnabled } = useFeatureFlags();
  const [selectedFeatureFlag, setSelectedFeatureFlag] = useState<string | null>(null);
  const [flagError, setFlagError] = useState<string | null>(null);
  const isWeb = Platform.OS === PlatformEnum.Web;

  const [toggle, { loading }] = useToggleMutation({
    refetchQueries: ['getFeatureFlags'],
  });

  const onPress = async (name: string) => {
    setFlagError(null);
    setSelectedFeatureFlag(name);
    await toggle({ variables: { name } }).catch(err => {
      if (isWeb) {
        setFlagError(err.toString());
      } else {
        Alert.alert('Error', err.toString());
      }
    });
    setSelectedFeatureFlag(null);
  };

  return (
    <DebugMenuWrapper>
      <DebugMenuHeader title="Feature Flags" onPress={() => navigation.goBack()} />

      <ScrollView>
        {flagError ? <Text style={styles.error}>{flagError}</Text> : null}

        <DebugMenuContentWrapper>
          {Object.values(Constant.FeatureFlags).map(featureFlag => (
            <React.Fragment key={featureFlag}>
              <MenuItem
                key={featureFlag}
                icon={isEnabled(featureFlag) ? 'CheckFilled' : null}
                iconColor={isEnabled(featureFlag) ? palette.tint.green[400] : palette.tint.red[400]}
                title={formatFeatureFlagName(featureFlag)}
                onPress={() => onPress(featureFlag)}
                subtitle={formatFeatureFlagName(featureFlag)}
                isDisabled={!isEnabled(featureFlag)}
                hasTopRadius
                hasBottomRadius
                isLoading={selectedFeatureFlag === featureFlag && loading}
              />

              <Spacer height={Spacing.SIZE_03} />
            </React.Fragment>
          ))}
        </DebugMenuContentWrapper>
      </ScrollView>
    </DebugMenuWrapper>
  );
};
