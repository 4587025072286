import isObject from 'lodash/isObject';

import { CustomerAddress } from '@app/libs/apollo/introspection';

export const formatAddress = (address?: CustomerAddress): string => {
  if (!address || (isObject(address) && !Object.keys(address).length)) {
    return '';
  }

  return `${address.street || ''}, ${address.postalCode || ''} ${address.city || ''}, ${
    address.country || ''
  }`;
};
