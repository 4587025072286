import { Platform, StyleSheet } from 'react-native';

import { Theme } from '@masteos/aphrodite';

import { PlatformEnum } from '@app/types/platform';

export const getStyle = ({
  isLowerThanLaptop,
  theme,
}: {
  isLowerThanLaptop: boolean;
  theme: Theme;
}) => {
  return StyleSheet.create({
    buttonWrapperReset: {
      flex: 1 / 3,
    },
    buttonWrapperSubmit: {
      flex: 2 / 3,
    },
    buttonsContainer: {
      flexDirection: 'row',
      marginBottom:
        isLowerThanLaptop && Platform.OS === PlatformEnum.Web ? theme.spacing.SIZE_10 : 0,
      paddingHorizontal: theme.spacing.SIZE_08,
      paddingVertical: theme.spacing.SIZE_04,
    },
    formContainer: {
      margin: theme.spacing.SIZE_08,
      ...(Platform.OS !== PlatformEnum.Web ? { marginTop: theme.spacing.SIZE_00 } : {}),
    },
    safeAreaView: { flex: 1 },
    selectWrapper: {
      width: '100%',
    },
  });
};
