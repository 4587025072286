import React, { forwardRef, useEffect, useRef } from 'react';

import { Platform, TextInput } from 'react-native';

import { Input as AphroditeInput } from '@masteos/aphrodite';

import { useFormContext } from './FormContext.web';
import { InputProps } from './types.web';

export const Input = forwardRef<TextInput, InputProps>(({ name, ...inputProps }, ref) => {
  const formContext = useFormContext();
  const localRef = useRef<TextInput>(null);
  const inputRef = ref ?? localRef;

  useEffect(() => {
    if (Platform.OS === 'web' && name) {
      (
        (inputRef as unknown as React.MutableRefObject<TextInput>)?.current as unknown as Element
      )?.setAttribute('name', name);
    }
  }, [name, inputRef, ref]);

  if (!formContext) {
    console.warn(
      // eslint-disable-next-line max-len
      `<Input name="${name}" ref={inputRef} /> must be used inside a Form component. Input has been rendered but not the name props was ignored`
    );

    return <AphroditeInput ref={inputRef} {...inputProps} />;
  }

  return <AphroditeInput {...inputProps} {...formContext.mapFieldProps(name)} ref={inputRef} />;
});

Input.displayName = 'Input';
