import { StyleSheet } from 'react-native';

import { Theme } from '@masteos/aphrodite';

export const getStyleSheet = ({
  isGreaterThanTablet,
  isLowerThanDesktop,
  isLowerThanLaptop,
  isMobile,
  theme,
}: {
  isGreaterThanTablet?: boolean;
  isLowerThanDesktop?: boolean;
  isLowerThanLaptop?: boolean;
  isMobile?: boolean;
  theme: Theme;
}) =>
  StyleSheet.create({
    renderItem: {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'center',
      paddingTop: theme.spacing.SIZE_05,
    },
    renderItemContent: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      maxWidth: isLowerThanLaptop ? '100%' : 1120,
      paddingHorizontal: isMobile
        ? theme.spacing.SIZE_06
        : isGreaterThanTablet
        ? theme.spacing.SIZE_09
        : theme.spacing.SIZE_15,
      width: '100%',
    },
    renderItemWrapper: { paddingHorizontal: theme.spacing.SIZE_02 },
    scrollViewContent: {
      paddingHorizontal: isLowerThanDesktop ? theme.spacing.SIZE_05 : theme.spacing.SIZE_09,
      width: '100%',
    },
  });
