import React, { useContext, useMemo } from 'react';

import { StyleSheet, View } from 'react-native';

import { palette, Spacing, Text, Image, Pressable } from '@masteos/aphrodite';

import { fromS3ID } from '@app/utils/from-s3id';
import { LogLevel, log } from '@app/utils/logs/log';

import { GalleryImagesProps, GalleryThumbnailProps } from './types';
import { GalleryContext } from './GalleryProvider';

const DefaultRatio = 3 / 2;

const getStyles = (ratio = DefaultRatio) =>
  StyleSheet.create({
    coverImageCount: {
      alignItems: 'center',
      backgroundColor: palette.neutral[900],
      borderRadius: 4,
      bottom: Spacing.SIZE_06,
      height: 24,
      justifyContent: 'center',
      position: 'absolute',
      right: Spacing.SIZE_06,
      width: 37,
      zIndex: 50,
    },
    image: { aspectRatio: ratio },
  });

type InternalGalleryThumbnailProps = {
  /**
   * @descritpion Ratio of the image thumbnail
   */
  ratio?: number;

  /**
   * @descritpion if true, call agora's fromS3() formatter to format the url based on the filename.
   */
  isFromS3?: boolean;

  /**
   * @descritpion The gallery of images to load in the Swiper modal component
   */
  gallery: GalleryImagesProps;

  /**
   * @descritpion callback called wehn thumbnail is pressed. The corresponding gallery is passed as first arg.
   */
  onPress: (gallery: GalleryImagesProps) => void;
};

const InternalGalleryThumbnail: React.FC<InternalGalleryThumbnailProps> = ({
  ratio = DefaultRatio,
  gallery,
  isFromS3 = true,
  onPress,
}) => {
  const handleOnPress = () => {
    onPress(gallery);
  };
  const styles = useMemo(() => getStyles(ratio), [ratio]);

  const coverImageUri = useMemo(() => {
    if (!Array.isArray(gallery.images) || gallery?.images.length === 0) {
      log('No images present in the gallery, cannot display cover images', LogLevel.error);
      return null;
    }
    return isFromS3 ? fromS3ID(gallery.images[0], 'w-500:q-100') : gallery.images[0];
  }, [isFromS3, fromS3ID, gallery.images]);

  if (!coverImageUri) {
    return null;
  }

  return (
    <View>
      <View style={styles.coverImageCount}>
        <Text textStyle="Body2" style={{ color: palette.base.white }}>
          1/{gallery.images.length}
        </Text>
      </View>

      <Pressable onPress={handleOnPress}>
        <Image
          source={{ uri: coverImageUri }}
          style={styles.image}
          resizeMode="cover"
          aspectRatio={ratio}
        />
      </Pressable>
    </View>
  );
};

export const GalleryThumbnail: React.FC<GalleryThumbnailProps> = ({ gallery, ...props }) => {
  const { openGallery } = useContext(GalleryContext);
  return <InternalGalleryThumbnail gallery={gallery} {...props} onPress={openGallery} />;
};
