import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { Animated, FlatList, ScrollView, View, ViewStyle } from 'react-native';

import { useResponsive, useTheme } from '@masteos/aphrodite';

import { TabBarProps } from './TabBar';
import { getStyleSheet } from './TabContent.styles';
import { Tab } from './TabView.type';

// TODO: move to Aphrodite
export const TabContent: React.FC<TabBarProps & { swipeToNavigate?: boolean }> = ({
  tabs,
  index,
}) => {
  const theme = useTheme();
  const { isMobile, isLowerThanDesktop, isLowerThanLaptop, isGreaterThanTablet } = useResponsive();

  const flatListRef = useRef<Animated.FlatList<Tab> & FlatList<Tab>>();

  useEffect(() => {
    flatListRef?.current?.scrollToItem({ animated: false, item: tabs[index] });
  }, [index, flatListRef]);

  const tabContentStyles = useMemo(
    () =>
      getStyleSheet({
        isGreaterThanTablet,
        isLowerThanDesktop,
        isLowerThanLaptop,
        isMobile,
        theme,
      }),
    [isGreaterThanTablet, isLowerThanDesktop, isLowerThanLaptop, isMobile, theme]
  );

  const getDisplay = useCallback(
    (i, j) => ({ display: i !== j ? 'none' : 'flex' } as ViewStyle),
    []
  );

  return (
    <>
      {tabs.map((item, i: number) => (
        <View key={item.key} style={[tabContentStyles.renderItem, getDisplay(i, index)]}>
          <View style={tabContentStyles.renderItemContent}>
            {/* Overflow must be inline-styles to apply */}
            {/* eslint-disable-next-line react-native/no-inline-styles */}
            <ScrollView style={[{ overflow: 'visible' }, tabContentStyles.scrollViewContent]}>
              {item.component()}
            </ScrollView>
          </View>
        </View>
      ))}
    </>
  );
};
