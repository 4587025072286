import { isNil } from 'lodash';

import { IconName, useResponsive } from '@masteos/aphrodite';

import { currencyFormatter } from '@app/utils/currency-formatter';
import { trackTooltip } from '@app/services/tracking/trackTracking';
import { RealEstate, RentalSituations } from '@app/libs/apollo/introspection';
import { useRealEstateTranslation } from '@app/shared/hooks/useRealEstateTranslations';

export interface Row {
  icon: IconName;
  title: string;
  tooltip?: { title: string; content: string };
  data: string;
  testID: string;
}

export interface PropertyRentOutput {
  onTooltipOpen: (section: string) => void;
  rows: Row[];
  width: number | string;
}

const DATA_BLOCK_WIDTH_ON_MOBILE = '100%';
const DATA_BLOCK_WIDTH = 234;

export const usePropertyRent = (property: RealEstate): PropertyRentOutput => {
  const { t } = useRealEstateTranslation();
  const { isLowerThanLaptop } = useResponsive();
  const { renting, furnishing } = property.project;
  const { totalLots } = property.building;
  const showIsFurnished = !isNil(furnishing.isFurnished);

  const width = isLowerThanLaptop ? DATA_BLOCK_WIDTH_ON_MOBILE : DATA_BLOCK_WIDTH;

  const rows: Row[] = [
    {
      data:
        renting.situation !== RentalSituations.Unknown
          ? t<string>(`propertyRent.${renting.situation}`)
          : '',
      icon: 'Key',
      testID: 'property-rent-data-block-rental-situation',
      title: t('propertyRent.rentalStatus'),
    },
    {
      data: totalLots?.toString(),
      icon: 'Archive',
      testID: 'property-rent-data-block-total-lots',
      title: t('propertyRent.numberOfLots'),
    },
    {
      data: currencyFormatter(property.project.renting.annual.income, 0),
      icon: 'Euro',
      testID: 'property-rent-data-block-annual-rent-in-come',
      title: t('propertyRent.annualRent'),
      tooltip: {
        content: t('propertyRent.annualRentDescription'),
        title: t('propertyRent.annualRent'),
      },
    },
    {
      data: currencyFormatter(property.annual.charges, 0),
      icon: 'Users',
      testID: 'property-rent-data-block-annual-charges',
      title: t('propertyRent.charge'),
      tooltip: {
        content: t('propertyRent.chargeDescription'),
        title: t('propertyRent.charge'),
      },
    },
    {
      data: currencyFormatter(
        (property.building.annual?.expenses || 0) - property.annual.charges,
        0
      ),
      icon: 'User',
      testID: 'property-rent-data-block-annual-owner-charges',
      title: t('propertyRent.ownerCharge'),
      tooltip: {
        content: t('propertyRent.ownerChargeDescription'),
        title: t('propertyRent.ownerCharge'),
      },
    },
  ];

  if (showIsFurnished) {
    rows.splice(2, 0, {
      data: furnishing.isFurnished
        ? t<string>('propertyRent.furnished')
        : t<string>('propertyRent.noFurnished'),
      icon: 'Chair',
      testID: 'property-rent-data-block-invest',
      title: t('propertyRent.invest'),
    });
  }

  const onTooltipOpen = (section: string) =>
    trackTooltip({ propertyId: property.id, tooltipType: section });

  return { onTooltipOpen, rows, width };
};
