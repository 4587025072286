import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';
import { RouteProp, useRoute } from '@react-navigation/native';

import { Form } from '@app/shared/contexts/form/index.web';
import { trackRegisterCompleted, trackToGTM } from '@app/services/tracking/trackTracking';
import { captureException } from '@app/libs/sentry/sentry';
import { trackChangeScreen } from '@app/services/tracking/screenTracking';
import { PlatformEnum } from '@app/types/platform';
import { trackChangePage } from '@app/services/tracking/pageTracking';
import { storage } from '@app/services/storage/storage';
import { DEFAULT_PREFIX_NUMBER } from '@app/constants/onboarding';
import { getOrigin } from '@app/utils/originAndUtm';
import { PublicNavigatorRoutes, PublicStackParamList } from '@app/navigation/types/routes';
import { useAuthentication } from '@app/shared/hooks/useAuthentication';
import { AuthenticationStrategySSO } from '@app/libs/identity/identity.type';
import { trackSimpleIdentify } from '@app/services/tracking/identifyTracking';

import { getStepRegisterValidationSchema } from '../validatorRules';
import { useOnboardingStateMachine } from '../stateMachine/stateMachine.hooks';
import { saveStateMachineToStorage } from '../stateMachine/stateMachine.utils';
import { StepRegisterForm } from './StepRegisterForm';
import { flushCacheForEmailExists, isSSOMethod } from '../onboarding.utils';
import { useStepRegisterProcess } from './stepRegister.hooks';

interface InitialValues {
  email: string;
  firstName: string;
  isSubscribeNewsletter: boolean;
  lastName: string;
  password: string;
  phoneNumberPrefix: string;
  phoneNumberSuffix: string;
  terms: boolean;
}

interface TrackRegisterEventProps {
  customerId: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  siteOrigin: string;
  userEmail: string;
}

type StepRegisterRouteProp = RouteProp<PublicStackParamList, PublicNavigatorRoutes.Register>;

export const StepRegister: React.FC = () => {
  const { t, i18n } = useTranslation();
  const {
    createCustomer,
    emailFromQueryParam,
    firstNameFromQueryParam,
    idTokenFromQueryParam,
    isLoading: createCustomerLoading,
    lastNameFromQueryParam,
    method,
    strategy,
  } = useStepRegisterProcess();
  const route = useRoute<StepRegisterRouteProp>();
  const { loginWithEmailPassword, completeAuthentication } = useAuthentication();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const { context } = useOnboardingStateMachine();

  const connectAndLogin = async (email: string, password: string) => {
    await storage.clear('masteos.onboarding');
    flushCacheForEmailExists();

    await (password
      ? loginWithEmailPassword(email, password)
      : completeAuthentication(idTokenFromQueryParam, strategy as AuthenticationStrategySSO));
  };

  const trackRegisterEvent = ({
    customerId,
    firstName,
    lastName,
    phoneNumber,
    userEmail,
  }: TrackRegisterEventProps): void => {
    const EVENT_NAME = 'register';
    trackToGTM(EVENT_NAME, customerId, { firstName, lastName, phoneNumber, userEmail });
  };

  const handleRegister = async (values: InitialValues) => {
    const email: string = values.email?.toLowerCase().trim();
    const firstName: string = values.firstName?.trim();
    const lastName: string = values.lastName?.trim();
    const password: string = values.password?.trim();
    const phoneNumber: string = values.phoneNumberSuffix
      ? `${values.phoneNumberPrefix}${values.phoneNumberSuffix}`.replace(/ /g, '')
      : undefined;
    const { isSubscribeNewsletter } = values;
    const siteOrigin = await getOrigin();

    setIsLoading(true);

    await saveStateMachineToStorage({
      ...context,
      email,
      firstName,
      isSubscribeNewsletter,
      lastName,
    });

    const simpleCustomer = await createCustomer({
      email,
      firstName,
      isSubscribeNewsletter,
      lang: i18n.language,
      lastName,
      password,
      phoneNumber,
      siteOrigin,
    });

    const sponsorEmail = await storage.readString('sponsorEmail');

    trackSimpleIdentify(simpleCustomer.id, simpleCustomer.email);

    trackRegisterCompleted({
      email,
      firstName,
      isReferred: !!sponsorEmail,
      isSubscribeNewsletter,
      lastName,
      method,
    });

    await storage.clear('sponsorEmail');

    try {
      await connectAndLogin(email, password);
      // To be removed once new tracking tested by the marketing team
      trackRegisterEvent({
        customerId: simpleCustomer.id,
        firstName,
        lastName,
        phoneNumber,
        siteOrigin,
        userEmail: email,
      });
    } catch (e) {
      captureException(e);
      setIsLoading(false);
      setError(t('authTunnel.errorAuthenticateMessage'));
    }
  };

  useEffect(() => {
    if (Platform.OS === PlatformEnum.Web) {
      trackChangePage(route.name, { method });
    } else {
      trackChangeScreen(route.name, { method });
    }
  }, []);

  const initialValues: InitialValues = {
    email: emailFromQueryParam || context.email || '',
    firstName: firstNameFromQueryParam || context.firstName || '',
    isSubscribeNewsletter: false,
    lastName: lastNameFromQueryParam || context.lastName || '',
    password: '',
    phoneNumberPrefix: DEFAULT_PREFIX_NUMBER,
    phoneNumberSuffix: '',
    terms: false,
  };

  return (
    <Form
      initialValues={initialValues}
      schema={getStepRegisterValidationSchema(t, method)}
      onSubmit={handleRegister}
    >
      {({ handleSubmit }) => (
        <StepRegisterForm
          error={error}
          isSSOContext={isSSOMethod(method)}
          handleSubmit={handleSubmit}
          isLoading={isLoading || createCustomerLoading}
        />
      )}
    </Form>
  );
};
