import { makeVar } from '@apollo/client';

import {
  GetCustomerSuggestionsQuery,
  Positioning,
  PositioningStatus,
} from '@app/libs/apollo/introspection';

import { realEstateToItemMapper } from '../search-engine/utils/real-estate-to-item.mapper';
import { PositioningItemList } from './Suggestions.types';

export const suggestionsCallLaunched = makeVar<string[]>([]);

export const POSITIONING_STATUS_DISPLAYED = [
  PositioningStatus.Recommendation,
  PositioningStatus.Pending,
  PositioningStatus.Confirmed,
];

export const formatSuggestions = (
  data: GetCustomerSuggestionsQuery,
  launchedCall: string[]
): PositioningItemList => {
  const positioningList = data?.getCustomerSuggestions || [];

  return positioningList
    .filter(positioning => POSITIONING_STATUS_DISPLAYED.includes(positioning.status))
    .map(({ showcase, ...rest }) => ({
      ...(rest as Omit<Positioning, 'showcase'>),
      afterConfirmCallLaunched: launchedCall.includes(rest.id),
      realEstateItem: realEstateToItemMapper(showcase),
    }));
};
