import { SearchEngineFilterFormProps } from '@app/features/search-engine/searchEngine.types';

import { SearchEngineFiltersDrawerType } from '../search-engine-filters-drawer/searchEngineFiltersDrawer.type';

export type SearchEngineFiltersHorizontalProps = Omit<
  SearchEngineFiltersDrawerType,
  'onReset' | 'handleFilterHide'
> & {
  onFilterShow: () => void;
  defaultValues: SearchEngineFilterFormProps;
  filterCount: number;
};

export enum SearchEngineFilterFormHorizontal {
  BUDGET = 'budget',
  COUNTRY = 'country',
  TYPOLOGY = 'typology',
  REGION_LIST = 'regionList',
  SORT_STRATEGY = 'sortStrategy',
}
