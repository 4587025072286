import { RealEstateBadgeType } from './constants';

export const getRealEstateBadgeType = (
  isNew: boolean,
  isExclusive: boolean
): RealEstateBadgeType => {
  if (isNew) {
    return RealEstateBadgeType.new;
  }

  if (isExclusive) {
    return RealEstateBadgeType.isExclusive;
  }

  return RealEstateBadgeType.none;
};
