import { useMemo } from 'react';

import { isAssetManagementConsultant as isAssetManagementConsultantUtil } from '@app/utils/customer.utils';

import { useStateOfCustomer } from './useStateOfCustomer';
import { UseCustomer, useCustomer } from './useCustomer';

export enum StateOfCustomer {
  ASSET_MANAGEMENT_CONSULTANT = 'assetManagementConsultant',
  IN_TRANSACTION = 'inTransaction',
  ONGOING_APPOINTMENT = 'ongoingAppointment',
  POST_APPOINTMENT = 'postAppointment',
  POST_SIGNED_MANDATE = 'postSignedMandate',
  PRE_APPOINTMENT = 'preAppointment',
  PRE_SIGNED_MANDATE = 'preSignedMandate',
}

export type CustomerInterface = UseCustomer & {
  isAssetManagementConsultant: boolean;
  stateOfCustomer: StateOfCustomer | null;
};

const useMe = (): CustomerInterface => {
  const customerHook = useCustomer();

  const isAssetManagementConsultant = useMemo(
    () => isAssetManagementConsultantUtil(customerHook?.customer),
    [customerHook?.customer]
  );
  const stateOfCustomer = useStateOfCustomer({
    customer: customerHook?.customer,
    isAssetManagementConsultant,
  });

  return {
    ...customerHook,
    isAssetManagementConsultant,
    stateOfCustomer,
  };
};

export { useMe };
