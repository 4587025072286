import { Platform, StyleSheet } from 'react-native';

import { palette, Theme } from '@masteos/aphrodite';

export const getInvestmentWarningStyles = (theme: Theme) =>
  StyleSheet.create({
    container:
      Platform.OS !== 'web'
        ? {
            flex: 1,
            marginVertical: theme.spacing.SIZE_09,
            paddingHorizontal: theme.spacing.SIZE_06,
          }
        : {},
    textColor: { color: palette.neutral[Platform.OS === 'web' ? 900 : 700] },
  });
