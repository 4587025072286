import React, { useEffect, useState } from 'react';

import { StyleSheet, View } from 'react-native';

import { ActionButtonSizeVariant, LikeButton, Theme, useTheme } from '@masteos/aphrodite';

import { useTranslation } from '@app/services/translations/translations';
import { useToastContext } from '@app/shared/contexts/toast/Toast';
import { useBookmarkProperty, useDislikeProperty } from '@app/utils/propertiesSearchResults';

interface LikePropertyActionButtonProps {
  hasBorder?: boolean;
  size?: ActionButtonSizeVariant;
  realEstateId: string;
  onLike?: (isFavorite: boolean) => void;
  onError?: (error: unknown) => void;
  isFavorite: boolean;
}

const getStyles = (theme: Theme) =>
  StyleSheet.create({ container: { padding: theme.spacing.SIZE_01 } });

export const LikePropertyActionButton: React.FC<LikePropertyActionButtonProps> = props => {
  const {
    hasBorder,
    size = 'm',
    realEstateId,
    isFavorite: defaultIsFavorite = false,
    onLike,
    onError,
  } = props;
  const [isFavorite, setIsFavorite] = useState(defaultIsFavorite);
  const { bookmarkProperty } = useBookmarkProperty();
  const { unbookmarkProperty } = useDislikeProperty();
  const { setValidMsg } = useToastContext();
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = getStyles(theme);

  useEffect(() => {
    setIsFavorite(defaultIsFavorite);
  }, [defaultIsFavorite]);

  const onLikePress = async () => {
    try {
      const variables: { realEstateId: string; isPositionedOn?: boolean } = {
        realEstateId,
      };

      if (!isFavorite) {
        variables.isPositionedOn = false;
      }

      await (!isFavorite ? bookmarkProperty : unbookmarkProperty)({
        variables,
      });
      onLike?.(isFavorite);
      setValidMsg({
        icon: !isFavorite ? 'HeartFilled' : 'Heart',
        title: !isFavorite
          ? t('searchEngine.toast.favorite.add')
          : t('searchEngine.toast.favorite.remove'),
        withClose: true,
      });
    } catch (error) {
      onError && onError(error);
      return;
    } finally {
      setIsFavorite((prevState: boolean) => !prevState);
    }
  };

  return (
    <View style={styles.container}>
      <LikeButton
        onPress={onLikePress}
        liked={isFavorite}
        size={size}
        withBorder={hasBorder}
        testID="like-button"
      />
    </View>
  );
};
