import React from 'react';

import { Controller } from 'react-hook-form';

import { Button, Input, PhoneInput, Spacer, useTheme } from '@masteos/aphrodite';

import { useTranslation } from '@app/services/translations/translations';
import { useFeatureFlags } from '@app/shared/hooks/useFeatureFlag';
import { FeatureFlags } from '@app/constants/feature-flags';
import { ProfileLocationSelect } from '@app/features/profile/ProfileLocationSelect.web';
import { ProfilePicture } from '@app/features/profile/ProfilePicture.web';
import * as S from '@app/features/profile/profile.styles.web';
import { formatAddress } from '@app/features/profile/utils/formatAddress.utils';
import { useProfileInformation } from '@app/features/profile/hooks/useProfileInformation';
import { ProfileInformationPayment } from '@app/features/profile/ProfileInformationPayment';

export const ProfileInformation: React.FC = () => {
  const { t } = useTranslation();
  const { control, onSubmit, getIbanHint, isSaving, isAssetManagementConsultant } =
    useProfileInformation();
  const { isEnabled } = useFeatureFlags();
  const isIbanFormEnabled = isEnabled(FeatureFlags.ibanForm);
  const theme = useTheme();

  return (
    <S.FormWrapper>
      <S.Form>
        <Controller
          name="avatarS3ID"
          control={control}
          render={({ field: { onChange, value } }) => (
            <ProfilePicture avatarUrl={value} onChange={onChange} size={96} />
          )}
        />
        <Spacer height={theme.spacing.SIZE_08} />
        <Controller
          name="lastName"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              label={t('shared.lastName')}
              value={value}
              onChangeText={onChange}
              error={error?.message}
            />
          )}
        />
        <Spacer height={theme.spacing.SIZE_06} />
        <Controller
          name="firstName"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              label={t('shared.firstName')}
              value={value}
              onChangeText={onChange}
              error={error?.message}
            />
          )}
        />
        <Spacer height={theme.spacing.SIZE_06} />
        <Controller
          name="email"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Input
              label={t('shared.email')}
              value={value}
              onChangeText={onChange}
              editable={false}
            />
          )}
        />
        <Spacer height={theme.spacing.SIZE_06} />
        <Controller
          name="address"
          control={control}
          render={({ field: { value, onChange } }) => (
            <ProfileLocationSelect
              placeholder={t('profile.addressLabel')}
              defaultValue={formatAddress(value)}
              setAddress={onChange}
              limit={2}
            />
          )}
        />
        <Spacer height={theme.spacing.SIZE_04} />

        <Controller
          name="phonePrefix"
          control={control}
          render={({ field: { onChange: onPrefixChange, value: prefixValue } }) => (
            <Controller
              name="phoneSuffix"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <PhoneInput
                  value={value}
                  label={t('profile.phoneLabel')}
                  countryValue={prefixValue}
                  onCountryChange={v => onPrefixChange(v.toString())}
                  onChange={onChange}
                  error={error?.message}
                />
              )}
            />
          )}
        />
        <Spacer height={theme.spacing.SIZE_06} />
        <Controller
          name="job"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Input label={t('profile.jobLabel')} value={value} onChangeText={onChange} />
          )}
        />
        <Spacer height={theme.spacing.SIZE_06} />
        <Controller
          name="promoCode"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Input label={t('profile.sponsorshipCode')} value={value} onChangeText={onChange} />
          )}
        />
        <Spacer height={theme.spacing.SIZE_09} />
        {!!isIbanFormEnabled && !isAssetManagementConsultant && (
          <>
            <Spacer height={theme.spacing.SIZE_09} />
            <ProfileInformationPayment control={control} getIbanHint={getIbanHint} />
          </>
        )}
        <Button onPress={onSubmit} isLoading={isSaving}>
          {t('shared.save')}
        </Button>
      </S.Form>
    </S.FormWrapper>
  );
};
