import React from 'react';

import { RadioGroup as AphroditeRadioGroup, Spacer, Spacing } from '@masteos/aphrodite';

import { useFormContext } from './FormContext.web';
import { ErrorMessage } from './ErrorMessage.web';

export interface RadioGroupProps {
  name: string;
  errorMessage?: string;
  children?: React.ReactNode;
}

export const RadioGroup: React.FC<RadioGroupProps> = ({
  name,
  errorMessage,
  ...radioGroupProps
}) => {
  const formContext = useFormContext();

  if (!formContext) {
    console.warn(
      `<RadioGroup name="${name}" /> must be used inside a Form component. RadioGroup has been rendered but the name props  have been ignored`
    );
    return (
      <AphroditeRadioGroup
        value={formContext.getValue(name)}
        onValueChange={value => formContext.setValue(name, value)}
        {...radioGroupProps}
      />
    );
  }

  return (
    <AphroditeRadioGroup
      onValueChange={value => formContext.setValue(name, value)}
      {...radioGroupProps}
      {...formContext.mapFieldProps(name)}
      value={formContext.getValue(name)}
    >
      <>
        <ErrorMessage message={errorMessage} name={name} />
        <Spacer height={Spacing.SIZE_04} />
        {radioGroupProps.children}
      </>
    </AphroditeRadioGroup>
  );
};
