import React, { useCallback, useRef, useState } from 'react';

import { Platform, ScrollView, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';

import { Spacer, useTheme } from '@masteos/aphrodite';

import { useRealEstateTitle } from '@app/shared/hooks/usePropertyTitle';
import { useTranslation } from '@app/services/translations/translations';
import { ApollonRoutes } from '@app/constants/routes.web';
import { useRealEstateChips } from '@app/shared/hooks/useRealEstateChips';
import {
  trackProjectPropertyPageClicked,
  trackProjectSubStepsClicked,
  trackProjectSubStepsDetailsClicked,
  trackProjectTabClicked,
  trackTimelineStepClicked,
} from '@app/services/tracking/trackTracking';
import { ProjectNavigatorRoutes } from '@app/navigation/types/routes';
import {
  ProjectCategory,
  ProjectNavigationProp,
  ProjectProps,
} from '@app/features/project/Project.types';
import { MENU_TOP_HEIGHT, projectStyles as styles } from '@app/features/project/Project.styles';
import { ProjectTabsView } from '@app/features/project/ProjectTabsView/ProjectTabsView';
import { ProjectHeader } from '@app/features/project/ProjectHeader/ProjectHeader';

const PADDING_CARD = 20;

export const Project: React.FC<ProjectProps> = ({
  project: { realEstate, renting, status, projectAmount, id, transaction },
}) => {
  const navigation = useNavigation<ProjectNavigationProp>();
  const { t } = useTranslation();
  const stageRefs = useRef<Record<ProjectCategory, View[]>>({
    [ProjectCategory.Transaction]: [],
    [ProjectCategory.Renovation]: [],
    [ProjectCategory.Renting]: [],
  });
  const containerRef = useRef<ScrollView>(null);
  const theme = useTheme();
  const realEstateTitle = useRealEstateTitle(realEstate, renting.type);
  const realEstateChips = useRealEstateChips(realEstate);
  // FIXME To fill when data will from the back
  const projectSteps = {
    [ProjectCategory.Transaction]: transaction,
    [ProjectCategory.Renovation]: {},
    [ProjectCategory.Renting]: {},
  };
  const [stepActive, setStepActive] = useState<number>(1);

  const address = realEstate.building.address
    ? `${realEstate.building.address.street}, ${t('propertyInfo.address', {
        city: realEstate.building.address.city,
        country: realEstate.building.address.country,
        postalCode: realEstate.building.address.postalCode,
      })}`
    : '';

  const detailsButtonStatus = expanded => (expanded ? 'Show' : 'Hide');
  const onPressGoBack = () => navigation.goBack();
  const navigateToRealEstate = () => {
    trackProjectPropertyPageClicked({
      projectId: id,
      projectStatus: status,
      propertyId: realEstate.id,
    });

    return Platform.select({
      native: () => navigation.navigate(ProjectNavigatorRoutes.RealEstate, { id: realEstate.id }),
      web: () => window.open(ApollonRoutes.PROPERTY.replace(':id', realEstate.id), '_blank'),
    })();
  };
  const scrollToStage = (stageNumber: number) => {
    trackTimelineStepClicked({
      category: Object.values(ProjectCategory)[stepActive - 1],
      projectId: id,
      stepNumber: stageNumber,
    });
    Object.values(stageRefs.current)[stepActive - 1][stageNumber - 1].measure(
      // eslint-disable-next-line max-params
      (_, _1, _2, _3, _4, pageY) =>
        containerRef.current.scrollTo({
          y: pageY - 2 * MENU_TOP_HEIGHT - PADDING_CARD,
        })
    );
  };

  const onExpandSubSteps = useCallback(
    (stepId: number) =>
      ({ expanded }: { expanded: boolean }) =>
        trackProjectSubStepsClicked({
          category: Object.values(ProjectCategory)[stepActive - 1],
          detailsButtonStatus: detailsButtonStatus(expanded),
          projectId: id,
          stepId,
        }),
    [id, stepActive]
  );
  const onExpandSubStepsDetails = useCallback(
    (stepId: number) =>
      (subStepId: number) =>
      ({ expanded }: { expanded: boolean }) =>
        trackProjectSubStepsDetailsClicked({
          category: Object.values(ProjectCategory)[stepActive - 1],
          detailsButtonStatus: detailsButtonStatus(expanded),
          projectId: id,
          stepId,
          subStepId,
        }),
    [id, stepActive]
  );
  const onChangeTab = useCallback(stepNumber => {
    setStepActive(stepNumber);
    trackProjectTabClicked({ name: Object.values(ProjectCategory)[stepNumber - 1] });
  }, []);

  const onTimelinePress = useCallback(
    stepNumber => {
      setStepActive(stepNumber);
      trackTimelineStepClicked({
        category: Object.values(ProjectCategory)[stepNumber - 1],
        projectId: id,
        stepNumber: null,
      });
    },
    [id]
  );

  return (
    <ScrollView
      ref={containerRef}
      testID="project-container"
      contentContainerStyle={styles.scrollViewContentContainer}
    >
      <ProjectHeader
        address={address}
        chips={realEstateChips}
        imageCover={realEstate.coverImage}
        onBack={onPressGoBack}
        onButtonPress={navigateToRealEstate}
        projectAmount={projectAmount}
        projectSteps={projectSteps}
        scrollToStage={scrollToStage}
        setStepActive={onTimelinePress}
        status={status}
        stepActive={stepActive}
        title={realEstateTitle}
      />
      <View style={styles.contentContainer}>
        <Spacer height={theme.spacing.SIZE_09} />
        <ProjectTabsView
          navigateToRealEstate={navigateToRealEstate}
          onExpandSubSteps={onExpandSubSteps}
          onExpandSubStepsDetails={onExpandSubStepsDetails}
          onTabPress={onChangeTab}
          projectSteps={projectSteps}
          realEstate={realEstate}
          stageRefs={stageRefs}
          stepActive={stepActive}
        />
      </View>
    </ScrollView>
  );
};
