import React, { useContext, useMemo } from 'react';

import { StyleSheet, View } from 'react-native';

import { Spacing } from '@masteos/aphrodite';

interface CellProps {
  children?: React.ReactNode;
}

export const Cell: React.FC<CellProps> = ({ children }) => {
  const { horizontalGap, verticalGap, col } = useContext(FlexGridContext);
  const styles = useMemo(
    () =>
      StyleSheet.create({
        innerCell: {
          paddingHorizontal: horizontalGap / 2,
          paddingVertical: verticalGap / 2,
          position: 'relative',
        },
        outerCell: {
          flexBasis: `${100 / col}%`,
        },
      }),
    [col, horizontalGap, verticalGap]
  );

  return (
    <View style={styles.outerCell}>
      <View style={styles.innerCell}>{children}</View>
    </View>
  );
};

type FlexGridComposition = {
  Cell: React.FC<{ children?: React.ReactNode }>;
};

const DEFAULT_GAP = Spacing.SIZE_06;
const DEFAULT_COL_NUMBER = 1;

const FlexGridContext = React.createContext<FlexGridProps>({
  col: DEFAULT_COL_NUMBER,
  horizontalGap: DEFAULT_GAP,
  verticalGap: DEFAULT_GAP,
});

type FlexGridProps = {
  col?: number;
  gap?: number;
  horizontalGap?: number;
  verticalGap?: number;
  children?: React.ReactNode;
};

export const FlexGrid: React.FC<FlexGridProps> & FlexGridComposition = ({
  children,
  gap = DEFAULT_GAP,
  col = DEFAULT_COL_NUMBER,
  horizontalGap,
  verticalGap,
}) => {
  const styles = useMemo(() => {
    const marginHorizontal = Number.isInteger(horizontalGap) ? -horizontalGap / 2 : -gap / 2;
    const marginVertical = Number.isInteger(verticalGap) ? -verticalGap / 2 : -gap / 2;
    return StyleSheet.create({
      container: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginHorizontal,
        marginVertical,
      },
    });
  }, [horizontalGap, verticalGap, gap]);
  return (
    <View style={styles.container}>
      <FlexGridContext.Provider
        value={{ col, horizontalGap: horizontalGap ?? gap, verticalGap: verticalGap ?? gap }}
      >
        {children}
      </FlexGridContext.Provider>
    </View>
  );
};

FlexGrid.Cell = Cell;
