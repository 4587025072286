import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Trans } from 'react-i18next';
import { View, FlatList, LayoutChangeEvent, Platform, ViewStyle } from 'react-native';
import { useFocusEffect, useNavigation } from '@react-navigation/native';

import { HeaderPage, Text } from '@masteos/aphrodite';

import { useColumnsNb } from '@app/shared/hooks/useColumnsNb';
import { trackSearchButtonClick } from '@app/services/tracking/trackTracking';
import { useTranslation } from '@app/services/translations/translations';
import { GetMyProjectsQuery } from '@app/libs/apollo/introspection';
import { PlatformEnum } from '@app/types/platform';
import { trackChangePage } from '@app/services/tracking/pageTracking';
import { trackChangeScreen } from '@app/services/tracking/screenTracking';
import { useMe } from '@app/shared/hooks/useMe';
import { ProjectNavigatorRoutes, MainMenuNavigatorRoutes } from '@app/navigation/types/routes';

import { ProjectListNavigationProps } from './ProjectList.type';
import { ProjectCardRealEstate } from './ProjectCard/projectCard.types';
import { ProjectCard } from './ProjectCard/ProjectCard';
import { getProjectListStyles } from './ProjectList.styles';
import { ProjectEmptyList } from './ProjectEmptyList';
import { useMyProjectsList } from './useProjectList.hook';

export const ProjectList: React.FC = () => {
  const navigation = useNavigation<ProjectListNavigationProps>();
  const { t } = useTranslation();
  const { data: { myProjects } = { myProjects: [] }, loading } = useMyProjectsList();
  const { customer } = useMe();
  const columnsNb = useColumnsNb();
  const isMobile = Platform.OS !== PlatformEnum.Web;
  const flatListStyle: ViewStyle = { ...(!isMobile && { overflow: 'visible' }) };

  const [parentWidth, setParentWidth] = useState(0);

  const styles = useMemo(
    () => getProjectListStyles(parentWidth, isMobile, columnsNb),
    [parentWidth, columnsNb, isMobile]
  );

  const onLayout = useCallback((event: LayoutChangeEvent) => {
    const { width } = event.nativeEvent.layout;
    setParentWidth(width);
  }, []);

  const onPressSearch = useCallback(() => {
    navigation.navigate(MainMenuNavigatorRoutes.RealEstates);
    trackSearchButtonClick();
  }, [navigation]);

  const onPressCard = useCallback(
    (projectId: string) => () => {
      navigation.navigate(ProjectNavigatorRoutes.Project, { id: projectId });
    },
    [navigation]
  );

  const renderItem = useCallback(
    ({
      item: { id, projectAmount, renting, status, realEstate },
    }: {
      item: GetMyProjectsQuery['myProjects'][0];
    }) => (
      <View style={styles.item} key={id} testID="project-list-item">
        <ProjectCard
          projectAmount={projectAmount}
          rentingType={renting?.type}
          status={status}
          realEstate={realEstate as ProjectCardRealEstate}
          onPress={onPressCard(id)}
        />
      </View>
    ),
    [styles.item, onPressCard]
  );

  const renderListEmptyComponent = useCallback(
    () => <ProjectEmptyList onPressSearch={onPressSearch} testID="project-empty-list" />,
    [onPressSearch]
  );

  const trackParams = useRef({
    hasAnActiveTransaction: customer.hasOngoingProject,
    projectNumber: myProjects.length,
  });

  useEffect(() => {
    trackParams.current = {
      hasAnActiveTransaction: customer.hasOngoingProject,
      projectNumber: myProjects.length,
    };
  }, [customer.hasOngoingProject, myProjects.length]);

  useFocusEffect(
    useCallback(() => {
      if (loading) {
        return;
      }
      const { projectNumber } = trackParams.current;
      if (Platform.OS === PlatformEnum.Web) {
        trackChangePage(MainMenuNavigatorRoutes.Projects, { projectNumber });
      } else {
        trackChangeScreen(MainMenuNavigatorRoutes.Projects, { projectNumber });
      }
    }, [loading])
  );

  const ListHeaderComponent = useCallback(
    () => (
      <View style={styles.listHeaderWrapper}>
        <HeaderPage title={t('projectList.myProjects')} />
        <Text textStyle="Body2" style={styles.statusDescription}>
          <Trans
            i18nKey="project.statusDescription"
            components={{
              italic: <Text textStyle="Body2Italic" />,
              medium: <Text textStyle="Body2Medium" />,
            }}
          />
        </Text>
      </View>
    ),
    [styles.statusDescription, t]
  );

  return (
    !loading && (
      <View onLayout={onLayout} style={styles.wrapper} testID="project-list-view">
        {!!parentWidth && (
          <FlatList
            data={myProjects}
            ListEmptyComponent={renderListEmptyComponent}
            ListHeaderComponent={ListHeaderComponent}
            renderItem={renderItem}
            columnWrapperStyle={(columnsNb > 1 && styles.itemContainer) || null}
            keyExtractor={project => project.id}
            numColumns={columnsNb}
            key={columnsNb}
            testID="project-list"
            style={flatListStyle}
          />
        )}
      </View>
    )
  );
};
