import React, { PropsWithChildren } from 'react';

import { Platform, SafeAreaView, StyleSheet } from 'react-native';

import { palette, Theme, useTheme } from '@masteos/aphrodite';

const getStyles = (theme: Theme, isMobile: boolean) =>
  StyleSheet.create({
    card: { width: '100%' },
    container: { backgroundColor: isMobile ? palette.neutral[100] : palette.base.white, flex: 1 },
    error: {
      color: palette.alert.error,
    },
    title: {
      color: isMobile ? theme.palette.base.white : theme.palette.base.black,
    },
  });

interface DebugMenuWrapperProps {
  testID?: string;
}

export const DebugMenuWrapper: React.FC<PropsWithChildren<DebugMenuWrapperProps>> = ({
  children,
  testID,
}) => {
  const theme = useTheme();
  const isMobile = Platform.OS !== 'web';
  const styles = getStyles(theme, isMobile);

  return (
    <SafeAreaView style={styles.container} testID={testID}>
      {children}
    </SafeAreaView>
  );
};
