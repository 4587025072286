import { Platform } from 'react-native';

import { PlatformEnum } from '@app/types/platform';

export enum AuthenticationStrategy {
  Local = 'local',
  Google = 'google',
  Apple = 'apple',
  AppleIos = 'apple-ios',
}

export enum ResetPasswordOrigin {
  ApollonUser = 'APOLLON_USER',
}

export type AuthenticationStrategySSO =
  | AuthenticationStrategy.Google
  | AuthenticationStrategy.Apple
  | AuthenticationStrategy.AppleIos;

export interface AuthenticationOptionsWithEmailPassword {
  email: string;
  password: string;
  strategy: AuthenticationStrategy.Local;
}

export interface AuthenticationOptionsWithSSO {
  idToken: string;
  platform: PlatformEnum | typeof Platform.OS;
  strategy: AuthenticationStrategySSO;
}

export type AuthenticateReqBody =
  | AuthenticationOptionsWithEmailPassword
  | AuthenticationOptionsWithSSO;

export interface ForgotPasswordReqBody {
  email: string;
  language: string;
}
export interface ResetPasswordReqBody {
  email: string;
  code: string;
}

export interface RenewPasswordReqBody {
  email: string;
  hash: string;
  password: string;
}

export type ForgotPasswordResponse = void;
export type ResetPasswordResponse = { hash: string };
export type RenewPasswordResponse = void;
export type AuthenticateResponse = { access_token: string };
export type AuthenticateGoogleResponse = {
  idToken: string;
  emailExists: boolean;
  email: string;
  id: string;
  firstName?: string;
  lastName?: string;
};
