import React from 'react';

import { StyleSheet, View } from 'react-native';

import { palette, Spacing, Text, IconName, Icon, useResponsive } from '@masteos/aphrodite';

export interface PropertyFinancialHeaderProps {
  title?: string;
  icon?: IconName;
  hasChevron?: boolean;
  chevronOpen?: boolean;
  amount?: string;
}

const styles = StyleSheet.create({
  columnTitle: {
    flex: 1,
    flexDirection: 'column',
  },
  headerLine: {
    alignItems: 'flex-start',
    flex: 1,
    flexDirection: 'row',
  },
  headerLineContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: Spacing.SIZE_04,
    paddingTop: Spacing.SIZE_04,
  },
  margin: {
    marginRight: Spacing.SIZE_04,
  },
  textStyle: {
    flexWrap: 'wrap',
  },
  title: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

export const PropertyFinancialHeader: React.FC<PropertyFinancialHeaderProps> = ({
  title,
  icon,
  hasChevron = false,
  chevronOpen,
  amount,
}) => {
  const { isLowerThanTablet } = useResponsive();

  return (
    <View style={styles.headerLineContainer}>
      <View style={styles.headerLine}>
        {!!icon && (
          <View style={styles.margin}>
            <Icon name={icon} size={23} color={palette.base.black} />
          </View>
        )}
        <View style={isLowerThanTablet ? styles.columnTitle : styles.title}>
          <Text textStyle="Headline2Medium">{title}</Text>
          {!!amount && (
            <Text style={styles.margin} textStyle="Headline2Medium">
              {amount}
            </Text>
          )}
        </View>
      </View>
      {!!hasChevron && <Icon name={chevronOpen ? 'ChevronUp' : 'ChevronDown'} size={22} />}
    </View>
  );
};
