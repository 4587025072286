import { LinkingOptions } from '@react-navigation/native';
import { Platform } from 'react-native';

import { config } from '@app/config';
import {
  AdvisorNavigatorRoutes,
  ForgotPasswordNavigatorRoutes,
  MainMenuNavigatorRoutes,
  PrivateNavigatorRoutes,
  ProfileNavigatorRoutes,
  ProjectNavigatorRoutes,
  PublicNavigatorRoutes,
  RealEstateNavigatorRoutes,
  SuggestionNavigatorRoutes,
} from '@app/navigation/types/routes';

import { subscribe } from '../utils/deeplink';

const LOCALHOST = 'http://localhost:9000';

const prefixes = [config.WEB_APP_URL ?? LOCALHOST];

export const MAIN_MENU_LINKING_PATHS = {
  [MainMenuNavigatorRoutes.Advisor]: 'advisor',
  [MainMenuNavigatorRoutes.Profile]: 'profile',
  [MainMenuNavigatorRoutes.Projects]: 'projects',
  [MainMenuNavigatorRoutes.RealEstates]: 'property',
  [MainMenuNavigatorRoutes.Suggestions]: 'suggestions',
};

export const privateLinking: LinkingOptions<ReactNavigation.RootParamList> = {
  config: {
    screens: {
      [PrivateNavigatorRoutes.Onboarding]: 'welcome',
      NotFound: '*',
      [PrivateNavigatorRoutes.MainMenu]: {
        path: '',
        screens: {
          [MainMenuNavigatorRoutes.Advisor]: {
            path: MAIN_MENU_LINKING_PATHS[MainMenuNavigatorRoutes.Advisor],
            screens: {
              [AdvisorNavigatorRoutes.AdvisorAppointment]: 'take-appointment',
              [AdvisorNavigatorRoutes.AdvisorRoot]: '',
            },
          },
          [MainMenuNavigatorRoutes.Profile]: {
            path: MAIN_MENU_LINKING_PATHS[MainMenuNavigatorRoutes.Profile],
            screens: {
              [ProfileNavigatorRoutes.ProfileRoot]: '',
              [ProfileNavigatorRoutes.ProfileDocument]: 'document/:id',
            },
          },
          [MainMenuNavigatorRoutes.Projects]: {
            path: MAIN_MENU_LINKING_PATHS[MainMenuNavigatorRoutes.Projects],
            screens: {
              [ProjectNavigatorRoutes.ProjectList]: '',
              [ProjectNavigatorRoutes.Project]: ':id',
            },
          },
          [MainMenuNavigatorRoutes.RealEstates]: {
            path: MAIN_MENU_LINKING_PATHS[MainMenuNavigatorRoutes.RealEstates],
            screens: {
              [RealEstateNavigatorRoutes.RealEstateList]: '',
              [RealEstateNavigatorRoutes.RealEstate]: ':id',
            },
          },
          [MainMenuNavigatorRoutes.Suggestions]: {
            path: MAIN_MENU_LINKING_PATHS[MainMenuNavigatorRoutes.Suggestions],
            screens: {
              [SuggestionNavigatorRoutes.SuggestionList]: '',
              [SuggestionNavigatorRoutes.RealEstate]: ':id',
            },
          },
          [MainMenuNavigatorRoutes.HomePage]: {
            path: '',
            screens: {
              [MainMenuNavigatorRoutes.HomePage]: '',
            },
          },
        },
      },
    },
  },

  prefixes,
  subscribe,
};

export const publicLinking: LinkingOptions<ReactNavigation.RootParamList> = {
  config: {
    screens: {
      [PublicNavigatorRoutes.Auth]: 'auth',
      [PublicNavigatorRoutes.ForgotPassword]: {
        path: 'forgot-password',
        screens: {
          [ForgotPasswordNavigatorRoutes.ForgotPasswordCodeStep]: 'codeStep',
          [ForgotPasswordNavigatorRoutes.ForgotPasswordNewPasswordStep]: 'newPassword',
          [ForgotPasswordNavigatorRoutes.ForgotPasswordEmailStep]: '',
        },
      },
      [PublicNavigatorRoutes.Login]: 'login',
      [PublicNavigatorRoutes.Register]: 'onboarding/register',
      [PublicNavigatorRoutes.SsoRedirect]: 'sso-redirect',

      NotFound: '*',

      ...(Platform.OS === 'web' && { [PublicNavigatorRoutes.RealEstate]: 'property/:id' }),
    },
  },
  prefixes,
};
