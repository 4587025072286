export enum ApollonRoutes {
  SKHOLE = '/skhole',
  ADVISOR = '/advisor',
  ADVISOR_APPOINTMENT = '/advisor/take-appointment',
  PROFILE_DOCUMENTS = '/profile/documents/:id',
  ERROR = '/error',
  HOME = '/',
  PROFILE = '/profile',
  PROPERTY = '/property/:id',
  PROPERTIES = '/properties',
  SELECTION = '/selections',
  FAVORITES = '/favorites',
  LOGIN = '/login',
  FORGOT_PASSWORD = '/forgot-password',
  REGISTER = '/register',
  FIRST_APPOINTMENT = '/first-appointment',
  PROJECT = '/projects/:id',
  PROJECTS = '/projects',
  ONBOARDING = '/onboarding',
  ONBOARDING_STEP_REGISTER = '/onboarding/register',
  AUTH = '/auth',
  SSO_REDIRECT = '/sso-redirect',
  APPLE_REDIRECT = '/auth/apple',
}
