import React, { PropsWithChildren } from 'react';

import { StyleSheet, View } from 'react-native';

import { Spacer, Text, Theme, useTheme } from '@masteos/aphrodite';

import { useTranslation } from '@app/services/translations/translations';
import { LayoutSideText } from '@app/shared/components/LayoutSideText/LayoutSideText';

import { ForgotPasswordHeader } from '../ForgotPasswordHeader.web';

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    columnStart: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    contentSideContainer: {
      alignItems: 'center',
      flex: 1,
    },
    italic: {
      fontStyle: 'italic',
    },
    rowContainer: {
      alignItems: 'center',
      flexDirection: 'row',
      marginVertical: theme.spacing.SIZE_07,
    },
    sideTextHeader: {
      color: theme.palette.base.white,
    },
    sideTextHeaderEnd: {
      color: theme.palette.base.white,
      paddingBottom: theme.spacing.SIZE_07,
    },
    sideTextListItem: {
      alignItems: 'center',
      color: theme.palette.base.white,
      textAlign: 'left',
      width: '75%',
    },
    sideTextNumber: {
      color: theme.palette.base.white,
      marginRight: theme.spacing.SIZE_05,
    },
    stepWrapper: {
      flex: 1,
      maxWidth: 335,
      paddingHorizontal: 20,
      paddingVertical: 35,
      width: '100%',
    },
    whiteText: {
      color: theme.palette.base.white,
    },
    width75: {
      width: '75%',
    },
  });

export const ForgotPasswordStepWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = getStyles(theme);

  const sideContent = (
    <View style={styles.columnStart}>
      <View style={styles.width75}>
        <Text textStyle="Body2Medium" style={[styles.whiteText, styles.italic]}>
          {t('forgotPassword.list.1')}
        </Text>
        <Text textStyle="Body2" style={styles.whiteText}>
          {t('forgotPassword.list.2')}
        </Text>
      </View>
      <Spacer height={theme.spacing.SIZE_03} />
      <View>
        <Text textStyle="Body2" style={styles.sideTextListItem}>
          {t('forgotPassword.list.3')}
        </Text>
      </View>
    </View>
  );

  return (
    <LayoutSideText translateKey="forgotPasswordSide" content={sideContent}>
      <ForgotPasswordHeader />
      <View style={styles.contentSideContainer}>
        <View style={styles.stepWrapper}>{children}</View>
      </View>
    </LayoutSideText>
  );
};
