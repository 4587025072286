import * as Yup from 'yup';
import { TFunction } from 'react-i18next';

export type ValidationSchema = (t: TFunction<'translation', undefined>) => Yup.AnyObjectSchema;

export const getValidatorRules: ValidationSchema = t =>
  Yup.object().shape({
    email: Yup.string()
      .required(t('register.requiredField'))
      .email(t('register.invalidEmail'))
      .label(t('shared.defaultErrorFieldName')),
  });
