import { FinancialModel } from '@masteos/agora';

import * as segment from '@app/libs/segment/segment.web';
import { RealEstate } from '@app/libs/apollo/introspection';
import { SignInMethod } from '@app/features/authBridge/authBridge.constants';
import {
  PublicNavigatorRoutes,
  ProfileNavigatorRoutes,
  AdvisorNavigatorRoutes,
  Routes,
  ProjectNavigatorRoutes,
  RealEstateNavigatorRoutes,
  ForgotPasswordNavigatorRoutes,
  MainMenuNavigatorRoutes,
  SuggestionNavigatorRoutes,
} from '@app/navigation/types/routes';

import { getRealEstateBadgeType } from './utils';

export enum WebPageName {
  ERROR = '/error',
  AUTH = '/auth',
  VIEW_DOCUMENT = '/profile/document',
  FAVORITES = '/favorites',
  SELECTIONS = '/selections',
  PROJECT = 'projectPage',
  PROJECTS = 'projectListPage',
  ONBOARDING = 'welcome',
}

export const trackChangePage = (
  pageId: WebPageName | Routes,
  params: {
    method?: SignInMethod;
    financialModel?: FinancialModel;
    property?: RealEstate;
    document?: { id: string; category: string };
    count?: number;
    propertyPosition?: number;
    project?: { id: string; status?: string };
    projectNumber?: number;
    eventName?: string;
    isFromSearchEngine?: boolean;
  } = {}
): void => {
  switch (pageId) {
    // Public routes
    case PublicNavigatorRoutes.Auth:
      segment.page('View auth form page');
      break;

    case PublicNavigatorRoutes.Login:
      segment.page('View login form page');
      break;

    case PublicNavigatorRoutes.Register:
      segment.page('View registration', { method: params.method });
      break;

    // Forgot password routes
    case ForgotPasswordNavigatorRoutes.ForgotPasswordEmailStep:
      segment.page('View forgotten password');
      break;

    case ForgotPasswordNavigatorRoutes.ForgotPasswordCodeStep:
      segment.page('View forgotten code');
      break;

    case ForgotPasswordNavigatorRoutes.ForgotPasswordNewPasswordStep:
      segment.page('View forgotten confirmed password');
      break;

    // Real estates routes
    case RealEstateNavigatorRoutes.RealEstateList:
      segment.page('View matching page');
      break;

    case RealEstateNavigatorRoutes.RealEstate:
      if (params.property && params.financialModel) {
        segment.page('View property page', {
          annualGrossReturn: params.financialModel.grossReturn,
          annualRevaluationRate: params.property.project.renting.annual.revaluationRate,
          ...(params.isFromSearchEngine && {
            badgeType: getRealEstateBadgeType(params.property.isNew, params.property.isExclusive),
          }),
          city: params?.property?.building.address?.city,
          investStrategy: params.property.project.strategy,
          isARecommandation: !!params.property?.metadata?.positioning?.isSuggestion,
          locationType: params.property.project.renting.type,
          projectAmount: params.financialModel.projectAmount,
          propertyId: params.property.id,
          propertyPosition: params.propertyPosition,
          region: params.property?.building.address?.regionName,
          renovationAmount: params.property.project.renovation.amount,
          type: params.property.type,
        });
      }
      break;

    // Project routes
    case ProjectNavigatorRoutes.Project:
      segment.page('Project Detail', {
        projectId: params.project?.id,
        projectStatus: params.project?.status,
        propertyId: params.property?.id,
      });
      break;

    case MainMenuNavigatorRoutes.Projects:
    case ProjectNavigatorRoutes.ProjectList:
      segment.page('Project Menu page', { projectNumber: params.projectNumber });
      break;

    // Advisor routes
    case AdvisorNavigatorRoutes.AdvisorRoot:
      segment.page('View advisor page');
      break;

    case AdvisorNavigatorRoutes.AdvisorAppointment:
      segment.page('View take appointment page');
      break;

    // Profile routes
    case MainMenuNavigatorRoutes.Profile:
    case ProfileNavigatorRoutes.ProfileRoot:
      segment.page('View profile page');
      break;

    case ProfileNavigatorRoutes.ProfileDocumentsList:
      segment.page('View a document', {
        documentCategory: params.document?.category,
        documentId: params.document?.id,
      });
      break;

    // WebPageName
    case WebPageName.ERROR:
      segment.page('View error page');
      break;

    case WebPageName.ONBOARDING:
      params.eventName && segment.page(params.eventName);
      break;

    case SuggestionNavigatorRoutes.SuggestionList:
      segment.page('View suggestions page');
      break;

    default:
      // we hide console on production thanks to "babel-plugin-transform-remove-console"
      console.log(`[Tracking] - This pageId doesn't have a tracking condition: ${pageId}`);
      break;
  }
};
