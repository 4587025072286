import numbro from 'numbro';
import numbroFrConfiguration from 'numbro/languages/fr-FR';

type Languages = 'fr-FR';

export const initialize = (): void => {
  numbro.registerLanguage(numbroFrConfiguration);
};

export const setLanguage = (lang: Languages): void => {
  numbro.setLanguage(lang);
};

export const formatOrdinal = (number: number): string => {
  return numbro(number).format({ output: 'ordinal' });
};
