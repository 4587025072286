import { NavigatorScreenParams } from '@react-navigation/native';

import { SearchEngineSection } from '@app/features/search-engine/searchEngine.types';
import { Document } from '@app/libs/apollo/introspection';

export enum PrivateNavigatorRoutes {
  MainMenu = 'MainMenu',
  Onboarding = 'Onboarding',
}

export enum MainMenuNavigatorRoutes {
  Advisor = 'Advisor',
  RealEstates = 'RealEstates',
  Projects = 'Projects',
  Profile = 'Profile',
  Suggestions = 'Suggestions',
  HomePage = 'HomePage',
}

export enum PublicNavigatorRoutes {
  Auth = 'Auth',
  ForgotPassword = 'ForgotPassword',
  Login = 'Login',
  Register = 'Register',
  RealEstate = 'RealEstate',
  SsoRedirect = 'SsoRedirect',
}

export enum ProjectNavigatorRoutes {
  Project = 'Project',
  ProjectList = 'ProjectList',
  RealEstate = 'RealEstate',
  Advisor = 'Advisor',
}

export enum RealEstateNavigatorRoutes {
  RealEstate = 'RealEstate',
  RealEstateList = 'RealEstateList',
  AdvisorAppointment = 'AdvisorAppointment',
  Suggestions = 'Suggestions',
}

export enum AdvisorNavigatorRoutes {
  AdvisorRoot = 'AdvisorRoot',
  AdvisorAppointment = 'AdvisorAppointment',
}

export enum SuggestionNavigatorRoutes {
  SuggestionList = 'SuggestionList',
  RealEstate = 'RealEstate',
}

export enum ForgotPasswordNavigatorRoutes {
  ForgotPasswordEmailStep = 'ForgotPasswordPasswordEmailStep',
  ForgotPasswordCodeStep = 'ForgotPasswordCodeStep',
  ForgotPasswordNewPasswordStep = 'ForgotPasswordNewPasswordStep',
}

export enum DebugMenuNavigatorRoutes {
  DebugMenuRoot = 'DebugMenuRoot',
  DebugMenuCodepush = 'DebugMenuCodepush',
  DebugMenuDevice = 'DebugMenuDevice',
  DebugMenuEnvironmentVariables = 'DebugMenuEnvironmentVariables',
  DebugMenuAsyncStorage = 'DebugMenuAsyncStorage',
  DebugMenuTranslations = 'DebugMenuTranslations',
  DebugMenuTracking = 'DebugMenuTracking',
  DebugMenuCustomer = 'DebugMenuCustomer',
  DebugMenuFeatureFlags = 'DebugMenuFeatureFlags',
}

export enum ProfileNavigatorRoutes {
  ProfileRoot = 'ProfileRoot',
  ProfileDocumentsList = 'ProfileDocumentsList',
  ProfileInformation = 'ProfileInformation',
  ProfileLang = 'ProfileLang',
  ProfilePassword = 'ProfilePassword',
  ProfileSponsorship = 'ProfileSponsorship',
  ProfileDocument = 'ProfileDocument',
  ProfileBetaTesters = 'ProfileBetaTesters',
  ProfileNotificationPreferences = 'ProfileNotificationPreferences',
  DebugMenu = 'DebugMenu',
  Auth = 'Auth',
}

export type Routes =
  | DebugMenuNavigatorRoutes
  | PublicNavigatorRoutes
  | PrivateNavigatorRoutes
  | ProjectNavigatorRoutes
  | RealEstateNavigatorRoutes
  | ProfileNavigatorRoutes
  | ForgotPasswordNavigatorRoutes
  | AdvisorNavigatorRoutes
  | MainMenuNavigatorRoutes
  | SuggestionNavigatorRoutes;

export type AdvisorStackParamList = {
  [AdvisorNavigatorRoutes.AdvisorRoot]: undefined;
  [AdvisorNavigatorRoutes.AdvisorAppointment]: undefined;
};

export type ForgotPasswordStackParamList = {
  [ForgotPasswordNavigatorRoutes.ForgotPasswordEmailStep]: { email: string };
  [ForgotPasswordNavigatorRoutes.ForgotPasswordCodeStep]: { email: string };
  [ForgotPasswordNavigatorRoutes.ForgotPasswordNewPasswordStep]: { hash: string; email: string };
};

export type DebugMenuStackParamList = {
  [DebugMenuNavigatorRoutes.DebugMenuRoot]: undefined;
  [DebugMenuNavigatorRoutes.DebugMenuCodepush]: undefined;
  [DebugMenuNavigatorRoutes.DebugMenuDevice]: undefined;
  [DebugMenuNavigatorRoutes.DebugMenuEnvironmentVariables]: undefined;
  [DebugMenuNavigatorRoutes.DebugMenuAsyncStorage]: undefined;
  [DebugMenuNavigatorRoutes.DebugMenuTranslations]: undefined;
  [DebugMenuNavigatorRoutes.DebugMenuTracking]: undefined;
  [DebugMenuNavigatorRoutes.DebugMenuCustomer]: undefined;
  [DebugMenuNavigatorRoutes.DebugMenuFeatureFlags]: undefined;
};

export type RealEstatesStackParamList = {
  [RealEstateNavigatorRoutes.RealEstate]: {
    id: string;
    isFromSearchEngine?: boolean;
    isFromNotifications?: boolean;
    propertyPosition?: number;
    threshold?: number;
    position?: number;
    section?: SearchEngineSection;
    currentScrollPosition?: number;
  };
  [RealEstateNavigatorRoutes.RealEstateList]: {
    isFromNotifications?: boolean;
    position: number;
    threshold: number;
    section?: SearchEngineSection;
  };
  [RealEstateNavigatorRoutes.AdvisorAppointment]: undefined;
};

export type ProjectsStackParamList = {
  [ProjectNavigatorRoutes.Project]: { id: string };
  [ProjectNavigatorRoutes.ProjectList]: undefined;
  [ProjectNavigatorRoutes.RealEstate]: { id: string };
};

export type SuggestionsStackParamList = {
  [SuggestionNavigatorRoutes.SuggestionList]: undefined;
  [SuggestionNavigatorRoutes.RealEstate]: { id: string };
};

export type MainMenuStackParamList = {
  [MainMenuNavigatorRoutes.Advisor]: NavigatorScreenParams<AdvisorStackParamList>;
  [MainMenuNavigatorRoutes.RealEstates]: NavigatorScreenParams<RealEstatesStackParamList>;
  [MainMenuNavigatorRoutes.Projects]: NavigatorScreenParams<ProjectsStackParamList>;
  [MainMenuNavigatorRoutes.Profile]: undefined;
  [MainMenuNavigatorRoutes.Suggestions]: undefined;
  [MainMenuNavigatorRoutes.HomePage]: undefined;
};

export type PrivateStackParamList = {
  [PrivateNavigatorRoutes.Onboarding]: undefined;
  [PrivateNavigatorRoutes.MainMenu]: NavigatorScreenParams<MainMenuStackParamList>;
};

export type PublicStackParamList = {
  [PublicNavigatorRoutes.Auth]: { deletedAccount?: boolean };
  [PublicNavigatorRoutes.ForgotPassword]: NavigatorScreenParams<ForgotPasswordStackParamList>;
  [PublicNavigatorRoutes.Login]: { email?: string; propertyId?: string };
  [PublicNavigatorRoutes.Register]: {
    email?: string;
    ['first-name']?: string;
    idToken?: string;
    ['last-name']?: string;
    method?: string;
    strategy?: string;
    propertyId?: string;
    sub?: string;
  };
  [PublicNavigatorRoutes.RealEstate]: { id: string };
  [PublicNavigatorRoutes.SsoRedirect]: {
    id?: string;
    idToken?: string;
    code?: string;
    type?: string;
    email?: string;
  };
};

export type ProfileStackParamList = {
  [ProfileNavigatorRoutes.ProfileRoot]: undefined;
  [ProfileNavigatorRoutes.ProfileDocumentsList]: {
    documents: Document[];
  };
  [ProfileNavigatorRoutes.ProfileDocument]: { id: string };
  [ProfileNavigatorRoutes.ProfileInformation]: undefined;
  [ProfileNavigatorRoutes.ProfileBetaTesters]: undefined;
  [ProfileNavigatorRoutes.ProfileLang]: undefined;
  [ProfileNavigatorRoutes.ProfilePassword]: undefined;
  [ProfileNavigatorRoutes.ProfileNotificationPreferences]: undefined;
  [ProfileNavigatorRoutes.ProfileSponsorship]: undefined;
  [ProfileNavigatorRoutes.DebugMenu]: undefined;
  [ProfileNavigatorRoutes.Auth]: { deletedAccount?: boolean };
};
