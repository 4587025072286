import React, { PropsWithChildren } from 'react';

import { useResponsive } from '@masteos/aphrodite';

import { useMe } from '@app/shared/hooks/useMe';

import { MobileNavigation } from './MobileNavigation.web';
import { DesktopNavigation } from './DesktopNavigation.web';

export type MainNavigationWebProps = PropsWithChildren<{}>;
export const MainNavigation: React.FC<MainNavigationWebProps> = ({ children, ...props }) => {
  const { customer } = useMe();
  const { isLowerThanLaptop } = useResponsive();

  const Nav = isLowerThanLaptop ? MobileNavigation : DesktopNavigation;

  if (!customer) {
    return <>{children}</>;
  }

  return <Nav {...props}>{children}</Nav>;
};
