import React, { useMemo } from 'react';

import { Control, Controller } from 'react-hook-form';
import { StyleSheet } from 'react-native';

import { Input, Spacer, Text, Theme, useTheme } from '@masteos/aphrodite';

import { useTranslation } from '@app/services/translations/translations';

import { ProfileInformationForm } from './hooks/useProfileInformation';

interface ProfileInformationPaymentProps {
  control: Control<ProfileInformationForm, unknown>;
  getIbanHint: (v: string) => string;
}

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    informationLabel: {
      color: theme.palette.neutral[700],
      textAlign: 'center',
    },
  });

export const ProfileInformationPayment: React.FC<ProfileInformationPaymentProps> = ({
  control,
  getIbanHint,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = useMemo(() => getStyles(theme), [theme]);

  return (
    <>
      <Text textStyle="Headline3Medium" style={styles.informationLabel}>
        {t('profile.paymentInformations')}
      </Text>
      <Spacer height={theme.spacing.SIZE_07} />

      <Controller
        name="ibanHolder"
        control={control}
        rules={{ deps: ['iban'] }}
        render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
          <Input
            label={t('profile.ibanHolder.label')}
            hint={t('profile.ibanHolder.hint')}
            value={value}
            onChangeText={onChange}
            error={error?.message}
            onBlur={onBlur}
          />
        )}
      />
      <Spacer height={theme.spacing.SIZE_04} />
      <Controller
        name="iban"
        control={control}
        rules={{ deps: ['ibanHolder'] }}
        render={({ field: { onChange, value, onBlur }, fieldState: { error, isTouched } }) => (
          <Input
            label={t('profile.iban.label')}
            placeholder={t('profile.iban.placeholder')}
            hint={getIbanHint(value)}
            value={value}
            onChangeText={onChange}
            error={!!error && t(error?.message, { hint: getIbanHint(value) })}
            isValid={!error && !!value && isTouched}
            onBlur={onBlur}
          />
        )}
      />
      <Spacer height={theme.spacing.SIZE_09} />
    </>
  );
};
