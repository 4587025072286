import React from 'react';

import { LocationType, TransactionStatus as ProjectStatus } from '@app/libs/apollo/introspection';
import { useTranslation } from '@app/services/translations/translations';
import { useRealEstateTitle } from '@app/shared/hooks/usePropertyTitle';
import { useRealEstateChips } from '@app/shared/hooks/useRealEstateChips';

import { InProgressProjectCard } from './InProgressProjectCard';
import { FinishedProjectCard } from './FinishedProjectCard';
import { CanceledProjectCard } from './CanceledProjectCard';
import { ProjectCardRealEstate } from './projectCard.types';

interface ProjectCardProps {
  status: ProjectStatus;
  projectAmount: number;
  rentingType?: LocationType;
  realEstate: ProjectCardRealEstate;
  onPress: () => void;
}

export const ProjectCard: React.FC<ProjectCardProps> = ({
  projectAmount,
  rentingType,
  status,
  realEstate,
  onPress,
}) => {
  const { t } = useTranslation();
  const { street, city, country, postalCode } = realEstate.building.address;
  const address = `${street}, ${t('propertyInfo.address', {
    city,
    country,
    postalCode,
  })}`;
  const realEstateTitle = useRealEstateTitle(realEstate, rentingType);
  const realEstateChips = useRealEstateChips(realEstate);

  switch (status) {
    case ProjectStatus.Ongoing:
      return (
        <InProgressProjectCard
          projectAmount={projectAmount}
          address={address}
          coverImage={realEstate.coverImage}
          title={realEstateTitle}
          chips={realEstateChips}
          onPress={onPress}
        />
      );
    case ProjectStatus.Finished:
      return (
        <FinishedProjectCard
          onPress={onPress}
          address={address}
          title={realEstateTitle}
          coverImage={realEstate.coverImage}
        />
      );
    case ProjectStatus.Canceled:
      return (
        <CanceledProjectCard
          address={address}
          title={realEstateTitle}
          coverImage={realEstate.coverImage}
        />
      );
    default:
      return null;
  }
};
