import React, { useMemo, useState } from 'react';

import { View, StyleSheet } from 'react-native';

import { palette, Text, Icon, Pressable } from '@masteos/aphrodite';

interface NavigationHeaderProps {
  onBack?: () => void;
  title: string;
  totalSteps?: number;
  currentStep?: number;
  paddingHorizontalTitle?: number;
}

const getStyles = ({ paddingHorizontalTitle }: { paddingHorizontalTitle: number }) =>
  StyleSheet.create({
    backButton: {
      height: 56,
      justifyContent: 'center',
      width: 44,
    },
    container: {
      justifyContent: 'center',
      paddingHorizontal: paddingHorizontalTitle,
    },
    subContainer: {
      borderRadius: 10,
      overflow: 'hidden',
    },
    subSubContainer: {
      alignItems: 'center',
      flexDirection: 'row',
      height: 56,
      justifyContent: 'space-between',
      width: '100%',
    },
    subSubSubContainer: {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    totalStepContainer: {
      alignItems: 'flex-end',
      height: 55,
      justifyContent: 'center',
      width: 50,
    },
  });

export const NavigationHeader: React.FC<NavigationHeaderProps> = ({
  onBack,
  title,
  totalSteps,
  currentStep,
  paddingHorizontalTitle = 22,
}) => {
  const [backButtonPressed, setBackButtonPressed] = useState<boolean>(false);

  const styles = useMemo(() => getStyles({ paddingHorizontalTitle }), [paddingHorizontalTitle]);

  return (
    <View style={styles.container}>
      <View style={styles.subContainer}>
        <View style={styles.subSubContainer}>
          <View style={styles.subSubSubContainer}>
            {!!onBack && (
              <Pressable
                onPressIn={() => setBackButtonPressed(true)}
                onPressOut={() => setBackButtonPressed(false)}
                onPress={onBack}
                style={styles.backButton}
              >
                <Icon
                  name="ArrowLeft"
                  size={24}
                  color={backButtonPressed ? palette.neutral[600] : palette.base.black}
                />
              </Pressable>
            )}
            <Text textStyle="Headline2Medium" style={{ color: palette.base.black }}>
              {title}
            </Text>
          </View>
          <View style={styles.totalStepContainer}>
            {!!totalSteps && (
              <Text textStyle="Headline2Medium" style={{ color: palette.base.black }}>
                {currentStep + 1}/{totalSteps}
              </Text>
            )}
          </View>
        </View>
      </View>
    </View>
  );
};
