import { useContext } from 'react';

import {
  FeatureFlagsContext,
  FeatureFlagsContextType,
} from '../contexts/featureFlags/FeatureFlagsProvider';
import { useMe } from './useMe';

/**
 * @deprecated use the methode isEnabled from the new hook useFeatureFlags
 * Feature flags are no longuer part of customer object and are retrieved sparately ( for unathenticated user for instence )
 * @param flagName
 * @returns
 */
export const useFeatureFlag = (flagName: string): boolean => {
  const { customer } = useMe();

  return customer?.account?.enabledFeatureFlags.includes(flagName);
};

export const useFeatureFlags = (): FeatureFlagsContextType => {
  return useContext(FeatureFlagsContext);
};
