import React, { useCallback, useContext, useMemo } from 'react';

import { LayoutChangeEvent, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { useReactiveVar } from '@apollo/client';

import { Button, Gap, useResponsive, useTheme } from '@masteos/aphrodite';

import { PositioningStatus, RealEstate } from '@app/libs/apollo/introspection';
import { StateOfCustomer, useMe } from '@app/shared/hooks/useMe';
import { getVisibilityRights } from '@app/utils/property';
import { PropertyLoginButtons } from '@app/features/propertyActions/PropertyLoginButtons.web';
import { ModalKey } from '@app/shared/contexts/modal-manager/modalManager.types';
import { ModalContext } from '@app/shared/contexts/modal-manager/ModalManagerProvider';
import { trackPositioningSeeMore } from '@app/services/tracking/trackTracking';
import { SuggestionsSourcePage } from '@app/services/tracking/constants';

import { MissingDocumentModal } from './MissingDocumentModal';
import { PositioningModal } from './PositioningModal';
import { PropertyAppointmentButton } from '../propertyActions/PropertyAppointmentButton';
import { getStyles } from './PropertyInfoBottomButtons.style';
import { getMissingDocumentList } from './PropertyInfoBottomButtons.utils';
import { usePositioningActions } from '../suggestions/hooks/usePositioningActions';
import { suggestionsCallLaunched } from '../suggestions/Suggestions.utils';

export interface PropertyInfoBottomButtonsProps {
  handleOnLayout?: (event: LayoutChangeEvent) => void;
  property: RealEstate;
}

export const PropertyInfoBottomButtons: React.FC<PropertyInfoBottomButtonsProps> = props => {
  const { t } = useTranslation();
  const { openModal } = useContext(ModalContext);
  const { handleOnLayout, property } = props;
  const { customer, stateOfCustomer } = useMe();
  const { canSeeLoginButton } = getVisibilityRights(customer);
  const { isNativeOrResponsive, isLowerThanDesktop, isLowerThanLaptop } = useResponsive();
  const isNativeOrLowerThanDesktop = isNativeOrResponsive || isLowerThanDesktop;

  const theme = useTheme();
  const styles = useMemo(
    () =>
      getStyles(
        theme,
        !!customer?.id,
        isNativeOrLowerThanDesktop,
        isLowerThanLaptop,
        isNativeOrResponsive
      ),
    [theme, customer?.id, isNativeOrLowerThanDesktop, isLowerThanLaptop, isNativeOrResponsive]
  );
  const launchedCall = useReactiveVar(suggestionsCallLaunched);

  const {
    handleApprovePositioning,
    handleCancelPositioning,
    handleNeedCallAfterConfirmPositioning,
  } = usePositioningActions();

  const missingDocumentList = useMemo(() => getMissingDocumentList(customer), [customer]);

  const missingDocumentModalDelay = 500;
  const openMissingDocumentModal = useCallback(() => {
    if (missingDocumentList.length) {
      setTimeout(
        () =>
          openModal(
            ModalKey.MissingDocument,
            <MissingDocumentModal documentList={missingDocumentList} />,
            {
              position: isNativeOrLowerThanDesktop ? 'bottom' : 'center',
              title: t('positioning.missingDocumentModal.title'),
            }
          ),
        missingDocumentModalDelay
      );
    }
  }, [missingDocumentList, isNativeOrLowerThanDesktop, openModal, t]);

  const openPositioningModal = useCallback(() => {
    openModal(
      ModalKey.Positioning,
      <PositioningModal
        realEstateId={property.id}
        callbackAfterConfirm={openMissingDocumentModal}
      />,
      {
        title: t('positioning.positioningModal.title'),
        webSize: 's',
      }
    );
  }, [property, openMissingDocumentModal, openModal, t]);

  const handleOnClick = useCallback(() => {
    openPositioningModal();
    trackPositioningSeeMore(property.id);
  }, [openPositioningModal, property.id]);

  const positioning = property.metadata?.positioning;
  const positioningStatus = positioning?.status;
  const sourcePage = SuggestionsSourcePage.PROPERTY_PAGE;

  if (canSeeLoginButton) {
    return (
      <View testID="login-buttons" style={styles.container} onLayout={handleOnLayout}>
        <View style={styles.buttonsContainerWrapper}>
          <PropertyLoginButtons />
        </View>
      </View>
    );
  }

  if (stateOfCustomer === StateOfCustomer.PRE_APPOINTMENT) {
    return (
      <View
        style={[styles.container, styles.preAppointment]}
        onLayout={handleOnLayout}
        testID="pre-appointment-button"
      >
        <PropertyAppointmentButton propertyId={property.id} />
      </View>
    );
  }

  if (
    !positioningStatus ||
    [PositioningStatus.PrePositioningCancelled, PositioningStatus.Cancelled].includes(
      positioningStatus
    )
  ) {
    return (
      <View style={styles.container} onLayout={handleOnLayout}>
        <View style={styles.buttonsContainerWrapper}>
          <Button
            onPress={handleOnClick}
            testID="button-interested-without-suggestion"
            iconName="ThumbsUp"
            iconPlacement="left"
            wrapperStyle={styles.buttonMobileFullWidthWrapperStyle}
          >
            {t('suggestions.suggestionCard.actions.interested')}
          </Button>
        </View>
      </View>
    );
  }

  const onPressCancelPositioning = () =>
    handleCancelPositioning(positioning.id, positioning.isSuggestion)(sourcePage);
  const onPressNeedCallAfterConfirmPositioning = () =>
    handleNeedCallAfterConfirmPositioning(positioning.id)(sourcePage);
  const onPressApprovePositioning = () =>
    handleApprovePositioning(positioning.id, property.id)(sourcePage, positioning?.isSuggestion);

  const shouldShowNotInterestedButton = [
    PositioningStatus.Recommendation,
    PositioningStatus.Pending,
  ].includes(positioningStatus);

  const labelForNotInterestedButton =
    positioningStatus !== PositioningStatus.Recommendation
      ? 'suggestions.suggestionCard.actions.noLongerInterested'
      : 'suggestions.suggestionCard.actions.notInterested';

  return (
    <View style={styles.container} onLayout={handleOnLayout}>
      <Gap gap={10} style={styles.buttonsContainerWrapper}>
        {!!shouldShowNotInterestedButton && (
          <Button
            onPress={onPressCancelPositioning}
            testID="button-not-interested"
            iconName="ThumbsDown"
            iconPlacement="left"
            variant="tertiary"
            textStyle={styles.buttonNotInterestedTextStyle}
            size="m"
          >
            {!isNativeOrLowerThanDesktop ? t(labelForNotInterestedButton) : ''}
          </Button>
        )}
        {positioningStatus == PositioningStatus.Confirmed ? (
          <Button
            onPress={onPressNeedCallAfterConfirmPositioning}
            variant="secondary"
            iconName="Phone"
            iconPlacement="left"
            disabled={launchedCall.includes(positioning.id)}
            testID="button-need-call-after-confirm"
            wrapperStyle={styles.buttonMobileFullWidthWrapperStyle}
            size="m"
          >
            {t('suggestions.suggestionCard.actions.needCallAfterConfirm', {
              advisorName: customer.advisor.firstName,
            })}
          </Button>
        ) : (
          ![PositioningStatus.Cancelled, PositioningStatus.PrePositioningCancelled].includes(
            positioningStatus
          ) && (
            <Button
              disabled={positioningStatus !== PositioningStatus.Recommendation}
              onPress={onPressApprovePositioning}
              testID="button-interested-from-suggestion"
              iconName={positioningStatus === PositioningStatus.Recommendation ? 'ThumbsUp' : null}
              iconPlacement="left"
              wrapperStyle={styles.buttonMobileFullWidthWrapperStyle}
              size="m"
            >
              {positioningStatus !== PositioningStatus.Recommendation
                ? t('shared.advisorContacted')
                : t('suggestions.suggestionCard.actions.interested')}
            </Button>
          )
        )}
      </Gap>
    </View>
  );
};
